import getIqResult from '../getIqResult.js';


 
const addOptionVariants = (chosenOptions) => {
    let wasTheSame = false;
    let wasTheSameCount = 0;

    for (let i = 0; i < chosenOptions.length - 1; i++) {
       const a = chosenOptions[i].split('.')[0];
       const b = chosenOptions[i + 1].split('.')[0];

       if (a === b) {
          if (wasTheSameCount > 1) {
             wasTheSameCount = -1;
          }

          if (!wasTheSame) {
             chosenOptions[i] += `.${wasTheSameCount}`;
             chosenOptions[i + 1] += `.${wasTheSameCount + 1}`;
          }

          if (wasTheSame) {
             chosenOptions[i + 1] += `.${wasTheSameCount + 1}`;
          }

          wasTheSame = true;
          wasTheSameCount++;
       } else {
          wasTheSameCount = 0;

          if (!wasTheSame && i === 0) {
             chosenOptions[i] += '.0';
             chosenOptions[i + 1] += '.0';
          }

          if (wasTheSame && i > 0) {
             chosenOptions[i + 1] += '.0';
          }

          if (!wasTheSame && i > 0) {
             chosenOptions[i + 1] += '.0';
          }

          wasTheSame = false;
       }
    }
    return chosenOptions;
 };


 


 const showOpinion = (opinionId, alldata) => {
    let data = alldata.sections;
    let [chosenOption, chosenSection, chosenVariant] = opinionId.split('.');
    return data[chosenSection].options[chosenOption][chosenVariant];
 };


// IDS-2 kompetencje /  IDS-2 przesiewowy /  IDS-2 surowy-przesiewowy
export const makeSentences = (chosenOptions, alldata, iqPrzeliczone, ids2DataIqOgolny, dataBadania) => {

   console.log(chosenOptions, alldata, iqPrzeliczone, ids2DataIqOgolny, dataBadania)

    const chosenOptionsWithVariants = addOptionVariants(chosenOptions);
    let sentencesArr = chosenOptionsWithVariants.map(opinionId => showOpinion(opinionId, alldata));


    if (iqPrzeliczone){
       sentencesArr.unshift(getIqResult(iqPrzeliczone, ids2DataIqOgolny).replaceAll("{{dataBadania}}", formatDate(dataBadania)));
    } 

    let sentences = new Intl.ListFormat('en', {
       style: 'narrow',
       type: 'unit',
    }).format(sentencesArr);

    return sentences;
 };





const getSentencecByTitleAndPoints = (detailsRaw, chosenOptions, allData) => {
   detailsRaw.forEach(detail => {
       const option = chosenOptions.find(o => parseInt(o.split('.')[1], 10) === detail.id);
       if (option) {
           const points = parseInt(option.split('.')[0], 10);
           detail.value = points;
       }
   });


   const resultsArray = [];

   const sortedDetailsRaw = detailsRaw.sort((a, b) => b.value - a.value);

   sortedDetailsRaw.forEach(detail => {
       const options = allData.options.filter(option => option.title === detail.name);
       options.forEach(option => {
           const rangeStart = Math.min(...option.range);
           const rangeEnd = Math.max(...option.range);
           if (detail.value >= rangeStart && detail.value <= rangeEnd) {
               resultsArray.push(option.result);
           }
       });
   });

   return resultsArray;
}






const expressionsTeenager = ["{{chłopca|dziewczynki}}", "{{Chłopiec|Dziewczynka}}", "{{Dziecko}}"];
const expressionsChild = ["{{Nastolatek|Nastolatka}}", "{{nastolatka|nastolatki}}"];

function removeSentencesWithExpressions(resultsArray, expressions) {
   for (let i = 0; i < resultsArray.length; i++) {
       resultsArray[i] = resultsArray[i].filter(sentence => {
           return !expressions.some(expression => sentence.includes(expression));
       });
   }
   return resultsArray;
}


function getRandomSenteces(nestedArray) {
   const selectedElements = [];

   for (let i = 0; i < nestedArray.length; i++) {
       if (Array.isArray(nestedArray[i])) {
           const randomIndex = Math.floor(Math.random() * nestedArray[i].length);
           selectedElements.push(nestedArray[i][randomIndex]);
       } else {
           selectedElements.push(nestedArray[i]);
       }
   }

   return selectedElements;
}



// IDS-2 ogolny /  IDS-2 surowy-ogolny
 export const makeSentencesDynamic =  (chosenOptions, alldata, iqPrzeliczone, ids2DataIqOgolny, dataBadania, dataWiek, detailsRaw) => {
  
   let cleanedArray = []
   let sentencesArr =  getSentencecByTitleAndPoints(detailsRaw, chosenOptions, alldata)
   const isChild = dataWiek.wiek.years < 11;
   const isTeenager = dataWiek.wiek.years >= 11 && dataWiek.wiek.years <= 19;
   const isAdult = dataWiek.wiek.years >19;

   if(isTeenager){
      cleanedArray = removeSentencesWithExpressions(sentencesArr, expressionsTeenager)
   }
   if(isChild){
      cleanedArray = removeSentencesWithExpressions(sentencesArr, expressionsChild)
   }
   if(isAdult){
      cleanedArray = removeSentencesWithExpressions(sentencesArr, expressionsChild)
      cleanedArray = removeSentencesWithExpressions(sentencesArr, expressionsTeenager)
   }
   
   sentencesArr = getRandomSenteces(cleanedArray)
  

    if (iqPrzeliczone){
       sentencesArr.unshift(getIqResult(iqPrzeliczone, ids2DataIqOgolny).replaceAll("{{dataBadania}}", formatDate(dataBadania)));
    } 

    let sentences = new Intl.ListFormat('en', {
       style: 'narrow',
       type: 'unit',
    }).format(sentencesArr);

    return sentences;
 };



// Helper function to validate input arrays
const validateInput = (array, name = 'Input') => {
   if (!Array.isArray(array)) {
     console.error(`${name} must be an array`);
     return false;
   }
   if (array.length === 0) {
     console.error(`${name} cannot be empty`);
     return false;
   }
   return true;
 };
 




 // Clean sentences with proper validation
 const cleanSentences = (sentences) => {
   if (!validateInput(sentences)) {
     return [];
   }
   
   return sentences.map(sentence => {
     if (typeof sentence !== 'string') {
       console.warn('Non-string value in sentences array:', sentence);
       return '';
     }
     return sentence.trim()
       .replace(/\s+/g, ' ')
       .replace(/\n\s+/g, '\n')
       .replace(/\s+\n/g, '\n')
       .replace(/\n+/g, '\n');
   }).filter(Boolean); // Remove empty strings
 };
 
 // IDS-P surowy-ogolny
 export const IDSPmakeSentences = (detailsRaw, detailsRawIq, datasentences, dataBadania) => {
   // Input validation
   if (!validateInput(detailsRaw, 'detailsRaw') || 
       !validateInput(detailsRawIq, 'detailsRawIq') || 
       !datasentences || 
       !dataBadania) {
     console.error('Invalid inputs to IDSPmakeSentences');
     return '';
   }
 
   let sentencesArr = [];
 
   // Pro// Process IQ płynna
  if (detailsRawIq[0]?.value && datasentences["iq płynna"]?.options) {
   const plynnaSentence = getIqResult(detailsRawIq[0].value, datasentences["iq płynna"].options);
   if (plynnaSentence && datasentences["iq płynna"]?.sentence) {
     sentencesArr.push(`${datasentences["iq płynna"].sentence} ${plynnaSentence}`);
   }
 }

 // Process IQ skrystalizowana
 if (detailsRawIq[1]?.value && datasentences["iq skrystalizowana"]?.options) {
   const skrystalizowanaSentence = getIqResult(detailsRawIq[1].value, datasentences["iq skrystalizowana"].options);
   if (skrystalizowanaSentence) {
     sentencesArr.push(skrystalizowanaSentence);
   }
 }

 // Process IQ ogólna
 if (detailsRawIq[2]?.value && datasentences["iq ogólne"]?.options) {
   const ogolnaSentence = getIqResult(detailsRawIq[2].value, datasentences["iq ogólne"].options);
   if (ogolnaSentence) {
     sentencesArr.push(ogolnaSentence);
   }
 }

 // Process IQ Płynna 1-7
 for (let i = 1; i < 8; i++) {
   if (detailsRaw[i-1]?.value && datasentences[i]?.options) {
     const sentence = getIqResult(detailsRaw[i-1].value, datasentences[i].options);
     if (sentence) {
       sentencesArr.push(sentence);
     }
   }
 }
 
   // Process remaining sections with proper section headers
   const sections = [
     { range: [7, 10], header: '\n\nUmiejętności psychomotoryczne\n' },
     { range: [10, 11], header: '\n\nKompetencje społeczno-emocjonalne\n' },
     { range: [11, 12], header: '\n\nMatematyka\n' },
     { range: [12, 15], header: '\n\nJęzyk\n' },
     { range: [15, 18], header: '\n\nMotywacja\n' }
   ];
 
   sections.forEach(section => {
     const hasValues = detailsRaw
       .slice(section.range[0], section.range[1])
       .some(item => item?.value);
     
       if (hasValues) {
         sentencesArr.push(section.header);
         for (let i = section.range[0] + 1; i <= section.range[1]; i++) {
           if (detailsRaw[i-1]?.value && datasentences[i]?.options) {
             const sentence = getIqResult(detailsRaw[i-1].value, datasentences[i].options);
             if (sentence) {
               sentencesArr.push(sentence);
             }
         }
       }
     }
   });
 
   // Clean and format sentences
   sentencesArr = cleanSentences(sentencesArr);
 
   // Join sentences with proper formatting
   let sentences = sentencesArr.join(' ');
 
   // Format date if present
   if (dataBadania && typeof dataBadania === 'object') {
     const formattedDate = formatDate(dataBadania);
     sentences = sentences.replaceAll("{{dataBadania}}", formattedDate);
   }
 
   return sentences;
 };
 
 // Helper function to format date
 const formatDate = (date) => {
   try {
     return new Intl.DateTimeFormat('pl-PL', {
       year: 'numeric',
       month: '2-digit',
       day: '2-digit'
     }).format(date);
   } catch (error) {
     console.error('Error formatting date:', error);
     return '';
   }
 };

function chooseRange(sentencesData, detailedSums) {

   // Convert detailedSums to an array and sort it by value in descending order
   const sortedDetailedSums = Object.entries(detailedSums)
   .sort((a, b) => b[1].iq - a[1].iq);


   // This will hold the final result arrays
   const results = [];
 
   // Iterate over each item in the sorted detailedSums
   for (const [sectionId, sectionData] of sortedDetailedSums) {

     // Find the corresponding section in sentencesData
     const section = sentencesData.sections[sectionId];

     
     // Check if the section exists
     if (section) {
       // Find the options range that the score falls into
       const option = section.options.find(option => 
         sectionData.iq >= option.range[0] && sectionData.iq <= option.range[1]
       );
 
       // If a matching range is found, add its result to the results array
       if (option) {
         const data = {range:option.range[0], sentences: option.result}
         results.push(data);
       }
     }
   }
 
   console.log("results:", results);
   return results;
 }





 function selectSentences(chosenSentences) {
   let lastIndexes = {}; 
   let selectedSentences = []; 

   chosenSentences.forEach(item => {
       let range = item.range;
       if (lastIndexes[range] === undefined) {
           lastIndexes[range] = -1;
       }

       let sentences = item.sentences;
       let index = (lastIndexes[range] + 1) % sentences.length;

       selectedSentences.push(sentences[index]); 
       lastIndexes[range] = index; 
   });

   return selectedSentences;
}


// IDS-2 pelny / IDS-2 surowy-pelny
 export const makeSentencesFull = (detailedSums, sentencesData, iqPrzeliczone, ids2DataIqOgolny, dataBadania) => {

   const chosenSentences = chooseRange(sentencesData, detailedSums);
   const sentencesArr = selectSentences(chosenSentences);

   if (iqPrzeliczone){
      sentencesArr.unshift(getIqResult(iqPrzeliczone, ids2DataIqOgolny).replaceAll("{{dataBadania}}", formatDate(dataBadania)));
   } 

   let sentences = new Intl.ListFormat('en', {
      style: 'narrow',
      type: 'unit',
   }).format(sentencesArr);

   return sentences;
};



 