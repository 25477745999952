const wiscvRaw = {
    6063: [
        {
          "name": "Wzory z klocków",
          "id": 1,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": 1,
              "max": 1
            },
            "3": {
              "min": 2,
              "max": 2
            },
            "4": {
              "min": 3,
              "max": 5
            },
            "5": {
              "min": 6,
              "max": 7
            },
            "6": {
              "min": 8,
              "max": 9
            },
            "7": {
              "min": 10,
              "max": 12
            },
            "8": {
              "min": 13,
              "max": 14
            },
            "9": {
              "min": 15,
              "max": 16
            },
            "10": {
              "min": 17,
              "max": 18
            },
            "11": {
              "min": 19,
              "max": 21
            },
            "12": {
              "min": 22,
              "max": 23
            },
            "13": {
              "min": 24,
              "max": 25
            },
            "14": {
              "min": 26,
              "max": 27
            },
            "15": {
              "min": 28,
              "max": 29
            },
            "16": {
              "min": 30,
              "max": 31
            },
            "17": {
              "min": 32,
              "max": 33
            },
            "18": {
              "min": 34,
              "max": 35
            },
            "19": {
              "min": 36,
              "max": 58
            }
          }
        },
        {
          "name": "Podobieństwa",
          "id": 2,
          "rows": {
            "1": {
              "min": null,
              "max": null
            },
            "2": {
              "min": null,
              "max": null
            },
            "3": {
              "min": null,
              "max": null
            },
            "4": {
              "min": 0,
              "max": 0
            },
            "5": {
              "min": 1,
              "max": 1
            },
            "6": {
              "min": 2,
              "max": 2
            },
            "7": {
              "min": 3,
              "max": 4
            },
            "8": {
              "min": 5,
              "max": 7
            },
            "9": {
              "min": 8,
              "max": 9
            },
            "10": {
              "min": 10,
              "max": 12
            },
            "11": {
              "min": 13,
              "max": 15
            },
            "12": {
              "min": 16,
              "max": 18
            },
            "13": {
              "min": 19,
              "max": 22
            },
            "14": {
              "min": 23,
              "max": 25
            },
            "15": {
              "min": 26,
              "max": 28
            },
            "16": {
              "min": 29,
              "max": 30
            },
            "17": {
              "min": 31,
              "max": 33
            },
            "18": {
              "min": 34,
              "max": 35
            },
            "19": {
              "min": 36,
              "max": 46
            }
          }
        },
        {
          "name": "Matryce",
          "id": 3,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": null,
              "max": null
            },
            "3": {
              "min": 1,
              "max": 1
            },
            "4": {
              "min": 2,
              "max": 2
            },
            "5": {
              "min": 3,
              "max": 4
            },
            "6": {
              "min": 5,
              "max": 5
            },
            "7": {
              "min": 6,
              "max": 7
            },
            "8": {
              "min": 8,
              "max": 8
            },
            "9": {
              "min": 9,
              "max": 9
            },
            "10": {
              "min": 10,
              "max": 11
            },
            "11": {
              "min": 12,
              "max": 12
            },
            "12": {
              "min": 13,
              "max": 13
            },
            "13": {
              "min": 14,
              "max": 15
            },
            "14": {
              "min": 16,
              "max": 16
            },
            "15": {
              "min": 17,
              "max": 18
            },
            "16": {
              "min": 19,
              "max": 19
            },
            "17": {
              "min": 20,
              "max": 20
            },
            "18": {
              "min": 21,
              "max": 22
            },
            "19": {
              "min": 23,
              "max": 32
            }
          }
        },
        {
          "name": "Powtarzanie liczb",
          "id": 4,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": null,
              "max": null
            },
            "3": {
              "min": 1,
              "max": 1
            },
            "4": {
              "min": 2,
              "max": 3
            },
            "5": {
              "min": 4,
              "max": 5
            },
            "6": {
              "min": 6,
              "max": 6
            },
            "7": {
              "min": 7,
              "max": 8
            },
            "8": {
              "min": 9,
              "max": 9
            },
            "9": {
              "min": 10,
              "max": 11
            },
            "10": {
              "min": 12,
              "max": 12
            },
            "11": {
              "min": 13,
              "max": 14
            },
            "12": {
              "min": 15,
              "max": 15
            },
            "13": {
              "min": 16,
              "max": 17
            },
            "14": {
              "min": 18,
              "max": 18
            },
            "15": {
              "min": 19,
              "max": 19
            },
            "16": {
              "min": 20,
              "max": 21
            },
            "17": {
              "min": 22,
              "max": 22
            },
            "18": {
              "min": 23,
              "max": 24
            },
            "19": {
              "min": 25,
              "max": 54
            }
          }
        },
        {
          "name": "Kodowanie",
          "id": 5,
          "rows": {
            "1": {
              "min": 0,
              "max": 1
            },
            "2": {
              "min": 2,
              "max": 2
            },
            "3": {
              "min": 3,
              "max": 4
            },
            "4": {
              "min": 5,
              "max": 6
            },
            "5": {
              "min": 7,
              "max": 9
            },
            "6": {
              "min": 10,
              "max": 12
            },
            "7": {
              "min": 13,
              "max": 16
            },
            "8": {
              "min": 17,
              "max": 20
            },
            "9": {
              "min": 21,
              "max": 23
            },
            "10": {
              "min": 24,
              "max": 27
            },
            "11": {
              "min": 28,
              "max": 31
            },
            "12": {
              "min": 32,
              "max": 34
            },
            "13": {
              "min": 35,
              "max": 37
            },
            "14": {
              "min": 38,
              "max": 40
            },
            "15": {
              "min": 41,
              "max": 43
            },
            "16": {
              "min": 44,
              "max": 46
            },
            "17": {
              "min": 47,
              "max": 48
            },
            "18": {
              "min": 49,
              "max": 51
            },
            "19": {
              "min": 52,
              "max": 75
            }
          }
        },
        {
          "name": "Słownik",
          "id": 6,
          "rows": {
            "1": {
              "min": 0,
              "max": 1
            },
            "2": {
              "min": 2,
              "max": 3
            },
            "3": {
              "min": 4,
              "max": 5
            },
            "4": {
              "min": 6,
              "max": 7
            },
            "5": {
              "min": 8,
              "max": 8
            },
            "6": {
              "min": 9,
              "max": 10
            },
            "7": {
              "min": 11,
              "max": 11
            },
            "8": {
              "min": 12,
              "max": 12
            },
            "9": {
              "min": 13,
              "max": 13
            },
            "10": {
              "min": 14,
              "max": 15
            },
            "11": {
              "min": 16,
              "max": 16
            },
            "12": {
              "min": 17,
              "max": 17
            },
            "13": {
              "min": 18,
              "max": 18
            },
            "14": {
              "min": 19,
              "max": 19
            },
            "15": {
              "min": 20,
              "max": 20
            },
            "16": {
              "min": null,
              "max": null
            },
            "17": {
              "min": 21,
              "max": 21
            },
            "18": {
              "min": 22,
              "max": 22
            },
            "19": {
              "min": 23,
              "max": 54
            }
          }
        },
        {
          "name": "Ważenie",
          "id": 7,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": 1,
              "max": 2
            },
            "3": {
              "min": 3,
              "max": 3
            },
            "4": {
              "min": 4,
              "max": 5
            },
            "5": {
              "min": 6,
              "max": 7
            },
            "6": {
              "min": 8,
              "max": 8
            },
            "7": {
              "min": 9,
              "max": 9
            },
            "8": {
              "min": 10,
              "max": 10
            },
            "9": {
              "min": 11,
              "max": 11
            },
            "10": {
              "min": 12,
              "max": 12
            },
            "11": {
              "min": 13,
              "max": 13
            },
            "12": {
              "min": 14,
              "max": 14
            },
            "13": {
              "min": 15,
              "max": 15
            },
            "14": {
              "min": 16,
              "max": 16
            },
            "15": {
              "min": 17,
              "max": 17
            },
            "16": {
              "min": 18,
              "max": 18
            },
            "17": {
              "min": 19,
              "max": 19
            },
            "18": {
              "min": 20,
              "max": 20
            },
            "19": {
              "min": 21,
              "max": 34
            }
          }
        },
        {
          "name": "Układnie wzoru",
          "id": 8,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": 1,
              "max": 1
            },
            "3": {
              "min": 2,
              "max": 2
            },
            "4": {
              "min": 3,
              "max": 3
            },
            "5": {
              "min": 4,
              "max": 4
            },
            "6": {
              "min": 5,
              "max": 5
            },
            "7": {
              "min": 6,
              "max": 6
            },
            "8": {
              "min": 7,
              "max": 7
            },
            "9": {
              "min": 8,
              "max": 9
            },
            "10": {
              "min": 10,
              "max": 10
            },
            "11": {
              "min": 11,
              "max": 11
            },
            "12": {
              "min": 12,
              "max": 12
            },
            "13": {
              "min": 13,
              "max": 14
            },
            "14": {
              "min": 15,
              "max": 15
            },
            "15": {
              "min": 16,
              "max": 16
            },
            "16": {
              "min": 17,
              "max": 18
            },
            "17": {
              "min": 19,
              "max": 19
            },
            "18": {
              "min": 20,
              "max": 20
            },
            "19": {
              "min": 21,
              "max": 29
            }
          }
        },
        {
          "name": "Zapamiętywanie obrazków",
          "id": 9,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 4
            },
            "3": {
              "min": 5,
              "max": 6
            },
            "4": {
              "min": 7,
              "max": 7
            },
            "5": {
              "min": 8,
              "max": 8
            },
            "6": {
              "min": 9,
              "max": 9
            },
            "7": {
              "min": 10,
              "max": 11
            },
            "8": {
              "min": 12,
              "max": 12
            },
            "9": {
              "min": 13,
              "max": 14
            },
            "10": {
              "min": 15,
              "max": 16
            },
            "11": {
              "min": 17,
              "max": 18
            },
            "12": {
              "min": 19,
              "max": 20
            },
            "13": {
              "min": 21,
              "max": 22
            },
            "14": {
              "min": 23,
              "max": 24
            },
            "15": {
              "min": 25,
              "max": 27
            },
            "16": {
              "min": 28,
              "max": 29
            },
            "17": {
              "min": 30,
              "max": 32
            },
            "18": {
              "min": 33,
              "max": 34
            },
            "19": {
              "min": 35,
              "max": 49
            }
          }
        },
        {
          "name": "Szukanie symboli",
          "id": 10,
          "rows": {
            "1": {
              "min": 0,
              "max": 1
            },
            "2": {
              "min": 2,
              "max": 2
            },
            "3": {
              "min": 3,
              "max": 3
            },
            "4": {
              "min": 4,
              "max": 5
            },
            "5": {
              "min": 6,
              "max": 7
            },
            "6": {
              "min": 8,
              "max": 9
            },
            "7": {
              "min": 10,
              "max": 12
            },
            "8": {
              "min": 13,
              "max": 15
            },
            "9": {
              "min": 16,
              "max": 17
            },
            "10": {
              "min": 18,
              "max": 20
            },
            "11": {
              "min": 21,
              "max": 22
            },
            "12": {
              "min": 23,
              "max": 24
            },
            "13": {
              "min": 25,
              "max": 27
            },
            "14": {
              "min": 28,
              "max": 29
            },
            "15": {
              "min": 30,
              "max": 31
            },
            "16": {
              "min": 32,
              "max": 32
            },
            "17": {
              "min": 33,
              "max": 34
            },
            "18": {
              "min": 35,
              "max": 36
            },
            "19": {
              "min": 37,
              "max": 42
            }
          }
        },
        {
          "name": "Wiadomości",
          "id": 11,
          "rows": {
            "1": {
              "min": 0,
              "max": 1
            },
            "2": {
              "min": 2,
              "max": 2
            },
            "3": {
              "min": 3,
              "max": 3
            },
            "4": {
              "min": 4,
              "max": 4
            },
            "5": {
              "min": 5,
              "max": 5
            },
            "6": {
              "min": 6,
              "max": 6
            },
            "7": {
              "min": null,
              "max": null
            },
            "8": {
              "min": 7,
              "max": 7
            },
            "9": {
              "min": 8,
              "max": 8
            },
            "10": {
              "min": 9,
              "max": 9
            },
            "11": {
              "min": 10,
              "max": 10
            },
            "12": {
              "min": null,
              "max": null
            },
            "13": {
              "min": 11,
              "max": 11
            },
            "14": {
              "min": 12,
              "max": 12
            },
            "15": {
              "min": 13,
              "max": 13
            },
            "16": {
              "min": 14,
              "max": 14
            },
            "17": {
              "min": null,
              "max": null
            },
            "18": {
              "min": 15,
              "max": 15
            },
            "19": {
              "min": 16,
              "max": 31
            }
          }
        },
        {
          "name": "Tworzenie pojęć",
          "id": 12,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": null,
              "max": null
            },
            "3": {
              "min": 1,
              "max": 1
            },
            "4": {
              "min": 2,
              "max": 2
            },
            "5": {
              "min": null,
              "max": null
            },
            "6": {
              "min": 3,
              "max": 3
            },
            "7": {
              "min": 4,
              "max": 4
            },
            "8": {
              "min": 5,
              "max": 6
            },
            "9": {
              "min": 7,
              "max": 7
            },
            "10": {
              "min": 8,
              "max": 8
            },
            "11": {
              "min": 9,
              "max": 9
            },
            "12": {
              "min": 10,
              "max": 11
            },
            "13": {
              "min": 12,
              "max": 12
            },
            "14": {
              "min": 13,
              "max": 13
            },
            "15": {
              "min": 14,
              "max": 15
            },
            "16": {
              "min": 16,
              "max": 16
            },
            "17": {
              "min": 17,
              "max": 18
            },
            "18": {
              "min": 19,
              "max": 19
            },
            "19": {
              "min": 20,
              "max": 27
            }
          }
        },
        {
          "name": "Porządkowanie liter i liczb",
          "id": 13,
          "rows": {
            "1": {
              "min": null,
              "max": null
            },
            "2": {
              "min": null,
              "max": null
            },
            "3": {
              "min": null,
              "max": null
            },
            "4": {
              "min": 0,
              "max": 0
            },
            "5": {
              "min": 1,
              "max": 1
            },
            "6": {
              "min": 2,
              "max": 2
            },
            "7": {
              "min": 3,
              "max": 3
            },
            "8": {
              "min": 4,
              "max": 4
            },
            "9": {
              "min": 5,
              "max": 5
            },
            "10": {
              "min": 6,
              "max": 7
            },
            "11": {
              "min": 8,
              "max": 8
            },
            "12": {
              "min": 9,
              "max": 10
            },
            "13": {
              "min": 11,
              "max": 11
            },
            "14": {
              "min": 12,
              "max": 13
            },
            "15": {
              "min": 14,
              "max": 15
            },
            "16": {
              "min": 16,
              "max": 17
            },
            "17": {
              "min": 18,
              "max": 18
            },
            "18": {
              "min": 19,
              "max": 20
            },
            "19": {
              "min": 21,
              "max": 30
            }
          }
        },
        {
          "name": "Wykreślanie",
          "id": 14,
          "rows": {
            "1": {
              "min": 0,
              "max": 2
            },
            "2": {
              "min": 3,
              "max": 5
            },
            "3": {
              "min": 6,
              "max": 9
            },
            "4": {
              "min": 10,
              "max": 14
            },
            "5": {
              "min": 15,
              "max": 19
            },
            "6": {
              "min": 20,
              "max": 24
            },
            "7": {
              "min": 25,
              "max": 29
            },
            "8": {
              "min": 30,
              "max": 34
            },
            "9": {
              "min": 35,
              "max": 39
            },
            "10": {
              "min": 40,
              "max": 43
            },
            "11": {
              "min": 44,
              "max": 48
            },
            "12": {
              "min": 49,
              "max": 52
            },
            "13": {
              "min": 53,
              "max": 56
            },
            "14": {
              "min": 57,
              "max": 60
            },
            "15": {
              "min": 61,
              "max": 63
            },
            "16": {
              "min": 64,
              "max": 67
            },
            "17": {
              "min": 68,
              "max": 71
            },
            "18": {
              "min": 72,
              "max": 74
            },
            "19": {
              "min": 75,
              "max": 128
            }
          }
        },
        {
          "name": "Rozumienie",
          "id": 15,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": null,
              "max": null
            },
            "3": {
              "min": 1,
              "max": 1
            },
            "4": {
              "min": 2,
              "max": 2
            },
            "5": {
              "min": 3,
              "max": 4
            },
            "6": {
              "min": 5,
              "max": 5
            },
            "7": {
              "min": 6,
              "max": 6
            },
            "8": {
              "min": 7,
              "max": 7
            },
            "9": {
              "min": 8,
              "max": 8
            },
            "10": {
              "min": 9,
              "max": 9
            },
            "11": {
              "min": 10,
              "max": 10
            },
            "12": {
              "min": 11,
              "max": 12
            },
            "13": {
              "min": 13,
              "max": 13
            },
            "14": {
              "min": 14,
              "max": 14
            },
            "15": {
              "min": 15,
              "max": 15
            },
            "16": {
              "min": 16,
              "max": 16
            },
            "17": {
              "min": 17,
              "max": 17
            },
            "18": {
              "min": 18,
              "max": 19
            },
            "19": {
              "min": 20,
              "max": 38
            }
          }
        },
        {
          "name": "Arytmetyka",
          "id": 16,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": 1,
              "max": 1
            },
            "3": {
              "min": 2,
              "max": 2
            },
            "4": {
              "min": 3,
              "max": 3
            },
            "5": {
              "min": 4,
              "max": 4
            },
            "6": {
              "min": 5,
              "max": 5
            },
            "7": {
              "min": 6,
              "max": 6
            },
            "8": {
              "min": 7,
              "max": 7
            },
            "9": {
              "min": 8,
              "max": 8
            },
            "10": {
              "min": 9,
              "max": 9
            },
            "11": {
              "min": 10,
              "max": 10
            },
            "12": {
              "min": 11,
              "max": 11
            },
            "13": {
              "min": 12,
              "max": 12
            },
            "14": {
              "min": null,
              "max": null
            },
            "15": {
              "min": 13,
              "max": 13
            },
            "16": {
              "min": 14,
              "max": 14
            },
            "17": {
              "min": 15,
              "max": 15
            },
            "18": {
              "min": 16,
              "max": 16
            },
            "19": {
              "min": 17,
              "max": 34
            }
          }
        }
      ], 

    6467: [
        {
          "name": "Wzory z klocków",
          "id": 1,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": 1,
              "max": 1
            },
            "3": {
              "min": 2,
              "max": 3
            },
            "4": {
              "min": 4,
              "max": 5
            },
            "5": {
              "min": 6,
              "max": 8
            },
            "6": {
              "min": 9,
              "max": 10
            },
            "7": {
              "min": 11,
              "max": 12
            },
            "8": {
              "min": 13,
              "max": 15
            },
            "9": {
              "min": 16,
              "max": 17
            },
            "10": {
              "min": 18,
              "max": 19
            },
            "11": {
              "min": 20,
              "max": 22
            },
            "12": {
              "min": 23,
              "max": 24
            },
            "13": {
              "min": 25,
              "max": 26
            },
            "14": {
              "min": 27,
              "max": 28
            },
            "15": {
              "min": 29,
              "max": 30
            },
            "16": {
              "min": 31,
              "max": 33
            },
            "17": {
              "min": 34,
              "max": 35
            },
            "18": {
              "min": 36,
              "max": 37
            },
            "19": {
              "min": 38,
              "max": 58
            }
          }
        },
        {
          "name": "Podobieństwa",
          "id": 2,
          "rows": {
            "1": {
              "min": null,
              "max": null
            },
            "2": {
              "min": null,
              "max": null
            },
            "3": {
              "min": 0,
              "max": 0
            },
            "4": {
              "min": null,
              "max": null
            },
            "5": {
              "min": 1,
              "max": 2
            },
            "6": {
              "min": 3,
              "max": 4
            },
            "7": {
              "min": 5,
              "max": 6
            },
            "8": {
              "min": 7,
              "max": 9
            },
            "9": {
              "min": 10,
              "max": 11
            },
            "10": {
              "min": 12,
              "max": 14
            },
            "11": {
              "min": 15,
              "max": 17
            },
            "12": {
              "min": 18,
              "max": 20
            },
            "13": {
              "min": 21,
              "max": 22
            },
            "14": {
              "min": 23,
              "max": 25
            },
            "15": {
              "min": 26,
              "max": 28
            },
            "16": {
              "min": 29,
              "max": 30
            },
            "17": {
              "min": 31,
              "max": 33
            },
            "18": {
              "min": 34,
              "max": 35
            },
            "19": {
              "min": 36,
              "max": 46
            }
          }
        },
        {
          "name": "Matryce",
          "id": 3,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": null,
              "max": null
            },
            "3": {
              "min": 1,
              "max": 2
            },
            "4": {
              "min": 3,
              "max": 3
            },
            "5": {
              "min": 4,
              "max": 4
            },
            "6": {
              "min": 5,
              "max": 6
            },
            "7": {
              "min": 7,
              "max": 7
            },
            "8": {
              "min": 8,
              "max": 9
            },
            "9": {
              "min": 10,
              "max": 10
            },
            "10": {
              "min": 11,
              "max": 11
            },
            "11": {
              "min": 12,
              "max": 13
            },
            "12": {
              "min": 14,
              "max": 14
            },
            "13": {
              "min": 15,
              "max": 16
            },
            "14": {
              "min": 17,
              "max": 17
            },
            "15": {
              "min": 18,
              "max": 18
            },
            "16": {
              "min": 19,
              "max": 20
            },
            "17": {
              "min": 21,
              "max": 21
            },
            "18": {
              "min": 22,
              "max": 22
            },
            "19": {
              "min": 23,
              "max": 32
            }
          }
        },
        {
          "name": "Powtarzanie liczb",
          "id": 4,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": 1,
              "max": 1
            },
            "3": {
              "min": 2,
              "max": 2
            },
            "4": {
              "min": 3,
              "max": 4
            },
            "5": {
              "min": 5,
              "max": 5
            },
            "6": {
              "min": 6,
              "max": 7
            },
            "7": {
              "min": 8,
              "max": 9
            },
            "8": {
              "min": 10,
              "max": 10
            },
            "9": {
              "min": 11,
              "max": 12
            },
            "10": {
              "min": 13,
              "max": 13
            },
            "11": {
              "min": 14,
              "max": 15
            },
            "12": {
              "min": 16,
              "max": 16
            },
            "13": {
              "min": 17,
              "max": 18
            },
            "14": {
              "min": 19,
              "max": 19
            },
            "15": {
              "min": 20,
              "max": 21
            },
            "16": {
              "min": 22,
              "max": 22
            },
            "17": {
              "min": 23,
              "max": 23
            },
            "18": {
              "min": 24,
              "max": 25
            },
            "19": {
              "min": 26,
              "max": 54
            }
          }
        },
        {
          "name": "Kodowanie",
          "id": 5,
          "rows": {
            "1": {
              "min": 0,
              "max": 1
            },
            "2": {
              "min": 2,
              "max": 2
            },
            "3": {
              "min": 3,
              "max": 4
            },
            "4": {
              "min": 5,
              "max": 7
            },
            "5": {
              "min": 8,
              "max": 10
            },
            "6": {
              "min": 11,
              "max": 13
            },
            "7": {
              "min": 14,
              "max": 17
            },
            "8": {
              "min": 18,
              "max": 21
            },
            "9": {
              "min": 22,
              "max": 25
            },
            "10": {
              "min": 26,
              "max": 29
            },
            "11": {
              "min": 30,
              "max": 33
            },
            "12": {
              "min": 34,
              "max": 37
            },
            "13": {
              "min": 38,
              "max": 40
            },
            "14": {
              "min": 41,
              "max": 43
            },
            "15": {
              "min": 44,
              "max": 46
            },
            "16": {
              "min": 47,
              "max": 49
            },
            "17": {
              "min": 50,
              "max": 52
            },
            "18": {
              "min": 53,
              "max": 55
            },
            "19": {
              "min": 56,
              "max": 75
            }
          }
        },
        {
          "name": "Słownik",
          "id": 6,
          "rows": {
            "1": {
              "min": 0,
              "max": 2
            },
            "2": {
              "min": 3,
              "max": 4
            },
            "3": {
              "min": 5,
              "max": 5
            },
            "4": {
              "min": 6,
              "max": 7
            },
            "5": {
              "min": 8,
              "max": 8
            },
            "6": {
              "min": 9,
              "max": 10
            },
            "7": {
              "min": 11,
              "max": 11
            },
            "8": {
              "min": 12,
              "max": 13
            },
            "9": {
              "min": 14,
              "max": 14
            },
            "10": {
              "min": 15,
              "max": 15
            },
            "11": {
              "min": 16,
              "max": 16
            },
            "12": {
              "min": 17,
              "max": 17
            },
            "13": {
              "min": 18,
              "max": 18
            },
            "14": {
              "min": 19,
              "max": 19
            },
            "15": {
              "min": 20,
              "max": 20
            },
            "16": {
              "min": 21,
              "max": 21
            },
            "17": {
              "min": 22,
              "max": 22
            },
            "18": {
              "min": 23,
              "max": 23
            },
            "19": {
              "min": 24,
              "max": 54
            }
          }
        },
        {
          "name": "Ważenie",
          "id": 7,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": 1,
              "max": 2
            },
            "3": {
              "min": 3,
              "max": 3
            },
            "4": {
              "min": 4,
              "max": 5
            },
            "5": {
              "min": 6,
              "max": 7
            },
            "6": {
              "min": 8,
              "max": 8
            },
            "7": {
              "min": 9,
              "max": 9
            },
            "8": {
              "min": 10,
              "max": 11
            },
            "9": {
              "min": 12,
              "max": 12
            },
            "10": {
              "min": 13,
              "max": 13
            },
            "11": {
              "min": 14,
              "max": 14
            },
            "12": {
              "min": 15,
              "max": 15
            },
            "13": {
              "min": 16,
              "max": 17
            },
            "14": {
              "min": 18,
              "max": 18
            },
            "15": {
              "min": 19,
              "max": 19
            },
            "16": {
              "min": 20,
              "max": 20
            },
            "17": {
              "min": 21,
              "max": 21
            },
            "18": {
              "min": 22,
              "max": 22
            },
            "19": {
              "min": 23,
              "max": 34
            }
          }
        },
        {
          "name": "Układnie wzoru",
          "id": 8,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": 1,
              "max": 1
            },
            "3": {
              "min": 2,
              "max": 2
            },
            "4": {
              "min": 3,
              "max": 3
            },
            "5": {
              "min": 4,
              "max": 4
            },
            "6": {
              "min": 5,
              "max": 5
            },
            "7": {
              "min": 6,
              "max": 7
            },
            "8": {
              "min": 8,
              "max": 8
            },
            "9": {
              "min": 9,
              "max": 9
            },
            "10": {
              "min": 10,
              "max": 11
            },
            "11": {
              "min": 12,
              "max": 12
            },
            "12": {
              "min": 13,
              "max": 13
            },
            "13": {
              "min": 14,
              "max": 14
            },
            "14": {
              "min": 15,
              "max": 16
            },
            "15": {
              "min": 17,
              "max": 17
            },
            "16": {
              "min": 18,
              "max": 18
            },
            "17": {
              "min": 19,
              "max": 19
            },
            "18": {
              "min": 20,
              "max": 20
            },
            "19": {
              "min": 21,
              "max": 29
            }
          }
        },
        {
          "name": "Zapamiętywanie obrazków",
          "id": 9,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 4
            },
            "3": {
              "min": 5,
              "max": 6
            },
            "4": {
              "min": 7,
              "max": 7
            },
            "5": {
              "min": 8,
              "max": 8
            },
            "6": {
              "min": 9,
              "max": 10
            },
            "7": {
              "min": 11,
              "max": 12
            },
            "8": {
              "min": 13,
              "max": 13
            },
            "9": {
              "min": 14,
              "max": 15
            },
            "10": {
              "min": 16,
              "max": 17
            },
            "11": {
              "min": 18,
              "max": 19
            },
            "12": {
              "min": 20,
              "max": 21
            },
            "13": {
              "min": 22,
              "max": 23
            },
            "14": {
              "min": 24,
              "max": 25
            },
            "15": {
              "min": 26,
              "max": 28
            },
            "16": {
              "min": 29,
              "max": 30
            },
            "17": {
              "min": 31,
              "max": 32
            },
            "18": {
              "min": 33,
              "max": 35
            },
            "19": {
              "min": 36,
              "max": 49
            }
          }
        },
        {
          "name": "Szukanie symboli",
          "id": 10,
          "rows": {
            "1": {
              "min": 0,
              "max": 1
            },
            "2": {
              "min": 2,
              "max": 2
            },
            "3": {
              "min": 3,
              "max": 4
            },
            "4": {
              "min": 5,
              "max": 5
            },
            "5": {
              "min": 6,
              "max": 8
            },
            "6": {
              "min": 9,
              "max": 10
            },
            "7": {
              "min": 11,
              "max": 13
            },
            "8": {
              "min": 14,
              "max": 15
            },
            "9": {
              "min": 16,
              "max": 18
            },
            "10": {
              "min": 19,
              "max": 20
            },
            "11": {
              "min": 21,
              "max": 23
            },
            "12": {
              "min": 24,
              "max": 25
            },
            "13": {
              "min": 26,
              "max": 27
            },
            "14": {
              "min": 28,
              "max": 29
            },
            "15": {
              "min": 30,
              "max": 31
            },
            "16": {
              "min": 32,
              "max": 32
            },
            "17": {
              "min": 33,
              "max": 34
            },
            "18": {
              "min": 35,
              "max": 36
            },
            "19": {
              "min": 37,
              "max": 42
            }
          }
        },
        {
          "name": "Wiadomości",
          "id": 11,
          "rows": {
            "1": {
              "min": 0,
              "max": 2
            },
            "2": {
              "min": null,
              "max": null
            },
            "3": {
              "min": 3,
              "max": 3
            },
            "4": {
              "min": 4,
              "max": 4
            },
            "5": {
              "min": 5,
              "max": 5
            },
            "6": {
              "min": 6,
              "max": 6
            },
            "7": {
              "min": 7,
              "max": 7
            },
            "8": {
              "min": 8,
              "max": 8
            },
            "9": {
              "min": 9,
              "max": 9
            },
            "10": {
              "min": null,
              "max": null
            },
            "11": {
              "min": 10,
              "max": 10
            },
            "12": {
              "min": 11,
              "max": 11
            },
            "13": {
              "min": 12,
              "max": 12
            },
            "14": {
              "min": 13,
              "max": 13
            },
            "15": {
              "min": null,
              "max": null
            },
            "16": {
              "min": 14,
              "max": 14
            },
            "17": {
              "min": 15,
              "max": 15
            },
            "18": {
              "min": 16,
              "max": 16
            },
            "19": {
              "min": 17,
              "max": 31
            }
          }
        },
        {
          "name": "Tworzenie pojęć",
          "id": 12,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": null,
              "max": null
            },
            "3": {
              "min": 1,
              "max": 1
            },
            "4": {
              "min": 2,
              "max": 2
            },
            "5": {
              "min": 3,
              "max": 3
            },
            "6": {
              "min": 4,
              "max": 4
            },
            "7": {
              "min": 5,
              "max": 5
            },
            "8": {
              "min": 6,
              "max": 6
            },
            "9": {
              "min": 7,
              "max": 7
            },
            "10": {
              "min": 8,
              "max": 8
            },
            "11": {
              "min": 9,
              "max": 10
            },
            "12": {
              "min": 11,
              "max": 11
            },
            "13": {
              "min": 12,
              "max": 12
            },
            "14": {
              "min": 13,
              "max": 14
            },
            "15": {
              "min": 15,
              "max": 15
            },
            "16": {
              "min": 16,
              "max": 17
            },
            "17": {
              "min": 18,
              "max": 18
            },
            "18": {
              "min": 19,
              "max": 19
            },
            "19": {
              "min": 20,
              "max": 27
            }
          }
        },
        {
          "name": "Porządkowanie liter i liczb",
          "id": 13,
          "rows": {
            "1": {
              "min": null,
              "max": null
            },
            "2": {
              "min": null,
              "max": null
            },
            "3": {
              "min": 0,
              "max": 0
            },
            "4": {
              "min": null,
              "max": null
            },
            "5": {
              "min": 1,
              "max": 1
            },
            "6": {
              "min": 2,
              "max": 3
            },
            "7": {
              "min": 4,
              "max": 4
            },
            "8": {
              "min": 5,
              "max": 5
            },
            "9": {
              "min": 6,
              "max": 6
            },
            "10": {
              "min": 7,
              "max": 8
            },
            "11": {
              "min": 9,
              "max": 9
            },
            "12": {
              "min": 10,
              "max": 11
            },
            "13": {
              "min": 12,
              "max": 12
            },
            "14": {
              "min": 13,
              "max": 14
            },
            "15": {
              "min": 15,
              "max": 15
            },
            "16": {
              "min": 16,
              "max": 17
            },
            "17": {
              "min": 18,
              "max": 18
            },
            "18": {
              "min": 19,
              "max": 20
            },
            "19": {
              "min": 21,
              "max": 30
            }
          }
        },
        {
          "name": "Wykreślanie",
          "id": 14,
          "rows": {
            "1": {
              "min": 0,
              "max": 2
            },
            "2": {
              "min": 3,
              "max": 6
            },
            "3": {
              "min": 7,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 16
            },
            "5": {
              "min": 17,
              "max": 21
            },
            "6": {
              "min": 22,
              "max": 26
            },
            "7": {
              "min": 27,
              "max": 31
            },
            "8": {
              "min": 32,
              "max": 35
            },
            "9": {
              "min": 36,
              "max": 40
            },
            "10": {
              "min": 41,
              "max": 45
            },
            "11": {
              "min": 46,
              "max": 49
            },
            "12": {
              "min": 50,
              "max": 54
            },
            "13": {
              "min": 55,
              "max": 58
            },
            "14": {
              "min": 59,
              "max": 62
            },
            "15": {
              "min": 63,
              "max": 67
            },
            "16": {
              "min": 68,
              "max": 71
            },
            "17": {
              "min": 72,
              "max": 75
            },
            "18": {
              "min": 76,
              "max": 79
            },
            "19": {
              "min": 80,
              "max": 128
            }
          }
        },
        {
          "name": "Rozumienie",
          "id": 15,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": null,
              "max": null
            },
            "3": {
              "min": 1,
              "max": 1
            },
            "4": {
              "min": 2,
              "max": 3
            },
            "5": {
              "min": 4,
              "max": 4
            },
            "6": {
              "min": 5,
              "max": 5
            },
            "7": {
              "min": 6,
              "max": 6
            },
            "8": {
              "min": 7,
              "max": 8
            },
            "9": {
              "min": 9,
              "max": 9
            },
            "10": {
              "min": 10,
              "max": 10
            },
            "11": {
              "min": 11,
              "max": 11
            },
            "12": {
              "min": 12,
              "max": 12
            },
            "13": {
              "min": 13,
              "max": 14
            },
            "14": {
              "min": 15,
              "max": 15
            },
            "15": {
              "min": 16,
              "max": 16
            },
            "16": {
              "min": 17,
              "max": 17
            },
            "17": {
              "min": 18,
              "max": 18
            },
            "18": {
              "min": 19,
              "max": 19
            },
            "19": {
              "min": 20,
              "max": 38
            }
          }
        },
        {
          "name": "Arytmetyka",
          "id": 16,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": 1,
              "max": 1
            },
            "3": {
              "min": 2,
              "max": 3
            },
            "4": {
              "min": 4,
              "max": 4
            },
            "5": {
              "min": 5,
              "max": 5
            },
            "6": {
              "min": 6,
              "max": 6
            },
            "7": {
              "min": 7,
              "max": 7
            },
            "8": {
              "min": 8,
              "max": 8
            },
            "9": {
              "min": 9,
              "max": 9
            },
            "10": {
              "min": 10,
              "max": 10
            },
            "11": {
              "min": 11,
              "max": 11
            },
            "12": {
              "min": 12,
              "max": 12
            },
            "13": {
              "min": 13,
              "max": 13
            },
            "14": {
              "min": 14,
              "max": 14
            },
            "15": {
              "min": 15,
              "max": 15
            },
            "16": {
              "min": null,
              "max": null
            },
            "17": {
              "min": 16,
              "max": 16
            },
            "18": {
              "min": 17,
              "max": 17
            },
            "19": {
              "min": 18,
              "max": 34
            }
          }
        }
      ],

    68611: [
        {
          "name": "Wzory z klocków",
          "id": 1,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": 1,
              "max": 1
            },
            "3": {
              "min": 2,
              "max": 3
            },
            "4": {
              "min": 4,
              "max": 6
            },
            "5": {
              "min": 7,
              "max": 8
            },
            "6": {
              "min": 9,
              "max": 11
            },
            "7": {
              "min": 12,
              "max": 13
            },
            "8": {
              "min": 14,
              "max": 16
            },
            "9": {
              "min": 17,
              "max": 18
            },
            "10": {
              "min": 19,
              "max": 20
            },
            "11": {
              "min": 21,
              "max": 23
            },
            "12": {
              "min": 24,
              "max": 25
            },
            "13": {
              "min": 26,
              "max": 27
            },
            "14": {
              "min": 28,
              "max": 30
            },
            "15": {
              "min": 31,
              "max": 32
            },
            "16": {
              "min": 33,
              "max": 34
            },
            "17": {
              "min": 35,
              "max": 36
            },
            "18": {
              "min": 37,
              "max": 39
            },
            "19": {
              "min": 40,
              "max": 58
            }
          }
        },
        {
          "name": "Podobieństwa",
          "id": 2,
          "rows": {
            "1": {
              "min": null,
              "max": null
            },
            "2": {
              "min": null,
              "max": null
            },
            "3": {
              "min": 0,
              "max": 0
            },
            "4": {
              "min": 1,
              "max": 1
            },
            "5": {
              "min": 2,
              "max": 3
            },
            "6": {
              "min": 4,
              "max": 5
            },
            "7": {
              "min": 6,
              "max": 8
            },
            "8": {
              "min": 9,
              "max": 10
            },
            "9": {
              "min": 11,
              "max": 13
            },
            "10": {
              "min": 14,
              "max": 16
            },
            "11": {
              "min": 17,
              "max": 18
            },
            "12": {
              "min": 19,
              "max": 21
            },
            "13": {
              "min": 22,
              "max": 23
            },
            "14": {
              "min": 24,
              "max": 26
            },
            "15": {
              "min": 27,
              "max": 28
            },
            "16": {
              "min": 29,
              "max": 30
            },
            "17": {
              "min": 31,
              "max": 33
            },
            "18": {
              "min": 34,
              "max": 35
            },
            "19": {
              "min": 36,
              "max": 46
            }
          }
        },
        {
          "name": "Matryce",
          "id": 3,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": 1,
              "max": 1
            },
            "3": {
              "min": 2,
              "max": 2
            },
            "4": {
              "min": 3,
              "max": 4
            },
            "5": {
              "min": 5,
              "max": 5
            },
            "6": {
              "min": 6,
              "max": 6
            },
            "7": {
              "min": 7,
              "max": 8
            },
            "8": {
              "min": 9,
              "max": 9
            },
            "9": {
              "min": 10,
              "max": 11
            },
            "10": {
              "min": 12,
              "max": 12
            },
            "11": {
              "min": 13,
              "max": 13
            },
            "12": {
              "min": 14,
              "max": 15
            },
            "13": {
              "min": 16,
              "max": 16
            },
            "14": {
              "min": 17,
              "max": 18
            },
            "15": {
              "min": 19,
              "max": 19
            },
            "16": {
              "min": 20,
              "max": 20
            },
            "17": {
              "min": 21,
              "max": 22
            },
            "18": {
              "min": 23,
              "max": 23
            },
            "19": {
              "min": 24,
              "max": 32
            }
          }
        },
        {
          "name": "Powtarzanie liczb",
          "id": 4,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": 1,
              "max": 1
            },
            "3": {
              "min": 2,
              "max": 3
            },
            "4": {
              "min": 4,
              "max": 4
            },
            "5": {
              "min": 5,
              "max": 6
            },
            "6": {
              "min": 7,
              "max": 8
            },
            "7": {
              "min": 9,
              "max": 9
            },
            "8": {
              "min": 10,
              "max": 11
            },
            "9": {
              "min": 12,
              "max": 13
            },
            "10": {
              "min": 14,
              "max": 14
            },
            "11": {
              "min": 15,
              "max": 16
            },
            "12": {
              "min": 17,
              "max": 17
            },
            "13": {
              "min": 18,
              "max": 19
            },
            "14": {
              "min": 20,
              "max": 20
            },
            "15": {
              "min": 21,
              "max": 22
            },
            "16": {
              "min": 23,
              "max": 23
            },
            "17": {
              "min": 24,
              "max": 24
            },
            "18": {
              "min": 25,
              "max": 26
            },
            "19": {
              "min": 27,
              "max": 54
            }
          }
        },
        {
          "name": "Kodowanie",
          "id": 5,
          "rows": {
            "1": {
              "min": 0,
              "max": 1
            },
            "2": {
              "min": 2,
              "max": 3
            },
            "3": {
              "min": 4,
              "max": 5
            },
            "4": {
              "min": 6,
              "max": 7
            },
            "5": {
              "min": 8,
              "max": 11
            },
            "6": {
              "min": 12,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 18
            },
            "8": {
              "min": 19,
              "max": 23
            },
            "9": {
              "min": 24,
              "max": 27
            },
            "10": {
              "min": 28,
              "max": 31
            },
            "11": {
              "min": 32,
              "max": 35
            },
            "12": {
              "min": 36,
              "max": 39
            },
            "13": {
              "min": 40,
              "max": 43
            },
            "14": {
              "min": 44,
              "max": 46
            },
            "15": {
              "min": 47,
              "max": 49
            },
            "16": {
              "min": 50,
              "max": 53
            },
            "17": {
              "min": 54,
              "max": 55
            },
            "18": {
              "min": 56,
              "max": 58
            },
            "19": {
              "min": 59,
              "max": 75
            }
          }
        },
        {
          "name": "Słownik",
          "id": 6,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 4
            },
            "3": {
              "min": 5,
              "max": 6
            },
            "4": {
              "min": 7,
              "max": 7
            },
            "5": {
              "min": 8,
              "max": 9
            },
            "6": {
              "min": 10,
              "max": 10
            },
            "7": {
              "min": 11,
              "max": 12
            },
            "8": {
              "min": 13,
              "max": 13
            },
            "9": {
              "min": 14,
              "max": 14
            },
            "10": {
              "min": 15,
              "max": 15
            },
            "11": {
              "min": 16,
              "max": 17
            },
            "12": {
              "min": 18,
              "max": 18
            },
            "13": {
              "min": 19,
              "max": 19
            },
            "14": {
              "min": 20,
              "max": 20
            },
            "15": {
              "min": 21,
              "max": 21
            },
            "16": {
              "min": 22,
              "max": 23
            },
            "17": {
              "min": 24,
              "max": 24
            },
            "18": {
              "min": 25,
              "max": 25
            },
            "19": {
              "min": 26,
              "max": 54
            }
          }
        },
        {
          "name": "Ważenie",
          "id": 7,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": 1,
              "max": 2
            },
            "3": {
              "min": 3,
              "max": 3
            },
            "4": {
              "min": 4,
              "max": 5
            },
            "5": {
              "min": 6,
              "max": 7
            },
            "6": {
              "min": 8,
              "max": 8
            },
            "7": {
              "min": 9,
              "max": 10
            },
            "8": {
              "min": 11,
              "max": 11
            },
            "9": {
              "min": 12,
              "max": 13
            },
            "10": {
              "min": 14,
              "max": 14
            },
            "11": {
              "min": 15,
              "max": 15
            },
            "12": {
              "min": 16,
              "max": 17
            },
            "13": {
              "min": 18,
              "max": 18
            },
            "14": {
              "min": 19,
              "max": 19
            },
            "15": {
              "min": 20,
              "max": 20
            },
            "16": {
              "min": 21,
              "max": 22
            },
            "17": {
              "min": 23,
              "max": 23
            },
            "18": {
              "min": 24,
              "max": 24
            },
            "19": {
              "min": 25,
              "max": 34
            }
          }
        },
        {
          "name": "Układnie wzoru",
          "id": 8,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": 1,
              "max": 1
            },
            "3": {
              "min": 2,
              "max": 2
            },
            "4": {
              "min": 3,
              "max": 3
            },
            "5": {
              "min": 4,
              "max": 4
            },
            "6": {
              "min": 5,
              "max": 6
            },
            "7": {
              "min": 7,
              "max": 7
            },
            "8": {
              "min": 8,
              "max": 9
            },
            "9": {
              "min": 10,
              "max": 10
            },
            "10": {
              "min": 11,
              "max": 11
            },
            "11": {
              "min": 12,
              "max": 12
            },
            "12": {
              "min": 13,
              "max": 14
            },
            "13": {
              "min": 15,
              "max": 15
            },
            "14": {
              "min": 16,
              "max": 16
            },
            "15": {
              "min": 17,
              "max": 17
            },
            "16": {
              "min": 18,
              "max": 18
            },
            "17": {
              "min": 19,
              "max": 20
            },
            "18": {
              "min": 21,
              "max": 21
            },
            "19": {
              "min": 22,
              "max": 29
            }
          }
        },
        {
          "name": "Zapamiętywanie obrazków",
          "id": 9,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 4
            },
            "3": {
              "min": 5,
              "max": 6
            },
            "4": {
              "min": 7,
              "max": 7
            },
            "5": {
              "min": 8,
              "max": 9
            },
            "6": {
              "min": 10,
              "max": 11
            },
            "7": {
              "min": 12,
              "max": 12
            },
            "8": {
              "min": 13,
              "max": 14
            },
            "9": {
              "min": 15,
              "max": 16
            },
            "10": {
              "min": 17,
              "max": 18
            },
            "11": {
              "min": 19,
              "max": 20
            },
            "12": {
              "min": 21,
              "max": 22
            },
            "13": {
              "min": 23,
              "max": 24
            },
            "14": {
              "min": 25,
              "max": 26
            },
            "15": {
              "min": 27,
              "max": 29
            },
            "16": {
              "min": 30,
              "max": 31
            },
            "17": {
              "min": 32,
              "max": 33
            },
            "18": {
              "min": 34,
              "max": 36
            },
            "19": {
              "min": 37,
              "max": 49
            }
          }
        },
        {
          "name": "Szukanie symboli",
          "id": 10,
          "rows": {
            "1": {
              "min": 0,
              "max": 1
            },
            "2": {
              "min": 2,
              "max": 3
            },
            "3": {
              "min": 4,
              "max": 4
            },
            "4": {
              "min": 5,
              "max": 6
            },
            "5": {
              "min": 7,
              "max": 9
            },
            "6": {
              "min": 10,
              "max": 11
            },
            "7": {
              "min": 12,
              "max": 14
            },
            "8": {
              "min": 15,
              "max": 17
            },
            "9": {
              "min": 18,
              "max": 20
            },
            "10": {
              "min": 21,
              "max": 22
            },
            "11": {
              "min": 23,
              "max": 25
            },
            "12": {
              "min": 26,
              "max": 27
            },
            "13": {
              "min": 28,
              "max": 29
            },
            "14": {
              "min": 30,
              "max": 31
            },
            "15": {
              "min": 32,
              "max": 33
            },
            "16": {
              "min": 34,
              "max": 35
            },
            "17": {
              "min": 36,
              "max": 36
            },
            "18": {
              "min": 37,
              "max": 38
            },
            "19": {
              "min": 39,
              "max": 42
            }
          }
        },
        {
          "name": "Wiadomości",
          "id": 11,
          "rows": {
            "1": {
              "min": 0,
              "max": 2
            },
            "2": {
              "min": 3,
              "max": 3
            },
            "3": {
              "min": 4,
              "max": 4
            },
            "4": {
              "min": 5,
              "max": 5
            },
            "5": {
              "min": 6,
              "max": 6
            },
            "6": {
              "min": null,
              "max": null
            },
            "7": {
              "min": 7,
              "max": 7
            },
            "8": {
              "min": 8,
              "max": 8
            },
            "9": {
              "min": 9,
              "max": 9
            },
            "10": {
              "min": 10,
              "max": 10
            },
            "11": {
              "min": 11,
              "max": 11
            },
            "12": {
              "min": null,
              "max": null
            },
            "13": {
              "min": 12,
              "max": 12
            },
            "14": {
              "min": 13,
              "max": 13
            },
            "15": {
              "min": 14,
              "max": 14
            },
            "16": {
              "min": 15,
              "max": 15
            },
            "17": {
              "min": 16,
              "max": 16
            },
            "18": {
              "min": null,
              "max": null
            },
            "19": {
              "min": 17,
              "max": 31
            }
          }
        },
        {
          "name": "Tworzenie pojęć",
          "id": 12,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": null,
              "max": null
            },
            "3": {
              "min": 1,
              "max": 1
            },
            "4": {
              "min": 2,
              "max": 2
            },
            "5": {
              "min": 3,
              "max": 3
            },
            "6": {
              "min": 4,
              "max": 4
            },
            "7": {
              "min": 5,
              "max": 5
            },
            "8": {
              "min": 6,
              "max": 6
            },
            "9": {
              "min": 7,
              "max": 8
            },
            "10": {
              "min": 9,
              "max": 9
            },
            "11": {
              "min": 10,
              "max": 10
            },
            "12": {
              "min": 11,
              "max": 12
            },
            "13": {
              "min": 13,
              "max": 13
            },
            "14": {
              "min": 14,
              "max": 14
            },
            "15": {
              "min": 15,
              "max": 15
            },
            "16": {
              "min": 16,
              "max": 17
            },
            "17": {
              "min": 18,
              "max": 18
            },
            "18": {
              "min": 19,
              "max": 19
            },
            "19": {
              "min": 20,
              "max": 27
            }
          }
        },
        {
          "name": "Porządkowanie liter i liczb",
          "id": 13,
          "rows": {
            "1": {
              "min": null,
              "max": null
            },
            "2": {
              "min": null,
              "max": null
            },
            "3": {
              "min": 0,
              "max": 0
            },
            "4": {
              "min": 1,
              "max": 1
            },
            "5": {
              "min": 2,
              "max": 2
            },
            "6": {
              "min": 3,
              "max": 3
            },
            "7": {
              "min": 4,
              "max": 5
            },
            "8": {
              "min": 6,
              "max": 6
            },
            "9": {
              "min": 7,
              "max": 8
            },
            "10": {
              "min": 9,
              "max": 9
            },
            "11": {
              "min": 10,
              "max": 10
            },
            "12": {
              "min": 11,
              "max": 12
            },
            "13": {
              "min": 13,
              "max": 13
            },
            "14": {
              "min": 14,
              "max": 14
            },
            "15": {
              "min": 15,
              "max": 16
            },
            "16": {
              "min": 17,
              "max": 17
            },
            "17": {
              "min": 18,
              "max": 18
            },
            "18": {
              "min": 19,
              "max": 20
            },
            "19": {
              "min": 21,
              "max": 30
            }
          }
        },
        {
          "name": "Wykreślanie",
          "id": 14,
          "rows": {
            "1": {
              "min": 0,
              "max": 5
            },
            "2": {
              "min": 6,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 13
            },
            "4": {
              "min": 14,
              "max": 18
            },
            "5": {
              "min": 19,
              "max": 23
            },
            "6": {
              "min": 24,
              "max": 28
            },
            "7": {
              "min": 29,
              "max": 32
            },
            "8": {
              "min": 33,
              "max": 37
            },
            "9": {
              "min": 38,
              "max": 42
            },
            "10": {
              "min": 43,
              "max": 46
            },
            "11": {
              "min": 47,
              "max": 51
            },
            "12": {
              "min": 52,
              "max": 56
            },
            "13": {
              "min": 57,
              "max": 61
            },
            "14": {
              "min": 62,
              "max": 65
            },
            "15": {
              "min": 66,
              "max": 70
            },
            "16": {
              "min": 71,
              "max": 75
            },
            "17": {
              "min": 76,
              "max": 79
            },
            "18": {
              "min": 80,
              "max": 84
            },
            "19": {
              "min": 85,
              "max": 128
            }
          }
        },
        {
          "name": "Rozumienie",
          "id": 15,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": null,
              "max": null
            },
            "3": {
              "min": 1,
              "max": 2
            },
            "4": {
              "min": 3,
              "max": 3
            },
            "5": {
              "min": 4,
              "max": 4
            },
            "6": {
              "min": 5,
              "max": 6
            },
            "7": {
              "min": 7,
              "max": 7
            },
            "8": {
              "min": 8,
              "max": 8
            },
            "9": {
              "min": 9,
              "max": 9
            },
            "10": {
              "min": 10,
              "max": 11
            },
            "11": {
              "min": 12,
              "max": 12
            },
            "12": {
              "min": 13,
              "max": 13
            },
            "13": {
              "min": 14,
              "max": 14
            },
            "14": {
              "min": 15,
              "max": 16
            },
            "15": {
              "min": 17,
              "max": 17
            },
            "16": {
              "min": 18,
              "max": 18
            },
            "17": {
              "min": 19,
              "max": 19
            },
            "18": {
              "min": 20,
              "max": 20
            },
            "19": {
              "min": 21,
              "max": 38
            }
          }
        },
        {
          "name": "Arytmetyka",
          "id": 16,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": 1,
              "max": 2
            },
            "3": {
              "min": 3,
              "max": 3
            },
            "4": {
              "min": 4,
              "max": 4
            },
            "5": {
              "min": 5,
              "max": 5
            },
            "6": {
              "min": 6,
              "max": 7
            },
            "7": {
              "min": 8,
              "max": 8
            },
            "8": {
              "min": 9,
              "max": 9
            },
            "9": {
              "min": 10,
              "max": 10
            },
            "10": {
              "min": 11,
              "max": 11
            },
            "11": {
              "min": 12,
              "max": 12
            },
            "12": {
              "min": 13,
              "max": 13
            },
            "13": {
              "min": 14,
              "max": 14
            },
            "14": {
              "min": 15,
              "max": 15
            },
            "15": {
              "min": 16,
              "max": 16
            },
            "16": {
              "min": 17,
              "max": 17
            },
            "17": {
              "min": 18,
              "max": 18
            },
            "18": {
              "min": 19,
              "max": 19
            },
            "19": {
              "min": 20,
              "max": 34
            }
          }
        }
      ],  
    
    7073: [
        {
          "name": "Wzory z klocków",
          "id": 1,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": 1,
              "max": 2
            },
            "3": {
              "min": 3,
              "max": 4
            },
            "4": {
              "min": 5,
              "max": 6
            },
            "5": {
              "min": 7,
              "max": 9
            },
            "6": {
              "min": 10,
              "max": 11
            },
            "7": {
              "min": 12,
              "max": 14
            },
            "8": {
              "min": 15,
              "max": 16
            },
            "9": {
              "min": 17,
              "max": 19
            },
            "10": {
              "min": 20,
              "max": 21
            },
            "11": {
              "min": 22,
              "max": 24
            },
            "12": {
              "min": 25,
              "max": 26
            },
            "13": {
              "min": 27,
              "max": 29
            },
            "14": {
              "min": 30,
              "max": 31
            },
            "15": {
              "min": 32,
              "max": 33
            },
            "16": {
              "min": 34,
              "max": 36
            },
            "17": {
              "min": 37,
              "max": 38
            },
            "18": {
              "min": 39,
              "max": 41
            },
            "19": {
              "min": 42,
              "max": 58
            }
          }
        },
        {
          "name": "Podobieństwa",
          "id": 2,
          "rows": {
            "1": {
              "min": null,
              "max": null
            },
            "2": {
              "min": 0,
              "max": 0
            },
            "3": {
              "min": 1,
              "max": 1
            },
            "4": {
              "min": 2,
              "max": 2
            },
            "5": {
              "min": 3,
              "max": 4
            },
            "6": {
              "min": 5,
              "max": 7
            },
            "7": {
              "min": 8,
              "max": 9
            },
            "8": {
              "min": 10,
              "max": 12
            },
            "9": {
              "min": 13,
              "max": 14
            },
            "10": {
              "min": 15,
              "max": 17
            },
            "11": {
              "min": 18,
              "max": 19
            },
            "12": {
              "min": 20,
              "max": 22
            },
            "13": {
              "min": 23,
              "max": 24
            },
            "14": {
              "min": 25,
              "max": 26
            },
            "15": {
              "min": 27,
              "max": 28
            },
            "16": {
              "min": 29,
              "max": 30
            },
            "17": {
              "min": 31,
              "max": 33
            },
            "18": {
              "min": 34,
              "max": 35
            },
            "19": {
              "min": 36,
              "max": 46
            }
          }
        },
        {
          "name": "Matryce",
          "id": 3,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": 1,
              "max": 1
            },
            "3": {
              "min": 2,
              "max": 3
            },
            "4": {
              "min": 4,
              "max": 4
            },
            "5": {
              "min": 5,
              "max": 6
            },
            "6": {
              "min": 7,
              "max": 7
            },
            "7": {
              "min": 8,
              "max": 8
            },
            "8": {
              "min": 9,
              "max": 10
            },
            "9": {
              "min": 11,
              "max": 11
            },
            "10": {
              "min": 12,
              "max": 13
            },
            "11": {
              "min": 14,
              "max": 14
            },
            "12": {
              "min": 15,
              "max": 15
            },
            "13": {
              "min": 16,
              "max": 17
            },
            "14": {
              "min": 18,
              "max": 18
            },
            "15": {
              "min": 19,
              "max": 19
            },
            "16": {
              "min": 20,
              "max": 21
            },
            "17": {
              "min": 22,
              "max": 22
            },
            "18": {
              "min": 23,
              "max": 23
            },
            "19": {
              "min": 24,
              "max": 32
            }
          }
        },
        {
          "name": "Powtarzanie liczb",
          "id": 4,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": 1,
              "max": 2
            },
            "3": {
              "min": 3,
              "max": 4
            },
            "4": {
              "min": 5,
              "max": 5
            },
            "5": {
              "min": 6,
              "max": 7
            },
            "6": {
              "min": 8,
              "max": 9
            },
            "7": {
              "min": 10,
              "max": 10
            },
            "8": {
              "min": 11,
              "max": 12
            },
            "9": {
              "min": 13,
              "max": 14
            },
            "10": {
              "min": 15,
              "max": 15
            },
            "11": {
              "min": 16,
              "max": 17
            },
            "12": {
              "min": 18,
              "max": 18
            },
            "13": {
              "min": 19,
              "max": 20
            },
            "14": {
              "min": 21,
              "max": 21
            },
            "15": {
              "min": 22,
              "max": 23
            },
            "16": {
              "min": 24,
              "max": 24
            },
            "17": {
              "min": 25,
              "max": 26
            },
            "18": {
              "min": 27,
              "max": 27
            },
            "19": {
              "min": 28,
              "max": 54
            }
          }
        },
        {
          "name": "Kodowanie",
          "id": 5,
          "rows": {
            "1": {
              "min": 0,
              "max": 1
            },
            "2": {
              "min": 2,
              "max": 3
            },
            "3": {
              "min": 4,
              "max": 5
            },
            "4": {
              "min": 6,
              "max": 8
            },
            "5": {
              "min": 9,
              "max": 11
            },
            "6": {
              "min": 12,
              "max": 15
            },
            "7": {
              "min": 16,
              "max": 20
            },
            "8": {
              "min": 21,
              "max": 24
            },
            "9": {
              "min": 25,
              "max": 29
            },
            "10": {
              "min": 30,
              "max": 33
            },
            "11": {
              "min": 34,
              "max": 38
            },
            "12": {
              "min": 39,
              "max": 42
            },
            "13": {
              "min": 43,
              "max": 45
            },
            "14": {
              "min": 46,
              "max": 49
            },
            "15": {
              "min": 50,
              "max": 53
            },
            "16": {
              "min": 54,
              "max": 56
            },
            "17": {
              "min": 57,
              "max": 59
            },
            "18": {
              "min": 60,
              "max": 62
            },
            "19": {
              "min": 63,
              "max": 75
            }
          }
        },
        {
          "name": "Słownik",
          "id": 6,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 5
            },
            "3": {
              "min": 6,
              "max": 6
            },
            "4": {
              "min": 7,
              "max": 8
            },
            "5": {
              "min": 9,
              "max": 9
            },
            "6": {
              "min": 10,
              "max": 11
            },
            "7": {
              "min": 12,
              "max": 12
            },
            "8": {
              "min": 13,
              "max": 13
            },
            "9": {
              "min": 14,
              "max": 15
            },
            "10": {
              "min": 16,
              "max": 16
            },
            "11": {
              "min": 17,
              "max": 17
            },
            "12": {
              "min": 18,
              "max": 19
            },
            "13": {
              "min": 20,
              "max": 20
            },
            "14": {
              "min": 21,
              "max": 21
            },
            "15": {
              "min": 22,
              "max": 23
            },
            "16": {
              "min": 24,
              "max": 24
            },
            "17": {
              "min": 25,
              "max": 25
            },
            "18": {
              "min": 26,
              "max": 26
            },
            "19": {
              "min": 27,
              "max": 54
            }
          }
        },
        {
          "name": "Ważenie",
          "id": 7,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": 1,
              "max": 2
            },
            "3": {
              "min": 3,
              "max": 3
            },
            "4": {
              "min": 4,
              "max": 5
            },
            "5": {
              "min": 6,
              "max": 7
            },
            "6": {
              "min": 8,
              "max": 8
            },
            "7": {
              "min": 9,
              "max": 10
            },
            "8": {
              "min": 11,
              "max": 12
            },
            "9": {
              "min": 13,
              "max": 13
            },
            "10": {
              "min": 14,
              "max": 15
            },
            "11": {
              "min": 16,
              "max": 16
            },
            "12": {
              "min": 17,
              "max": 18
            },
            "13": {
              "min": 19,
              "max": 19
            },
            "14": {
              "min": 20,
              "max": 20
            },
            "15": {
              "min": 21,
              "max": 22
            },
            "16": {
              "min": 23,
              "max": 23
            },
            "17": {
              "min": 24,
              "max": 24
            },
            "18": {
              "min": 25,
              "max": 25
            },
            "19": {
              "min": 26,
              "max": 34
            }
          }
        },
        {
          "name": "Układnie wzoru",
          "id": 8,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": 1,
              "max": 1
            },
            "3": {
              "min": 2,
              "max": 2
            },
            "4": {
              "min": 3,
              "max": 3
            },
            "5": {
              "min": 4,
              "max": 5
            },
            "6": {
              "min": 6,
              "max": 6
            },
            "7": {
              "min": 7,
              "max": 8
            },
            "8": {
              "min": 9,
              "max": 9
            },
            "9": {
              "min": 10,
              "max": 10
            },
            "10": {
              "min": 11,
              "max": 12
            },
            "11": {
              "min": 13,
              "max": 13
            },
            "12": {
              "min": 14,
              "max": 14
            },
            "13": {
              "min": 15,
              "max": 15
            },
            "14": {
              "min": 16,
              "max": 17
            },
            "15": {
              "min": 18,
              "max": 18
            },
            "16": {
              "min": 19,
              "max": 19
            },
            "17": {
              "min": 20,
              "max": 20
            },
            "18": {
              "min": 21,
              "max": 21
            },
            "19": {
              "min": 22,
              "max": 29
            }
          }
        },
        {
          "name": "Zapamiętywanie obrazków",
          "id": 9,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 4
            },
            "3": {
              "min": 5,
              "max": 6
            },
            "4": {
              "min": 7,
              "max": 8
            },
            "5": {
              "min": 9,
              "max": 9
            },
            "6": {
              "min": 10,
              "max": 11
            },
            "7": {
              "min": 12,
              "max": 13
            },
            "8": {
              "min": 14,
              "max": 15
            },
            "9": {
              "min": 16,
              "max": 17
            },
            "10": {
              "min": 18,
              "max": 19
            },
            "11": {
              "min": 20,
              "max": 21
            },
            "12": {
              "min": 22,
              "max": 23
            },
            "13": {
              "min": 24,
              "max": 25
            },
            "14": {
              "min": 26,
              "max": 27
            },
            "15": {
              "min": 28,
              "max": 30
            },
            "16": {
              "min": 31,
              "max": 32
            },
            "17": {
              "min": 33,
              "max": 34
            },
            "18": {
              "min": 35,
              "max": 37
            },
            "19": {
              "min": 38,
              "max": 49
            }
          }
        },
        {
          "name": "Szukanie symboli",
          "id": 10,
          "rows": {
            "1": {
              "min": 0,
              "max": 2
            },
            "2": {
              "min": 3,
              "max": 3
            },
            "3": {
              "min": 4,
              "max": 5
            },
            "4": {
              "min": 6,
              "max": 8
            },
            "5": {
              "min": 9,
              "max": 10
            },
            "6": {
              "min": 11,
              "max": 13
            },
            "7": {
              "min": 14,
              "max": 16
            },
            "8": {
              "min": 17,
              "max": 19
            },
            "9": {
              "min": 20,
              "max": 22
            },
            "10": {
              "min": 23,
              "max": 24
            },
            "11": {
              "min": 25,
              "max": 27
            },
            "12": {
              "min": 28,
              "max": 29
            },
            "13": {
              "min": 30,
              "max": 31
            },
            "14": {
              "min": 32,
              "max": 33
            },
            "15": {
              "min": 34,
              "max": 35
            },
            "16": {
              "min": 36,
              "max": 37
            },
            "17": {
              "min": 38,
              "max": 39
            },
            "18": {
              "min": 40,
              "max": 40
            },
            "19": {
              "min": 41,
              "max": 42
            }
          }
        },
        {
          "name": "Wiadomości",
          "id": 11,
          "rows": {
            "1": {
              "min": 0,
              "max": 2
            },
            "2": {
              "min": 3,
              "max": 3
            },
            "3": {
              "min": 4,
              "max": 4
            },
            "4": {
              "min": 5,
              "max": 5
            },
            "5": {
              "min": 6,
              "max": 6
            },
            "6": {
              "min": 7,
              "max": 7
            },
            "7": {
              "min": 8,
              "max": 8
            },
            "8": {
              "min": 9,
              "max": 9
            },
            "9": {
              "min": null,
              "max": null
            },
            "10": {
              "min": 10,
              "max": 10
            },
            "11": {
              "min": 11,
              "max": 11
            },
            "12": {
              "min": 12,
              "max": 12
            },
            "13": {
              "min": 13,
              "max": 13
            },
            "14": {
              "min": 14,
              "max": 14
            },
            "15": {
              "min": 15,
              "max": 15
            },
            "16": {
              "min": null,
              "max": null
            },
            "17": {
              "min": 16,
              "max": 16
            },
            "18": {
              "min": 17,
              "max": 17
            },
            "19": {
              "min": 18,
              "max": 31
            }
          }
        },
        {
          "name": "Tworzenie pojęć",
          "id": 12,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": null,
              "max": null
            },
            "3": {
              "min": 1,
              "max": 1
            },
            "4": {
              "min": 2,
              "max": 2
            },
            "5": {
              "min": 3,
              "max": 3
            },
            "6": {
              "min": 4,
              "max": 4
            },
            "7": {
              "min": 5,
              "max": 6
            },
            "8": {
              "min": 7,
              "max": 7
            },
            "9": {
              "min": 8,
              "max": 8
            },
            "10": {
              "min": 9,
              "max": 9
            },
            "11": {
              "min": 10,
              "max": 11
            },
            "12": {
              "min": 12,
              "max": 12
            },
            "13": {
              "min": 13,
              "max": 13
            },
            "14": {
              "min": 14,
              "max": 14
            },
            "15": {
              "min": 15,
              "max": 16
            },
            "16": {
              "min": 17,
              "max": 17
            },
            "17": {
              "min": 18,
              "max": 18
            },
            "18": {
              "min": 19,
              "max": 19
            },
            "19": {
              "min": 20,
              "max": 27
            }
          }
        },
        {
          "name": "Porządkowanie liter i liczb",
          "id": 13,
          "rows": {
            "1": {
              "min": null,
              "max": null
            },
            "2": {
              "min": 0,
              "max": 0
            },
            "3": {
              "min": null,
              "max": null
            },
            "4": {
              "min": 1,
              "max": 2
            },
            "5": {
              "min": 3,
              "max": 3
            },
            "6": {
              "min": 4,
              "max": 4
            },
            "7": {
              "min": 5,
              "max": 6
            },
            "8": {
              "min": 7,
              "max": 7
            },
            "9": {
              "min": 8,
              "max": 9
            },
            "10": {
              "min": 10,
              "max": 10
            },
            "11": {
              "min": 11,
              "max": 11
            },
            "12": {
              "min": 12,
              "max": 12
            },
            "13": {
              "min": 13,
              "max": 14
            },
            "14": {
              "min": 15,
              "max": 15
            },
            "15": {
              "min": 16,
              "max": 16
            },
            "16": {
              "min": 17,
              "max": 17
            },
            "17": {
              "min": 18,
              "max": 19
            },
            "18": {
              "min": 20,
              "max": 20
            },
            "19": {
              "min": 21,
              "max": 30
            }
          }
        },
        {
          "name": "Wykreślanie",
          "id": 14,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 12
            },
            "3": {
              "min": 13,
              "max": 16
            },
            "4": {
              "min": 17,
              "max": 21
            },
            "5": {
              "min": 22,
              "max": 25
            },
            "6": {
              "min": 26,
              "max": 29
            },
            "7": {
              "min": 30,
              "max": 34
            },
            "8": {
              "min": 35,
              "max": 39
            },
            "9": {
              "min": 40,
              "max": 43
            },
            "10": {
              "min": 44,
              "max": 48
            },
            "11": {
              "min": 49,
              "max": 53
            },
            "12": {
              "min": 54,
              "max": 58
            },
            "13": {
              "min": 59,
              "max": 63
            },
            "14": {
              "min": 64,
              "max": 68
            },
            "15": {
              "min": 69,
              "max": 73
            },
            "16": {
              "min": 74,
              "max": 79
            },
            "17": {
              "min": 80,
              "max": 84
            },
            "18": {
              "min": 85,
              "max": 89
            },
            "19": {
              "min": 90,
              "max": 128
            }
          }
        },
        {
          "name": "Rozumienie",
          "id": 15,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": 1,
              "max": 1
            },
            "3": {
              "min": 2,
              "max": 2
            },
            "4": {
              "min": 3,
              "max": 3
            },
            "5": {
              "min": 4,
              "max": 5
            },
            "6": {
              "min": 6,
              "max": 6
            },
            "7": {
              "min": 7,
              "max": 7
            },
            "8": {
              "min": 8,
              "max": 9
            },
            "9": {
              "min": 10,
              "max": 10
            },
            "10": {
              "min": 11,
              "max": 11
            },
            "11": {
              "min": 12,
              "max": 13
            },
            "12": {
              "min": 14,
              "max": 14
            },
            "13": {
              "min": 15,
              "max": 15
            },
            "14": {
              "min": 16,
              "max": 16
            },
            "15": {
              "min": 17,
              "max": 18
            },
            "16": {
              "min": 19,
              "max": 19
            },
            "17": {
              "min": 20,
              "max": 20
            },
            "18": {
              "min": 21,
              "max": 21
            },
            "19": {
              "min": 22,
              "max": 38
            }
          }
        },
        {
          "name": "Arytmetyka",
          "id": 16,
          "rows": {
            "1": {
              "min": 0,
              "max": 1
            },
            "2": {
              "min": 2,
              "max": 2
            },
            "3": {
              "min": 3,
              "max": 3
            },
            "4": {
              "min": 4,
              "max": 5
            },
            "5": {
              "min": 6,
              "max": 6
            },
            "6": {
              "min": 7,
              "max": 7
            },
            "7": {
              "min": 8,
              "max": 9
            },
            "8": {
              "min": 10,
              "max": 10
            },
            "9": {
              "min": 11,
              "max": 11
            },
            "10": {
              "min": 12,
              "max": 12
            },
            "11": {
              "min": 13,
              "max": 13
            },
            "12": {
              "min": 14,
              "max": 14
            },
            "13": {
              "min": 15,
              "max": 15
            },
            "14": {
              "min": 16,
              "max": 16
            },
            "15": {
              "min": 17,
              "max": 17
            },
            "16": {
              "min": 18,
              "max": 18
            },
            "17": {
              "min": 19,
              "max": 19
            },
            "18": {
              "min": 20,
              "max": 20
            },
            "19": {
              "min": 21,
              "max": 34
            }
          }
        }
      ],   

    7477: [
        {
          "name": "Wzory z klocków",
          "id": 1,
          "rows": {
            "1": {
              "min": 0,
              "max": 1
            },
            "2": {
              "min": 2,
              "max": 3
            },
            "3": {
              "min": 4,
              "max": 5
            },
            "4": {
              "min": 6,
              "max": 7
            },
            "5": {
              "min": 8,
              "max": 10
            },
            "6": {
              "min": 11,
              "max": 12
            },
            "7": {
              "min": 13,
              "max": 15
            },
            "8": {
              "min": 16,
              "max": 17
            },
            "9": {
              "min": 18,
              "max": 20
            },
            "10": {
              "min": 21,
              "max": 22
            },
            "11": {
              "min": 23,
              "max": 25
            },
            "12": {
              "min": 26,
              "max": 27
            },
            "13": {
              "min": 28,
              "max": 30
            },
            "14": {
              "min": 31,
              "max": 32
            },
            "15": {
              "min": 33,
              "max": 35
            },
            "16": {
              "min": 36,
              "max": 37
            },
            "17": {
              "min": 38,
              "max": 40
            },
            "18": {
              "min": 41,
              "max": 42
            },
            "19": {
              "min": 43,
              "max": 58
            }
          }
        },
        {
          "name": "Podobieństwa",
          "id": 2,
          "rows": {
            "1": {
              "min": null,
              "max": null
            },
            "2": {
              "min": 0,
              "max": 0
            },
            "3": {
              "min": 1,
              "max": 2
            },
            "4": {
              "min": 3,
              "max": 3
            },
            "5": {
              "min": 4,
              "max": 5
            },
            "6": {
              "min": 6,
              "max": 8
            },
            "7": {
              "min": 9,
              "max": 11
            },
            "8": {
              "min": 12,
              "max": 13
            },
            "9": {
              "min": 14,
              "max": 16
            },
            "10": {
              "min": 17,
              "max": 18
            },
            "11": {
              "min": 19,
              "max": 20
            },
            "12": {
              "min": 21,
              "max": 23
            },
            "13": {
              "min": 24,
              "max": 25
            },
            "14": {
              "min": 26,
              "max": 27
            },
            "15": {
              "min": 28,
              "max": 29
            },
            "16": {
              "min": 30,
              "max": 31
            },
            "17": {
              "min": 32,
              "max": 33
            },
            "18": {
              "min": 34,
              "max": 35
            },
            "19": {
              "min": 36,
              "max": 46
            }
          }
        },
        {
          "name": "Matryce",
          "id": 3,
          "rows": {
            "1": {
              "min": 0,
              "max": 1
            },
            "2": {
              "min": 2,
              "max": 2
            },
            "3": {
              "min": 3,
              "max": 3
            },
            "4": {
              "min": 4,
              "max": 5
            },
            "5": {
              "min": 6,
              "max": 6
            },
            "6": {
              "min": 7,
              "max": 8
            },
            "7": {
              "min": 9,
              "max": 9
            },
            "8": {
              "min": 10,
              "max": 11
            },
            "9": {
              "min": 12,
              "max": 12
            },
            "10": {
              "min": 13,
              "max": 13
            },
            "11": {
              "min": 14,
              "max": 15
            },
            "12": {
              "min": 16,
              "max": 16
            },
            "13": {
              "min": 17,
              "max": 17
            },
            "14": {
              "min": 18,
              "max": 19
            },
            "15": {
              "min": 20,
              "max": 20
            },
            "16": {
              "min": 21,
              "max": 21
            },
            "17": {
              "min": 22,
              "max": 23
            },
            "18": {
              "min": 24,
              "max": 24
            },
            "19": {
              "min": 25,
              "max": 32
            }
          }
        },
        {
          "name": "Powtarzanie liczb",
          "id": 4,
          "rows": {
            "1": {
              "min": 0,
              "max": 1
            },
            "2": {
              "min": 2,
              "max": 3
            },
            "3": {
              "min": 4,
              "max": 4
            },
            "4": {
              "min": 5,
              "max": 6
            },
            "5": {
              "min": 7,
              "max": 8
            },
            "6": {
              "min": 9,
              "max": 10
            },
            "7": {
              "min": 11,
              "max": 11
            },
            "8": {
              "min": 12,
              "max": 13
            },
            "9": {
              "min": 14,
              "max": 14
            },
            "10": {
              "min": 15,
              "max": 16
            },
            "11": {
              "min": 17,
              "max": 18
            },
            "12": {
              "min": 19,
              "max": 19
            },
            "13": {
              "min": 20,
              "max": 21
            },
            "14": {
              "min": 22,
              "max": 22
            },
            "15": {
              "min": 23,
              "max": 24
            },
            "16": {
              "min": 25,
              "max": 25
            },
            "17": {
              "min": 26,
              "max": 26
            },
            "18": {
              "min": 27,
              "max": 28
            },
            "19": {
              "min": 29,
              "max": 54
            }
          }
        },
        {
          "name": "Kodowanie",
          "id": 5,
          "rows": {
            "1": {
              "min": 0,
              "max": 1
            },
            "2": {
              "min": 2,
              "max": 3
            },
            "3": {
              "min": 4,
              "max": 5
            },
            "4": {
              "min": 6,
              "max": 8
            },
            "5": {
              "min": 9,
              "max": 12
            },
            "6": {
              "min": 13,
              "max": 16
            },
            "7": {
              "min": 17,
              "max": 21
            },
            "8": {
              "min": 22,
              "max": 26
            },
            "9": {
              "min": 27,
              "max": 31
            },
            "10": {
              "min": 32,
              "max": 35
            },
            "11": {
              "min": 36,
              "max": 40
            },
            "12": {
              "min": 41,
              "max": 44
            },
            "13": {
              "min": 45,
              "max": 48
            },
            "14": {
              "min": 49,
              "max": 52
            },
            "15": {
              "min": 53,
              "max": 56
            },
            "16": {
              "min": 57,
              "max": 59
            },
            "17": {
              "min": 60,
              "max": 63
            },
            "18": {
              "min": 64,
              "max": 66
            },
            "19": {
              "min": 67,
              "max": 75
            }
          }
        },
        {
          "name": "Słownik",
          "id": 6,
          "rows": {
            "1": {
              "min": 0,
              "max": 4
            },
            "2": {
              "min": 5,
              "max": 5
            },
            "3": {
              "min": 6,
              "max": 7
            },
            "4": {
              "min": 8,
              "max": 8
            },
            "5": {
              "min": 9,
              "max": 10
            },
            "6": {
              "min": 11,
              "max": 11
            },
            "7": {
              "min": 12,
              "max": 12
            },
            "8": {
              "min": 13,
              "max": 14
            },
            "9": {
              "min": 15,
              "max": 15
            },
            "10": {
              "min": 16,
              "max": 17
            },
            "11": {
              "min": 18,
              "max": 18
            },
            "12": {
              "min": 19,
              "max": 20
            },
            "13": {
              "min": 21,
              "max": 21
            },
            "14": {
              "min": 22,
              "max": 22
            },
            "15": {
              "min": 23,
              "max": 24
            },
            "16": {
              "min": 25,
              "max": 25
            },
            "17": {
              "min": 26,
              "max": 27
            },
            "18": {
              "min": 28,
              "max": 28
            },
            "19": {
              "min": 29,
              "max": 54
            }
          }
        },
        {
          "name": "Ważenie",
          "id": 7,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": 1,
              "max": 2
            },
            "3": {
              "min": 3,
              "max": 3
            },
            "4": {
              "min": 4,
              "max": 5
            },
            "5": {
              "min": 6,
              "max": 7
            },
            "6": {
              "min": 8,
              "max": 8
            },
            "7": {
              "min": 9,
              "max": 10
            },
            "8": {
              "min": 11,
              "max": 12
            },
            "9": {
              "min": 13,
              "max": 14
            },
            "10": {
              "min": 15,
              "max": 15
            },
            "11": {
              "min": 16,
              "max": 17
            },
            "12": {
              "min": 18,
              "max": 19
            },
            "13": {
              "min": 20,
              "max": 20
            },
            "14": {
              "min": 21,
              "max": 21
            },
            "15": {
              "min": 22,
              "max": 23
            },
            "16": {
              "min": 24,
              "max": 24
            },
            "17": {
              "min": 25,
              "max": 26
            },
            "18": {
              "min": 27,
              "max": 27
            },
            "19": {
              "min": 28,
              "max": 34
            }
          }
        },
        {
          "name": "Układnie wzoru",
          "id": 8,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": 1,
              "max": 1
            },
            "3": {
              "min": 2,
              "max": 2
            },
            "4": {
              "min": 3,
              "max": 3
            },
            "5": {
              "min": 4,
              "max": 5
            },
            "6": {
              "min": 6,
              "max": 7
            },
            "7": {
              "min": 8,
              "max": 8
            },
            "8": {
              "min": 9,
              "max": 10
            },
            "9": {
              "min": 11,
              "max": 11
            },
            "10": {
              "min": 12,
              "max": 12
            },
            "11": {
              "min": 13,
              "max": 14
            },
            "12": {
              "min": 15,
              "max": 15
            },
            "13": {
              "min": 16,
              "max": 16
            },
            "14": {
              "min": 17,
              "max": 17
            },
            "15": {
              "min": 18,
              "max": 18
            },
            "16": {
              "min": 19,
              "max": 19
            },
            "17": {
              "min": 20,
              "max": 20
            },
            "18": {
              "min": 21,
              "max": 21
            },
            "19": {
              "min": 22,
              "max": 29
            }
          }
        },
        {
          "name": "Zapamiętywanie obrazków",
          "id": 9,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 4
            },
            "3": {
              "min": 5,
              "max": 6
            },
            "4": {
              "min": 7,
              "max": 8
            },
            "5": {
              "min": 9,
              "max": 10
            },
            "6": {
              "min": 11,
              "max": 12
            },
            "7": {
              "min": 13,
              "max": 14
            },
            "8": {
              "min": 15,
              "max": 16
            },
            "9": {
              "min": 17,
              "max": 18
            },
            "10": {
              "min": 19,
              "max": 20
            },
            "11": {
              "min": 21,
              "max": 22
            },
            "12": {
              "min": 23,
              "max": 24
            },
            "13": {
              "min": 25,
              "max": 26
            },
            "14": {
              "min": 27,
              "max": 29
            },
            "15": {
              "min": 30,
              "max": 31
            },
            "16": {
              "min": 32,
              "max": 33
            },
            "17": {
              "min": 34,
              "max": 35
            },
            "18": {
              "min": 36,
              "max": 38
            },
            "19": {
              "min": 39,
              "max": 49
            }
          }
        },
        {
          "name": "Szukanie symboli",
          "id": 10,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 4
            },
            "3": {
              "min": 5,
              "max": 6
            },
            "4": {
              "min": 7,
              "max": 9
            },
            "5": {
              "min": 10,
              "max": 11
            },
            "6": {
              "min": 12,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 17
            },
            "8": {
              "min": 18,
              "max": 20
            },
            "9": {
              "min": 21,
              "max": 23
            },
            "10": {
              "min": 24,
              "max": 26
            },
            "11": {
              "min": 27,
              "max": 29
            },
            "12": {
              "min": 30,
              "max": 31
            },
            "13": {
              "min": 32,
              "max": 33
            },
            "14": {
              "min": 34,
              "max": 35
            },
            "15": {
              "min": 36,
              "max": 37
            },
            "16": {
              "min": 38,
              "max": 39
            },
            "17": {
              "min": 40,
              "max": 40
            },
            "18": {
              "min": 41,
              "max": 41
            },
            "19": {
              "min": 42,
              "max": 42
            }
          }
        },
        {
          "name": "Wiadomości",
          "id": 11,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 4
            },
            "3": {
              "min": 5,
              "max": 5
            },
            "4": {
              "min": 6,
              "max": 6
            },
            "5": {
              "min": null,
              "max": null
            },
            "6": {
              "min": 7,
              "max": 7
            },
            "7": {
              "min": 8,
              "max": 8
            },
            "8": {
              "min": 9,
              "max": 9
            },
            "9": {
              "min": 10,
              "max": 10
            },
            "10": {
              "min": 11,
              "max": 11
            },
            "11": {
              "min": 12,
              "max": 12
            },
            "12": {
              "min": 13,
              "max": 13
            },
            "13": {
              "min": null,
              "max": null
            },
            "14": {
              "min": 14,
              "max": 14
            },
            "15": {
              "min": 15,
              "max": 15
            },
            "16": {
              "min": 16,
              "max": 16
            },
            "17": {
              "min": 17,
              "max": 17
            },
            "18": {
              "min": 18,
              "max": 18
            },
            "19": {
              "min": 19,
              "max": 31
            }
          }
        },
        {
          "name": "Tworzenie pojęć",
          "id": 12,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": null,
              "max": null
            },
            "3": {
              "min": 1,
              "max": 1
            },
            "4": {
              "min": 2,
              "max": 2
            },
            "5": {
              "min": 3,
              "max": 4
            },
            "6": {
              "min": 5,
              "max": 5
            },
            "7": {
              "min": 6,
              "max": 6
            },
            "8": {
              "min": 7,
              "max": 7
            },
            "9": {
              "min": 8,
              "max": 9
            },
            "10": {
              "min": 10,
              "max": 10
            },
            "11": {
              "min": 11,
              "max": 11
            },
            "12": {
              "min": 12,
              "max": 12
            },
            "13": {
              "min": 13,
              "max": 14
            },
            "14": {
              "min": 15,
              "max": 15
            },
            "15": {
              "min": 16,
              "max": 16
            },
            "16": {
              "min": 17,
              "max": 17
            },
            "17": {
              "min": 18,
              "max": 18
            },
            "18": {
              "min": 19,
              "max": 19
            },
            "19": {
              "min": 20,
              "max": 27
            }
          }
        },
        {
          "name": "Porządkowanie liter i liczb",
          "id": 13,
          "rows": {
            "1": {
              "min": null,
              "max": null
            },
            "2": {
              "min": 0,
              "max": 0
            },
            "3": {
              "min": 1,
              "max": 1
            },
            "4": {
              "min": 2,
              "max": 2
            },
            "5": {
              "min": 3,
              "max": 4
            },
            "6": {
              "min": 5,
              "max": 5
            },
            "7": {
              "min": 6,
              "max": 7
            },
            "8": {
              "min": 8,
              "max": 8
            },
            "9": {
              "min": 9,
              "max": 9
            },
            "10": {
              "min": 10,
              "max": 11
            },
            "11": {
              "min": 12,
              "max": 12
            },
            "12": {
              "min": 13,
              "max": 13
            },
            "13": {
              "min": 14,
              "max": 14
            },
            "14": {
              "min": 15,
              "max": 16
            },
            "15": {
              "min": 17,
              "max": 17
            },
            "16": {
              "min": 18,
              "max": 18
            },
            "17": {
              "min": 19,
              "max": 19
            },
            "18": {
              "min": 20,
              "max": 20
            },
            "19": {
              "min": 21,
              "max": 30
            }
          }
        },
        {
          "name": "Wykreślanie",
          "id": 14,
          "rows": {
            "1": {
              "min": 0,
              "max": 12
            },
            "2": {
              "min": 13,
              "max": 15
            },
            "3": {
              "min": 16,
              "max": 19
            },
            "4": {
              "min": 20,
              "max": 23
            },
            "5": {
              "min": 24,
              "max": 27
            },
            "6": {
              "min": 28,
              "max": 31
            },
            "7": {
              "min": 32,
              "max": 36
            },
            "8": {
              "min": 37,
              "max": 40
            },
            "9": {
              "min": 41,
              "max": 45
            },
            "10": {
              "min": 46,
              "max": 50
            },
            "11": {
              "min": 51,
              "max": 55
            },
            "12": {
              "min": 56,
              "max": 60
            },
            "13": {
              "min": 61,
              "max": 66
            },
            "14": {
              "min": 67,
              "max": 71
            },
            "15": {
              "min": 72,
              "max": 77
            },
            "16": {
              "min": 78,
              "max": 82
            },
            "17": {
              "min": 83,
              "max": 88
            },
            "18": {
              "min": 89,
              "max": 94
            },
            "19": {
              "min": 95,
              "max": 128
            }
          }
        },
        {
          "name": "Rozumienie",
          "id": 15,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": 1,
              "max": 1
            },
            "3": {
              "min": 2,
              "max": 2
            },
            "4": {
              "min": 3,
              "max": 4
            },
            "5": {
              "min": 5,
              "max": 5
            },
            "6": {
              "min": 6,
              "max": 6
            },
            "7": {
              "min": 7,
              "max": 8
            },
            "8": {
              "min": 9,
              "max": 9
            },
            "9": {
              "min": 10,
              "max": 11
            },
            "10": {
              "min": 12,
              "max": 12
            },
            "11": {
              "min": 13,
              "max": 13
            },
            "12": {
              "min": 14,
              "max": 15
            },
            "13": {
              "min": 16,
              "max": 16
            },
            "14": {
              "min": 17,
              "max": 17
            },
            "15": {
              "min": 18,
              "max": 18
            },
            "16": {
              "min": 19,
              "max": 20
            },
            "17": {
              "min": 21,
              "max": 21
            },
            "18": {
              "min": 22,
              "max": 22
            },
            "19": {
              "min": 23,
              "max": 38
            }
          }
        },
        {
          "name": "Arytmetyka",
          "id": 16,
          "rows": {
            "1": {
              "min": 0,
              "max": 1
            },
            "2": {
              "min": 2,
              "max": 2
            },
            "3": {
              "min": 3,
              "max": 4
            },
            "4": {
              "min": 5,
              "max": 5
            },
            "5": {
              "min": 6,
              "max": 7
            },
            "6": {
              "min": 8,
              "max": 8
            },
            "7": {
              "min": 9,
              "max": 9
            },
            "8": {
              "min": 10,
              "max": 11
            },
            "9": {
              "min": 12,
              "max": 12
            },
            "10": {
              "min": 13,
              "max": 13
            },
            "11": {
              "min": 14,
              "max": 14
            },
            "12": {
              "min": 15,
              "max": 15
            },
            "13": {
              "min": 16,
              "max": 16
            },
            "14": {
              "min": 17,
              "max": 17
            },
            "15": {
              "min": 18,
              "max": 18
            },
            "16": {
              "min": 19,
              "max": 19
            },
            "17": {
              "min": 20,
              "max": 20
            },
            "18": {
              "min": 21,
              "max": 21
            },
            "19": {
              "min": 22,
              "max": 34
            }
          }
        }
      ],     
    
    78711: [
        {
          "name": "Wzory z klocków",
          "id": 1,
          "rows": {
            "1": {
              "min": 0,
              "max": 1
            },
            "2": {
              "min": 2,
              "max": 3
            },
            "3": {
              "min": 4,
              "max": 5
            },
            "4": {
              "min": 6,
              "max": 8
            },
            "5": {
              "min": 9,
              "max": 10
            },
            "6": {
              "min": 11,
              "max": 13
            },
            "7": {
              "min": 14,
              "max": 15
            },
            "8": {
              "min": 16,
              "max": 18
            },
            "9": {
              "min": 19,
              "max": 21
            },
            "10": {
              "min": 22,
              "max": 23
            },
            "11": {
              "min": 24,
              "max": 26
            },
            "12": {
              "min": 27,
              "max": 28
            },
            "13": {
              "min": 29,
              "max": 31
            },
            "14": {
              "min": 32,
              "max": 34
            },
            "15": {
              "min": 35,
              "max": 36
            },
            "16": {
              "min": 37,
              "max": 39
            },
            "17": {
              "min": 40,
              "max": 41
            },
            "18": {
              "min": 42,
              "max": 44
            },
            "19": {
              "min": 45,
              "max": 58
            }
          }
        },
        {
          "name": "Podobieństwa",
          "id": 2,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": 1,
              "max": 1
            },
            "3": {
              "min": 2,
              "max": 2
            },
            "4": {
              "min": 3,
              "max": 4
            },
            "5": {
              "min": 5,
              "max": 7
            },
            "6": {
              "min": 8,
              "max": 9
            },
            "7": {
              "min": 10,
              "max": 12
            },
            "8": {
              "min": 13,
              "max": 14
            },
            "9": {
              "min": 15,
              "max": 17
            },
            "10": {
              "min": 18,
              "max": 19
            },
            "11": {
              "min": 20,
              "max": 22
            },
            "12": {
              "min": 23,
              "max": 24
            },
            "13": {
              "min": 25,
              "max": 26
            },
            "14": {
              "min": 27,
              "max": 28
            },
            "15": {
              "min": 29,
              "max": 29
            },
            "16": {
              "min": 30,
              "max": 31
            },
            "17": {
              "min": 32,
              "max": 33
            },
            "18": {
              "min": 34,
              "max": 35
            },
            "19": {
              "min": 36,
              "max": 46
            }
          }
        },
        {
          "name": "Matryce",
          "id": 3,
          "rows": {
            "1": {
              "min": 0,
              "max": 1
            },
            "2": {
              "min": 2,
              "max": 2
            },
            "3": {
              "min": 3,
              "max": 4
            },
            "4": {
              "min": 5,
              "max": 5
            },
            "5": {
              "min": 6,
              "max": 7
            },
            "6": {
              "min": 8,
              "max": 8
            },
            "7": {
              "min": 9,
              "max": 10
            },
            "8": {
              "min": 11,
              "max": 11
            },
            "9": {
              "min": 12,
              "max": 13
            },
            "10": {
              "min": 14,
              "max": 14
            },
            "11": {
              "min": 15,
              "max": 15
            },
            "12": {
              "min": 16,
              "max": 17
            },
            "13": {
              "min": 18,
              "max": 18
            },
            "14": {
              "min": 19,
              "max": 19
            },
            "15": {
              "min": 20,
              "max": 21
            },
            "16": {
              "min": 22,
              "max": 22
            },
            "17": {
              "min": 23,
              "max": 23
            },
            "18": {
              "min": 24,
              "max": 24
            },
            "19": {
              "min": 25,
              "max": 32
            }
          }
        },
        {
          "name": "Powtarzanie liczb",
          "id": 4,
          "rows": {
            "1": {
              "min": 0,
              "max": 2
            },
            "2": {
              "min": 3,
              "max": 3
            },
            "3": {
              "min": 4,
              "max": 5
            },
            "4": {
              "min": 6,
              "max": 7
            },
            "5": {
              "min": 8,
              "max": 9
            },
            "6": {
              "min": 10,
              "max": 10
            },
            "7": {
              "min": 11,
              "max": 12
            },
            "8": {
              "min": 13,
              "max": 14
            },
            "9": {
              "min": 15,
              "max": 15
            },
            "10": {
              "min": 16,
              "max": 17
            },
            "11": {
              "min": 18,
              "max": 18
            },
            "12": {
              "min": 19,
              "max": 20
            },
            "13": {
              "min": 21,
              "max": 21
            },
            "14": {
              "min": 22,
              "max": 23
            },
            "15": {
              "min": 24,
              "max": 24
            },
            "16": {
              "min": 25,
              "max": 26
            },
            "17": {
              "min": 27,
              "max": 27
            },
            "18": {
              "min": 28,
              "max": 29
            },
            "19": {
              "min": 30,
              "max": 54
            }
          }
        },
        {
          "name": "Kodowanie",
          "id": 5,
          "rows": {
            "1": {
              "min": 0,
              "max": 2
            },
            "2": {
              "min": 3,
              "max": 3
            },
            "3": {
              "min": 4,
              "max": 6
            },
            "4": {
              "min": 7,
              "max": 9
            },
            "5": {
              "min": 10,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 17
            },
            "7": {
              "min": 18,
              "max": 22
            },
            "8": {
              "min": 23,
              "max": 27
            },
            "9": {
              "min": 28,
              "max": 32
            },
            "10": {
              "min": 33,
              "max": 37
            },
            "11": {
              "min": 38,
              "max": 42
            },
            "12": {
              "min": 43,
              "max": 47
            },
            "13": {
              "min": 48,
              "max": 51
            },
            "14": {
              "min": 52,
              "max": 55
            },
            "15": {
              "min": 56,
              "max": 59
            },
            "16": {
              "min": 60,
              "max": 63
            },
            "17": {
              "min": 64,
              "max": 66
            },
            "18": {
              "min": 67,
              "max": 69
            },
            "19": {
              "min": 70,
              "max": 75
            }
          }
        },
        {
          "name": "Słownik",
          "id": 6,
          "rows": {
            "1": {
              "min": 0,
              "max": 5
            },
            "2": {
              "min": 6,
              "max": 6
            },
            "3": {
              "min": 7,
              "max": 7
            },
            "4": {
              "min": 8,
              "max": 9
            },
            "5": {
              "min": 10,
              "max": 10
            },
            "6": {
              "min": 11,
              "max": 11
            },
            "7": {
              "min": 12,
              "max": 13
            },
            "8": {
              "min": 14,
              "max": 14
            },
            "9": {
              "min": 15,
              "max": 16
            },
            "10": {
              "min": 17,
              "max": 17
            },
            "11": {
              "min": 18,
              "max": 19
            },
            "12": {
              "min": 20,
              "max": 20
            },
            "13": {
              "min": 21,
              "max": 22
            },
            "14": {
              "min": 23,
              "max": 24
            },
            "15": {
              "min": 25,
              "max": 25
            },
            "16": {
              "min": 26,
              "max": 27
            },
            "17": {
              "min": 28,
              "max": 28
            },
            "18": {
              "min": 29,
              "max": 30
            },
            "19": {
              "min": 31,
              "max": 54
            }
          }
        },
        {
          "name": "Ważenie",
          "id": 7,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": 1,
              "max": 2
            },
            "3": {
              "min": 3,
              "max": 3
            },
            "4": {
              "min": 4,
              "max": 5
            },
            "5": {
              "min": 6,
              "max": 7
            },
            "6": {
              "min": 8,
              "max": 9
            },
            "7": {
              "min": 10,
              "max": 11
            },
            "8": {
              "min": 12,
              "max": 13
            },
            "9": {
              "min": 14,
              "max": 15
            },
            "10": {
              "min": 16,
              "max": 16
            },
            "11": {
              "min": 17,
              "max": 18
            },
            "12": {
              "min": 19,
              "max": 20
            },
            "13": {
              "min": 21,
              "max": 21
            },
            "14": {
              "min": 22,
              "max": 23
            },
            "15": {
              "min": 24,
              "max": 24
            },
            "16": {
              "min": 25,
              "max": 25
            },
            "17": {
              "min": 26,
              "max": 27
            },
            "18": {
              "min": 28,
              "max": 28
            },
            "19": {
              "min": 29,
              "max": 34
            }
          }
        },
        {
          "name": "Układnie wzoru",
          "id": 8,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": 1,
              "max": 1
            },
            "3": {
              "min": 2,
              "max": 2
            },
            "4": {
              "min": 3,
              "max": 4
            },
            "5": {
              "min": 5,
              "max": 5
            },
            "6": {
              "min": 6,
              "max": 7
            },
            "7": {
              "min": 8,
              "max": 9
            },
            "8": {
              "min": 10,
              "max": 10
            },
            "9": {
              "min": 11,
              "max": 12
            },
            "10": {
              "min": 13,
              "max": 13
            },
            "11": {
              "min": 14,
              "max": 14
            },
            "12": {
              "min": 15,
              "max": 15
            },
            "13": {
              "min": 16,
              "max": 17
            },
            "14": {
              "min": 18,
              "max": 18
            },
            "15": {
              "min": 19,
              "max": 19
            },
            "16": {
              "min": 20,
              "max": 20
            },
            "17": {
              "min": 21,
              "max": 21
            },
            "18": {
              "min": 22,
              "max": 22
            },
            "19": {
              "min": 23,
              "max": 29
            }
          }
        },
        {
          "name": "Zapamiętywanie obrazków",
          "id": 9,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 4
            },
            "3": {
              "min": 5,
              "max": 6
            },
            "4": {
              "min": 7,
              "max": 8
            },
            "5": {
              "min": 9,
              "max": 10
            },
            "6": {
              "min": 11,
              "max": 12
            },
            "7": {
              "min": 13,
              "max": 14
            },
            "8": {
              "min": 15,
              "max": 17
            },
            "9": {
              "min": 18,
              "max": 19
            },
            "10": {
              "min": 20,
              "max": 21
            },
            "11": {
              "min": 22,
              "max": 23
            },
            "12": {
              "min": 24,
              "max": 25
            },
            "13": {
              "min": 26,
              "max": 27
            },
            "14": {
              "min": 28,
              "max": 30
            },
            "15": {
              "min": 31,
              "max": 32
            },
            "16": {
              "min": 33,
              "max": 34
            },
            "17": {
              "min": 35,
              "max": 36
            },
            "18": {
              "min": 37,
              "max": 38
            },
            "19": {
              "min": 39,
              "max": 49
            }
          }
        },
        {
          "name": "Szukanie symboli",
          "id": 10,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 5
            },
            "3": {
              "min": 6,
              "max": 7
            },
            "4": {
              "min": 8,
              "max": 10
            },
            "5": {
              "min": 11,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 16
            },
            "7": {
              "min": 17,
              "max": 19
            },
            "8": {
              "min": 20,
              "max": 22
            },
            "9": {
              "min": 23,
              "max": 25
            },
            "10": {
              "min": 26,
              "max": 28
            },
            "11": {
              "min": 29,
              "max": 30
            },
            "12": {
              "min": 31,
              "max": 33
            },
            "13": {
              "min": 34,
              "max": 35
            },
            "14": {
              "min": 36,
              "max": 37
            },
            "15": {
              "min": 38,
              "max": 38
            },
            "16": {
              "min": 39,
              "max": 40
            },
            "17": {
              "min": 41,
              "max": 41
            },
            "18": {
              "min": null,
              "max": null
            },
            "19": {
              "min": 42,
              "max": 42
            }
          }
        },
        {
          "name": "Wiadomości",
          "id": 11,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 4
            },
            "3": {
              "min": 5,
              "max": 5
            },
            "4": {
              "min": 6,
              "max": 6
            },
            "5": {
              "min": 7,
              "max": 7
            },
            "6": {
              "min": 8,
              "max": 8
            },
            "7": {
              "min": 9,
              "max": 9
            },
            "8": {
              "min": 10,
              "max": 10
            },
            "9": {
              "min": null,
              "max": null
            },
            "10": {
              "min": 11,
              "max": 11
            },
            "11": {
              "min": 12,
              "max": 12
            },
            "12": {
              "min": 13,
              "max": 13
            },
            "13": {
              "min": 14,
              "max": 14
            },
            "14": {
              "min": 15,
              "max": 15
            },
            "15": {
              "min": 16,
              "max": 16
            },
            "16": {
              "min": 17,
              "max": 17
            },
            "17": {
              "min": 18,
              "max": 18
            },
            "18": {
              "min": null,
              "max": null
            },
            "19": {
              "min": 19,
              "max": 31
            }
          }
        },
        {
          "name": "Tworzenie pojęć",
          "id": 12,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": null,
              "max": null
            },
            "3": {
              "min": 1,
              "max": 1
            },
            "4": {
              "min": 2,
              "max": 3
            },
            "5": {
              "min": 4,
              "max": 4
            },
            "6": {
              "min": 5,
              "max": 5
            },
            "7": {
              "min": 6,
              "max": 7
            },
            "8": {
              "min": 8,
              "max": 8
            },
            "9": {
              "min": 9,
              "max": 9
            },
            "10": {
              "min": 10,
              "max": 10
            },
            "11": {
              "min": 11,
              "max": 12
            },
            "12": {
              "min": 13,
              "max": 13
            },
            "13": {
              "min": 14,
              "max": 14
            },
            "14": {
              "min": 15,
              "max": 15
            },
            "15": {
              "min": 16,
              "max": 16
            },
            "16": {
              "min": 17,
              "max": 17
            },
            "17": {
              "min": 18,
              "max": 19
            },
            "18": {
              "min": 20,
              "max": 20
            },
            "19": {
              "min": 21,
              "max": 27
            }
          }
        },
        {
          "name": "Porządkowanie liter i liczb",
          "id": 13,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": null,
              "max": null
            },
            "3": {
              "min": 1,
              "max": 2
            },
            "4": {
              "min": 3,
              "max": 3
            },
            "5": {
              "min": 4,
              "max": 5
            },
            "6": {
              "min": 6,
              "max": 6
            },
            "7": {
              "min": 7,
              "max": 8
            },
            "8": {
              "min": 9,
              "max": 9
            },
            "9": {
              "min": 10,
              "max": 10
            },
            "10": {
              "min": 11,
              "max": 12
            },
            "11": {
              "min": 13,
              "max": 13
            },
            "12": {
              "min": 14,
              "max": 14
            },
            "13": {
              "min": 15,
              "max": 15
            },
            "14": {
              "min": 16,
              "max": 16
            },
            "15": {
              "min": 17,
              "max": 17
            },
            "16": {
              "min": 18,
              "max": 18
            },
            "17": {
              "min": 19,
              "max": 19
            },
            "18": {
              "min": 20,
              "max": 20
            },
            "19": {
              "min": 21,
              "max": 30
            }
          }
        },
        {
          "name": "Wykreślanie",
          "id": 14,
          "rows": {
            "1": {
              "min": 0,
              "max": 14
            },
            "2": {
              "min": 15,
              "max": 18
            },
            "3": {
              "min": 19,
              "max": 21
            },
            "4": {
              "min": 22,
              "max": 25
            },
            "5": {
              "min": 26,
              "max": 29
            },
            "6": {
              "min": 30,
              "max": 33
            },
            "7": {
              "min": 34,
              "max": 37
            },
            "8": {
              "min": 38,
              "max": 42
            },
            "9": {
              "min": 43,
              "max": 47
            },
            "10": {
              "min": 48,
              "max": 52
            },
            "11": {
              "min": 53,
              "max": 57
            },
            "12": {
              "min": 58,
              "max": 62
            },
            "13": {
              "min": 63,
              "max": 68
            },
            "14": {
              "min": 69,
              "max": 74
            },
            "15": {
              "min": 75,
              "max": 80
            },
            "16": {
              "min": 81,
              "max": 86
            },
            "17": {
              "min": 87,
              "max": 92
            },
            "18": {
              "min": 93,
              "max": 99
            },
            "19": {
              "min": 100,
              "max": 128
            }
          }
        },
        {
          "name": "Rozumienie",
          "id": 15,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": 1,
              "max": 1
            },
            "3": {
              "min": 2,
              "max": 2
            },
            "4": {
              "min": 3,
              "max": 4
            },
            "5": {
              "min": 5,
              "max": 5
            },
            "6": {
              "min": 6,
              "max": 7
            },
            "7": {
              "min": 8,
              "max": 8
            },
            "8": {
              "min": 9,
              "max": 10
            },
            "9": {
              "min": 11,
              "max": 11
            },
            "10": {
              "min": 12,
              "max": 13
            },
            "11": {
              "min": 14,
              "max": 14
            },
            "12": {
              "min": 15,
              "max": 15
            },
            "13": {
              "min": 16,
              "max": 17
            },
            "14": {
              "min": 18,
              "max": 18
            },
            "15": {
              "min": 19,
              "max": 19
            },
            "16": {
              "min": 20,
              "max": 21
            },
            "17": {
              "min": 22,
              "max": 22
            },
            "18": {
              "min": 23,
              "max": 23
            },
            "19": {
              "min": 24,
              "max": 38
            }
          }
        },
        {
          "name": "Arytmetyka",
          "id": 16,
          "rows": {
            "1": {
              "min": 0,
              "max": 1
            },
            "2": {
              "min": 2,
              "max": 3
            },
            "3": {
              "min": 4,
              "max": 4
            },
            "4": {
              "min": 5,
              "max": 6
            },
            "5": {
              "min": 7,
              "max": 7
            },
            "6": {
              "min": 8,
              "max": 9
            },
            "7": {
              "min": 10,
              "max": 10
            },
            "8": {
              "min": 11,
              "max": 11
            },
            "9": {
              "min": 12,
              "max": 13
            },
            "10": {
              "min": 14,
              "max": 14
            },
            "11": {
              "min": 15,
              "max": 15
            },
            "12": {
              "min": 16,
              "max": 16
            },
            "13": {
              "min": 17,
              "max": 17
            },
            "14": {
              "min": 18,
              "max": 18
            },
            "15": {
              "min": 19,
              "max": 19
            },
            "16": {
              "min": 20,
              "max": 20
            },
            "17": {
              "min": 21,
              "max": 21
            },
            "18": {
              "min": 22,
              "max": 22
            },
            "19": {
              "min": 23,
              "max": 34
            }
          }
        }
      ],
    
    8083: [
        {
          "name": "Wzory z klocków",
          "id": 1,
          "rows": {
            "1": {
              "min": 0,
              "max": 2
            },
            "2": {
              "min": 3,
              "max": 4
            },
            "3": {
              "min": 5,
              "max": 6
            },
            "4": {
              "min": 7,
              "max": 9
            },
            "5": {
              "min": 10,
              "max": 11
            },
            "6": {
              "min": 12,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 16
            },
            "8": {
              "min": 17,
              "max": 19
            },
            "9": {
              "min": 20,
              "max": 21
            },
            "10": {
              "min": 22,
              "max": 24
            },
            "11": {
              "min": 25,
              "max": 27
            },
            "12": {
              "min": 28,
              "max": 29
            },
            "13": {
              "min": 30,
              "max": 32
            },
            "14": {
              "min": 33,
              "max": 35
            },
            "15": {
              "min": 36,
              "max": 38
            },
            "16": {
              "min": 39,
              "max": 40
            },
            "17": {
              "min": 41,
              "max": 43
            },
            "18": {
              "min": 44,
              "max": 46
            },
            "19": {
              "min": 47,
              "max": 58
            }
          }
        },
        {
          "name": "Podobieństwa",
          "id": 2,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": 1,
              "max": 1
            },
            "3": {
              "min": 2,
              "max": 3
            },
            "4": {
              "min": 4,
              "max": 5
            },
            "5": {
              "min": 6,
              "max": 8
            },
            "6": {
              "min": 9,
              "max": 11
            },
            "7": {
              "min": 12,
              "max": 13
            },
            "8": {
              "min": 14,
              "max": 16
            },
            "9": {
              "min": 17,
              "max": 18
            },
            "10": {
              "min": 19,
              "max": 20
            },
            "11": {
              "min": 21,
              "max": 22
            },
            "12": {
              "min": 23,
              "max": 24
            },
            "13": {
              "min": 25,
              "max": 26
            },
            "14": {
              "min": 27,
              "max": 28
            },
            "15": {
              "min": 29,
              "max": 30
            },
            "16": {
              "min": 31,
              "max": 32
            },
            "17": {
              "min": 33,
              "max": 33
            },
            "18": {
              "min": 34,
              "max": 35
            },
            "19": {
              "min": 36,
              "max": 46
            }
          }
        },
        {
          "name": "Matryce",
          "id": 3,
          "rows": {
            "1": {
              "min": 0,
              "max": 1
            },
            "2": {
              "min": 2,
              "max": 3
            },
            "3": {
              "min": 4,
              "max": 4
            },
            "4": {
              "min": 5,
              "max": 6
            },
            "5": {
              "min": 7,
              "max": 7
            },
            "6": {
              "min": 8,
              "max": 9
            },
            "7": {
              "min": 10,
              "max": 10
            },
            "8": {
              "min": 11,
              "max": 12
            },
            "9": {
              "min": 13,
              "max": 13
            },
            "10": {
              "min": 14,
              "max": 14
            },
            "11": {
              "min": 15,
              "max": 16
            },
            "12": {
              "min": 17,
              "max": 17
            },
            "13": {
              "min": 18,
              "max": 18
            },
            "14": {
              "min": 19,
              "max": 20
            },
            "15": {
              "min": 21,
              "max": 21
            },
            "16": {
              "min": 22,
              "max": 22
            },
            "17": {
              "min": 23,
              "max": 24
            },
            "18": {
              "min": 25,
              "max": 25
            },
            "19": {
              "min": 26,
              "max": 32
            }
          }
        },
        {
          "name": "Powtarzanie liczb",
          "id": 4,
          "rows": {
            "1": {
              "min": 0,
              "max": 2
            },
            "2": {
              "min": 3,
              "max": 4
            },
            "3": {
              "min": 5,
              "max": 6
            },
            "4": {
              "min": 7,
              "max": 8
            },
            "5": {
              "min": 9,
              "max": 9
            },
            "6": {
              "min": 10,
              "max": 11
            },
            "7": {
              "min": 12,
              "max": 13
            },
            "8": {
              "min": 14,
              "max": 14
            },
            "9": {
              "min": 15,
              "max": 16
            },
            "10": {
              "min": 17,
              "max": 18
            },
            "11": {
              "min": 19,
              "max": 19
            },
            "12": {
              "min": 20,
              "max": 21
            },
            "13": {
              "min": 22,
              "max": 22
            },
            "14": {
              "min": 23,
              "max": 24
            },
            "15": {
              "min": 25,
              "max": 25
            },
            "16": {
              "min": 26,
              "max": 27
            },
            "17": {
              "min": 28,
              "max": 28
            },
            "18": {
              "min": 29,
              "max": 30
            },
            "19": {
              "min": 31,
              "max": 54
            }
          }
        },
        {
          "name": "Kodowanie",
          "id": 5,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 10
            },
            "3": {
              "min": 11,
              "max": 13
            },
            "4": {
              "min": 14,
              "max": 15
            },
            "5": {
              "min": 16,
              "max": 18
            },
            "6": {
              "min": 19,
              "max": 21
            },
            "7": {
              "min": 22,
              "max": 24
            },
            "8": {
              "min": 25,
              "max": 27
            },
            "9": {
              "min": 28,
              "max": 30
            },
            "10": {
              "min": 31,
              "max": 33
            },
            "11": {
              "min": 34,
              "max": 36
            },
            "12": {
              "min": 37,
              "max": 39
            },
            "13": {
              "min": 40,
              "max": 41
            },
            "14": {
              "min": 42,
              "max": 44
            },
            "15": {
              "min": 45,
              "max": 47
            },
            "16": {
              "min": 48,
              "max": 50
            },
            "17": {
              "min": 51,
              "max": 53
            },
            "18": {
              "min": 54,
              "max": 56
            },
            "19": {
              "min": 57,
              "max": 117
            }
          }
        },
        {
          "name": "Słownik",
          "id": 6,
          "rows": {
            "1": {
              "min": 0,
              "max": 5
            },
            "2": {
              "min": 6,
              "max": 7
            },
            "3": {
              "min": 8,
              "max": 8
            },
            "4": {
              "min": 9,
              "max": 9
            },
            "5": {
              "min": 10,
              "max": 11
            },
            "6": {
              "min": 12,
              "max": 12
            },
            "7": {
              "min": 13,
              "max": 13
            },
            "8": {
              "min": 14,
              "max": 15
            },
            "9": {
              "min": 16,
              "max": 17
            },
            "10": {
              "min": 18,
              "max": 18
            },
            "11": {
              "min": 19,
              "max": 20
            },
            "12": {
              "min": 21,
              "max": 21
            },
            "13": {
              "min": 22,
              "max": 23
            },
            "14": {
              "min": 24,
              "max": 25
            },
            "15": {
              "min": 26,
              "max": 27
            },
            "16": {
              "min": 28,
              "max": 28
            },
            "17": {
              "min": 29,
              "max": 30
            },
            "18": {
              "min": 31,
              "max": 32
            },
            "19": {
              "min": 33,
              "max": 54
            }
          }
        },
        {
          "name": "Ważenie",
          "id": 7,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": 1,
              "max": 2
            },
            "3": {
              "min": 3,
              "max": 3
            },
            "4": {
              "min": 4,
              "max": 5
            },
            "5": {
              "min": 6,
              "max": 7
            },
            "6": {
              "min": 8,
              "max": 9
            },
            "7": {
              "min": 10,
              "max": 11
            },
            "8": {
              "min": 12,
              "max": 13
            },
            "9": {
              "min": 14,
              "max": 15
            },
            "10": {
              "min": 16,
              "max": 17
            },
            "11": {
              "min": 18,
              "max": 19
            },
            "12": {
              "min": 20,
              "max": 20
            },
            "13": {
              "min": 21,
              "max": 22
            },
            "14": {
              "min": 23,
              "max": 23
            },
            "15": {
              "min": 24,
              "max": 25
            },
            "16": {
              "min": 26,
              "max": 26
            },
            "17": {
              "min": 27,
              "max": 27
            },
            "18": {
              "min": 28,
              "max": 29
            },
            "19": {
              "min": 30,
              "max": 34
            }
          }
        },
        {
          "name": "Układnie wzoru",
          "id": 8,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": 1,
              "max": 1
            },
            "3": {
              "min": 2,
              "max": 2
            },
            "4": {
              "min": 3,
              "max": 4
            },
            "5": {
              "min": 5,
              "max": 6
            },
            "6": {
              "min": 7,
              "max": 7
            },
            "7": {
              "min": 8,
              "max": 9
            },
            "8": {
              "min": 10,
              "max": 11
            },
            "9": {
              "min": 12,
              "max": 12
            },
            "10": {
              "min": 13,
              "max": 14
            },
            "11": {
              "min": 15,
              "max": 15
            },
            "12": {
              "min": 16,
              "max": 16
            },
            "13": {
              "min": 17,
              "max": 17
            },
            "14": {
              "min": 18,
              "max": 18
            },
            "15": {
              "min": 19,
              "max": 19
            },
            "16": {
              "min": 20,
              "max": 20
            },
            "17": {
              "min": 21,
              "max": 21
            },
            "18": {
              "min": 22,
              "max": 22
            },
            "19": {
              "min": 23,
              "max": 29
            }
          }
        },
        {
          "name": "Zapamiętywanie obrazków",
          "id": 9,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 4
            },
            "3": {
              "min": 5,
              "max": 6
            },
            "4": {
              "min": 7,
              "max": 9
            },
            "5": {
              "min": 10,
              "max": 11
            },
            "6": {
              "min": 12,
              "max": 13
            },
            "7": {
              "min": 14,
              "max": 15
            },
            "8": {
              "min": 16,
              "max": 17
            },
            "9": {
              "min": 18,
              "max": 20
            },
            "10": {
              "min": 21,
              "max": 22
            },
            "11": {
              "min": 23,
              "max": 24
            },
            "12": {
              "min": 25,
              "max": 26
            },
            "13": {
              "min": 27,
              "max": 28
            },
            "14": {
              "min": 29,
              "max": 31
            },
            "15": {
              "min": 32,
              "max": 33
            },
            "16": {
              "min": 34,
              "max": 35
            },
            "17": {
              "min": 36,
              "max": 37
            },
            "18": {
              "min": 38,
              "max": 39
            },
            "19": {
              "min": 40,
              "max": 49
            }
          }
        },
        {
          "name": "Szukanie symboli",
          "id": 10,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 5
            },
            "3": {
              "min": 6,
              "max": 7
            },
            "4": {
              "min": 8,
              "max": 9
            },
            "5": {
              "min": 10,
              "max": 11
            },
            "6": {
              "min": 12,
              "max": 13
            },
            "7": {
              "min": 14,
              "max": 15
            },
            "8": {
              "min": 16,
              "max": 16
            },
            "9": {
              "min": 17,
              "max": 18
            },
            "10": {
              "min": 19,
              "max": 20
            },
            "11": {
              "min": 21,
              "max": 22
            },
            "12": {
              "min": 23,
              "max": 23
            },
            "13": {
              "min": 24,
              "max": 25
            },
            "14": {
              "min": 26,
              "max": 27
            },
            "15": {
              "min": 28,
              "max": 29
            },
            "16": {
              "min": 30,
              "max": 30
            },
            "17": {
              "min": 31,
              "max": 32
            },
            "18": {
              "min": 33,
              "max": 34
            },
            "19": {
              "min": 35,
              "max": 60
            }
          }
        },
        {
          "name": "Wiadomości",
          "id": 11,
          "rows": {
            "1": {
              "min": 0,
              "max": 4
            },
            "2": {
              "min": 5,
              "max": 5
            },
            "3": {
              "min": null,
              "max": null
            },
            "4": {
              "min": 6,
              "max": 6
            },
            "5": {
              "min": 7,
              "max": 7
            },
            "6": {
              "min": 8,
              "max": 8
            },
            "7": {
              "min": 9,
              "max": 9
            },
            "8": {
              "min": 10,
              "max": 10
            },
            "9": {
              "min": 11,
              "max": 11
            },
            "10": {
              "min": 12,
              "max": 12
            },
            "11": {
              "min": 13,
              "max": 13
            },
            "12": {
              "min": 14,
              "max": 14
            },
            "13": {
              "min": 15,
              "max": 15
            },
            "14": {
              "min": null,
              "max": null
            },
            "15": {
              "min": 16,
              "max": 16
            },
            "16": {
              "min": 17,
              "max": 17
            },
            "17": {
              "min": 18,
              "max": 18
            },
            "18": {
              "min": 19,
              "max": 19
            },
            "19": {
              "min": 20,
              "max": 31
            }
          }
        },
        {
          "name": "Tworzenie pojęć",
          "id": 12,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": null,
              "max": null
            },
            "3": {
              "min": 1,
              "max": 2
            },
            "4": {
              "min": 3,
              "max": 3
            },
            "5": {
              "min": 4,
              "max": 4
            },
            "6": {
              "min": 5,
              "max": 6
            },
            "7": {
              "min": 7,
              "max": 7
            },
            "8": {
              "min": 8,
              "max": 8
            },
            "9": {
              "min": 9,
              "max": 10
            },
            "10": {
              "min": 11,
              "max": 11
            },
            "11": {
              "min": 12,
              "max": 12
            },
            "12": {
              "min": 13,
              "max": 13
            },
            "13": {
              "min": 14,
              "max": 14
            },
            "14": {
              "min": 15,
              "max": 16
            },
            "15": {
              "min": 17,
              "max": 17
            },
            "16": {
              "min": 18,
              "max": 18
            },
            "17": {
              "min": 19,
              "max": 19
            },
            "18": {
              "min": 20,
              "max": 20
            },
            "19": {
              "min": 21,
              "max": 27
            }
          }
        },
        {
          "name": "Porządkowanie liter i liczb",
          "id": 13,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": 1,
              "max": 1
            },
            "3": {
              "min": 2,
              "max": 2
            },
            "4": {
              "min": 3,
              "max": 4
            },
            "5": {
              "min": 5,
              "max": 5
            },
            "6": {
              "min": 6,
              "max": 7
            },
            "7": {
              "min": 8,
              "max": 8
            },
            "8": {
              "min": 9,
              "max": 10
            },
            "9": {
              "min": 11,
              "max": 11
            },
            "10": {
              "min": 12,
              "max": 12
            },
            "11": {
              "min": 13,
              "max": 13
            },
            "12": {
              "min": 14,
              "max": 15
            },
            "13": {
              "min": 16,
              "max": 16
            },
            "14": {
              "min": 17,
              "max": 17
            },
            "15": {
              "min": 18,
              "max": 18
            },
            "16": {
              "min": 19,
              "max": 19
            },
            "17": {
              "min": null,
              "max": null
            },
            "18": {
              "min": 20,
              "max": 20
            },
            "19": {
              "min": 21,
              "max": 30
            }
          }
        },
        {
          "name": "Wykreślanie",
          "id": 14,
          "rows": {
            "1": {
              "min": 0,
              "max": 16
            },
            "2": {
              "min": 17,
              "max": 19
            },
            "3": {
              "min": 20,
              "max": 23
            },
            "4": {
              "min": 24,
              "max": 26
            },
            "5": {
              "min": 27,
              "max": 30
            },
            "6": {
              "min": 31,
              "max": 34
            },
            "7": {
              "min": 35,
              "max": 39
            },
            "8": {
              "min": 40,
              "max": 43
            },
            "9": {
              "min": 44,
              "max": 48
            },
            "10": {
              "min": 49,
              "max": 53
            },
            "11": {
              "min": 54,
              "max": 59
            },
            "12": {
              "min": 60,
              "max": 64
            },
            "13": {
              "min": 65,
              "max": 70
            },
            "14": {
              "min": 71,
              "max": 76
            },
            "15": {
              "min": 77,
              "max": 83
            },
            "16": {
              "min": 84,
              "max": 89
            },
            "17": {
              "min": 90,
              "max": 96
            },
            "18": {
              "min": 97,
              "max": 103
            },
            "19": {
              "min": 104,
              "max": 128
            }
          }
        },
        {
          "name": "Rozumienie",
          "id": 15,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": 1,
              "max": 1
            },
            "3": {
              "min": 2,
              "max": 3
            },
            "4": {
              "min": 4,
              "max": 4
            },
            "5": {
              "min": 5,
              "max": 6
            },
            "6": {
              "min": 7,
              "max": 7
            },
            "7": {
              "min": 8,
              "max": 9
            },
            "8": {
              "min": 10,
              "max": 10
            },
            "9": {
              "min": 11,
              "max": 12
            },
            "10": {
              "min": 13,
              "max": 13
            },
            "11": {
              "min": 14,
              "max": 15
            },
            "12": {
              "min": 16,
              "max": 16
            },
            "13": {
              "min": 17,
              "max": 18
            },
            "14": {
              "min": 19,
              "max": 19
            },
            "15": {
              "min": 20,
              "max": 20
            },
            "16": {
              "min": 21,
              "max": 22
            },
            "17": {
              "min": 23,
              "max": 23
            },
            "18": {
              "min": 24,
              "max": 24
            },
            "19": {
              "min": 25,
              "max": 38
            }
          }
        },
        {
          "name": "Arytmetyka",
          "id": 16,
          "rows": {
            "1": {
              "min": 0,
              "max": 1
            },
            "2": {
              "min": 2,
              "max": 3
            },
            "3": {
              "min": 4,
              "max": 5
            },
            "4": {
              "min": 6,
              "max": 6
            },
            "5": {
              "min": 7,
              "max": 8
            },
            "6": {
              "min": 9,
              "max": 9
            },
            "7": {
              "min": 10,
              "max": 11
            },
            "8": {
              "min": 12,
              "max": 12
            },
            "9": {
              "min": 13,
              "max": 13
            },
            "10": {
              "min": 14,
              "max": 15
            },
            "11": {
              "min": 16,
              "max": 16
            },
            "12": {
              "min": 17,
              "max": 17
            },
            "13": {
              "min": 18,
              "max": 18
            },
            "14": {
              "min": 19,
              "max": 19
            },
            "15": {
              "min": 20,
              "max": 20
            },
            "16": {
              "min": 21,
              "max": 21
            },
            "17": {
              "min": 22,
              "max": 22
            },
            "18": {
              "min": 23,
              "max": 23
            },
            "19": {
              "min": 24,
              "max": 34
            }
          }
        }
      ],  

    8487: [
        {
          "name": "Wzory z klocków",
          "id": 1,
          "rows": {
            "1": {
              "min": 0,
              "max": 2
            },
            "2": {
              "min": 3,
              "max": 5
            },
            "3": {
              "min": 6,
              "max": 7
            },
            "4": {
              "min": 8,
              "max": 9
            },
            "5": {
              "min": 10,
              "max": 12
            },
            "6": {
              "min": 13,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 17
            },
            "8": {
              "min": 18,
              "max": 20
            },
            "9": {
              "min": 21,
              "max": 22
            },
            "10": {
              "min": 23,
              "max": 25
            },
            "11": {
              "min": 26,
              "max": 28
            },
            "12": {
              "min": 29,
              "max": 30
            },
            "13": {
              "min": 31,
              "max": 33
            },
            "14": {
              "min": 34,
              "max": 36
            },
            "15": {
              "min": 37,
              "max": 39
            },
            "16": {
              "min": 40,
              "max": 42
            },
            "17": {
              "min": 43,
              "max": 44
            },
            "18": {
              "min": 45,
              "max": 47
            },
            "19": {
              "min": 48,
              "max": 58
            }
          }
        },
        {
          "name": "Podobieństwa",
          "id": 2,
          "rows": {
            "1": {
              "min": 0,
              "max": 1
            },
            "2": {
              "min": 2,
              "max": 2
            },
            "3": {
              "min": 3,
              "max": 4
            },
            "4": {
              "min": 5,
              "max": 6
            },
            "5": {
              "min": 7,
              "max": 9
            },
            "6": {
              "min": 10,
              "max": 12
            },
            "7": {
              "min": 13,
              "max": 14
            },
            "8": {
              "min": 15,
              "max": 17
            },
            "9": {
              "min": 18,
              "max": 19
            },
            "10": {
              "min": 20,
              "max": 21
            },
            "11": {
              "min": 22,
              "max": 23
            },
            "12": {
              "min": 24,
              "max": 25
            },
            "13": {
              "min": 26,
              "max": 27
            },
            "14": {
              "min": 28,
              "max": 29
            },
            "15": {
              "min": 30,
              "max": 31
            },
            "16": {
              "min": 32,
              "max": 32
            },
            "17": {
              "min": 33,
              "max": 34
            },
            "18": {
              "min": 35,
              "max": 35
            },
            "19": {
              "min": 36,
              "max": 46
            }
          }
        },
        {
          "name": "Matryce",
          "id": 3,
          "rows": {
            "1": {
              "min": 0,
              "max": 2
            },
            "2": {
              "min": 3,
              "max": 4
            },
            "3": {
              "min": 5,
              "max": 5
            },
            "4": {
              "min": 6,
              "max": 7
            },
            "5": {
              "min": 8,
              "max": 8
            },
            "6": {
              "min": 9,
              "max": 9
            },
            "7": {
              "min": 10,
              "max": 11
            },
            "8": {
              "min": 12,
              "max": 12
            },
            "9": {
              "min": 13,
              "max": 14
            },
            "10": {
              "min": 15,
              "max": 15
            },
            "11": {
              "min": 16,
              "max": 16
            },
            "12": {
              "min": 17,
              "max": 18
            },
            "13": {
              "min": 19,
              "max": 19
            },
            "14": {
              "min": 20,
              "max": 20
            },
            "15": {
              "min": 21,
              "max": 22
            },
            "16": {
              "min": 23,
              "max": 23
            },
            "17": {
              "min": 24,
              "max": 24
            },
            "18": {
              "min": 25,
              "max": 25
            },
            "19": {
              "min": 26,
              "max": 32
            }
          }
        },
        {
          "name": "Powtarzanie liczb",
          "id": 4,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 5
            },
            "3": {
              "min": 6,
              "max": 7
            },
            "4": {
              "min": 8,
              "max": 8
            },
            "5": {
              "min": 9,
              "max": 10
            },
            "6": {
              "min": 11,
              "max": 12
            },
            "7": {
              "min": 13,
              "max": 14
            },
            "8": {
              "min": 15,
              "max": 15
            },
            "9": {
              "min": 16,
              "max": 17
            },
            "10": {
              "min": 18,
              "max": 18
            },
            "11": {
              "min": 19,
              "max": 20
            },
            "12": {
              "min": 21,
              "max": 22
            },
            "13": {
              "min": 23,
              "max": 23
            },
            "14": {
              "min": 24,
              "max": 25
            },
            "15": {
              "min": 26,
              "max": 26
            },
            "16": {
              "min": 27,
              "max": 28
            },
            "17": {
              "min": 29,
              "max": 29
            },
            "18": {
              "min": 30,
              "max": 31
            },
            "19": {
              "min": 32,
              "max": 54
            }
          }
        },
        {
          "name": "Kodowanie",
          "id": 5,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 11
            },
            "3": {
              "min": 12,
              "max": 14
            },
            "4": {
              "min": 15,
              "max": 17
            },
            "5": {
              "min": 18,
              "max": 20
            },
            "6": {
              "min": 21,
              "max": 23
            },
            "7": {
              "min": 24,
              "max": 26
            },
            "8": {
              "min": 27,
              "max": 29
            },
            "9": {
              "min": 30,
              "max": 32
            },
            "10": {
              "min": 33,
              "max": 35
            },
            "11": {
              "min": 36,
              "max": 38
            },
            "12": {
              "min": 39,
              "max": 41
            },
            "13": {
              "min": 42,
              "max": 44
            },
            "14": {
              "min": 45,
              "max": 47
            },
            "15": {
              "min": 48,
              "max": 50
            },
            "16": {
              "min": 51,
              "max": 53
            },
            "17": {
              "min": 54,
              "max": 56
            },
            "18": {
              "min": 57,
              "max": 59
            },
            "19": {
              "min": 60,
              "max": 117
            }
          }
        },
        {
          "name": "Słownik",
          "id": 6,
          "rows": {
            "1": {
              "min": 0,
              "max": 6
            },
            "2": {
              "min": 7,
              "max": 7
            },
            "3": {
              "min": 8,
              "max": 8
            },
            "4": {
              "min": 9,
              "max": 10
            },
            "5": {
              "min": 11,
              "max": 11
            },
            "6": {
              "min": 12,
              "max": 13
            },
            "7": {
              "min": 14,
              "max": 14
            },
            "8": {
              "min": 15,
              "max": 16
            },
            "9": {
              "min": 17,
              "max": 17
            },
            "10": {
              "min": 18,
              "max": 19
            },
            "11": {
              "min": 20,
              "max": 21
            },
            "12": {
              "min": 22,
              "max": 22
            },
            "13": {
              "min": 23,
              "max": 24
            },
            "14": {
              "min": 25,
              "max": 26
            },
            "15": {
              "min": 27,
              "max": 28
            },
            "16": {
              "min": 29,
              "max": 30
            },
            "17": {
              "min": 31,
              "max": 32
            },
            "18": {
              "min": 33,
              "max": 34
            },
            "19": {
              "min": 35,
              "max": 54
            }
          }
        },
        {
          "name": "Ważenie",
          "id": 7,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": 1,
              "max": 2
            },
            "3": {
              "min": 3,
              "max": 3
            },
            "4": {
              "min": 4,
              "max": 5
            },
            "5": {
              "min": 6,
              "max": 7
            },
            "6": {
              "min": 8,
              "max": 10
            },
            "7": {
              "min": 11,
              "max": 12
            },
            "8": {
              "min": 13,
              "max": 14
            },
            "9": {
              "min": 15,
              "max": 16
            },
            "10": {
              "min": 17,
              "max": 18
            },
            "11": {
              "min": 19,
              "max": 20
            },
            "12": {
              "min": 21,
              "max": 21
            },
            "13": {
              "min": 22,
              "max": 23
            },
            "14": {
              "min": 24,
              "max": 24
            },
            "15": {
              "min": 25,
              "max": 26
            },
            "16": {
              "min": 27,
              "max": 27
            },
            "17": {
              "min": 28,
              "max": 28
            },
            "18": {
              "min": 29,
              "max": 29
            },
            "19": {
              "min": 30,
              "max": 34
            }
          }
        },
        {
          "name": "Układnie wzoru",
          "id": 8,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": 1,
              "max": 1
            },
            "3": {
              "min": 2,
              "max": 3
            },
            "4": {
              "min": 4,
              "max": 4
            },
            "5": {
              "min": 5,
              "max": 6
            },
            "6": {
              "min": 7,
              "max": 8
            },
            "7": {
              "min": 9,
              "max": 10
            },
            "8": {
              "min": 11,
              "max": 11
            },
            "9": {
              "min": 12,
              "max": 13
            },
            "10": {
              "min": 14,
              "max": 14
            },
            "11": {
              "min": 15,
              "max": 15
            },
            "12": {
              "min": 16,
              "max": 17
            },
            "13": {
              "min": 18,
              "max": 18
            },
            "14": {
              "min": 19,
              "max": 19
            },
            "15": {
              "min": 20,
              "max": 20
            },
            "16": {
              "min": 21,
              "max": 21
            },
            "17": {
              "min": 22,
              "max": 22
            },
            "18": {
              "min": 23,
              "max": 23
            },
            "19": {
              "min": 24,
              "max": 29
            }
          }
        },
        {
          "name": "Zapamiętywanie obrazków",
          "id": 9,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 4
            },
            "3": {
              "min": 5,
              "max": 7
            },
            "4": {
              "min": 8,
              "max": 9
            },
            "5": {
              "min": 10,
              "max": 11
            },
            "6": {
              "min": 12,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 16
            },
            "8": {
              "min": 17,
              "max": 18
            },
            "9": {
              "min": 19,
              "max": 20
            },
            "10": {
              "min": 21,
              "max": 23
            },
            "11": {
              "min": 24,
              "max": 25
            },
            "12": {
              "min": 26,
              "max": 27
            },
            "13": {
              "min": 28,
              "max": 29
            },
            "14": {
              "min": 30,
              "max": 32
            },
            "15": {
              "min": 33,
              "max": 34
            },
            "16": {
              "min": 35,
              "max": 36
            },
            "17": {
              "min": 37,
              "max": 38
            },
            "18": {
              "min": 39,
              "max": 40
            },
            "19": {
              "min": 41,
              "max": 49
            }
          }
        },
        {
          "name": "Szukanie symboli",
          "id": 10,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 5
            },
            "3": {
              "min": 6,
              "max": 7
            },
            "4": {
              "min": 8,
              "max": 9
            },
            "5": {
              "min": 10,
              "max": 11
            },
            "6": {
              "min": 12,
              "max": 13
            },
            "7": {
              "min": 14,
              "max": 15
            },
            "8": {
              "min": 16,
              "max": 17
            },
            "9": {
              "min": 18,
              "max": 19
            },
            "10": {
              "min": 20,
              "max": 21
            },
            "11": {
              "min": 22,
              "max": 23
            },
            "12": {
              "min": 24,
              "max": 24
            },
            "13": {
              "min": 25,
              "max": 26
            },
            "14": {
              "min": 27,
              "max": 28
            },
            "15": {
              "min": 29,
              "max": 30
            },
            "16": {
              "min": 31,
              "max": 31
            },
            "17": {
              "min": 32,
              "max": 33
            },
            "18": {
              "min": 34,
              "max": 35
            },
            "19": {
              "min": 36,
              "max": 60
            }
          }
        },
        {
          "name": "Wiadomości",
          "id": 11,
          "rows": {
            "1": {
              "min": 0,
              "max": 4
            },
            "2": {
              "min": 5,
              "max": 5
            },
            "3": {
              "min": 6,
              "max": 6
            },
            "4": {
              "min": 7,
              "max": 7
            },
            "5": {
              "min": 8,
              "max": 8
            },
            "6": {
              "min": 9,
              "max": 9
            },
            "7": {
              "min": null,
              "max": null
            },
            "8": {
              "min": 10,
              "max": 10
            },
            "9": {
              "min": 11,
              "max": 11
            },
            "10": {
              "min": 12,
              "max": 12
            },
            "11": {
              "min": 13,
              "max": 13
            },
            "12": {
              "min": 14,
              "max": 14
            },
            "13": {
              "min": 15,
              "max": 15
            },
            "14": {
              "min": 16,
              "max": 16
            },
            "15": {
              "min": 17,
              "max": 17
            },
            "16": {
              "min": 18,
              "max": 18
            },
            "17": {
              "min": 19,
              "max": 19
            },
            "18": {
              "min": 20,
              "max": 20
            },
            "19": {
              "min": 21,
              "max": 31
            }
          }
        },
        {
          "name": "Tworzenie pojęć",
          "id": 12,
          "rows": {
            "1": {
              "min": null,
              "max": null
            },
            "2": {
              "min": 1,
              "max": 1
            },
            "3": {
              "min": 2,
              "max": 2
            },
            "4": {
              "min": 3,
              "max": 3
            },
            "5": {
              "min": 4,
              "max": 5
            },
            "6": {
              "min": 6,
              "max": 6
            },
            "7": {
              "min": 7,
              "max": 8
            },
            "8": {
              "min": 9,
              "max": 9
            },
            "9": {
              "min": 10,
              "max": 10
            },
            "10": {
              "min": 11,
              "max": 11
            },
            "11": {
              "min": 12,
              "max": 13
            },
            "12": {
              "min": 14,
              "max": 14
            },
            "13": {
              "min": 15,
              "max": 15
            },
            "14": {
              "min": 16,
              "max": 16
            },
            "15": {
              "min": 17,
              "max": 17
            },
            "16": {
              "min": 18,
              "max": 18
            },
            "17": {
              "min": 19,
              "max": 19
            },
            "18": {
              "min": 20,
              "max": 20
            },
            "19": {
              "min": 21,
              "max": 27
            }
          }
        },
        {
          "name": "Porządkowanie liter i liczb",
          "id": 13,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": 1,
              "max": 1
            },
            "3": {
              "min": 2,
              "max": 3
            },
            "4": {
              "min": 4,
              "max": 4
            },
            "5": {
              "min": 5,
              "max": 6
            },
            "6": {
              "min": 7,
              "max": 8
            },
            "7": {
              "min": 9,
              "max": 9
            },
            "8": {
              "min": 10,
              "max": 11
            },
            "9": {
              "min": 12,
              "max": 12
            },
            "10": {
              "min": 13,
              "max": 13
            },
            "11": {
              "min": 14,
              "max": 14
            },
            "12": {
              "min": 15,
              "max": 15
            },
            "13": {
              "min": 16,
              "max": 16
            },
            "14": {
              "min": 17,
              "max": 17
            },
            "15": {
              "min": 18,
              "max": 18
            },
            "16": {
              "min": 19,
              "max": 19
            },
            "17": {
              "min": 20,
              "max": 20
            },
            "18": {
              "min": 21,
              "max": 21
            },
            "19": {
              "min": 22,
              "max": 30
            }
          }
        },
        {
          "name": "Wykreślanie",
          "id": 14,
          "rows": {
            "1": {
              "min": 0,
              "max": 18
            },
            "2": {
              "min": 19,
              "max": 21
            },
            "3": {
              "min": 22,
              "max": 24
            },
            "4": {
              "min": 25,
              "max": 28
            },
            "5": {
              "min": 29,
              "max": 32
            },
            "6": {
              "min": 33,
              "max": 36
            },
            "7": {
              "min": 37,
              "max": 40
            },
            "8": {
              "min": 41,
              "max": 45
            },
            "9": {
              "min": 46,
              "max": 50
            },
            "10": {
              "min": 51,
              "max": 55
            },
            "11": {
              "min": 56,
              "max": 61
            },
            "12": {
              "min": 62,
              "max": 66
            },
            "13": {
              "min": 67,
              "max": 72
            },
            "14": {
              "min": 73,
              "max": 79
            },
            "15": {
              "min": 80,
              "max": 85
            },
            "16": {
              "min": 86,
              "max": 92
            },
            "17": {
              "min": 93,
              "max": 100
            },
            "18": {
              "min": 101,
              "max": 107
            },
            "19": {
              "min": 108,
              "max": 128
            }
          }
        },
        {
          "name": "Rozumienie",
          "id": 15,
          "rows": {
            "1": {
              "min": 100,
              "max": 0
            },
            "2": {
              "min": 1,
              "max": 2
            },
            "3": {
              "min": 3,
              "max": 3
            },
            "4": {
              "min": 4,
              "max": 5
            },
            "5": {
              "min": 6,
              "max": 6
            },
            "6": {
              "min": 7,
              "max": 8
            },
            "7": {
              "min": 9,
              "max": 9
            },
            "8": {
              "min": 10,
              "max": 11
            },
            "9": {
              "min": 12,
              "max": 13
            },
            "10": {
              "min": 14,
              "max": 14
            },
            "11": {
              "min": 15,
              "max": 15
            },
            "12": {
              "min": 16,
              "max": 17
            },
            "13": {
              "min": 18,
              "max": 18
            },
            "14": {
              "min": 19,
              "max": 20
            },
            "15": {
              "min": 21,
              "max": 21
            },
            "16": {
              "min": 22,
              "max": 22
            },
            "17": {
              "min": 23,
              "max": 24
            },
            "18": {
              "min": 25,
              "max": 25
            },
            "19": {
              "min": 26,
              "max": 38
            }
          }
        },
        {
          "name": "Arytmetyka",
          "id": 16,
          "rows": {
            "1": {
              "min": 0,
              "max": 2
            },
            "2": {
              "min": 3,
              "max": 3
            },
            "3": {
              "min": 4,
              "max": 5
            },
            "4": {
              "min": 6,
              "max": 7
            },
            "5": {
              "min": 8,
              "max": 8
            },
            "6": {
              "min": 9,
              "max": 10
            },
            "7": {
              "min": 11,
              "max": 11
            },
            "8": {
              "min": 12,
              "max": 13
            },
            "9": {
              "min": 14,
              "max": 14
            },
            "10": {
              "min": 15,
              "max": 15
            },
            "11": {
              "min": 16,
              "max": 17
            },
            "12": {
              "min": 18,
              "max": 18
            },
            "13": {
              "min": 19,
              "max": 19
            },
            "14": {
              "min": 20,
              "max": 20
            },
            "15": {
              "min": 21,
              "max": 21
            },
            "16": {
              "min": 22,
              "max": 22
            },
            "17": {
              "min": 23,
              "max": 23
            },
            "18": {
              "min": 24,
              "max": 24
            },
            "19": {
              "min": 25,
              "max": 34
            }
          }
        }
      ],
      
    88811: [
        {
          "name": "Wzory z klocków",
          "id": 1,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 5
            },
            "3": {
              "min": 6,
              "max": 8
            },
            "4": {
              "min": 9,
              "max": 10
            },
            "5": {
              "min": 11,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 15
            },
            "7": {
              "min": 16,
              "max": 18
            },
            "8": {
              "min": 19,
              "max": 20
            },
            "9": {
              "min": 21,
              "max": 23
            },
            "10": {
              "min": 24,
              "max": 26
            },
            "11": {
              "min": 27,
              "max": 29
            },
            "12": {
              "min": 30,
              "max": 31
            },
            "13": {
              "min": 32,
              "max": 34
            },
            "14": {
              "min": 35,
              "max": 37
            },
            "15": {
              "min": 38,
              "max": 40
            },
            "16": {
              "min": 41,
              "max": 43
            },
            "17": {
              "min": 44,
              "max": 46
            },
            "18": {
              "min": 47,
              "max": 49
            },
            "19": {
              "min": 50,
              "max": 58
            }
          }
        },
        {
          "name": "Podobieństwa",
          "id": 2,
          "rows": {
            "1": {
              "min": 0,
              "max": 1
            },
            "2": {
              "min": 2,
              "max": 3
            },
            "3": {
              "min": 4,
              "max": 5
            },
            "4": {
              "min": 6,
              "max": 8
            },
            "5": {
              "min": 9,
              "max": 10
            },
            "6": {
              "min": 11,
              "max": 13
            },
            "7": {
              "min": 14,
              "max": 16
            },
            "8": {
              "min": 17,
              "max": 18
            },
            "9": {
              "min": 19,
              "max": 20
            },
            "10": {
              "min": 21,
              "max": 22
            },
            "11": {
              "min": 23,
              "max": 24
            },
            "12": {
              "min": 25,
              "max": 26
            },
            "13": {
              "min": 27,
              "max": 28
            },
            "14": {
              "min": 29,
              "max": 30
            },
            "15": {
              "min": 31,
              "max": 31
            },
            "16": {
              "min": 32,
              "max": 33
            },
            "17": {
              "min": 34,
              "max": 34
            },
            "18": {
              "min": 35,
              "max": 36
            },
            "19": {
              "min": 37,
              "max": 46
            }
          }
        },
        {
          "name": "Matryce",
          "id": 3,
          "rows": {
            "1": {
              "min": 0,
              "max": 2
            },
            "2": {
              "min": 3,
              "max": 4
            },
            "3": {
              "min": 5,
              "max": 6
            },
            "4": {
              "min": 7,
              "max": 7
            },
            "5": {
              "min": 8,
              "max": 9
            },
            "6": {
              "min": 10,
              "max": 10
            },
            "7": {
              "min": 11,
              "max": 11
            },
            "8": {
              "min": 12,
              "max": 13
            },
            "9": {
              "min": 14,
              "max": 14
            },
            "10": {
              "min": 15,
              "max": 16
            },
            "11": {
              "min": 17,
              "max": 17
            },
            "12": {
              "min": 18,
              "max": 18
            },
            "13": {
              "min": 19,
              "max": 19
            },
            "14": {
              "min": 20,
              "max": 21
            },
            "15": {
              "min": 22,
              "max": 22
            },
            "16": {
              "min": 23,
              "max": 23
            },
            "17": {
              "min": 24,
              "max": 25
            },
            "18": {
              "min": 26,
              "max": 26
            },
            "19": {
              "min": 27,
              "max": 32
            }
          }
        },
        {
          "name": "Powtarzanie liczb",
          "id": 4,
          "rows": {
            "1": {
              "min": 0,
              "max": 4
            },
            "2": {
              "min": 5,
              "max": 5
            },
            "3": {
              "min": 6,
              "max": 7
            },
            "4": {
              "min": 8,
              "max": 9
            },
            "5": {
              "min": 10,
              "max": 11
            },
            "6": {
              "min": 12,
              "max": 13
            },
            "7": {
              "min": 14,
              "max": 14
            },
            "8": {
              "min": 15,
              "max": 16
            },
            "9": {
              "min": 17,
              "max": 18
            },
            "10": {
              "min": 19,
              "max": 19
            },
            "11": {
              "min": 20,
              "max": 21
            },
            "12": {
              "min": 22,
              "max": 22
            },
            "13": {
              "min": 23,
              "max": 24
            },
            "14": {
              "min": 25,
              "max": 25
            },
            "15": {
              "min": 26,
              "max": 27
            },
            "16": {
              "min": 28,
              "max": 28
            },
            "17": {
              "min": 29,
              "max": 30
            },
            "18": {
              "min": 31,
              "max": 31
            },
            "19": {
              "min": 32,
              "max": 54
            }
          }
        },
        {
          "name": "Kodowanie",
          "id": 5,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 11
            },
            "3": {
              "min": 12,
              "max": 14
            },
            "4": {
              "min": 15,
              "max": 18
            },
            "5": {
              "min": 19,
              "max": 21
            },
            "6": {
              "min": 22,
              "max": 24
            },
            "7": {
              "min": 25,
              "max": 27
            },
            "8": {
              "min": 28,
              "max": 30
            },
            "9": {
              "min": 31,
              "max": 33
            },
            "10": {
              "min": 34,
              "max": 36
            },
            "11": {
              "min": 37,
              "max": 40
            },
            "12": {
              "min": 41,
              "max": 43
            },
            "13": {
              "min": 44,
              "max": 46
            },
            "14": {
              "min": 47,
              "max": 49
            },
            "15": {
              "min": 50,
              "max": 52
            },
            "16": {
              "min": 53,
              "max": 55
            },
            "17": {
              "min": 56,
              "max": 59
            },
            "18": {
              "min": 60,
              "max": 62
            },
            "19": {
              "min": 63,
              "max": 117
            }
          }
        },
        {
          "name": "Słownik",
          "id": 6,
          "rows": {
            "1": {
              "min": 0,
              "max": 6
            },
            "2": {
              "min": 7,
              "max": 7
            },
            "3": {
              "min": 8,
              "max": 9
            },
            "4": {
              "min": 10,
              "max": 10
            },
            "5": {
              "min": 11,
              "max": 12
            },
            "6": {
              "min": 13,
              "max": 13
            },
            "7": {
              "min": 14,
              "max": 15
            },
            "8": {
              "min": 16,
              "max": 16
            },
            "9": {
              "min": 17,
              "max": 18
            },
            "10": {
              "min": 19,
              "max": 20
            },
            "11": {
              "min": 21,
              "max": 22
            },
            "12": {
              "min": 23,
              "max": 24
            },
            "13": {
              "min": 25,
              "max": 25
            },
            "14": {
              "min": 26,
              "max": 28
            },
            "15": {
              "min": 29,
              "max": 30
            },
            "16": {
              "min": 31,
              "max": 32
            },
            "17": {
              "min": 33,
              "max": 34
            },
            "18": {
              "min": 35,
              "max": 36
            },
            "19": {
              "min": 37,
              "max": 54
            }
          }
        },
        {
          "name": "Ważenie",
          "id": 7,
          "rows": {
            "1": {
              "min": 0,
              "max": 1
            },
            "2": {
              "min": 2,
              "max": 2
            },
            "3": {
              "min": 3,
              "max": 3
            },
            "4": {
              "min": 4,
              "max": 6
            },
            "5": {
              "min": 7,
              "max": 8
            },
            "6": {
              "min": 9,
              "max": 10
            },
            "7": {
              "min": 11,
              "max": 13
            },
            "8": {
              "min": 14,
              "max": 15
            },
            "9": {
              "min": 16,
              "max": 17
            },
            "10": {
              "min": 18,
              "max": 19
            },
            "11": {
              "min": 20,
              "max": 20
            },
            "12": {
              "min": 21,
              "max": 22
            },
            "13": {
              "min": 23,
              "max": 23
            },
            "14": {
              "min": 24,
              "max": 25
            },
            "15": {
              "min": 26,
              "max": 26
            },
            "16": {
              "min": 27,
              "max": 28
            },
            "17": {
              "min": 29,
              "max": 29
            },
            "18": {
              "min": 30,
              "max": 30
            },
            "19": {
              "min": 31,
              "max": 34
            }
          }
        },
        {
          "name": "Układnie wzoru",
          "id": 8,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": 1,
              "max": 1
            },
            "3": {
              "min": 2,
              "max": 3
            },
            "4": {
              "min": 4,
              "max": 5
            },
            "5": {
              "min": 6,
              "max": 6
            },
            "6": {
              "min": 7,
              "max": 8
            },
            "7": {
              "min": 9,
              "max": 10
            },
            "8": {
              "min": 11,
              "max": 12
            },
            "9": {
              "min": 13,
              "max": 13
            },
            "10": {
              "min": 14,
              "max": 15
            },
            "11": {
              "min": 16,
              "max": 16
            },
            "12": {
              "min": 17,
              "max": 17
            },
            "13": {
              "min": 18,
              "max": 18
            },
            "14": {
              "min": 19,
              "max": 19
            },
            "15": {
              "min": 20,
              "max": 20
            },
            "16": {
              "min": 21,
              "max": 21
            },
            "17": {
              "min": 22,
              "max": 22
            },
            "18": {
              "min": 23,
              "max": 23
            },
            "19": {
              "min": 24,
              "max": 29
            }
          }
        },
        {
          "name": "Zapamiętywanie obrazków",
          "id": 9,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 5
            },
            "3": {
              "min": 6,
              "max": 7
            },
            "4": {
              "min": 8,
              "max": 9
            },
            "5": {
              "min": 10,
              "max": 12
            },
            "6": {
              "min": 13,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 16
            },
            "8": {
              "min": 17,
              "max": 19
            },
            "9": {
              "min": 20,
              "max": 21
            },
            "10": {
              "min": 22,
              "max": 23
            },
            "11": {
              "min": 24,
              "max": 26
            },
            "12": {
              "min": 27,
              "max": 28
            },
            "13": {
              "min": 29,
              "max": 30
            },
            "14": {
              "min": 31,
              "max": 32
            },
            "15": {
              "min": 33,
              "max": 35
            },
            "16": {
              "min": 36,
              "max": 37
            },
            "17": {
              "min": 38,
              "max": 39
            },
            "18": {
              "min": 40,
              "max": 41
            },
            "19": {
              "min": 42,
              "max": 49
            }
          }
        },
        {
          "name": "Szukanie symboli",
          "id": 10,
          "rows": {
            "1": {
              "min": 0,
              "max": 4
            },
            "2": {
              "min": 5,
              "max": 6
            },
            "3": {
              "min": 7,
              "max": 8
            },
            "4": {
              "min": 9,
              "max": 10
            },
            "5": {
              "min": 11,
              "max": 12
            },
            "6": {
              "min": 13,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 16
            },
            "8": {
              "min": 17,
              "max": 18
            },
            "9": {
              "min": 19,
              "max": 20
            },
            "10": {
              "min": 21,
              "max": 21
            },
            "11": {
              "min": 22,
              "max": 23
            },
            "12": {
              "min": 24,
              "max": 25
            },
            "13": {
              "min": 26,
              "max": 27
            },
            "14": {
              "min": 28,
              "max": 29
            },
            "15": {
              "min": 30,
              "max": 31
            },
            "16": {
              "min": 32,
              "max": 33
            },
            "17": {
              "min": 34,
              "max": 34
            },
            "18": {
              "min": 35,
              "max": 36
            },
            "19": {
              "min": 37,
              "max": 60
            }
          }
        },
        {
          "name": "Wiadomości",
          "id": 11,
          "rows": {
            "1": {
              "min": 0,
              "max": 5
            },
            "2": {
              "min": null,
              "max": null
            },
            "3": {
              "min": 6,
              "max": 6
            },
            "4": {
              "min": 7,
              "max": 7
            },
            "5": {
              "min": 8,
              "max": 8
            },
            "6": {
              "min": 9,
              "max": 9
            },
            "7": {
              "min": 10,
              "max": 10
            },
            "8": {
              "min": 11,
              "max": 11
            },
            "9": {
              "min": 12,
              "max": 12
            },
            "10": {
              "min": 13,
              "max": 13
            },
            "11": {
              "min": 14,
              "max": 14
            },
            "12": {
              "min": 15,
              "max": 15
            },
            "13": {
              "min": 16,
              "max": 16
            },
            "14": {
              "min": 17,
              "max": 17
            },
            "15": {
              "min": 18,
              "max": 18
            },
            "16": {
              "min": 19,
              "max": 19
            },
            "17": {
              "min": null,
              "max": null
            },
            "18": {
              "min": 20,
              "max": 20
            },
            "19": {
              "min": 21,
              "max": 31
            }
          }
        },
        {
          "name": "Tworzenie pojęć",
          "id": 12,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": 1,
              "max": 1
            },
            "3": {
              "min": 2,
              "max": 2
            },
            "4": {
              "min": 3,
              "max": 4
            },
            "5": {
              "min": 5,
              "max": 5
            },
            "6": {
              "min": 6,
              "max": 7
            },
            "7": {
              "min": 8,
              "max": 8
            },
            "8": {
              "min": 9,
              "max": 9
            },
            "9": {
              "min": 10,
              "max": 11
            },
            "10": {
              "min": 12,
              "max": 12
            },
            "11": {
              "min": 13,
              "max": 13
            },
            "12": {
              "min": 14,
              "max": 14
            },
            "13": {
              "min": 15,
              "max": 15
            },
            "14": {
              "min": 16,
              "max": 16
            },
            "15": {
              "min": 17,
              "max": 17
            },
            "16": {
              "min": 18,
              "max": 18
            },
            "17": {
              "min": 19,
              "max": 19
            },
            "18": {
              "min": 20,
              "max": 20
            },
            "19": {
              "min": 21,
              "max": 27
            }
          }
        },
        {
          "name": "Porządkowanie liter i liczb",
          "id": 13,
          "rows": {
            "1": {
              "min": 0,
              "max": 1
            },
            "2": {
              "min": 2,
              "max": 2
            },
            "3": {
              "min": 3,
              "max": 4
            },
            "4": {
              "min": 5,
              "max": 5
            },
            "5": {
              "min": 6,
              "max": 7
            },
            "6": {
              "min": 8,
              "max": 8
            },
            "7": {
              "min": 9,
              "max": 10
            },
            "8": {
              "min": 11,
              "max": 11
            },
            "9": {
              "min": 12,
              "max": 12
            },
            "10": {
              "min": 13,
              "max": 14
            },
            "11": {
              "min": 15,
              "max": 15
            },
            "12": {
              "min": 16,
              "max": 16
            },
            "13": {
              "min": 17,
              "max": 17
            },
            "14": {
              "min": null,
              "max": null
            },
            "15": {
              "min": 18,
              "max": 18
            },
            "16": {
              "min": 19,
              "max": 19
            },
            "17": {
              "min": 20,
              "max": 20
            },
            "18": {
              "min": 21,
              "max": 21
            },
            "19": {
              "min": 22,
              "max": 30
            }
          }
        },
        {
          "name": "Wykreślanie",
          "id": 14,
          "rows": {
            "1": {
              "min": 0,
              "max": 20
            },
            "2": {
              "min": 21,
              "max": 23
            },
            "3": {
              "min": 24,
              "max": 26
            },
            "4": {
              "min": 27,
              "max": 30
            },
            "5": {
              "min": 31,
              "max": 33
            },
            "6": {
              "min": 34,
              "max": 37
            },
            "7": {
              "min": 38,
              "max": 42
            },
            "8": {
              "min": 43,
              "max": 46
            },
            "9": {
              "min": 47,
              "max": 51
            },
            "10": {
              "min": 52,
              "max": 57
            },
            "11": {
              "min": 58,
              "max": 62
            },
            "12": {
              "min": 63,
              "max": 68
            },
            "13": {
              "min": 69,
              "max": 74
            },
            "14": {
              "min": 75,
              "max": 81
            },
            "15": {
              "min": 82,
              "max": 88
            },
            "16": {
              "min": 89,
              "max": 95
            },
            "17": {
              "min": 96,
              "max": 102
            },
            "18": {
              "min": 103,
              "max": 110
            },
            "19": {
              "min": 111,
              "max": 128
            }
          }
        },
        {
          "name": "Rozumienie",
          "id": 15,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": 1,
              "max": 2
            },
            "3": {
              "min": 3,
              "max": 4
            },
            "4": {
              "min": 5,
              "max": 5
            },
            "5": {
              "min": 6,
              "max": 7
            },
            "6": {
              "min": 8,
              "max": 8
            },
            "7": {
              "min": 9,
              "max": 10
            },
            "8": {
              "min": 11,
              "max": 12
            },
            "9": {
              "min": 13,
              "max": 13
            },
            "10": {
              "min": 14,
              "max": 15
            },
            "11": {
              "min": 16,
              "max": 16
            },
            "12": {
              "min": 17,
              "max": 18
            },
            "13": {
              "min": 19,
              "max": 19
            },
            "14": {
              "min": 20,
              "max": 20
            },
            "15": {
              "min": 21,
              "max": 22
            },
            "16": {
              "min": 23,
              "max": 23
            },
            "17": {
              "min": 24,
              "max": 25
            },
            "18": {
              "min": 26,
              "max": 26
            },
            "19": {
              "min": 27,
              "max": 38
            }
          }
        },
        {
          "name": "Arytmetyka",
          "id": 16,
          "rows": {
            "1": {
              "min": 0,
              "max": 2
            },
            "2": {
              "min": 3,
              "max": 4
            },
            "3": {
              "min": 5,
              "max": 6
            },
            "4": {
              "min": 7,
              "max": 7
            },
            "5": {
              "min": 8,
              "max": 9
            },
            "6": {
              "min": 10,
              "max": 11
            },
            "7": {
              "min": 12,
              "max": 12
            },
            "8": {
              "min": 13,
              "max": 14
            },
            "9": {
              "min": 15,
              "max": 15
            },
            "10": {
              "min": 16,
              "max": 16
            },
            "11": {
              "min": 17,
              "max": 17
            },
            "12": {
              "min": 18,
              "max": 19
            },
            "13": {
              "min": 20,
              "max": 20
            },
            "14": {
              "min": 21,
              "max": 21
            },
            "15": {
              "min": 22,
              "max": 22
            },
            "16": {
              "min": 23,
              "max": 23
            },
            "17": {
              "min": 24,
              "max": 24
            },
            "18": {
              "min": 25,
              "max": 25
            },
            "19": {
              "min": 26,
              "max": 34
            }
          }
        }
      ], 
      
    9093: [
        {
          "name": "Wzory z klocków",
          "id": 1,
          "rows": {
            "1": {
              "min": 0,
              "max": 4
            },
            "2": {
              "min": 5,
              "max": 6
            },
            "3": {
              "min": 7,
              "max": 8
            },
            "4": {
              "min": 9,
              "max": 11
            },
            "5": {
              "min": 12,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 16
            },
            "7": {
              "min": 17,
              "max": 18
            },
            "8": {
              "min": 19,
              "max": 21
            },
            "9": {
              "min": 22,
              "max": 24
            },
            "10": {
              "min": 25,
              "max": 27
            },
            "11": {
              "min": 28,
              "max": 30
            },
            "12": {
              "min": 31,
              "max": 32
            },
            "13": {
              "min": 33,
              "max": 35
            },
            "14": {
              "min": 36,
              "max": 38
            },
            "15": {
              "min": 39,
              "max": 41
            },
            "16": {
              "min": 42,
              "max": 44
            },
            "17": {
              "min": 45,
              "max": 47
            },
            "18": {
              "min": 48,
              "max": 50
            },
            "19": {
              "min": 51,
              "max": 58
            }
          }
        },
        {
          "name": "Podobieństwa",
          "id": 2,
          "rows": {
            "1": {
              "min": 0,
              "max": 2
            },
            "2": {
              "min": 3,
              "max": 3
            },
            "3": {
              "min": 4,
              "max": 6
            },
            "4": {
              "min": 7,
              "max": 9
            },
            "5": {
              "min": 10,
              "max": 12
            },
            "6": {
              "min": 13,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 17
            },
            "8": {
              "min": 18,
              "max": 19
            },
            "9": {
              "min": 20,
              "max": 21
            },
            "10": {
              "min": 22,
              "max": 23
            },
            "11": {
              "min": 24,
              "max": 25
            },
            "12": {
              "min": 26,
              "max": 27
            },
            "13": {
              "min": 28,
              "max": 29
            },
            "14": {
              "min": 30,
              "max": 30
            },
            "15": {
              "min": 31,
              "max": 32
            },
            "16": {
              "min": 33,
              "max": 33
            },
            "17": {
              "min": 34,
              "max": 35
            },
            "18": {
              "min": 36,
              "max": 36
            },
            "19": {
              "min": 37,
              "max": 46
            }
          }
        },
        {
          "name": "Matryce",
          "id": 3,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 5
            },
            "3": {
              "min": 6,
              "max": 6
            },
            "4": {
              "min": 7,
              "max": 8
            },
            "5": {
              "min": 9,
              "max": 9
            },
            "6": {
              "min": 10,
              "max": 11
            },
            "7": {
              "min": 12,
              "max": 12
            },
            "8": {
              "min": 13,
              "max": 13
            },
            "9": {
              "min": 14,
              "max": 15
            },
            "10": {
              "min": 16,
              "max": 16
            },
            "11": {
              "min": 17,
              "max": 17
            },
            "12": {
              "min": 18,
              "max": 19
            },
            "13": {
              "min": 20,
              "max": 20
            },
            "14": {
              "min": 21,
              "max": 21
            },
            "15": {
              "min": 22,
              "max": 22
            },
            "16": {
              "min": 23,
              "max": 24
            },
            "17": {
              "min": 25,
              "max": 25
            },
            "18": {
              "min": 26,
              "max": 26
            },
            "19": {
              "min": 27,
              "max": 32
            }
          }
        },
        {
          "name": "Powtarzanie liczb",
          "id": 4,
          "rows": {
            "1": {
              "min": 0,
              "max": 4
            },
            "2": {
              "min": 5,
              "max": 6
            },
            "3": {
              "min": 7,
              "max": 8
            },
            "4": {
              "min": 9,
              "max": 10
            },
            "5": {
              "min": 11,
              "max": 12
            },
            "6": {
              "min": 13,
              "max": 13
            },
            "7": {
              "min": 14,
              "max": 15
            },
            "8": {
              "min": 16,
              "max": 17
            },
            "9": {
              "min": 18,
              "max": 18
            },
            "10": {
              "min": 19,
              "max": 20
            },
            "11": {
              "min": 21,
              "max": 21
            },
            "12": {
              "min": 22,
              "max": 23
            },
            "13": {
              "min": 24,
              "max": 25
            },
            "14": {
              "min": 26,
              "max": 26
            },
            "15": {
              "min": 27,
              "max": 28
            },
            "16": {
              "min": 29,
              "max": 29
            },
            "17": {
              "min": 30,
              "max": 31
            },
            "18": {
              "min": 32,
              "max": 32
            },
            "19": {
              "min": 33,
              "max": 54
            }
          }
        },
        {
          "name": "Kodowanie",
          "id": 5,
          "rows": {
            "1": {
              "min": 0,
              "max": 9
            },
            "2": {
              "min": 10,
              "max": 12
            },
            "3": {
              "min": 13,
              "max": 15
            },
            "4": {
              "min": 16,
              "max": 19
            },
            "5": {
              "min": 20,
              "max": 22
            },
            "6": {
              "min": 23,
              "max": 25
            },
            "7": {
              "min": 26,
              "max": 28
            },
            "8": {
              "min": 29,
              "max": 32
            },
            "9": {
              "min": 33,
              "max": 35
            },
            "10": {
              "min": 36,
              "max": 38
            },
            "11": {
              "min": 39,
              "max": 42
            },
            "12": {
              "min": 43,
              "max": 45
            },
            "13": {
              "min": 46,
              "max": 48
            },
            "14": {
              "min": 49,
              "max": 51
            },
            "15": {
              "min": 52,
              "max": 55
            },
            "16": {
              "min": 56,
              "max": 58
            },
            "17": {
              "min": 59,
              "max": 61
            },
            "18": {
              "min": 62,
              "max": 65
            },
            "19": {
              "min": 66,
              "max": 117
            }
          }
        },
        {
          "name": "Słownik",
          "id": 6,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 9
            },
            "4": {
              "min": 10,
              "max": 11
            },
            "5": {
              "min": 12,
              "max": 12
            },
            "6": {
              "min": 13,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 15
            },
            "8": {
              "min": 16,
              "max": 17
            },
            "9": {
              "min": 18,
              "max": 19
            },
            "10": {
              "min": 20,
              "max": 21
            },
            "11": {
              "min": 22,
              "max": 23
            },
            "12": {
              "min": 24,
              "max": 25
            },
            "13": {
              "min": 26,
              "max": 27
            },
            "14": {
              "min": 28,
              "max": 29
            },
            "15": {
              "min": 30,
              "max": 31
            },
            "16": {
              "min": 32,
              "max": 33
            },
            "17": {
              "min": 34,
              "max": 36
            },
            "18": {
              "min": 37,
              "max": 38
            },
            "19": {
              "min": 39,
              "max": 54
            }
          }
        },
        {
          "name": "Ważenie",
          "id": 7,
          "rows": {
            "1": {
              "min": 0,
              "max": 1
            },
            "2": {
              "min": 2,
              "max": 2
            },
            "3": {
              "min": 3,
              "max": 4
            },
            "4": {
              "min": 5,
              "max": 6
            },
            "5": {
              "min": 7,
              "max": 8
            },
            "6": {
              "min": 9,
              "max": 11
            },
            "7": {
              "min": 12,
              "max": 13
            },
            "8": {
              "min": 14,
              "max": 15
            },
            "9": {
              "min": 16,
              "max": 17
            },
            "10": {
              "min": 18,
              "max": 19
            },
            "11": {
              "min": 20,
              "max": 21
            },
            "12": {
              "min": 22,
              "max": 23
            },
            "13": {
              "min": 24,
              "max": 24
            },
            "14": {
              "min": 25,
              "max": 26
            },
            "15": {
              "min": 27,
              "max": 27
            },
            "16": {
              "min": 28,
              "max": 28
            },
            "17": {
              "min": 29,
              "max": 29
            },
            "18": {
              "min": 30,
              "max": 30
            },
            "19": {
              "min": 31,
              "max": 34
            }
          }
        },
        {
          "name": "Układnie wzoru",
          "id": 8,
          "rows": {
            "1": {
              "min": 0,
              "max": 1
            },
            "2": {
              "min": 2,
              "max": 2
            },
            "3": {
              "min": 3,
              "max": 3
            },
            "4": {
              "min": 4,
              "max": 5
            },
            "5": {
              "min": 6,
              "max": 7
            },
            "6": {
              "min": 8,
              "max": 9
            },
            "7": {
              "min": 10,
              "max": 11
            },
            "8": {
              "min": 12,
              "max": 12
            },
            "9": {
              "min": 13,
              "max": 14
            },
            "10": {
              "min": 15,
              "max": 15
            },
            "11": {
              "min": 16,
              "max": 16
            },
            "12": {
              "min": 17,
              "max": 18
            },
            "13": {
              "min": 19,
              "max": 19
            },
            "14": {
              "min": 20,
              "max": 20
            },
            "15": {
              "min": 21,
              "max": 21
            },
            "16": {
              "min": 22,
              "max": 22
            },
            "17": {
              "min": 23,
              "max": 23
            },
            "18": {
              "min": null,
              "max": null
            },
            "19": {
              "min": 24,
              "max": 29
            }
          }
        },
        {
          "name": "Zapamiętywanie obrazków",
          "id": 9,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 5
            },
            "3": {
              "min": 6,
              "max": 7
            },
            "4": {
              "min": 8,
              "max": 10
            },
            "5": {
              "min": 11,
              "max": 12
            },
            "6": {
              "min": 13,
              "max": 15
            },
            "7": {
              "min": 16,
              "max": 17
            },
            "8": {
              "min": 18,
              "max": 20
            },
            "9": {
              "min": 21,
              "max": 22
            },
            "10": {
              "min": 23,
              "max": 24
            },
            "11": {
              "min": 25,
              "max": 26
            },
            "12": {
              "min": 27,
              "max": 29
            },
            "13": {
              "min": 30,
              "max": 31
            },
            "14": {
              "min": 32,
              "max": 33
            },
            "15": {
              "min": 34,
              "max": 36
            },
            "16": {
              "min": 37,
              "max": 38
            },
            "17": {
              "min": 39,
              "max": 40
            },
            "18": {
              "min": 41,
              "max": 42
            },
            "19": {
              "min": 43,
              "max": 49
            }
          }
        },
        {
          "name": "Szukanie symboli",
          "id": 10,
          "rows": {
            "1": {
              "min": 0,
              "max": 4
            },
            "2": {
              "min": 5,
              "max": 6
            },
            "3": {
              "min": 7,
              "max": 8
            },
            "4": {
              "min": 9,
              "max": 10
            },
            "5": {
              "min": 11,
              "max": 12
            },
            "6": {
              "min": 13,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 16
            },
            "8": {
              "min": 17,
              "max": 18
            },
            "9": {
              "min": 19,
              "max": 20
            },
            "10": {
              "min": 21,
              "max": 22
            },
            "11": {
              "min": 23,
              "max": 24
            },
            "12": {
              "min": 25,
              "max": 26
            },
            "13": {
              "min": 27,
              "max": 28
            },
            "14": {
              "min": 29,
              "max": 30
            },
            "15": {
              "min": 31,
              "max": 32
            },
            "16": {
              "min": 33,
              "max": 34
            },
            "17": {
              "min": 35,
              "max": 35
            },
            "18": {
              "min": 36,
              "max": 37
            },
            "19": {
              "min": 38,
              "max": 60
            }
          }
        },
        {
          "name": "Wiadomości",
          "id": 11,
          "rows": {
            "1": {
              "min": 0,
              "max": 5
            },
            "2": {
              "min": 6,
              "max": 6
            },
            "3": {
              "min": 7,
              "max": 7
            },
            "4": {
              "min": 8,
              "max": 8
            },
            "5": {
              "min": null,
              "max": null
            },
            "6": {
              "min": 9,
              "max": 9
            },
            "7": {
              "min": 10,
              "max": 10
            },
            "8": {
              "min": 11,
              "max": 11
            },
            "9": {
              "min": 12,
              "max": 12
            },
            "10": {
              "min": 13,
              "max": 13
            },
            "11": {
              "min": 14,
              "max": 14
            },
            "12": {
              "min": 15,
              "max": 15
            },
            "13": {
              "min": 16,
              "max": 16
            },
            "14": {
              "min": 17,
              "max": 17
            },
            "15": {
              "min": 18,
              "max": 18
            },
            "16": {
              "min": 19,
              "max": 19
            },
            "17": {
              "min": 20,
              "max": 20
            },
            "18": {
              "min": 21,
              "max": 21
            },
            "19": {
              "min": 22,
              "max": 31
            }
          }
        },
        {
          "name": "Tworzenie pojęć",
          "id": 12,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": 1,
              "max": 1
            },
            "3": {
              "min": 2,
              "max": 3
            },
            "4": {
              "min": 4,
              "max": 4
            },
            "5": {
              "min": 5,
              "max": 6
            },
            "6": {
              "min": 7,
              "max": 7
            },
            "7": {
              "min": 8,
              "max": 8
            },
            "8": {
              "min": 9,
              "max": 10
            },
            "9": {
              "min": 11,
              "max": 11
            },
            "10": {
              "min": 12,
              "max": 12
            },
            "11": {
              "min": 13,
              "max": 13
            },
            "12": {
              "min": 14,
              "max": 14
            },
            "13": {
              "min": 15,
              "max": 16
            },
            "14": {
              "min": 17,
              "max": 17
            },
            "15": {
              "min": 18,
              "max": 18
            },
            "16": {
              "min": 19,
              "max": 19
            },
            "17": {
              "min": 20,
              "max": 20
            },
            "18": {
              "min": 21,
              "max": 21
            },
            "19": {
              "min": 22,
              "max": 27
            }
          }
        },
        {
          "name": "Porządkowanie liter i liczb",
          "id": 13,
          "rows": {
            "1": {
              "min": 0,
              "max": 1
            },
            "2": {
              "min": 2,
              "max": 2
            },
            "3": {
              "min": 3,
              "max": 4
            },
            "4": {
              "min": 5,
              "max": 6
            },
            "5": {
              "min": 7,
              "max": 8
            },
            "6": {
              "min": 9,
              "max": 9
            },
            "7": {
              "min": 10,
              "max": 11
            },
            "8": {
              "min": 12,
              "max": 12
            },
            "9": {
              "min": 13,
              "max": 13
            },
            "10": {
              "min": 14,
              "max": 14
            },
            "11": {
              "min": 15,
              "max": 15
            },
            "12": {
              "min": 16,
              "max": 16
            },
            "13": {
              "min": 17,
              "max": 17
            },
            "14": {
              "min": 18,
              "max": 18
            },
            "15": {
              "min": 19,
              "max": 19
            },
            "16": {
              "min": null,
              "max": null
            },
            "17": {
              "min": 20,
              "max": 20
            },
            "18": {
              "min": 21,
              "max": 21
            },
            "19": {
              "min": 22,
              "max": 30
            }
          }
        },
        {
          "name": "Wykreślanie",
          "id": 14,
          "rows": {
            "1": {
              "min": 0,
              "max": 21
            },
            "2": {
              "min": 22,
              "max": 24
            },
            "3": {
              "min": 25,
              "max": 27
            },
            "4": {
              "min": 28,
              "max": 31
            },
            "5": {
              "min": 32,
              "max": 35
            },
            "6": {
              "min": 36,
              "max": 39
            },
            "7": {
              "min": 40,
              "max": 43
            },
            "8": {
              "min": 44,
              "max": 48
            },
            "9": {
              "min": 49,
              "max": 53
            },
            "10": {
              "min": 54,
              "max": 58
            },
            "11": {
              "min": 59,
              "max": 64
            },
            "12": {
              "min": 65,
              "max": 70
            },
            "13": {
              "min": 71,
              "max": 76
            },
            "14": {
              "min": 77,
              "max": 83
            },
            "15": {
              "min": 84,
              "max": 90
            },
            "16": {
              "min": 91,
              "max": 97
            },
            "17": {
              "min": 98,
              "max": 105
            },
            "18": {
              "min": 106,
              "max": 112
            },
            "19": {
              "min": 113,
              "max": 128
            }
          }
        },
        {
          "name": "Rozumienie",
          "id": 15,
          "rows": {
            "1": {
              "min": 0,
              "max": 1
            },
            "2": {
              "min": 2,
              "max": 2
            },
            "3": {
              "min": 3,
              "max": 4
            },
            "4": {
              "min": 5,
              "max": 6
            },
            "5": {
              "min": 7,
              "max": 7
            },
            "6": {
              "min": 8,
              "max": 9
            },
            "7": {
              "min": 10,
              "max": 11
            },
            "8": {
              "min": 12,
              "max": 12
            },
            "9": {
              "min": 13,
              "max": 14
            },
            "10": {
              "min": 15,
              "max": 15
            },
            "11": {
              "min": 16,
              "max": 17
            },
            "12": {
              "min": 18,
              "max": 18
            },
            "13": {
              "min": 19,
              "max": 20
            },
            "14": {
              "min": 21,
              "max": 21
            },
            "15": {
              "min": 22,
              "max": 23
            },
            "16": {
              "min": 24,
              "max": 24
            },
            "17": {
              "min": 25,
              "max": 26
            },
            "18": {
              "min": 27,
              "max": 27
            },
            "19": {
              "min": 28,
              "max": 38
            }
          }
        },
        {
          "name": "Arytmetyka",
          "id": 16,
          "rows": {
            "1": {
              "min": 0,
              "max": 2
            },
            "2": {
              "min": 3,
              "max": 4
            },
            "3": {
              "min": 5,
              "max": 6
            },
            "4": {
              "min": 7,
              "max": 8
            },
            "5": {
              "min": 9,
              "max": 10
            },
            "6": {
              "min": 11,
              "max": 11
            },
            "7": {
              "min": 12,
              "max": 13
            },
            "8": {
              "min": 14,
              "max": 14
            },
            "9": {
              "min": 15,
              "max": 16
            },
            "10": {
              "min": 17,
              "max": 17
            },
            "11": {
              "min": 18,
              "max": 18
            },
            "12": {
              "min": 19,
              "max": 19
            },
            "13": {
              "min": 20,
              "max": 21
            },
            "14": {
              "min": 22,
              "max": 22
            },
            "15": {
              "min": 23,
              "max": 23
            },
            "16": {
              "min": 24,
              "max": 24
            },
            "17": {
              "min": 25,
              "max": 25
            },
            "18": {
              "min": 26,
              "max": 26
            },
            "19": {
              "min": 27,
              "max": 34
            }
          }
        }
      ],   
      
    9497: [
        {
          "name": "Wzory z klocków",
          "id": 1,
          "rows": {
            "1": {
              "min": 0,
              "max": 4
            },
            "2": {
              "min": 5,
              "max": 6
            },
            "3": {
              "min": 7,
              "max": 9
            },
            "4": {
              "min": 10,
              "max": 11
            },
            "5": {
              "min": 12,
              "max": 14
            },
            "6": {
              "min": 15,
              "max": 16
            },
            "7": {
              "min": 17,
              "max": 19
            },
            "8": {
              "min": 20,
              "max": 22
            },
            "9": {
              "min": 23,
              "max": 25
            },
            "10": {
              "min": 26,
              "max": 28
            },
            "11": {
              "min": 29,
              "max": 30
            },
            "12": {
              "min": 31,
              "max": 33
            },
            "13": {
              "min": 34,
              "max": 36
            },
            "14": {
              "min": 37,
              "max": 39
            },
            "15": {
              "min": 40,
              "max": 42
            },
            "16": {
              "min": 43,
              "max": 45
            },
            "17": {
              "min": 46,
              "max": 48
            },
            "18": {
              "min": 49,
              "max": 51
            },
            "19": {
              "min": 52,
              "max": 58
            }
          }
        },
        {
          "name": "Podobieństwa",
          "id": 2,
          "rows": {
            "1": {
              "min": 0,
              "max": 2
            },
            "2": {
              "min": 3,
              "max": 4
            },
            "3": {
              "min": 5,
              "max": 7
            },
            "4": {
              "min": 8,
              "max": 10
            },
            "5": {
              "min": 11,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 15
            },
            "7": {
              "min": 16,
              "max": 18
            },
            "8": {
              "min": 19,
              "max": 20
            },
            "9": {
              "min": 21,
              "max": 22
            },
            "10": {
              "min": 23,
              "max": 24
            },
            "11": {
              "min": 25,
              "max": 26
            },
            "12": {
              "min": 27,
              "max": 28
            },
            "13": {
              "min": 29,
              "max": 29
            },
            "14": {
              "min": 30,
              "max": 31
            },
            "15": {
              "min": 32,
              "max": 32
            },
            "16": {
              "min": 33,
              "max": 34
            },
            "17": {
              "min": 35,
              "max": 35
            },
            "18": {
              "min": 36,
              "max": 37
            },
            "19": {
              "min": 38,
              "max": 46
            }
          }
        },
        {
          "name": "Matryce",
          "id": 3,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 5
            },
            "3": {
              "min": 6,
              "max": 7
            },
            "4": {
              "min": 8,
              "max": 8
            },
            "5": {
              "min": 9,
              "max": 10
            },
            "6": {
              "min": 11,
              "max": 11
            },
            "7": {
              "min": 12,
              "max": 12
            },
            "8": {
              "min": 13,
              "max": 14
            },
            "9": {
              "min": 15,
              "max": 15
            },
            "10": {
              "min": 16,
              "max": 17
            },
            "11": {
              "min": 18,
              "max": 18
            },
            "12": {
              "min": 19,
              "max": 19
            },
            "13": {
              "min": 20,
              "max": 20
            },
            "14": {
              "min": 21,
              "max": 22
            },
            "15": {
              "min": 23,
              "max": 23
            },
            "16": {
              "min": 24,
              "max": 24
            },
            "17": {
              "min": 25,
              "max": 25
            },
            "18": {
              "min": 26,
              "max": 27
            },
            "19": {
              "min": 28,
              "max": 32
            }
          }
        },
        {
          "name": "Powtarzanie liczb",
          "id": 4,
          "rows": {
            "1": {
              "min": 0,
              "max": 5
            },
            "2": {
              "min": 6,
              "max": 7
            },
            "3": {
              "min": 8,
              "max": 9
            },
            "4": {
              "min": 10,
              "max": 10
            },
            "5": {
              "min": 11,
              "max": 12
            },
            "6": {
              "min": 13,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 16
            },
            "8": {
              "min": 17,
              "max": 17
            },
            "9": {
              "min": 18,
              "max": 19
            },
            "10": {
              "min": 20,
              "max": 21
            },
            "11": {
              "min": 22,
              "max": 22
            },
            "12": {
              "min": 23,
              "max": 24
            },
            "13": {
              "min": 25,
              "max": 25
            },
            "14": {
              "min": 26,
              "max": 27
            },
            "15": {
              "min": 28,
              "max": 28
            },
            "16": {
              "min": 29,
              "max": 30
            },
            "17": {
              "min": 31,
              "max": 31
            },
            "18": {
              "min": 32,
              "max": 33
            },
            "19": {
              "min": 34,
              "max": 54
            }
          }
        },
        {
          "name": "Kodowanie",
          "id": 5,
          "rows": {
            "1": {
              "min": 0,
              "max": 9
            },
            "2": {
              "min": 10,
              "max": 13
            },
            "3": {
              "min": 14,
              "max": 16
            },
            "4": {
              "min": 17,
              "max": 20
            },
            "5": {
              "min": 21,
              "max": 23
            },
            "6": {
              "min": 24,
              "max": 26
            },
            "7": {
              "min": 27,
              "max": 30
            },
            "8": {
              "min": 31,
              "max": 33
            },
            "9": {
              "min": 34,
              "max": 37
            },
            "10": {
              "min": 38,
              "max": 40
            },
            "11": {
              "min": 41,
              "max": 43
            },
            "12": {
              "min": 44,
              "max": 47
            },
            "13": {
              "min": 48,
              "max": 50
            },
            "14": {
              "min": 51,
              "max": 54
            },
            "15": {
              "min": 55,
              "max": 57
            },
            "16": {
              "min": 58,
              "max": 60
            },
            "17": {
              "min": 61,
              "max": 64
            },
            "18": {
              "min": 65,
              "max": 67
            },
            "19": {
              "min": 68,
              "max": 117
            }
          }
        },
        {
          "name": "Słownik",
          "id": 6,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 11
            },
            "5": {
              "min": 12,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 16
            },
            "8": {
              "min": 17,
              "max": 18
            },
            "9": {
              "min": 19,
              "max": 20
            },
            "10": {
              "min": 21,
              "max": 22
            },
            "11": {
              "min": 23,
              "max": 24
            },
            "12": {
              "min": 25,
              "max": 26
            },
            "13": {
              "min": 27,
              "max": 28
            },
            "14": {
              "min": 29,
              "max": 30
            },
            "15": {
              "min": 31,
              "max": 33
            },
            "16": {
              "min": 34,
              "max": 35
            },
            "17": {
              "min": 36,
              "max": 38
            },
            "18": {
              "min": 39,
              "max": 40
            },
            "19": {
              "min": 41,
              "max": 54
            }
          }
        },
        {
          "name": "Ważenie",
          "id": 7,
          "rows": {
            "1": {
              "min": 0,
              "max": 1
            },
            "2": {
              "min": 2,
              "max": 3
            },
            "3": {
              "min": 4,
              "max": 5
            },
            "4": {
              "min": 6,
              "max": 7
            },
            "5": {
              "min": 8,
              "max": 9
            },
            "6": {
              "min": 10,
              "max": 12
            },
            "7": {
              "min": 13,
              "max": 14
            },
            "8": {
              "min": 15,
              "max": 16
            },
            "9": {
              "min": 17,
              "max": 18
            },
            "10": {
              "min": 19,
              "max": 20
            },
            "11": {
              "min": 21,
              "max": 22
            },
            "12": {
              "min": 23,
              "max": 23
            },
            "13": {
              "min": 24,
              "max": 25
            },
            "14": {
              "min": 26,
              "max": 26
            },
            "15": {
              "min": 27,
              "max": 27
            },
            "16": {
              "min": 28,
              "max": 28
            },
            "17": {
              "min": 29,
              "max": 30
            },
            "18": {
              "min": 31,
              "max": 31
            },
            "19": {
              "min": 32,
              "max": 34
            }
          }
        },
        {
          "name": "Układnie wzoru",
          "id": 8,
          "rows": {
            "1": {
              "min": 0,
              "max": 1
            },
            "2": {
              "min": 2,
              "max": 2
            },
            "3": {
              "min": 3,
              "max": 4
            },
            "4": {
              "min": 5,
              "max": 5
            },
            "5": {
              "min": 6,
              "max": 7
            },
            "6": {
              "min": 8,
              "max": 9
            },
            "7": {
              "min": 10,
              "max": 11
            },
            "8": {
              "min": 12,
              "max": 13
            },
            "9": {
              "min": 14,
              "max": 14
            },
            "10": {
              "min": 15,
              "max": 16
            },
            "11": {
              "min": 17,
              "max": 17
            },
            "12": {
              "min": 18,
              "max": 18
            },
            "13": {
              "min": 19,
              "max": 19
            },
            "14": {
              "min": 20,
              "max": 20
            },
            "15": {
              "min": 21,
              "max": 21
            },
            "16": {
              "min": 22,
              "max": 22
            },
            "17": {
              "min": 23,
              "max": 23
            },
            "18": {
              "min": 24,
              "max": 24
            },
            "19": {
              "min": 25,
              "max": 29
            }
          }
        },
        {
          "name": "Zapamiętywanie obrazków",
          "id": 9,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 5
            },
            "3": {
              "min": 6,
              "max": 8
            },
            "4": {
              "min": 9,
              "max": 10
            },
            "5": {
              "min": 11,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 15
            },
            "7": {
              "min": 16,
              "max": 18
            },
            "8": {
              "min": 19,
              "max": 20
            },
            "9": {
              "min": 21,
              "max": 23
            },
            "10": {
              "min": 24,
              "max": 25
            },
            "11": {
              "min": 26,
              "max": 27
            },
            "12": {
              "min": 28,
              "max": 30
            },
            "13": {
              "min": 31,
              "max": 32
            },
            "14": {
              "min": 33,
              "max": 34
            },
            "15": {
              "min": 35,
              "max": 36
            },
            "16": {
              "min": 37,
              "max": 39
            },
            "17": {
              "min": 40,
              "max": 41
            },
            "18": {
              "min": 42,
              "max": 43
            },
            "19": {
              "min": 44,
              "max": 49
            }
          }
        },
        {
          "name": "Szukanie symboli",
          "id": 10,
          "rows": {
            "1": {
              "min": 0,
              "max": 4
            },
            "2": {
              "min": 5,
              "max": 6
            },
            "3": {
              "min": 7,
              "max": 9
            },
            "4": {
              "min": 10,
              "max": 11
            },
            "5": {
              "min": 12,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 15
            },
            "7": {
              "min": 16,
              "max": 17
            },
            "8": {
              "min": 18,
              "max": 19
            },
            "9": {
              "min": 20,
              "max": 21
            },
            "10": {
              "min": 22,
              "max": 23
            },
            "11": {
              "min": 24,
              "max": 25
            },
            "12": {
              "min": 26,
              "max": 27
            },
            "13": {
              "min": 28,
              "max": 29
            },
            "14": {
              "min": 30,
              "max": 31
            },
            "15": {
              "min": 32,
              "max": 33
            },
            "16": {
              "min": 34,
              "max": 35
            },
            "17": {
              "min": 36,
              "max": 36
            },
            "18": {
              "min": 37,
              "max": 38
            },
            "19": {
              "min": 39,
              "max": 60
            }
          }
        },
        {
          "name": "Wiadomości",
          "id": 11,
          "rows": {
            "1": {
              "min": 0,
              "max": 5
            },
            "2": {
              "min": 6,
              "max": 6
            },
            "3": {
              "min": 7,
              "max": 7
            },
            "4": {
              "min": 8,
              "max": 8
            },
            "5": {
              "min": 9,
              "max": 9
            },
            "6": {
              "min": 10,
              "max": 10
            },
            "7": {
              "min": 11,
              "max": 11
            },
            "8": {
              "min": 12,
              "max": 12
            },
            "9": {
              "min": 13,
              "max": 13
            },
            "10": {
              "min": 14,
              "max": 14
            },
            "11": {
              "min": 15,
              "max": 15
            },
            "12": {
              "min": 16,
              "max": 16
            },
            "13": {
              "min": 17,
              "max": 17
            },
            "14": {
              "min": 18,
              "max": 18
            },
            "15": {
              "min": 19,
              "max": 19
            },
            "16": {
              "min": 20,
              "max": 20
            },
            "17": {
              "min": 21,
              "max": 21
            },
            "18": {
              "min": 22,
              "max": 22
            },
            "19": {
              "min": 23,
              "max": 31
            }
          }
        },
        {
          "name": "Tworzenie pojęć",
          "id": 12,
          "rows": {
            "1": {
              "min": 0,
              "max": 0
            },
            "2": {
              "min": 1,
              "max": 2
            },
            "3": {
              "min": 3,
              "max": 3
            },
            "4": {
              "min": 4,
              "max": 5
            },
            "5": {
              "min": 6,
              "max": 6
            },
            "6": {
              "min": 7,
              "max": 7
            },
            "7": {
              "min": 8,
              "max": 9
            },
            "8": {
              "min": 10,
              "max": 10
            },
            "9": {
              "min": 11,
              "max": 11
            },
            "10": {
              "min": 12,
              "max": 12
            },
            "11": {
              "min": 13,
              "max": 14
            },
            "12": {
              "min": 15,
              "max": 15
            },
            "13": {
              "min": 16,
              "max": 16
            },
            "14": {
              "min": 17,
              "max": 17
            },
            "15": {
              "min": 18,
              "max": 18
            },
            "16": {
              "min": 19,
              "max": 19
            },
            "17": {
              "min": 20,
              "max": 20
            },
            "18": {
              "min": 21,
              "max": 21
            },
            "19": {
              "min": 22,
              "max": 27
            }
          }
        },
        {
          "name": "Porządkowanie liter i liczb",
          "id": 13,
          "rows": {
            "1": {
              "min": 0,
              "max": 1
            },
            "2": {
              "min": 2,
              "max": 3
            },
            "3": {
              "min": 4,
              "max": 5
            },
            "4": {
              "min": 6,
              "max": 7
            },
            "5": {
              "min": 8,
              "max": 8
            },
            "6": {
              "min": 9,
              "max": 10
            },
            "7": {
              "min": 11,
              "max": 11
            },
            "8": {
              "min": 12,
              "max": 12
            },
            "9": {
              "min": 13,
              "max": 14
            },
            "10": {
              "min": 15,
              "max": 15
            },
            "11": {
              "min": 16,
              "max": 16
            },
            "12": {
              "min": 17,
              "max": 17
            },
            "13": {
              "min": null,
              "max": null
            },
            "14": {
              "min": 18,
              "max": 18
            },
            "15": {
              "min": 19,
              "max": 19
            },
            "16": {
              "min": 20,
              "max": 20
            },
            "17": {
              "min": 21,
              "max": 21
            },
            "18": {
              "min": null,
              "max": null
            },
            "19": {
              "min": 22,
              "max": 30
            }
          }
        },
        {
          "name": "Wykreślanie",
          "id": 14,
          "rows": {
            "1": {
              "min": 0,
              "max": 22
            },
            "2": {
              "min": 23,
              "max": 25
            },
            "3": {
              "min": 26,
              "max": 29
            },
            "4": {
              "min": 30,
              "max": 32
            },
            "5": {
              "min": 33,
              "max": 36
            },
            "6": {
              "min": 37,
              "max": 40
            },
            "7": {
              "min": 41,
              "max": 45
            },
            "8": {
              "min": 46,
              "max": 50
            },
            "9": {
              "min": 51,
              "max": 55
            },
            "10": {
              "min": 56,
              "max": 60
            },
            "11": {
              "min": 61,
              "max": 66
            },
            "12": {
              "min": 67,
              "max": 72
            },
            "13": {
              "min": 73,
              "max": 78
            },
            "14": {
              "min": 79,
              "max": 85
            },
            "15": {
              "min": 86,
              "max": 92
            },
            "16": {
              "min": 93,
              "max": 99
            },
            "17": {
              "min": 100,
              "max": 107
            },
            "18": {
              "min": 108,
              "max": 114
            },
            "19": {
              "min": 115,
              "max": 128
            }
          }
        },
        {
          "name": "Rozumienie",
          "id": 15,
          "rows": {
            "1": {
              "min": 0,
              "max": 1
            },
            "2": {
              "min": 2,
              "max": 3
            },
            "3": {
              "min": 4,
              "max": 5
            },
            "4": {
              "min": 6,
              "max": 6
            },
            "5": {
              "min": 7,
              "max": 8
            },
            "6": {
              "min": 9,
              "max": 9
            },
            "7": {
              "min": 10,
              "max": 11
            },
            "8": {
              "min": 12,
              "max": 13
            },
            "9": {
              "min": 14,
              "max": 14
            },
            "10": {
              "min": 15,
              "max": 16
            },
            "11": {
              "min": 17,
              "max": 17
            },
            "12": {
              "min": 18,
              "max": 19
            },
            "13": {
              "min": 20,
              "max": 20
            },
            "14": {
              "min": 21,
              "max": 22
            },
            "15": {
              "min": 23,
              "max": 24
            },
            "16": {
              "min": 25,
              "max": 25
            },
            "17": {
              "min": 26,
              "max": 27
            },
            "18": {
              "min": 28,
              "max": 28
            },
            "19": {
              "min": 29,
              "max": 38
            }
          }
        },
        {
          "name": "Arytmetyka",
          "id": 16,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 5
            },
            "3": {
              "min": 6,
              "max": 7
            },
            "4": {
              "min": 8,
              "max": 8
            },
            "5": {
              "min": 9,
              "max": 10
            },
            "6": {
              "min": 11,
              "max": 12
            },
            "7": {
              "min": 13,
              "max": 13
            },
            "8": {
              "min": 14,
              "max": 15
            },
            "9": {
              "min": 16,
              "max": 16
            },
            "10": {
              "min": 17,
              "max": 18
            },
            "11": {
              "min": 19,
              "max": 19
            },
            "12": {
              "min": 20,
              "max": 20
            },
            "13": {
              "min": 21,
              "max": 21
            },
            "14": {
              "min": 22,
              "max": 23
            },
            "15": {
              "min": 24,
              "max": 24
            },
            "16": {
              "min": 25,
              "max": 25
            },
            "17": {
              "min": 26,
              "max": 26
            },
            "18": {
              "min": 27,
              "max": 27
            },
            "19": {
              "min": 28,
              "max": 34
            }
          }
        }
      ],  

    98911: [
        {
          "name": "Wzory z klocków",
          "id": 1,
          "rows": {
            "1": {
              "min": 0,
              "max": 5
            },
            "2": {
              "min": 6,
              "max": 7
            },
            "3": {
              "min": 8,
              "max": 9
            },
            "4": {
              "min": 10,
              "max": 12
            },
            "5": {
              "min": 13,
              "max": 15
            },
            "6": {
              "min": 16,
              "max": 17
            },
            "7": {
              "min": 18,
              "max": 20
            },
            "8": {
              "min": 21,
              "max": 23
            },
            "9": {
              "min": 24,
              "max": 25
            },
            "10": {
              "min": 26,
              "max": 28
            },
            "11": {
              "min": 29,
              "max": 31
            },
            "12": {
              "min": 32,
              "max": 34
            },
            "13": {
              "min": 35,
              "max": 37
            },
            "14": {
              "min": 38,
              "max": 40
            },
            "15": {
              "min": 41,
              "max": 43
            },
            "16": {
              "min": 44,
              "max": 46
            },
            "17": {
              "min": 47,
              "max": 49
            },
            "18": {
              "min": 50,
              "max": 52
            },
            "19": {
              "min": 53,
              "max": 58
            }
          }
        },
        {
          "name": "Podobieństwa",
          "id": 2,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 5
            },
            "3": {
              "min": 6,
              "max": 8
            },
            "4": {
              "min": 9,
              "max": 11
            },
            "5": {
              "min": 12,
              "max": 14
            },
            "6": {
              "min": 15,
              "max": 17
            },
            "7": {
              "min": 18,
              "max": 19
            },
            "8": {
              "min": 20,
              "max": 21
            },
            "9": {
              "min": 22,
              "max": 23
            },
            "10": {
              "min": 24,
              "max": 25
            },
            "11": {
              "min": 26,
              "max": 27
            },
            "12": {
              "min": 28,
              "max": 28
            },
            "13": {
              "min": 29,
              "max": 30
            },
            "14": {
              "min": 31,
              "max": 31
            },
            "15": {
              "min": 32,
              "max": 33
            },
            "16": {
              "min": 34,
              "max": 34
            },
            "17": {
              "min": 35,
              "max": 36
            },
            "18": {
              "min": 37,
              "max": 37
            },
            "19": {
              "min": 38,
              "max": 46
            }
          }
        },
        {
          "name": "Matryce",
          "id": 3,
          "rows": {
            "1": {
              "min": 0,
              "max": 4
            },
            "2": {
              "min": 5,
              "max": 6
            },
            "3": {
              "min": 7,
              "max": 7
            },
            "4": {
              "min": 8,
              "max": 9
            },
            "5": {
              "min": 10,
              "max": 10
            },
            "6": {
              "min": 11,
              "max": 12
            },
            "7": {
              "min": 13,
              "max": 13
            },
            "8": {
              "min": 14,
              "max": 14
            },
            "9": {
              "min": 15,
              "max": 16
            },
            "10": {
              "min": 17,
              "max": 17
            },
            "11": {
              "min": 18,
              "max": 18
            },
            "12": {
              "min": 19,
              "max": 20
            },
            "13": {
              "min": 21,
              "max": 21
            },
            "14": {
              "min": 22,
              "max": 22
            },
            "15": {
              "min": 23,
              "max": 23
            },
            "16": {
              "min": 24,
              "max": 25
            },
            "17": {
              "min": 26,
              "max": 26
            },
            "18": {
              "min": 27,
              "max": 27
            },
            "19": {
              "min": 28,
              "max": 32
            }
          }
        },
        {
          "name": "Powtarzanie liczb",
          "id": 4,
          "rows": {
            "1": {
              "min": 0,
              "max": 6
            },
            "2": {
              "min": 7,
              "max": 7
            },
            "3": {
              "min": 8,
              "max": 9
            },
            "4": {
              "min": 10,
              "max": 11
            },
            "5": {
              "min": 12,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 15
            },
            "7": {
              "min": 16,
              "max": 16
            },
            "8": {
              "min": 17,
              "max": 18
            },
            "9": {
              "min": 19,
              "max": 20
            },
            "10": {
              "min": 21,
              "max": 21
            },
            "11": {
              "min": 22,
              "max": 23
            },
            "12": {
              "min": 24,
              "max": 24
            },
            "13": {
              "min": 25,
              "max": 26
            },
            "14": {
              "min": 27,
              "max": 27
            },
            "15": {
              "min": 28,
              "max": 29
            },
            "16": {
              "min": 30,
              "max": 30
            },
            "17": {
              "min": 31,
              "max": 32
            },
            "18": {
              "min": 33,
              "max": 33
            },
            "19": {
              "min": 34,
              "max": 54
            }
          }
        },
        {
          "name": "Kodowanie",
          "id": 5,
          "rows": {
            "1": {
              "min": 0,
              "max": 10
            },
            "2": {
              "min": 11,
              "max": 14
            },
            "3": {
              "min": 15,
              "max": 17
            },
            "4": {
              "min": 18,
              "max": 21
            },
            "5": {
              "min": 22,
              "max": 24
            },
            "6": {
              "min": 25,
              "max": 28
            },
            "7": {
              "min": 29,
              "max": 31
            },
            "8": {
              "min": 32,
              "max": 35
            },
            "9": {
              "min": 36,
              "max": 38
            },
            "10": {
              "min": 39,
              "max": 42
            },
            "11": {
              "min": 43,
              "max": 45
            },
            "12": {
              "min": 46,
              "max": 49
            },
            "13": {
              "min": 50,
              "max": 52
            },
            "14": {
              "min": 53,
              "max": 56
            },
            "15": {
              "min": 57,
              "max": 59
            },
            "16": {
              "min": 60,
              "max": 64
            },
            "17": {
              "min": 65,
              "max": 66
            },
            "18": {
              "min": 67,
              "max": 70
            },
            "19": {
              "min": 71,
              "max": 117
            }
          }
        },
        {
          "name": "Słownik",
          "id": 6,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 11
            },
            "5": {
              "min": 12,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 15
            },
            "7": {
              "min": 16,
              "max": 17
            },
            "8": {
              "min": 18,
              "max": 18
            },
            "9": {
              "min": 19,
              "max": 20
            },
            "10": {
              "min": 21,
              "max": 23
            },
            "11": {
              "min": 24,
              "max": 25
            },
            "12": {
              "min": 26,
              "max": 27
            },
            "13": {
              "min": 28,
              "max": 29
            },
            "14": {
              "min": 30,
              "max": 32
            },
            "15": {
              "min": 33,
              "max": 34
            },
            "16": {
              "min": 35,
              "max": 37
            },
            "17": {
              "min": 38,
              "max": 40
            },
            "18": {
              "min": 41,
              "max": 42
            },
            "19": {
              "min": 43,
              "max": 54
            }
          }
        },
        {
          "name": "Ważenie",
          "id": 7,
          "rows": {
            "1": {
              "min": 0,
              "max": 2
            },
            "2": {
              "min": 3,
              "max": 3
            },
            "3": {
              "min": 4,
              "max": 5
            },
            "4": {
              "min": 6,
              "max": 8
            },
            "5": {
              "min": 9,
              "max": 10
            },
            "6": {
              "min": 11,
              "max": 12
            },
            "7": {
              "min": 13,
              "max": 15
            },
            "8": {
              "min": 16,
              "max": 17
            },
            "9": {
              "min": 18,
              "max": 19
            },
            "10": {
              "min": 20,
              "max": 21
            },
            "11": {
              "min": 22,
              "max": 22
            },
            "12": {
              "min": 23,
              "max": 24
            },
            "13": {
              "min": 25,
              "max": 25
            },
            "14": {
              "min": 26,
              "max": 26
            },
            "15": {
              "min": 27,
              "max": 28
            },
            "16": {
              "min": 29,
              "max": 29
            },
            "17": {
              "min": 30,
              "max": 30
            },
            "18": {
              "min": 31,
              "max": 31
            },
            "19": {
              "min": 32,
              "max": 34
            }
          }
        },
        {
          "name": "Układnie wzoru",
          "id": 8,
          "rows": {
            "1": {
              "min": 0,
              "max": 1
            },
            "2": {
              "min": 2,
              "max": 2
            },
            "3": {
              "min": 3,
              "max": 4
            },
            "4": {
              "min": 5,
              "max": 6
            },
            "5": {
              "min": 7,
              "max": 8
            },
            "6": {
              "min": 9,
              "max": 10
            },
            "7": {
              "min": 11,
              "max": 11
            },
            "8": {
              "min": 12,
              "max": 13
            },
            "9": {
              "min": 14,
              "max": 15
            },
            "10": {
              "min": 16,
              "max": 16
            },
            "11": {
              "min": 17,
              "max": 17
            },
            "12": {
              "min": 18,
              "max": 18
            },
            "13": {
              "min": 19,
              "max": 19
            },
            "14": {
              "min": 20,
              "max": 21
            },
            "15": {
              "min": null,
              "max": null
            },
            "16": {
              "min": 22,
              "max": 22
            },
            "17": {
              "min": 23,
              "max": 23
            },
            "18": {
              "min": 24,
              "max": 24
            },
            "19": {
              "min": 25,
              "max": 29
            }
          }
        },
        {
          "name": "Zapamiętywanie obrazków",
          "id": 9,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 6
            },
            "3": {
              "min": 7,
              "max": 8
            },
            "4": {
              "min": 9,
              "max": 11
            },
            "5": {
              "min": 12,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 16
            },
            "7": {
              "min": 17,
              "max": 18
            },
            "8": {
              "min": 19,
              "max": 21
            },
            "9": {
              "min": 22,
              "max": 23
            },
            "10": {
              "min": 24,
              "max": 26
            },
            "11": {
              "min": 27,
              "max": 28
            },
            "12": {
              "min": 29,
              "max": 30
            },
            "13": {
              "min": 31,
              "max": 33
            },
            "14": {
              "min": 34,
              "max": 35
            },
            "15": {
              "min": 36,
              "max": 37
            },
            "16": {
              "min": 38,
              "max": 39
            },
            "17": {
              "min": 40,
              "max": 42
            },
            "18": {
              "min": 43,
              "max": 44
            },
            "19": {
              "min": 45,
              "max": 49
            }
          }
        },
        {
          "name": "Szukanie symboli",
          "id": 10,
          "rows": {
            "1": {
              "min": 0,
              "max": 5
            },
            "2": {
              "min": 6,
              "max": 7
            },
            "3": {
              "min": 8,
              "max": 9
            },
            "4": {
              "min": 10,
              "max": 11
            },
            "5": {
              "min": 12,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 16
            },
            "7": {
              "min": 17,
              "max": 18
            },
            "8": {
              "min": 19,
              "max": 20
            },
            "9": {
              "min": 21,
              "max": 22
            },
            "10": {
              "min": 23,
              "max": 24
            },
            "11": {
              "min": 25,
              "max": 26
            },
            "12": {
              "min": 27,
              "max": 28
            },
            "13": {
              "min": 29,
              "max": 30
            },
            "14": {
              "min": 31,
              "max": 32
            },
            "15": {
              "min": 33,
              "max": 34
            },
            "16": {
              "min": 35,
              "max": 36
            },
            "17": {
              "min": 37,
              "max": 38
            },
            "18": {
              "min": 39,
              "max": 39
            },
            "19": {
              "min": 40,
              "max": 60
            }
          }
        },
        {
          "name": "Wiadomości",
          "id": 11,
          "rows": {
            "1": {
              "min": 0,
              "max": 6
            },
            "2": {
              "min": null,
              "max": null
            },
            "3": {
              "min": 7,
              "max": 7
            },
            "4": {
              "min": 8,
              "max": 8
            },
            "5": {
              "min": 9,
              "max": 9
            },
            "6": {
              "min": 10,
              "max": 10
            },
            "7": {
              "min": 11,
              "max": 11
            },
            "8": {
              "min": 12,
              "max": 12
            },
            "9": {
              "min": 13,
              "max": 13
            },
            "10": {
              "min": 14,
              "max": 14
            },
            "11": {
              "min": 15,
              "max": 15
            },
            "12": {
              "min": 16,
              "max": 16
            },
            "13": {
              "min": 17,
              "max": 17
            },
            "14": {
              "min": 18,
              "max": 18
            },
            "15": {
              "min": 19,
              "max": 19
            },
            "16": {
              "min": 20,
              "max": 20
            },
            "17": {
              "min": 21,
              "max": 21
            },
            "18": {
              "min": 22,
              "max": 23
            },
            "19": {
              "min": 24,
              "max": 31
            }
          }
        },
        {
          "name": "Tworzenie pojęć",
          "id": 12,
          "rows": {
            "1": {
              "min": 0,
              "max": 1
            },
            "2": {
              "min": 2,
              "max": 2
            },
            "3": {
              "min": 3,
              "max": 4
            },
            "4": {
              "min": 5,
              "max": 5
            },
            "5": {
              "min": 6,
              "max": 6
            },
            "6": {
              "min": 7,
              "max": 8
            },
            "7": {
              "min": 9,
              "max": 9
            },
            "8": {
              "min": 10,
              "max": 10
            },
            "9": {
              "min": 11,
              "max": 12
            },
            "10": {
              "min": 13,
              "max": 13
            },
            "11": {
              "min": 14,
              "max": 14
            },
            "12": {
              "min": 15,
              "max": 15
            },
            "13": {
              "min": 16,
              "max": 16
            },
            "14": {
              "min": 17,
              "max": 17
            },
            "15": {
              "min": 18,
              "max": 18
            },
            "16": {
              "min": 19,
              "max": 19
            },
            "17": {
              "min": 20,
              "max": 20
            },
            "18": {
              "min": 21,
              "max": 21
            },
            "19": {
              "min": 22,
              "max": 27
            }
          }
        },
        {
          "name": "Porządkowanie liter i liczb",
          "id": 13,
          "rows": {
            "1": {
              "min": 0,
              "max": 2
            },
            "2": {
              "min": 3,
              "max": 4
            },
            "3": {
              "min": 5,
              "max": 5
            },
            "4": {
              "min": 6,
              "max": 7
            },
            "5": {
              "min": 8,
              "max": 9
            },
            "6": {
              "min": 10,
              "max": 10
            },
            "7": {
              "min": 11,
              "max": 12
            },
            "8": {
              "min": 13,
              "max": 13
            },
            "9": {
              "min": 14,
              "max": 14
            },
            "10": {
              "min": 15,
              "max": 15
            },
            "11": {
              "min": 16,
              "max": 16
            },
            "12": {
              "min": 17,
              "max": 17
            },
            "13": {
              "min": 18,
              "max": 18
            },
            "14": {
              "min": 19,
              "max": 19
            },
            "15": {
              "min": null,
              "max": null
            },
            "16": {
              "min": 20,
              "max": 20
            },
            "17": {
              "min": 21,
              "max": 21
            },
            "18": {
              "min": null,
              "max": null
            },
            "19": {
              "min": 22,
              "max": 30
            }
          }
        },
        {
          "name": "Wykreślanie",
          "id": 14,
          "rows": {
            "1": {
              "min": 0,
              "max": 23
            },
            "2": {
              "min": 24,
              "max": 26
            },
            "3": {
              "min": 27,
              "max": 30
            },
            "4": {
              "min": 31,
              "max": 33
            },
            "5": {
              "min": 34,
              "max": 37
            },
            "6": {
              "min": 38,
              "max": 42
            },
            "7": {
              "min": 43,
              "max": 46
            },
            "8": {
              "min": 47,
              "max": 51
            },
            "9": {
              "min": 52,
              "max": 56
            },
            "10": {
              "min": 57,
              "max": 62
            },
            "11": {
              "min": 63,
              "max": 68
            },
            "12": {
              "min": 69,
              "max": 74
            },
            "13": {
              "min": 75,
              "max": 80
            },
            "14": {
              "min": 81,
              "max": 87
            },
            "15": {
              "min": 88,
              "max": 94
            },
            "16": {
              "min": 95,
              "max": 101
            },
            "17": {
              "min": 102,
              "max": 109
            },
            "18": {
              "min": 110,
              "max": 116
            },
            "19": {
              "min": 117,
              "max": 128
            }
          }
        },
        {
          "name": "Rozumienie",
          "id": 15,
          "rows": {
            "1": {
              "min": 0,
              "max": 2
            },
            "2": {
              "min": 3,
              "max": 3
            },
            "3": {
              "min": 4,
              "max": 5
            },
            "4": {
              "min": 6,
              "max": 7
            },
            "5": {
              "min": 8,
              "max": 8
            },
            "6": {
              "min": 9,
              "max": 10
            },
            "7": {
              "min": 11,
              "max": 12
            },
            "8": {
              "min": 13,
              "max": 13
            },
            "9": {
              "min": 14,
              "max": 15
            },
            "10": {
              "min": 16,
              "max": 16
            },
            "11": {
              "min": 17,
              "max": 18
            },
            "12": {
              "min": 19,
              "max": 20
            },
            "13": {
              "min": 21,
              "max": 21
            },
            "14": {
              "min": 22,
              "max": 23
            },
            "15": {
              "min": 24,
              "max": 24
            },
            "16": {
              "min": 25,
              "max": 26
            },
            "17": {
              "min": 27,
              "max": 27
            },
            "18": {
              "min": 28,
              "max": 29
            },
            "19": {
              "min": 30,
              "max": 38
            }
          }
        },
        {
          "name": "Arytmetyka",
          "id": 16,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 5
            },
            "3": {
              "min": 6,
              "max": 7
            },
            "4": {
              "min": 8,
              "max": 9
            },
            "5": {
              "min": 10,
              "max": 11
            },
            "6": {
              "min": 12,
              "max": 12
            },
            "7": {
              "min": 13,
              "max": 14
            },
            "8": {
              "min": 15,
              "max": 15
            },
            "9": {
              "min": 16,
              "max": 17
            },
            "10": {
              "min": 18,
              "max": 18
            },
            "11": {
              "min": 19,
              "max": 20
            },
            "12": {
              "min": 21,
              "max": 21
            },
            "13": {
              "min": 22,
              "max": 22
            },
            "14": {
              "min": 23,
              "max": 23
            },
            "15": {
              "min": 24,
              "max": 25
            },
            "16": {
              "min": 26,
              "max": 26
            },
            "17": {
              "min": 27,
              "max": 27
            },
            "18": {
              "min": 28,
              "max": 28
            },
            "19": {
              "min": 29,
              "max": 34
            }
          }
        }
      ],
    
    100103: [
        {
          "name": "Wzory z klocków",
          "id": 1,
          "rows": {
            "1": {
              "min": 0,
              "max": 5
            },
            "2": {
              "min": 6,
              "max": 7
            },
            "3": {
              "min": 8,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 13
            },
            "5": {
              "min": 14,
              "max": 15
            },
            "6": {
              "min": 16,
              "max": 18
            },
            "7": {
              "min": 19,
              "max": 21
            },
            "8": {
              "min": 22,
              "max": 23
            },
            "9": {
              "min": 24,
              "max": 26
            },
            "10": {
              "min": 27,
              "max": 29
            },
            "11": {
              "min": 30,
              "max": 32
            },
            "12": {
              "min": 33,
              "max": 35
            },
            "13": {
              "min": 36,
              "max": 38
            },
            "14": {
              "min": 39,
              "max": 41
            },
            "15": {
              "min": 42,
              "max": 44
            },
            "16": {
              "min": 45,
              "max": 47
            },
            "17": {
              "min": 48,
              "max": 50
            },
            "18": {
              "min": 51,
              "max": 53
            },
            "19": {
              "min": 54,
              "max": 58
            }
          }
        },
        {
          "name": "Podobieństwa",
          "id": 2,
          "rows": {
            "1": {
              "min": 0,
              "max": 4
            },
            "2": {
              "min": 5,
              "max": 7
            },
            "3": {
              "min": 8,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 12
            },
            "5": {
              "min": 13,
              "max": 15
            },
            "6": {
              "min": 16,
              "max": 18
            },
            "7": {
              "min": 19,
              "max": 20
            },
            "8": {
              "min": 21,
              "max": 22
            },
            "9": {
              "min": 23,
              "max": 24
            },
            "10": {
              "min": 25,
              "max": 26
            },
            "11": {
              "min": 27,
              "max": 27
            },
            "12": {
              "min": 28,
              "max": 29
            },
            "13": {
              "min": 30,
              "max": 30
            },
            "14": {
              "min": 31,
              "max": 32
            },
            "15": {
              "min": 33,
              "max": 33
            },
            "16": {
              "min": 34,
              "max": 35
            },
            "17": {
              "min": 36,
              "max": 36
            },
            "18": {
              "min": 37,
              "max": 38
            },
            "19": {
              "min": 39,
              "max": 46
            }
          }
        },
        {
          "name": "Matryce",
          "id": 3,
          "rows": {
            "1": {
              "min": 0,
              "max": 4
            },
            "2": {
              "min": 5,
              "max": 6
            },
            "3": {
              "min": 7,
              "max": 8
            },
            "4": {
              "min": 9,
              "max": 9
            },
            "5": {
              "min": 10,
              "max": 11
            },
            "6": {
              "min": 12,
              "max": 12
            },
            "7": {
              "min": 13,
              "max": 13
            },
            "8": {
              "min": 14,
              "max": 15
            },
            "9": {
              "min": 16,
              "max": 16
            },
            "10": {
              "min": 17,
              "max": 17
            },
            "11": {
              "min": 18,
              "max": 19
            },
            "12": {
              "min": 20,
              "max": 20
            },
            "13": {
              "min": 21,
              "max": 21
            },
            "14": {
              "min": 22,
              "max": 22
            },
            "15": {
              "min": 23,
              "max": 24
            },
            "16": {
              "min": 25,
              "max": 25
            },
            "17": {
              "min": 26,
              "max": 26
            },
            "18": {
              "min": 27,
              "max": 27
            },
            "19": {
              "min": 28,
              "max": 32
            }
          }
        },
        {
          "name": "Powtarzanie liczb",
          "id": 4,
          "rows": {
            "1": {
              "min": 0,
              "max": 6
            },
            "2": {
              "min": 7,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 12
            },
            "5": {
              "min": 13,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 15
            },
            "7": {
              "min": 16,
              "max": 17
            },
            "8": {
              "min": 18,
              "max": 19
            },
            "9": {
              "min": 20,
              "max": 20
            },
            "10": {
              "min": 21,
              "max": 22
            },
            "11": {
              "min": 23,
              "max": 23
            },
            "12": {
              "min": 24,
              "max": 25
            },
            "13": {
              "min": 26,
              "max": 27
            },
            "14": {
              "min": 28,
              "max": 28
            },
            "15": {
              "min": 29,
              "max": 30
            },
            "16": {
              "min": 31,
              "max": 31
            },
            "17": {
              "min": 32,
              "max": 33
            },
            "18": {
              "min": 34,
              "max": 34
            },
            "19": {
              "min": 35,
              "max": 54
            }
          }
        },
        {
          "name": "Kodowanie",
          "id": 5,
          "rows": {
            "1": {
              "min": 0,
              "max": 11
            },
            "2": {
              "min": 12,
              "max": 14
            },
            "3": {
              "min": 15,
              "max": 18
            },
            "4": {
              "min": 19,
              "max": 22
            },
            "5": {
              "min": 23,
              "max": 25
            },
            "6": {
              "min": 26,
              "max": 29
            },
            "7": {
              "min": 30,
              "max": 33
            },
            "8": {
              "min": 34,
              "max": 36
            },
            "9": {
              "min": 37,
              "max": 40
            },
            "10": {
              "min": 41,
              "max": 43
            },
            "11": {
              "min": 44,
              "max": 47
            },
            "12": {
              "min": 48,
              "max": 51
            },
            "13": {
              "min": 52,
              "max": 54
            },
            "14": {
              "min": 55,
              "max": 58
            },
            "15": {
              "min": 59,
              "max": 62
            },
            "16": {
              "min": 63,
              "max": 65
            },
            "17": {
              "min": 66,
              "max": 69
            },
            "18": {
              "min": 70,
              "max": 73
            },
            "19": {
              "min": 74,
              "max": 117
            }
          }
        },
        {
          "name": "Słownik",
          "id": 6,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 12
            },
            "5": {
              "min": 13,
              "max": 14
            },
            "6": {
              "min": 15,
              "max": 15
            },
            "7": {
              "min": 16,
              "max": 17
            },
            "8": {
              "min": 18,
              "max": 19
            },
            "9": {
              "min": 20,
              "max": 21
            },
            "10": {
              "min": 22,
              "max": 23
            },
            "11": {
              "min": 24,
              "max": 26
            },
            "12": {
              "min": 27,
              "max": 28
            },
            "13": {
              "min": 29,
              "max": 31
            },
            "14": {
              "min": 32,
              "max": 33
            },
            "15": {
              "min": 34,
              "max": 36
            },
            "16": {
              "min": 37,
              "max": 39
            },
            "17": {
              "min": 40,
              "max": 41
            },
            "18": {
              "min": 42,
              "max": 44
            },
            "19": {
              "min": 45,
              "max": 54
            }
          }
        },
        {
          "name": "Ważenie",
          "id": 7,
          "rows": {
            "1": {
              "min": 0,
              "max": 2
            },
            "2": {
              "min": 3,
              "max": 4
            },
            "3": {
              "min": 5,
              "max": 6
            },
            "4": {
              "min": 7,
              "max": 8
            },
            "5": {
              "min": 9,
              "max": 11
            },
            "6": {
              "min": 12,
              "max": 13
            },
            "7": {
              "min": 14,
              "max": 15
            },
            "8": {
              "min": 16,
              "max": 17
            },
            "9": {
              "min": 18,
              "max": 19
            },
            "10": {
              "min": 20,
              "max": 21
            },
            "11": {
              "min": 22,
              "max": 23
            },
            "12": {
              "min": 24,
              "max": 24
            },
            "13": {
              "min": 25,
              "max": 26
            },
            "14": {
              "min": 27,
              "max": 27
            },
            "15": {
              "min": 28,
              "max": 28
            },
            "16": {
              "min": 29,
              "max": 29
            },
            "17": {
              "min": 30,
              "max": 30
            },
            "18": {
              "min": 31,
              "max": 31
            },
            "19": {
              "min": 32,
              "max": 34
            }
          }
        },
        {
          "name": "Układnie wzoru",
          "id": 8,
          "rows": {
            "1": {
              "min": 0,
              "max": 1
            },
            "2": {
              "min": 2,
              "max": 2
            },
            "3": {
              "min": 3,
              "max": 4
            },
            "4": {
              "min": 5,
              "max": 6
            },
            "5": {
              "min": 7,
              "max": 8
            },
            "6": {
              "min": 9,
              "max": 10
            },
            "7": {
              "min": 11,
              "max": 12
            },
            "8": {
              "min": 13,
              "max": 13
            },
            "9": {
              "min": 14,
              "max": 15
            },
            "10": {
              "min": 16,
              "max": 16
            },
            "11": {
              "min": 17,
              "max": 18
            },
            "12": {
              "min": 19,
              "max": 19
            },
            "13": {
              "min": 20,
              "max": 20
            },
            "14": {
              "min": 21,
              "max": 21
            },
            "15": {
              "min": 22,
              "max": 22
            },
            "16": {
              "min": 23,
              "max": 23
            },
            "17": {
              "min": 24,
              "max": 24
            },
            "18": {
              "min": null,
              "max": null
            },
            "19": {
              "min": 25,
              "max": 29
            }
          }
        },
        {
          "name": "Zapamiętywanie obrazków",
          "id": 9,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 6
            },
            "3": {
              "min": 7,
              "max": 9
            },
            "4": {
              "min": 10,
              "max": 11
            },
            "5": {
              "min": 12,
              "max": 14
            },
            "6": {
              "min": 15,
              "max": 16
            },
            "7": {
              "min": 17,
              "max": 19
            },
            "8": {
              "min": 20,
              "max": 21
            },
            "9": {
              "min": 22,
              "max": 24
            },
            "10": {
              "min": 25,
              "max": 26
            },
            "11": {
              "min": 27,
              "max": 29
            },
            "12": {
              "min": 30,
              "max": 31
            },
            "13": {
              "min": 32,
              "max": 33
            },
            "14": {
              "min": 34,
              "max": 36
            },
            "15": {
              "min": 37,
              "max": 38
            },
            "16": {
              "min": 39,
              "max": 40
            },
            "17": {
              "min": 41,
              "max": 42
            },
            "18": {
              "min": 43,
              "max": 44
            },
            "19": {
              "min": 45,
              "max": 49
            }
          }
        },
        {
          "name": "Szukanie symboli",
          "id": 10,
          "rows": {
            "1": {
              "min": 0,
              "max": 5
            },
            "2": {
              "min": 6,
              "max": 7
            },
            "3": {
              "min": 8,
              "max": 9
            },
            "4": {
              "min": 10,
              "max": 12
            },
            "5": {
              "min": 13,
              "max": 14
            },
            "6": {
              "min": 15,
              "max": 16
            },
            "7": {
              "min": 17,
              "max": 18
            },
            "8": {
              "min": 19,
              "max": 20
            },
            "9": {
              "min": 21,
              "max": 23
            },
            "10": {
              "min": 24,
              "max": 25
            },
            "11": {
              "min": 26,
              "max": 27
            },
            "12": {
              "min": 28,
              "max": 29
            },
            "13": {
              "min": 30,
              "max": 31
            },
            "14": {
              "min": 32,
              "max": 33
            },
            "15": {
              "min": 34,
              "max": 35
            },
            "16": {
              "min": 36,
              "max": 37
            },
            "17": {
              "min": 38,
              "max": 39
            },
            "18": {
              "min": 40,
              "max": 41
            },
            "19": {
              "min": 42,
              "max": 60
            }
          }
        },
        {
          "name": "Wiadomości",
          "id": 11,
          "rows": {
            "1": {
              "min": 0,
              "max": 6
            },
            "2": {
              "min": 7,
              "max": 7
            },
            "3": {
              "min": 8,
              "max": 8
            },
            "4": {
              "min": 9,
              "max": 9
            },
            "5": {
              "min": 10,
              "max": 10
            },
            "6": {
              "min": null,
              "max": null
            },
            "7": {
              "min": 11,
              "max": 11
            },
            "8": {
              "min": 12,
              "max": 12
            },
            "9": {
              "min": 13,
              "max": 13
            },
            "10": {
              "min": 14,
              "max": 15
            },
            "11": {
              "min": 16,
              "max": 16
            },
            "12": {
              "min": 17,
              "max": 17
            },
            "13": {
              "min": 18,
              "max": 18
            },
            "14": {
              "min": 19,
              "max": 19
            },
            "15": {
              "min": 20,
              "max": 20
            },
            "16": {
              "min": 21,
              "max": 21
            },
            "17": {
              "min": 22,
              "max": 22
            },
            "18": {
              "min": 23,
              "max": 23
            },
            "19": {
              "min": 24,
              "max": 31
            }
          }
        },
        {
          "name": "Tworzenie pojęć",
          "id": 12,
          "rows": {
            "1": {
              "min": 0,
              "max": 1
            },
            "2": {
              "min": 2,
              "max": 2
            },
            "3": {
              "min": 3,
              "max": 4
            },
            "4": {
              "min": 5,
              "max": 6
            },
            "5": {
              "min": 7,
              "max": 7
            },
            "6": {
              "min": 8,
              "max": 8
            },
            "7": {
              "min": 9,
              "max": 10
            },
            "8": {
              "min": 11,
              "max": 11
            },
            "9": {
              "min": 12,
              "max": 12
            },
            "10": {
              "min": 13,
              "max": 13
            },
            "11": {
              "min": 14,
              "max": 14
            },
            "12": {
              "min": 15,
              "max": 15
            },
            "13": {
              "min": 16,
              "max": 16
            },
            "14": {
              "min": 17,
              "max": 17
            },
            "15": {
              "min": 18,
              "max": 18
            },
            "16": {
              "min": 19,
              "max": 19
            },
            "17": {
              "min": 20,
              "max": 20
            },
            "18": {
              "min": 21,
              "max": 21
            },
            "19": {
              "min": 22,
              "max": 27
            }
          }
        },
        {
          "name": "Porządkowanie liter i liczb",
          "id": 13,
          "rows": {
            "1": {
              "min": 0,
              "max": 2
            },
            "2": {
              "min": 3,
              "max": 4
            },
            "3": {
              "min": 5,
              "max": 6
            },
            "4": {
              "min": 7,
              "max": 8
            },
            "5": {
              "min": 9,
              "max": 9
            },
            "6": {
              "min": 10,
              "max": 11
            },
            "7": {
              "min": 12,
              "max": 12
            },
            "8": {
              "min": 13,
              "max": 13
            },
            "9": {
              "min": 14,
              "max": 14
            },
            "10": {
              "min": 15,
              "max": 15
            },
            "11": {
              "min": 16,
              "max": 16
            },
            "12": {
              "min": 17,
              "max": 17
            },
            "13": {
              "min": 18,
              "max": 18
            },
            "14": {
              "min": 19,
              "max": 19
            },
            "15": {
              "min": 20,
              "max": 20
            },
            "16": {
              "min": null,
              "max": null
            },
            "17": {
              "min": 21,
              "max": 21
            },
            "18": {
              "min": 22,
              "max": 22
            },
            "19": {
              "min": 23,
              "max": 30
            }
          }
        },
        {
          "name": "Wykreślanie",
          "id": 14,
          "rows": {
            "1": {
              "min": 0,
              "max": 24
            },
            "2": {
              "min": 25,
              "max": 27
            },
            "3": {
              "min": 28,
              "max": 31
            },
            "4": {
              "min": 32,
              "max": 35
            },
            "5": {
              "min": 36,
              "max": 39
            },
            "6": {
              "min": 40,
              "max": 43
            },
            "7": {
              "min": 44,
              "max": 48
            },
            "8": {
              "min": 49,
              "max": 53
            },
            "9": {
              "min": 54,
              "max": 58
            },
            "10": {
              "min": 59,
              "max": 63
            },
            "11": {
              "min": 64,
              "max": 69
            },
            "12": {
              "min": 70,
              "max": 75
            },
            "13": {
              "min": 76,
              "max": 82
            },
            "14": {
              "min": 83,
              "max": 89
            },
            "15": {
              "min": 90,
              "max": 96
            },
            "16": {
              "min": 97,
              "max": 103
            },
            "17": {
              "min": 104,
              "max": 110
            },
            "18": {
              "min": 111,
              "max": 117
            },
            "19": {
              "min": 118,
              "max": 128
            }
          }
        },
        {
          "name": "Rozumienie",
          "id": 15,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 4
            },
            "3": {
              "min": 5,
              "max": 6
            },
            "4": {
              "min": 7,
              "max": 7
            },
            "5": {
              "min": 8,
              "max": 9
            },
            "6": {
              "min": 10,
              "max": 11
            },
            "7": {
              "min": 12,
              "max": 12
            },
            "8": {
              "min": 13,
              "max": 14
            },
            "9": {
              "min": 15,
              "max": 15
            },
            "10": {
              "min": 16,
              "max": 17
            },
            "11": {
              "min": 18,
              "max": 19
            },
            "12": {
              "min": 20,
              "max": 20
            },
            "13": {
              "min": 21,
              "max": 22
            },
            "14": {
              "min": 23,
              "max": 23
            },
            "15": {
              "min": 24,
              "max": 25
            },
            "16": {
              "min": 26,
              "max": 27
            },
            "17": {
              "min": 28,
              "max": 28
            },
            "18": {
              "min": 29,
              "max": 30
            },
            "19": {
              "min": 31,
              "max": 38
            }
          }
        },
        {
          "name": "Arytmetyka",
          "id": 16,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 5
            },
            "3": {
              "min": 6,
              "max": 7
            },
            "4": {
              "min": 8,
              "max": 9
            },
            "5": {
              "min": 10,
              "max": 11
            },
            "6": {
              "min": 12,
              "max": 13
            },
            "7": {
              "min": 14,
              "max": 15
            },
            "8": {
              "min": 16,
              "max": 16
            },
            "9": {
              "min": 17,
              "max": 18
            },
            "10": {
              "min": 19,
              "max": 19
            },
            "11": {
              "min": 20,
              "max": 20
            },
            "12": {
              "min": 21,
              "max": 22
            },
            "13": {
              "min": 23,
              "max": 23
            },
            "14": {
              "min": 24,
              "max": 24
            },
            "15": {
              "min": 25,
              "max": 25
            },
            "16": {
              "min": 26,
              "max": 26
            },
            "17": {
              "min": 27,
              "max": 28
            },
            "18": {
              "min": 29,
              "max": 29
            },
            "19": {
              "min": 30,
              "max": 34
            }
          }
        }
      ],    

    104107: [
        {
          "name": "Wzory z klocków",
          "id": 1,
          "rows": {
            "1": {
              "min": 0,
              "max": 6
            },
            "2": {
              "min": 7,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 13
            },
            "5": {
              "min": 14,
              "max": 16
            },
            "6": {
              "min": 17,
              "max": 18
            },
            "7": {
              "min": 19,
              "max": 21
            },
            "8": {
              "min": 22,
              "max": 24
            },
            "9": {
              "min": 25,
              "max": 27
            },
            "10": {
              "min": 28,
              "max": 30
            },
            "11": {
              "min": 31,
              "max": 33
            },
            "12": {
              "min": 34,
              "max": 36
            },
            "13": {
              "min": 37,
              "max": 39
            },
            "14": {
              "min": 40,
              "max": 42
            },
            "15": {
              "min": 43,
              "max": 45
            },
            "16": {
              "min": 46,
              "max": 48
            },
            "17": {
              "min": 49,
              "max": 51
            },
            "18": {
              "min": 52,
              "max": 54
            },
            "19": {
              "min": 55,
              "max": 58
            }
          }
        },
        {
          "name": "Podobieństwa",
          "id": 2,
          "rows": {
            "1": {
              "min": 0,
              "max": 5
            },
            "2": {
              "min": 6,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 14
            },
            "5": {
              "min": 15,
              "max": 16
            },
            "6": {
              "min": 17,
              "max": 18
            },
            "7": {
              "min": 19,
              "max": 21
            },
            "8": {
              "min": 22,
              "max": 23
            },
            "9": {
              "min": 24,
              "max": 24
            },
            "10": {
              "min": 25,
              "max": 26
            },
            "11": {
              "min": 27,
              "max": 28
            },
            "12": {
              "min": 29,
              "max": 29
            },
            "13": {
              "min": 30,
              "max": 31
            },
            "14": {
              "min": 32,
              "max": 33
            },
            "15": {
              "min": 34,
              "max": 34
            },
            "16": {
              "min": 35,
              "max": 35
            },
            "17": {
              "min": 36,
              "max": 37
            },
            "18": {
              "min": 38,
              "max": 38
            },
            "19": {
              "min": 39,
              "max": 46
            }
          }
        },
        {
          "name": "Matryce",
          "id": 3,
          "rows": {
            "1": {
              "min": 0,
              "max": 5
            },
            "2": {
              "min": 6,
              "max": 6
            },
            "3": {
              "min": 7,
              "max": 8
            },
            "4": {
              "min": 9,
              "max": 9
            },
            "5": {
              "min": 10,
              "max": 11
            },
            "6": {
              "min": 12,
              "max": 12
            },
            "7": {
              "min": 13,
              "max": 14
            },
            "8": {
              "min": 15,
              "max": 15
            },
            "9": {
              "min": 16,
              "max": 16
            },
            "10": {
              "min": 17,
              "max": 18
            },
            "11": {
              "min": 19,
              "max": 19
            },
            "12": {
              "min": 20,
              "max": 20
            },
            "13": {
              "min": 21,
              "max": 22
            },
            "14": {
              "min": 23,
              "max": 23
            },
            "15": {
              "min": 24,
              "max": 24
            },
            "16": {
              "min": 25,
              "max": 25
            },
            "17": {
              "min": 26,
              "max": 27
            },
            "18": {
              "min": 28,
              "max": 28
            },
            "19": {
              "min": 29,
              "max": 32
            }
          }
        },
        {
          "name": "Powtarzanie liczb",
          "id": 4,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 12
            },
            "5": {
              "min": 13,
              "max": 14
            },
            "6": {
              "min": 15,
              "max": 16
            },
            "7": {
              "min": 17,
              "max": 17
            },
            "8": {
              "min": 18,
              "max": 19
            },
            "9": {
              "min": 20,
              "max": 21
            },
            "10": {
              "min": 22,
              "max": 22
            },
            "11": {
              "min": 23,
              "max": 24
            },
            "12": {
              "min": 25,
              "max": 26
            },
            "13": {
              "min": 27,
              "max": 27
            },
            "14": {
              "min": 28,
              "max": 29
            },
            "15": {
              "min": 30,
              "max": 30
            },
            "16": {
              "min": 31,
              "max": 32
            },
            "17": {
              "min": 33,
              "max": 33
            },
            "18": {
              "min": 34,
              "max": 35
            },
            "19": {
              "min": 36,
              "max": 54
            }
          }
        },
        {
          "name": "Kodowanie",
          "id": 5,
          "rows": {
            "1": {
              "min": 0,
              "max": 11
            },
            "2": {
              "min": 12,
              "max": 15
            },
            "3": {
              "min": 16,
              "max": 19
            },
            "4": {
              "min": 20,
              "max": 23
            },
            "5": {
              "min": 24,
              "max": 26
            },
            "6": {
              "min": 27,
              "max": 30
            },
            "7": {
              "min": 31,
              "max": 34
            },
            "8": {
              "min": 35,
              "max": 38
            },
            "9": {
              "min": 39,
              "max": 41
            },
            "10": {
              "min": 42,
              "max": 45
            },
            "11": {
              "min": 46,
              "max": 49
            },
            "12": {
              "min": 50,
              "max": 53
            },
            "13": {
              "min": 54,
              "max": 56
            },
            "14": {
              "min": 57,
              "max": 60
            },
            "15": {
              "min": 61,
              "max": 64
            },
            "16": {
              "min": 65,
              "max": 68
            },
            "17": {
              "min": 69,
              "max": 71
            },
            "18": {
              "min": 72,
              "max": 75
            },
            "19": {
              "min": 76,
              "max": 117
            }
          }
        },
        {
          "name": "Słownik",
          "id": 6,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 12
            },
            "5": {
              "min": 13,
              "max": 14
            },
            "6": {
              "min": 15,
              "max": 16
            },
            "7": {
              "min": 17,
              "max": 18
            },
            "8": {
              "min": 19,
              "max": 20
            },
            "9": {
              "min": 21,
              "max": 22
            },
            "10": {
              "min": 23,
              "max": 24
            },
            "11": {
              "min": 25,
              "max": 27
            },
            "12": {
              "min": 28,
              "max": 29
            },
            "13": {
              "min": 30,
              "max": 32
            },
            "14": {
              "min": 33,
              "max": 35
            },
            "15": {
              "min": 36,
              "max": 37
            },
            "16": {
              "min": 38,
              "max": 40
            },
            "17": {
              "min": 41,
              "max": 43
            },
            "18": {
              "min": 44,
              "max": 46
            },
            "19": {
              "min": 47,
              "max": 54
            }
          }
        },
        {
          "name": "Ważenie",
          "id": 7,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 5
            },
            "3": {
              "min": 6,
              "max": 7
            },
            "4": {
              "min": 8,
              "max": 9
            },
            "5": {
              "min": 10,
              "max": 11
            },
            "6": {
              "min": 12,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 16
            },
            "8": {
              "min": 17,
              "max": 18
            },
            "9": {
              "min": 19,
              "max": 20
            },
            "10": {
              "min": 21,
              "max": 22
            },
            "11": {
              "min": 23,
              "max": 23
            },
            "12": {
              "min": 24,
              "max": 25
            },
            "13": {
              "min": 26,
              "max": 26
            },
            "14": {
              "min": 27,
              "max": 27
            },
            "15": {
              "min": 28,
              "max": 28
            },
            "16": {
              "min": 29,
              "max": 29
            },
            "17": {
              "min": 30,
              "max": 30
            },
            "18": {
              "min": 31,
              "max": 31
            },
            "19": {
              "min": 32,
              "max": 34
            }
          }
        },
        {
          "name": "Układnie wzoru",
          "id": 8,
          "rows": {
            "1": {
              "min": 0,
              "max": 1
            },
            "2": {
              "min": 2,
              "max": 3
            },
            "3": {
              "min": 4,
              "max": 4
            },
            "4": {
              "min": 5,
              "max": 6
            },
            "5": {
              "min": 7,
              "max": 8
            },
            "6": {
              "min": 9,
              "max": 10
            },
            "7": {
              "min": 11,
              "max": 12
            },
            "8": {
              "min": 13,
              "max": 14
            },
            "9": {
              "min": 15,
              "max": 15
            },
            "10": {
              "min": 16,
              "max": 17
            },
            "11": {
              "min": 18,
              "max": 18
            },
            "12": {
              "min": 19,
              "max": 19
            },
            "13": {
              "min": 20,
              "max": 20
            },
            "14": {
              "min": 21,
              "max": 21
            },
            "15": {
              "min": 22,
              "max": 22
            },
            "16": {
              "min": 23,
              "max": 23
            },
            "17": {
              "min": 24,
              "max": 24
            },
            "18": {
              "min": 25,
              "max": 25
            },
            "19": {
              "min": 26,
              "max": 29
            }
          }
        },
        {
          "name": "Zapamiętywanie obrazków",
          "id": 9,
          "rows": {
            "1": {
              "min": 0,
              "max": 4
            },
            "2": {
              "min": 5,
              "max": 6
            },
            "3": {
              "min": 7,
              "max": 9
            },
            "4": {
              "min": 10,
              "max": 12
            },
            "5": {
              "min": 13,
              "max": 14
            },
            "6": {
              "min": 15,
              "max": 17
            },
            "7": {
              "min": 18,
              "max": 20
            },
            "8": {
              "min": 21,
              "max": 22
            },
            "9": {
              "min": 23,
              "max": 24
            },
            "10": {
              "min": 25,
              "max": 27
            },
            "11": {
              "min": 28,
              "max": 29
            },
            "12": {
              "min": 30,
              "max": 32
            },
            "13": {
              "min": 33,
              "max": 34
            },
            "14": {
              "min": 35,
              "max": 36
            },
            "15": {
              "min": 37,
              "max": 39
            },
            "16": {
              "min": 40,
              "max": 41
            },
            "17": {
              "min": 42,
              "max": 43
            },
            "18": {
              "min": 44,
              "max": 45
            },
            "19": {
              "min": 46,
              "max": 49
            }
          }
        },
        {
          "name": "Szukanie symboli",
          "id": 10,
          "rows": {
            "1": {
              "min": 0,
              "max": 5
            },
            "2": {
              "min": 6,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 12
            },
            "5": {
              "min": 13,
              "max": 15
            },
            "6": {
              "min": 16,
              "max": 17
            },
            "7": {
              "min": 18,
              "max": 19
            },
            "8": {
              "min": 20,
              "max": 21
            },
            "9": {
              "min": 22,
              "max": 23
            },
            "10": {
              "min": 24,
              "max": 25
            },
            "11": {
              "min": 26,
              "max": 28
            },
            "12": {
              "min": 29,
              "max": 30
            },
            "13": {
              "min": 31,
              "max": 32
            },
            "14": {
              "min": 33,
              "max": 34
            },
            "15": {
              "min": 35,
              "max": 36
            },
            "16": {
              "min": 37,
              "max": 38
            },
            "17": {
              "min": 39,
              "max": 40
            },
            "18": {
              "min": 41,
              "max": 42
            },
            "19": {
              "min": 43,
              "max": 60
            }
          }
        },
        {
          "name": "Wiadomości",
          "id": 11,
          "rows": {
            "1": {
              "min": 0,
              "max": 6
            },
            "2": {
              "min": 7,
              "max": 7
            },
            "3": {
              "min": 8,
              "max": 8
            },
            "4": {
              "min": 9,
              "max": 9
            },
            "5": {
              "min": 10,
              "max": 10
            },
            "6": {
              "min": 11,
              "max": 11
            },
            "7": {
              "min": 12,
              "max": 12
            },
            "8": {
              "min": 13,
              "max": 13
            },
            "9": {
              "min": 14,
              "max": 14
            },
            "10": {
              "min": 15,
              "max": 15
            },
            "11": {
              "min": 16,
              "max": 16
            },
            "12": {
              "min": 17,
              "max": 17
            },
            "13": {
              "min": 18,
              "max": 18
            },
            "14": {
              "min": 19,
              "max": 19
            },
            "15": {
              "min": 20,
              "max": 20
            },
            "16": {
              "min": 21,
              "max": 22
            },
            "17": {
              "min": 23,
              "max": 23
            },
            "18": {
              "min": 24,
              "max": 24
            },
            "19": {
              "min": 25,
              "max": 31
            }
          }
        },
        {
          "name": "Tworzenie pojęć",
          "id": 12,
          "rows": {
            "1": {
              "min": 0,
              "max": 1
            },
            "2": {
              "min": 2,
              "max": 3
            },
            "3": {
              "min": 4,
              "max": 5
            },
            "4": {
              "min": 6,
              "max": 6
            },
            "5": {
              "min": 7,
              "max": 7
            },
            "6": {
              "min": 8,
              "max": 9
            },
            "7": {
              "min": 10,
              "max": 10
            },
            "8": {
              "min": 11,
              "max": 11
            },
            "9": {
              "min": 12,
              "max": 12
            },
            "10": {
              "min": 13,
              "max": 13
            },
            "11": {
              "min": 14,
              "max": 15
            },
            "12": {
              "min": 16,
              "max": 16
            },
            "13": {
              "min": 17,
              "max": 17
            },
            "14": {
              "min": 18,
              "max": 18
            },
            "15": {
              "min": 19,
              "max": 19
            },
            "16": {
              "min": 20,
              "max": 20
            },
            "17": {
              "min": 21,
              "max": 21
            },
            "18": {
              "min": 22,
              "max": 22
            },
            "19": {
              "min": 23,
              "max": 27
            }
          }
        },
        {
          "name": "Porządkowanie liter i liczb",
          "id": 13,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 5
            },
            "3": {
              "min": 6,
              "max": 7
            },
            "4": {
              "min": 8,
              "max": 8
            },
            "5": {
              "min": 9,
              "max": 10
            },
            "6": {
              "min": 11,
              "max": 11
            },
            "7": {
              "min": 12,
              "max": 13
            },
            "8": {
              "min": 14,
              "max": 14
            },
            "9": {
              "min": 15,
              "max": 15
            },
            "10": {
              "min": 16,
              "max": 16
            },
            "11": {
              "min": 17,
              "max": 17
            },
            "12": {
              "min": 18,
              "max": 18
            },
            "13": {
              "min": null,
              "max": null
            },
            "14": {
              "min": 19,
              "max": 19
            },
            "15": {
              "min": 20,
              "max": 20
            },
            "16": {
              "min": null,
              "max": null
            },
            "17": {
              "min": 21,
              "max": 21
            },
            "18": {
              "min": 22,
              "max": 22
            },
            "19": {
              "min": 23,
              "max": 30
            }
          }
        },
        {
          "name": "Wykreślanie",
          "id": 14,
          "rows": {
            "1": {
              "min": 0,
              "max": 24
            },
            "2": {
              "min": 25,
              "max": 28
            },
            "3": {
              "min": 29,
              "max": 32
            },
            "4": {
              "min": 33,
              "max": 36
            },
            "5": {
              "min": 37,
              "max": 40
            },
            "6": {
              "min": 41,
              "max": 44
            },
            "7": {
              "min": 45,
              "max": 49
            },
            "8": {
              "min": 50,
              "max": 54
            },
            "9": {
              "min": 55,
              "max": 59
            },
            "10": {
              "min": 60,
              "max": 65
            },
            "11": {
              "min": 66,
              "max": 71
            },
            "12": {
              "min": 72,
              "max": 77
            },
            "13": {
              "min": 78,
              "max": 84
            },
            "14": {
              "min": 85,
              "max": 90
            },
            "15": {
              "min": 91,
              "max": 97
            },
            "16": {
              "min": 98,
              "max": 104
            },
            "17": {
              "min": 105,
              "max": 111
            },
            "18": {
              "min": 112,
              "max": 118
            },
            "19": {
              "min": 119,
              "max": 128
            }
          }
        },
        {
          "name": "Rozumienie",
          "id": 15,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 5
            },
            "3": {
              "min": 6,
              "max": 6
            },
            "4": {
              "min": 7,
              "max": 8
            },
            "5": {
              "min": 9,
              "max": 9
            },
            "6": {
              "min": 10,
              "max": 11
            },
            "7": {
              "min": 12,
              "max": 13
            },
            "8": {
              "min": 14,
              "max": 14
            },
            "9": {
              "min": 15,
              "max": 16
            },
            "10": {
              "min": 17,
              "max": 18
            },
            "11": {
              "min": 19,
              "max": 19
            },
            "12": {
              "min": 20,
              "max": 21
            },
            "13": {
              "min": 22,
              "max": 23
            },
            "14": {
              "min": 24,
              "max": 24
            },
            "15": {
              "min": 25,
              "max": 26
            },
            "16": {
              "min": 27,
              "max": 28
            },
            "17": {
              "min": 29,
              "max": 29
            },
            "18": {
              "min": 30,
              "max": 31
            },
            "19": {
              "min": 32,
              "max": 38
            }
          }
        },
        {
          "name": "Arytmetyka",
          "id": 16,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 6
            },
            "3": {
              "min": 7,
              "max": 8
            },
            "4": {
              "min": 9,
              "max": 10
            },
            "5": {
              "min": 11,
              "max": 12
            },
            "6": {
              "min": 13,
              "max": 13
            },
            "7": {
              "min": 14,
              "max": 15
            },
            "8": {
              "min": 16,
              "max": 17
            },
            "9": {
              "min": 18,
              "max": 18
            },
            "10": {
              "min": 19,
              "max": 20
            },
            "11": {
              "min": 21,
              "max": 21
            },
            "12": {
              "min": 22,
              "max": 22
            },
            "13": {
              "min": 23,
              "max": 24
            },
            "14": {
              "min": 25,
              "max": 25
            },
            "15": {
              "min": 26,
              "max": 26
            },
            "16": {
              "min": 27,
              "max": 27
            },
            "17": {
              "min": 28,
              "max": 28
            },
            "18": {
              "min": 29,
              "max": 29
            },
            "19": {
              "min": 30,
              "max": 34
            }
          }
        }
      ]  ,
    
    1081011: [
        {
          "name": "Wzory z klocków",
          "id": 1,
          "rows": {
            "1": {
              "min": 0,
              "max": 6
            },
            "2": {
              "min": 7,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 14
            },
            "5": {
              "min": 15,
              "max": 16
            },
            "6": {
              "min": 17,
              "max": 19
            },
            "7": {
              "min": 20,
              "max": 22
            },
            "8": {
              "min": 23,
              "max": 25
            },
            "9": {
              "min": 26,
              "max": 28
            },
            "10": {
              "min": 29,
              "max": 31
            },
            "11": {
              "min": 32,
              "max": 34
            },
            "12": {
              "min": 35,
              "max": 37
            },
            "13": {
              "min": 38,
              "max": 40
            },
            "14": {
              "min": 41,
              "max": 43
            },
            "15": {
              "min": 44,
              "max": 46
            },
            "16": {
              "min": 47,
              "max": 49
            },
            "17": {
              "min": 50,
              "max": 52
            },
            "18": {
              "min": 53,
              "max": 54
            },
            "19": {
              "min": 55,
              "max": 58
            }
          }
        },
        {
          "name": "Podobieństwa",
          "id": 2,
          "rows": {
            "1": {
              "min": 0,
              "max": 6
            },
            "2": {
              "min": 7,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 12
            },
            "4": {
              "min": 13,
              "max": 15
            },
            "5": {
              "min": 16,
              "max": 17
            },
            "6": {
              "min": 18,
              "max": 19
            },
            "7": {
              "min": 20,
              "max": 21
            },
            "8": {
              "min": 22,
              "max": 23
            },
            "9": {
              "min": 24,
              "max": 25
            },
            "10": {
              "min": 26,
              "max": 27
            },
            "11": {
              "min": 28,
              "max": 28
            },
            "12": {
              "min": 29,
              "max": 30
            },
            "13": {
              "min": 31,
              "max": 32
            },
            "14": {
              "min": 33,
              "max": 33
            },
            "15": {
              "min": 34,
              "max": 34
            },
            "16": {
              "min": 35,
              "max": 36
            },
            "17": {
              "min": 37,
              "max": 37
            },
            "18": {
              "min": 38,
              "max": 39
            },
            "19": {
              "min": 40,
              "max": 46
            }
          }
        },
        {
          "name": "Matryce",
          "id": 3,
          "rows": {
            "1": {
              "min": 0,
              "max": 5
            },
            "2": {
              "min": 6,
              "max": 7
            },
            "3": {
              "min": 8,
              "max": 8
            },
            "4": {
              "min": 9,
              "max": 10
            },
            "5": {
              "min": 11,
              "max": 11
            },
            "6": {
              "min": 12,
              "max": 13
            },
            "7": {
              "min": 14,
              "max": 14
            },
            "8": {
              "min": 15,
              "max": 16
            },
            "9": {
              "min": 17,
              "max": 17
            },
            "10": {
              "min": 18,
              "max": 18
            },
            "11": {
              "min": 19,
              "max": 19
            },
            "12": {
              "min": 20,
              "max": 21
            },
            "13": {
              "min": 22,
              "max": 22
            },
            "14": {
              "min": 23,
              "max": 23
            },
            "15": {
              "min": 24,
              "max": 24
            },
            "16": {
              "min": 25,
              "max": 26
            },
            "17": {
              "min": 27,
              "max": 27
            },
            "18": {
              "min": 28,
              "max": 28
            },
            "19": {
              "min": 29,
              "max": 32
            }
          }
        },
        {
          "name": "Powtarzanie liczb",
          "id": 4,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 13
            },
            "5": {
              "min": 14,
              "max": 14
            },
            "6": {
              "min": 15,
              "max": 16
            },
            "7": {
              "min": 17,
              "max": 18
            },
            "8": {
              "min": 19,
              "max": 20
            },
            "9": {
              "min": 21,
              "max": 21
            },
            "10": {
              "min": 22,
              "max": 23
            },
            "11": {
              "min": 24,
              "max": 24
            },
            "12": {
              "min": 25,
              "max": 26
            },
            "13": {
              "min": 27,
              "max": 28
            },
            "14": {
              "min": 29,
              "max": 29
            },
            "15": {
              "min": 30,
              "max": 31
            },
            "16": {
              "min": 32,
              "max": 32
            },
            "17": {
              "min": 33,
              "max": 34
            },
            "18": {
              "min": 35,
              "max": 35
            },
            "19": {
              "min": 36,
              "max": 54
            }
          }
        },
        {
          "name": "Kodowanie",
          "id": 5,
          "rows": {
            "1": {
              "min": 0,
              "max": 12
            },
            "2": {
              "min": 13,
              "max": 16
            },
            "3": {
              "min": 17,
              "max": 20
            },
            "4": {
              "min": 21,
              "max": 24
            },
            "5": {
              "min": 25,
              "max": 28
            },
            "6": {
              "min": 29,
              "max": 31
            },
            "7": {
              "min": 32,
              "max": 35
            },
            "8": {
              "min": 36,
              "max": 39
            },
            "9": {
              "min": 40,
              "max": 43
            },
            "10": {
              "min": 44,
              "max": 47
            },
            "11": {
              "min": 48,
              "max": 51
            },
            "12": {
              "min": 52,
              "max": 55
            },
            "13": {
              "min": 56,
              "max": 58
            },
            "14": {
              "min": 59,
              "max": 62
            },
            "15": {
              "min": 63,
              "max": 66
            },
            "16": {
              "min": 67,
              "max": 70
            },
            "17": {
              "min": 71,
              "max": 74
            },
            "18": {
              "min": 75,
              "max": 78
            },
            "19": {
              "min": 79,
              "max": 117
            }
          }
        },
        {
          "name": "Słownik",
          "id": 6,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 13
            },
            "5": {
              "min": 14,
              "max": 15
            },
            "6": {
              "min": 16,
              "max": 17
            },
            "7": {
              "min": 18,
              "max": 19
            },
            "8": {
              "min": 20,
              "max": 21
            },
            "9": {
              "min": 22,
              "max": 23
            },
            "10": {
              "min": 24,
              "max": 25
            },
            "11": {
              "min": 26,
              "max": 28
            },
            "12": {
              "min": 29,
              "max": 30
            },
            "13": {
              "min": 31,
              "max": 33
            },
            "14": {
              "min": 34,
              "max": 36
            },
            "15": {
              "min": 37,
              "max": 39
            },
            "16": {
              "min": 40,
              "max": 42
            },
            "17": {
              "min": 43,
              "max": 44
            },
            "18": {
              "min": 45,
              "max": 47
            },
            "19": {
              "min": 48,
              "max": 54
            }
          }
        },
        {
          "name": "Ważenie",
          "id": 7,
          "rows": {
            "1": {
              "min": 0,
              "max": 4
            },
            "2": {
              "min": 5,
              "max": 5
            },
            "3": {
              "min": 6,
              "max": 7
            },
            "4": {
              "min": 8,
              "max": 10
            },
            "5": {
              "min": 11,
              "max": 12
            },
            "6": {
              "min": 13,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 17
            },
            "8": {
              "min": 18,
              "max": 19
            },
            "9": {
              "min": 20,
              "max": 20
            },
            "10": {
              "min": 21,
              "max": 22
            },
            "11": {
              "min": 23,
              "max": 24
            },
            "12": {
              "min": 25,
              "max": 25
            },
            "13": {
              "min": 26,
              "max": 26
            },
            "14": {
              "min": 27,
              "max": 27
            },
            "15": {
              "min": 28,
              "max": 28
            },
            "16": {
              "min": 29,
              "max": 29
            },
            "17": {
              "min": 30,
              "max": 30
            },
            "18": {
              "min": 31,
              "max": 31
            },
            "19": {
              "min": 32,
              "max": 34
            }
          }
        },
        {
          "name": "Układnie wzoru",
          "id": 8,
          "rows": {
            "1": {
              "min": 0,
              "max": 2
            },
            "2": {
              "min": 3,
              "max": 3
            },
            "3": {
              "min": 4,
              "max": 5
            },
            "4": {
              "min": 6,
              "max": 7
            },
            "5": {
              "min": 8,
              "max": 9
            },
            "6": {
              "min": 10,
              "max": 11
            },
            "7": {
              "min": 12,
              "max": 13
            },
            "8": {
              "min": 14,
              "max": 14
            },
            "9": {
              "min": 15,
              "max": 16
            },
            "10": {
              "min": 17,
              "max": 17
            },
            "11": {
              "min": 18,
              "max": 18
            },
            "12": {
              "min": 19,
              "max": 20
            },
            "13": {
              "min": 21,
              "max": 21
            },
            "14": {
              "min": 22,
              "max": 22
            },
            "15": {
              "min": 23,
              "max": 23
            },
            "16": {
              "min": null,
              "max": null
            },
            "17": {
              "min": 24,
              "max": 24
            },
            "18": {
              "min": 25,
              "max": 25
            },
            "19": {
              "min": 26,
              "max": 29
            }
          }
        },
        {
          "name": "Zapamiętywanie obrazków",
          "id": 9,
          "rows": {
            "1": {
              "min": 0,
              "max": 4
            },
            "2": {
              "min": 5,
              "max": 7
            },
            "3": {
              "min": 8,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 12
            },
            "5": {
              "min": 13,
              "max": 15
            },
            "6": {
              "min": 16,
              "max": 18
            },
            "7": {
              "min": 19,
              "max": 20
            },
            "8": {
              "min": 21,
              "max": 23
            },
            "9": {
              "min": 24,
              "max": 25
            },
            "10": {
              "min": 26,
              "max": 28
            },
            "11": {
              "min": 29,
              "max": 30
            },
            "12": {
              "min": 31,
              "max": 32
            },
            "13": {
              "min": 33,
              "max": 35
            },
            "14": {
              "min": 36,
              "max": 37
            },
            "15": {
              "min": 38,
              "max": 39
            },
            "16": {
              "min": 40,
              "max": 42
            },
            "17": {
              "min": 43,
              "max": 44
            },
            "18": {
              "min": 45,
              "max": 46
            },
            "19": {
              "min": 47,
              "max": 49
            }
          }
        },
        {
          "name": "Szukanie symboli",
          "id": 10,
          "rows": {
            "1": {
              "min": 0,
              "max": 5
            },
            "2": {
              "min": 6,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 13
            },
            "5": {
              "min": 14,
              "max": 15
            },
            "6": {
              "min": 16,
              "max": 17
            },
            "7": {
              "min": 18,
              "max": 20
            },
            "8": {
              "min": 21,
              "max": 22
            },
            "9": {
              "min": 23,
              "max": 24
            },
            "10": {
              "min": 25,
              "max": 26
            },
            "11": {
              "min": 27,
              "max": 28
            },
            "12": {
              "min": 29,
              "max": 31
            },
            "13": {
              "min": 32,
              "max": 33
            },
            "14": {
              "min": 34,
              "max": 35
            },
            "15": {
              "min": 36,
              "max": 37
            },
            "16": {
              "min": 38,
              "max": 39
            },
            "17": {
              "min": 40,
              "max": 41
            },
            "18": {
              "min": 42,
              "max": 43
            },
            "19": {
              "min": 44,
              "max": 60
            }
          }
        },
        {
          "name": "Wiadomości",
          "id": 11,
          "rows": {
            "1": {
              "min": 0,
              "max": 6
            },
            "2": {
              "min": 7,
              "max": 7
            },
            "3": {
              "min": 8,
              "max": 8
            },
            "4": {
              "min": 9,
              "max": 9
            },
            "5": {
              "min": 10,
              "max": 10
            },
            "6": {
              "min": 11,
              "max": 11
            },
            "7": {
              "min": 12,
              "max": 12
            },
            "8": {
              "min": 13,
              "max": 13
            },
            "9": {
              "min": 14,
              "max": 14
            },
            "10": {
              "min": 15,
              "max": 15
            },
            "11": {
              "min": 16,
              "max": 17
            },
            "12": {
              "min": 18,
              "max": 18
            },
            "13": {
              "min": 19,
              "max": 19
            },
            "14": {
              "min": 20,
              "max": 20
            },
            "15": {
              "min": 21,
              "max": 21
            },
            "16": {
              "min": 22,
              "max": 22
            },
            "17": {
              "min": 23,
              "max": 23
            },
            "18": {
              "min": 24,
              "max": 25
            },
            "19": {
              "min": 26,
              "max": 31
            }
          }
        },
        {
          "name": "Tworzenie pojęć",
          "id": 12,
          "rows": {
            "1": {
              "min": 0,
              "max": 2
            },
            "2": {
              "min": 3,
              "max": 4
            },
            "3": {
              "min": 5,
              "max": 5
            },
            "4": {
              "min": 6,
              "max": 6
            },
            "5": {
              "min": 7,
              "max": 8
            },
            "6": {
              "min": 9,
              "max": 9
            },
            "7": {
              "min": 10,
              "max": 10
            },
            "8": {
              "min": 11,
              "max": 12
            },
            "9": {
              "min": 13,
              "max": 13
            },
            "10": {
              "min": 14,
              "max": 14
            },
            "11": {
              "min": 15,
              "max": 15
            },
            "12": {
              "min": 16,
              "max": 16
            },
            "13": {
              "min": 17,
              "max": 17
            },
            "14": {
              "min": 18,
              "max": 18
            },
            "15": {
              "min": 19,
              "max": 19
            },
            "16": {
              "min": 20,
              "max": 20
            },
            "17": {
              "min": 21,
              "max": 21
            },
            "18": {
              "min": 22,
              "max": 22
            },
            "19": {
              "min": 23,
              "max": 27
            }
          }
        },
        {
          "name": "Porządkowanie liter i liczb",
          "id": 13,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 5
            },
            "3": {
              "min": 6,
              "max": 7
            },
            "4": {
              "min": 8,
              "max": 9
            },
            "5": {
              "min": 10,
              "max": 10
            },
            "6": {
              "min": 11,
              "max": 12
            },
            "7": {
              "min": 13,
              "max": 13
            },
            "8": {
              "min": 14,
              "max": 14
            },
            "9": {
              "min": 15,
              "max": 15
            },
            "10": {
              "min": 16,
              "max": 16
            },
            "11": {
              "min": 17,
              "max": 17
            },
            "12": {
              "min": 18,
              "max": 18
            },
            "13": {
              "min": 19,
              "max": 19
            },
            "14": {
              "min": null,
              "max": null
            },
            "15": {
              "min": 20,
              "max": 20
            },
            "16": {
              "min": 21,
              "max": 21
            },
            "17": {
              "min": null,
              "max": null
            },
            "18": {
              "min": 22,
              "max": 22
            },
            "19": {
              "min": 23,
              "max": 30
            }
          }
        },
        {
          "name": "Wykreślanie",
          "id": 14,
          "rows": {
            "1": {
              "min": 0,
              "max": 25
            },
            "2": {
              "min": 26,
              "max": 28
            },
            "3": {
              "min": 29,
              "max": 32
            },
            "4": {
              "min": 33,
              "max": 37
            },
            "5": {
              "min": 38,
              "max": 41
            },
            "6": {
              "min": 42,
              "max": 46
            },
            "7": {
              "min": 47,
              "max": 51
            },
            "8": {
              "min": 52,
              "max": 56
            },
            "9": {
              "min": 57,
              "max": 61
            },
            "10": {
              "min": 62,
              "max": 67
            },
            "11": {
              "min": 68,
              "max": 73
            },
            "12": {
              "min": 74,
              "max": 79
            },
            "13": {
              "min": 80,
              "max": 85
            },
            "14": {
              "min": 86,
              "max": 92
            },
            "15": {
              "min": 93,
              "max": 99
            },
            "16": {
              "min": 100,
              "max": 106
            },
            "17": {
              "min": 107,
              "max": 112
            },
            "18": {
              "min": 113,
              "max": 119
            },
            "19": {
              "min": 120,
              "max": 128
            }
          }
        },
        {
          "name": "Rozumienie",
          "id": 15,
          "rows": {
            "1": {
              "min": 0,
              "max": 4
            },
            "2": {
              "min": 5,
              "max": 5
            },
            "3": {
              "min": 6,
              "max": 7
            },
            "4": {
              "min": 8,
              "max": 8
            },
            "5": {
              "min": 9,
              "max": 10
            },
            "6": {
              "min": 11,
              "max": 12
            },
            "7": {
              "min": 13,
              "max": 13
            },
            "8": {
              "min": 14,
              "max": 15
            },
            "9": {
              "min": 16,
              "max": 16
            },
            "10": {
              "min": 17,
              "max": 18
            },
            "11": {
              "min": 19,
              "max": 20
            },
            "12": {
              "min": 21,
              "max": 21
            },
            "13": {
              "min": 22,
              "max": 23
            },
            "14": {
              "min": 24,
              "max": 25
            },
            "15": {
              "min": 26,
              "max": 27
            },
            "16": {
              "min": 28,
              "max": 28
            },
            "17": {
              "min": 29,
              "max": 30
            },
            "18": {
              "min": 31,
              "max": 32
            },
            "19": {
              "min": 33,
              "max": 38
            }
          }
        },
        {
          "name": "Arytmetyka",
          "id": 16,
          "rows": {
            "1": {
              "min": 0,
              "max": 4
            },
            "2": {
              "min": 5,
              "max": 6
            },
            "3": {
              "min": 7,
              "max": 8
            },
            "4": {
              "min": 9,
              "max": 10
            },
            "5": {
              "min": 11,
              "max": 12
            },
            "6": {
              "min": 13,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 16
            },
            "8": {
              "min": 17,
              "max": 17
            },
            "9": {
              "min": 18,
              "max": 19
            },
            "10": {
              "min": 20,
              "max": 20
            },
            "11": {
              "min": 21,
              "max": 22
            },
            "12": {
              "min": 23,
              "max": 23
            },
            "13": {
              "min": 24,
              "max": 24
            },
            "14": {
              "min": 25,
              "max": 25
            },
            "15": {
              "min": 26,
              "max": 27
            },
            "16": {
              "min": 28,
              "max": 28
            },
            "17": {
              "min": 29,
              "max": 29
            },
            "18": {
              "min": 30,
              "max": 30
            },
            "19": {
              "min": 31,
              "max": 34
            }
          }
        }
      ],  

    110113: [
        {
          "name": "Wzory z klocków",
          "id": 1,
          "rows": {
            "1": {
              "min": 0,
              "max": 6
            },
            "2": {
              "min": 7,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 14
            },
            "5": {
              "min": 15,
              "max": 17
            },
            "6": {
              "min": 18,
              "max": 20
            },
            "7": {
              "min": 21,
              "max": 23
            },
            "8": {
              "min": 24,
              "max": 25
            },
            "9": {
              "min": 26,
              "max": 28
            },
            "10": {
              "min": 29,
              "max": 31
            },
            "11": {
              "min": 32,
              "max": 34
            },
            "12": {
              "min": 35,
              "max": 37
            },
            "13": {
              "min": 38,
              "max": 40
            },
            "14": {
              "min": 41,
              "max": 43
            },
            "15": {
              "min": 44,
              "max": 47
            },
            "16": {
              "min": 48,
              "max": 49
            },
            "17": {
              "min": 50,
              "max": 52
            },
            "18": {
              "min": 53,
              "max": 55
            },
            "19": {
              "min": 56,
              "max": 58
            }
          }
        },
        {
          "name": "Podobieństwa",
          "id": 2,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 10
            },
            "3": {
              "min": 11,
              "max": 13
            },
            "4": {
              "min": 14,
              "max": 16
            },
            "5": {
              "min": 17,
              "max": 18
            },
            "6": {
              "min": 19,
              "max": 20
            },
            "7": {
              "min": 21,
              "max": 22
            },
            "8": {
              "min": 23,
              "max": 24
            },
            "9": {
              "min": 25,
              "max": 26
            },
            "10": {
              "min": 27,
              "max": 27
            },
            "11": {
              "min": 28,
              "max": 29
            },
            "12": {
              "min": 30,
              "max": 31
            },
            "13": {
              "min": 32,
              "max": 32
            },
            "14": {
              "min": 33,
              "max": 34
            },
            "15": {
              "min": 35,
              "max": 35
            },
            "16": {
              "min": 36,
              "max": 36
            },
            "17": {
              "min": 37,
              "max": 38
            },
            "18": {
              "min": 39,
              "max": 39
            },
            "19": {
              "min": 40,
              "max": 46
            }
          }
        },
        {
          "name": "Matryce",
          "id": 3,
          "rows": {
            "1": {
              "min": 0,
              "max": 5
            },
            "2": {
              "min": 6,
              "max": 7
            },
            "3": {
              "min": 8,
              "max": 9
            },
            "4": {
              "min": 10,
              "max": 10
            },
            "5": {
              "min": 11,
              "max": 12
            },
            "6": {
              "min": 13,
              "max": 13
            },
            "7": {
              "min": 14,
              "max": 15
            },
            "8": {
              "min": 16,
              "max": 16
            },
            "9": {
              "min": 17,
              "max": 17
            },
            "10": {
              "min": 18,
              "max": 19
            },
            "11": {
              "min": 20,
              "max": 20
            },
            "12": {
              "min": 21,
              "max": 21
            },
            "13": {
              "min": 22,
              "max": 22
            },
            "14": {
              "min": 23,
              "max": 24
            },
            "15": {
              "min": 25,
              "max": 25
            },
            "16": {
              "min": 26,
              "max": 26
            },
            "17": {
              "min": 27,
              "max": 27
            },
            "18": {
              "min": 28,
              "max": 28
            },
            "19": {
              "min": 29,
              "max": 32
            }
          }
        },
        {
          "name": "Powtarzanie liczb",
          "id": 4,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 10
            },
            "3": {
              "min": 11,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 13
            },
            "5": {
              "min": 14,
              "max": 15
            },
            "6": {
              "min": 16,
              "max": 17
            },
            "7": {
              "min": 18,
              "max": 18
            },
            "8": {
              "min": 19,
              "max": 20
            },
            "9": {
              "min": 21,
              "max": 22
            },
            "10": {
              "min": 23,
              "max": 23
            },
            "11": {
              "min": 24,
              "max": 25
            },
            "12": {
              "min": 26,
              "max": 27
            },
            "13": {
              "min": 28,
              "max": 28
            },
            "14": {
              "min": 29,
              "max": 30
            },
            "15": {
              "min": 31,
              "max": 31
            },
            "16": {
              "min": 32,
              "max": 33
            },
            "17": {
              "min": 34,
              "max": 34
            },
            "18": {
              "min": 35,
              "max": 36
            },
            "19": {
              "min": 37,
              "max": 54
            }
          }
        },
        {
          "name": "Kodowanie",
          "id": 5,
          "rows": {
            "1": {
              "min": 0,
              "max": 13
            },
            "2": {
              "min": 14,
              "max": 17
            },
            "3": {
              "min": 18,
              "max": 21
            },
            "4": {
              "min": 22,
              "max": 25
            },
            "5": {
              "min": 26,
              "max": 29
            },
            "6": {
              "min": 30,
              "max": 33
            },
            "7": {
              "min": 34,
              "max": 37
            },
            "8": {
              "min": 38,
              "max": 41
            },
            "9": {
              "min": 42,
              "max": 45
            },
            "10": {
              "min": 46,
              "max": 49
            },
            "11": {
              "min": 50,
              "max": 53
            },
            "12": {
              "min": 54,
              "max": 56
            },
            "13": {
              "min": 57,
              "max": 60
            },
            "14": {
              "min": 61,
              "max": 64
            },
            "15": {
              "min": 65,
              "max": 68
            },
            "16": {
              "min": 69,
              "max": 72
            },
            "17": {
              "min": 73,
              "max": 76
            },
            "18": {
              "min": 77,
              "max": 80
            },
            "19": {
              "min": 81,
              "max": 117
            }
          }
        },
        {
          "name": "Słownik",
          "id": 6,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 13
            },
            "5": {
              "min": 14,
              "max": 15
            },
            "6": {
              "min": 16,
              "max": 17
            },
            "7": {
              "min": 18,
              "max": 19
            },
            "8": {
              "min": 20,
              "max": 22
            },
            "9": {
              "min": 23,
              "max": 24
            },
            "10": {
              "min": 25,
              "max": 26
            },
            "11": {
              "min": 27,
              "max": 29
            },
            "12": {
              "min": 30,
              "max": 32
            },
            "13": {
              "min": 33,
              "max": 34
            },
            "14": {
              "min": 35,
              "max": 37
            },
            "15": {
              "min": 38,
              "max": 40
            },
            "16": {
              "min": 41,
              "max": 43
            },
            "17": {
              "min": 44,
              "max": 46
            },
            "18": {
              "min": 47,
              "max": 49
            },
            "19": {
              "min": 50,
              "max": 54
            }
          }
        },
        {
          "name": "Ważenie",
          "id": 7,
          "rows": {
            "1": {
              "min": 0,
              "max": 4
            },
            "2": {
              "min": 5,
              "max": 6
            },
            "3": {
              "min": 7,
              "max": 8
            },
            "4": {
              "min": 9,
              "max": 10
            },
            "5": {
              "min": 11,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 15
            },
            "7": {
              "min": 16,
              "max": 17
            },
            "8": {
              "min": 18,
              "max": 19
            },
            "9": {
              "min": 20,
              "max": 21
            },
            "10": {
              "min": 22,
              "max": 23
            },
            "11": {
              "min": 24,
              "max": 24
            },
            "12": {
              "min": 25,
              "max": 25
            },
            "13": {
              "min": 26,
              "max": 27
            },
            "14": {
              "min": 28,
              "max": 28
            },
            "15": {
              "min": 29,
              "max": 29
            },
            "16": {
              "min": 30,
              "max": 30
            },
            "17": {
              "min": null,
              "max": null
            },
            "18": {
              "min": 31,
              "max": 31
            },
            "19": {
              "min": 32,
              "max": 34
            }
          }
        },
        {
          "name": "Układnie wzoru",
          "id": 8,
          "rows": {
            "1": {
              "min": 0,
              "max": 2
            },
            "2": {
              "min": 3,
              "max": 3
            },
            "3": {
              "min": 4,
              "max": 5
            },
            "4": {
              "min": 6,
              "max": 7
            },
            "5": {
              "min": 8,
              "max": 9
            },
            "6": {
              "min": 10,
              "max": 11
            },
            "7": {
              "min": 12,
              "max": 13
            },
            "8": {
              "min": 14,
              "max": 15
            },
            "9": {
              "min": 16,
              "max": 16
            },
            "10": {
              "min": 17,
              "max": 17
            },
            "11": {
              "min": 18,
              "max": 19
            },
            "12": {
              "min": 20,
              "max": 20
            },
            "13": {
              "min": 21,
              "max": 21
            },
            "14": {
              "min": 22,
              "max": 22
            },
            "15": {
              "min": 23,
              "max": 23
            },
            "16": {
              "min": 24,
              "max": 24
            },
            "17": {
              "min": 25,
              "max": 25
            },
            "18": {
              "min": null,
              "max": null
            },
            "19": {
              "min": 26,
              "max": 29
            }
          }
        },
        {
          "name": "Zapamiętywanie obrazków",
          "id": 9,
          "rows": {
            "1": {
              "min": 0,
              "max": 5
            },
            "2": {
              "min": 6,
              "max": 7
            },
            "3": {
              "min": 8,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 13
            },
            "5": {
              "min": 14,
              "max": 15
            },
            "6": {
              "min": 16,
              "max": 18
            },
            "7": {
              "min": 19,
              "max": 21
            },
            "8": {
              "min": 22,
              "max": 23
            },
            "9": {
              "min": 24,
              "max": 26
            },
            "10": {
              "min": 27,
              "max": 28
            },
            "11": {
              "min": 29,
              "max": 30
            },
            "12": {
              "min": 31,
              "max": 33
            },
            "13": {
              "min": 34,
              "max": 35
            },
            "14": {
              "min": 36,
              "max": 38
            },
            "15": {
              "min": 39,
              "max": 40
            },
            "16": {
              "min": 41,
              "max": 42
            },
            "17": {
              "min": 43,
              "max": 44
            },
            "18": {
              "min": 45,
              "max": 46
            },
            "19": {
              "min": 47,
              "max": 49
            }
          }
        },
        {
          "name": "Szukanie symboli",
          "id": 10,
          "rows": {
            "1": {
              "min": 0,
              "max": 6
            },
            "2": {
              "min": 7,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 13
            },
            "5": {
              "min": 14,
              "max": 16
            },
            "6": {
              "min": 17,
              "max": 18
            },
            "7": {
              "min": 19,
              "max": 20
            },
            "8": {
              "min": 21,
              "max": 23
            },
            "9": {
              "min": 24,
              "max": 25
            },
            "10": {
              "min": 26,
              "max": 27
            },
            "11": {
              "min": 28,
              "max": 29
            },
            "12": {
              "min": 30,
              "max": 31
            },
            "13": {
              "min": 32,
              "max": 34
            },
            "14": {
              "min": 35,
              "max": 36
            },
            "15": {
              "min": 37,
              "max": 38
            },
            "16": {
              "min": 39,
              "max": 40
            },
            "17": {
              "min": 41,
              "max": 42
            },
            "18": {
              "min": 43,
              "max": 44
            },
            "19": {
              "min": 45,
              "max": 60
            }
          }
        },
        {
          "name": "Wiadomości",
          "id": 11,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 9
            },
            "4": {
              "min": null,
              "max": null
            },
            "5": {
              "min": 10,
              "max": 10
            },
            "6": {
              "min": 11,
              "max": 12
            },
            "7": {
              "min": 13,
              "max": 13
            },
            "8": {
              "min": 14,
              "max": 14
            },
            "9": {
              "min": 15,
              "max": 15
            },
            "10": {
              "min": 16,
              "max": 16
            },
            "11": {
              "min": 17,
              "max": 17
            },
            "12": {
              "min": 18,
              "max": 18
            },
            "13": {
              "min": 19,
              "max": 19
            },
            "14": {
              "min": 20,
              "max": 20
            },
            "15": {
              "min": 21,
              "max": 22
            },
            "16": {
              "min": 23,
              "max": 23
            },
            "17": {
              "min": 24,
              "max": 24
            },
            "18": {
              "min": 25,
              "max": 25
            },
            "19": {
              "min": 26,
              "max": 31
            }
          }
        },
        {
          "name": "Tworzenie pojęć",
          "id": 12,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 4
            },
            "3": {
              "min": 5,
              "max": 5
            },
            "4": {
              "min": 6,
              "max": 7
            },
            "5": {
              "min": 8,
              "max": 8
            },
            "6": {
              "min": 9,
              "max": 9
            },
            "7": {
              "min": 10,
              "max": 11
            },
            "8": {
              "min": 12,
              "max": 12
            },
            "9": {
              "min": 13,
              "max": 13
            },
            "10": {
              "min": 14,
              "max": 14
            },
            "11": {
              "min": 15,
              "max": 15
            },
            "12": {
              "min": 16,
              "max": 16
            },
            "13": {
              "min": 17,
              "max": 17
            },
            "14": {
              "min": 18,
              "max": 18
            },
            "15": {
              "min": 19,
              "max": 19
            },
            "16": {
              "min": 20,
              "max": 20
            },
            "17": {
              "min": 21,
              "max": 21
            },
            "18": {
              "min": 22,
              "max": 22
            },
            "19": {
              "min": 23,
              "max": 27
            }
          }
        },
        {
          "name": "Porządkowanie liter i liczb",
          "id": 13,
          "rows": {
            "1": {
              "min": 0,
              "max": 4
            },
            "2": {
              "min": 5,
              "max": 6
            },
            "3": {
              "min": 7,
              "max": 8
            },
            "4": {
              "min": 9,
              "max": 9
            },
            "5": {
              "min": 10,
              "max": 11
            },
            "6": {
              "min": 12,
              "max": 12
            },
            "7": {
              "min": 13,
              "max": 13
            },
            "8": {
              "min": 14,
              "max": 15
            },
            "9": {
              "min": null,
              "max": null
            },
            "10": {
              "min": 16,
              "max": 16
            },
            "11": {
              "min": 17,
              "max": 17
            },
            "12": {
              "min": 18,
              "max": 18
            },
            "13": {
              "min": 19,
              "max": 19
            },
            "14": {
              "min": 20,
              "max": 20
            },
            "15": {
              "min": null,
              "max": null
            },
            "16": {
              "min": 21,
              "max": 21
            },
            "17": {
              "min": null,
              "max": null
            },
            "18": {
              "min": 22,
              "max": 22
            },
            "19": {
              "min": 23,
              "max": 30
            }
          }
        },
        {
          "name": "Wykreślanie",
          "id": 14,
          "rows": {
            "1": {
              "min": 0,
              "max": 25
            },
            "2": {
              "min": 26,
              "max": 29
            },
            "3": {
              "min": 30,
              "max": 33
            },
            "4": {
              "min": 34,
              "max": 37
            },
            "5": {
              "min": 38,
              "max": 42
            },
            "6": {
              "min": 43,
              "max": 47
            },
            "7": {
              "min": 48,
              "max": 52
            },
            "8": {
              "min": 53,
              "max": 57
            },
            "9": {
              "min": 58,
              "max": 63
            },
            "10": {
              "min": 64,
              "max": 68
            },
            "11": {
              "min": 69,
              "max": 74
            },
            "12": {
              "min": 75,
              "max": 81
            },
            "13": {
              "min": 82,
              "max": 87
            },
            "14": {
              "min": 88,
              "max": 93
            },
            "15": {
              "min": 94,
              "max": 100
            },
            "16": {
              "min": 101,
              "max": 107
            },
            "17": {
              "min": 108,
              "max": 113
            },
            "18": {
              "min": 114,
              "max": 119
            },
            "19": {
              "min": 120,
              "max": 128
            }
          }
        },
        {
          "name": "Rozumienie",
          "id": 15,
          "rows": {
            "1": {
              "min": 0,
              "max": 4
            },
            "2": {
              "min": 5,
              "max": 6
            },
            "3": {
              "min": 7,
              "max": 7
            },
            "4": {
              "min": 8,
              "max": 9
            },
            "5": {
              "min": 10,
              "max": 10
            },
            "6": {
              "min": 11,
              "max": 12
            },
            "7": {
              "min": 13,
              "max": 14
            },
            "8": {
              "min": 15,
              "max": 15
            },
            "9": {
              "min": 16,
              "max": 17
            },
            "10": {
              "min": 18,
              "max": 19
            },
            "11": {
              "min": 20,
              "max": 20
            },
            "12": {
              "min": 21,
              "max": 22
            },
            "13": {
              "min": 23,
              "max": 24
            },
            "14": {
              "min": 25,
              "max": 26
            },
            "15": {
              "min": 27,
              "max": 27
            },
            "16": {
              "min": 28,
              "max": 29
            },
            "17": {
              "min": 30,
              "max": 31
            },
            "18": {
              "min": 32,
              "max": 33
            },
            "19": {
              "min": 34,
              "max": 38
            }
          }
        },
        {
          "name": "Arytmetyka",
          "id": 16,
          "rows": {
            "1": {
              "min": 0,
              "max": 4
            },
            "2": {
              "min": 5,
              "max": 6
            },
            "3": {
              "min": 7,
              "max": 9
            },
            "4": {
              "min": 10,
              "max": 11
            },
            "5": {
              "min": 12,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 16
            },
            "8": {
              "min": 17,
              "max": 18
            },
            "9": {
              "min": 19,
              "max": 19
            },
            "10": {
              "min": 20,
              "max": 21
            },
            "11": {
              "min": 22,
              "max": 22
            },
            "12": {
              "min": 23,
              "max": 23
            },
            "13": {
              "min": 24,
              "max": 25
            },
            "14": {
              "min": 26,
              "max": 26
            },
            "15": {
              "min": 27,
              "max": 27
            },
            "16": {
              "min": 28,
              "max": 28
            },
            "17": {
              "min": 29,
              "max": 29
            },
            "18": {
              "min": 30,
              "max": 31
            },
            "19": {
              "min": 32,
              "max": 34
            }
          }
        }
      ],

    114117: [
        {
          "name": "Wzory z klocków",
          "id": 1,
          "rows": {
            "1": {
              "min": 0,
              "max": 6
            },
            "2": {
              "min": 7,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 12
            },
            "4": {
              "min": 13,
              "max": 15
            },
            "5": {
              "min": 16,
              "max": 17
            },
            "6": {
              "min": 18,
              "max": 20
            },
            "7": {
              "min": 21,
              "max": 23
            },
            "8": {
              "min": 24,
              "max": 26
            },
            "9": {
              "min": 27,
              "max": 29
            },
            "10": {
              "min": 30,
              "max": 32
            },
            "11": {
              "min": 33,
              "max": 35
            },
            "12": {
              "min": 36,
              "max": 38
            },
            "13": {
              "min": 39,
              "max": 41
            },
            "14": {
              "min": 42,
              "max": 44
            },
            "15": {
              "min": 45,
              "max": 47
            },
            "16": {
              "min": 48,
              "max": 50
            },
            "17": {
              "min": 51,
              "max": 53
            },
            "18": {
              "min": 54,
              "max": 55
            },
            "19": {
              "min": 56,
              "max": 58
            }
          }
        },
        {
          "name": "Podobieństwa",
          "id": 2,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 11
            },
            "3": {
              "min": 12,
              "max": 14
            },
            "4": {
              "min": 15,
              "max": 16
            },
            "5": {
              "min": 17,
              "max": 19
            },
            "6": {
              "min": 20,
              "max": 21
            },
            "7": {
              "min": 22,
              "max": 23
            },
            "8": {
              "min": 24,
              "max": 24
            },
            "9": {
              "min": 25,
              "max": 26
            },
            "10": {
              "min": 27,
              "max": 28
            },
            "11": {
              "min": 29,
              "max": 29
            },
            "12": {
              "min": 30,
              "max": 31
            },
            "13": {
              "min": 32,
              "max": 33
            },
            "14": {
              "min": 34,
              "max": 34
            },
            "15": {
              "min": 35,
              "max": 35
            },
            "16": {
              "min": 36,
              "max": 37
            },
            "17": {
              "min": 38,
              "max": 38
            },
            "18": {
              "min": 39,
              "max": 39
            },
            "19": {
              "min": 40,
              "max": 46
            }
          }
        },
        {
          "name": "Matryce",
          "id": 3,
          "rows": {
            "1": {
              "min": 0,
              "max": 6
            },
            "2": {
              "min": 7,
              "max": 7
            },
            "3": {
              "min": 8,
              "max": 9
            },
            "4": {
              "min": 10,
              "max": 11
            },
            "5": {
              "min": 12,
              "max": 12
            },
            "6": {
              "min": 13,
              "max": 13
            },
            "7": {
              "min": 14,
              "max": 15
            },
            "8": {
              "min": 16,
              "max": 16
            },
            "9": {
              "min": 17,
              "max": 18
            },
            "10": {
              "min": 19,
              "max": 19
            },
            "11": {
              "min": 20,
              "max": 20
            },
            "12": {
              "min": 21,
              "max": 21
            },
            "13": {
              "min": 22,
              "max": 23
            },
            "14": {
              "min": 24,
              "max": 24
            },
            "15": {
              "min": 25,
              "max": 25
            },
            "16": {
              "min": 26,
              "max": 26
            },
            "17": {
              "min": 27,
              "max": 27
            },
            "18": {
              "min": 28,
              "max": 29
            },
            "19": {
              "min": 30,
              "max": 32
            }
          }
        },
        {
          "name": "Powtarzanie liczb",
          "id": 4,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 10
            },
            "3": {
              "min": 11,
              "max": 12
            },
            "4": {
              "min": 13,
              "max": 14
            },
            "5": {
              "min": 15,
              "max": 15
            },
            "6": {
              "min": 16,
              "max": 17
            },
            "7": {
              "min": 18,
              "max": 19
            },
            "8": {
              "min": 20,
              "max": 21
            },
            "9": {
              "min": 22,
              "max": 22
            },
            "10": {
              "min": 23,
              "max": 24
            },
            "11": {
              "min": 25,
              "max": 25
            },
            "12": {
              "min": 26,
              "max": 27
            },
            "13": {
              "min": 28,
              "max": 29
            },
            "14": {
              "min": 30,
              "max": 30
            },
            "15": {
              "min": 31,
              "max": 32
            },
            "16": {
              "min": 33,
              "max": 33
            },
            "17": {
              "min": 34,
              "max": 35
            },
            "18": {
              "min": 36,
              "max": 36
            },
            "19": {
              "min": 37,
              "max": 54
            }
          }
        },
        {
          "name": "Kodowanie",
          "id": 5,
          "rows": {
            "1": {
              "min": 0,
              "max": 13
            },
            "2": {
              "min": 14,
              "max": 17
            },
            "3": {
              "min": 18,
              "max": 22
            },
            "4": {
              "min": 23,
              "max": 26
            },
            "5": {
              "min": 27,
              "max": 30
            },
            "6": {
              "min": 31,
              "max": 34
            },
            "7": {
              "min": 35,
              "max": 38
            },
            "8": {
              "min": 39,
              "max": 42
            },
            "9": {
              "min": 43,
              "max": 46
            },
            "10": {
              "min": 47,
              "max": 50
            },
            "11": {
              "min": 51,
              "max": 54
            },
            "12": {
              "min": 55,
              "max": 58
            },
            "13": {
              "min": 59,
              "max": 62
            },
            "14": {
              "min": 63,
              "max": 66
            },
            "15": {
              "min": 67,
              "max": 70
            },
            "16": {
              "min": 71,
              "max": 74
            },
            "17": {
              "min": 75,
              "max": 78
            },
            "18": {
              "min": 79,
              "max": 82
            },
            "19": {
              "min": 83,
              "max": 117
            }
          }
        },
        {
          "name": "Słownik",
          "id": 6,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 13
            },
            "5": {
              "min": 14,
              "max": 16
            },
            "6": {
              "min": 17,
              "max": 18
            },
            "7": {
              "min": 19,
              "max": 20
            },
            "8": {
              "min": 21,
              "max": 22
            },
            "9": {
              "min": 23,
              "max": 25
            },
            "10": {
              "min": 26,
              "max": 27
            },
            "11": {
              "min": 28,
              "max": 30
            },
            "12": {
              "min": 31,
              "max": 33
            },
            "13": {
              "min": 34,
              "max": 36
            },
            "14": {
              "min": 37,
              "max": 38
            },
            "15": {
              "min": 39,
              "max": 41
            },
            "16": {
              "min": 42,
              "max": 44
            },
            "17": {
              "min": 45,
              "max": 47
            },
            "18": {
              "min": 48,
              "max": 50
            },
            "19": {
              "min": 51,
              "max": 54
            }
          }
        },
        {
          "name": "Ważenie",
          "id": 7,
          "rows": {
            "1": {
              "min": 0,
              "max": 5
            },
            "2": {
              "min": 6,
              "max": 6
            },
            "3": {
              "min": 7,
              "max": 8
            },
            "4": {
              "min": 9,
              "max": 11
            },
            "5": {
              "min": 12,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 15
            },
            "7": {
              "min": 16,
              "max": 16
            },
            "8": {
              "min": 17,
              "max": 20
            },
            "9": {
              "min": 21,
              "max": 21
            },
            "10": {
              "min": 22,
              "max": 23
            },
            "11": {
              "min": 24,
              "max": 24
            },
            "12": {
              "min": 25,
              "max": 26
            },
            "13": {
              "min": 27,
              "max": 27
            },
            "14": {
              "min": 28,
              "max": 28
            },
            "15": {
              "min": 29,
              "max": 29
            },
            "16": {
              "min": 30,
              "max": 30
            },
            "17": {
              "min": null,
              "max": null
            },
            "18": {
              "min": 31,
              "max": 31
            },
            "19": {
              "min": 32,
              "max": 34
            }
          }
        },
        {
          "name": "Układnie wzoru",
          "id": 8,
          "rows": {
            "1": {
              "min": 0,
              "max": 2
            },
            "2": {
              "min": 3,
              "max": 3
            },
            "3": {
              "min": 4,
              "max": 5
            },
            "4": {
              "min": 6,
              "max": 7
            },
            "5": {
              "min": 8,
              "max": 9
            },
            "6": {
              "min": 10,
              "max": 11
            },
            "7": {
              "min": 12,
              "max": 13
            },
            "8": {
              "min": 14,
              "max": 15
            },
            "9": {
              "min": 16,
              "max": 16
            },
            "10": {
              "min": 17,
              "max": 18
            },
            "11": {
              "min": 19,
              "max": 19
            },
            "12": {
              "min": 20,
              "max": 20
            },
            "13": {
              "min": 21,
              "max": 21
            },
            "14": {
              "min": 22,
              "max": 22
            },
            "15": {
              "min": 23,
              "max": 23
            },
            "16": {
              "min": 24,
              "max": 24
            },
            "17": {
              "min": 25,
              "max": 25
            },
            "18": {
              "min": 26,
              "max": 26
            },
            "19": {
              "min": 27,
              "max": 29
            }
          }
        },
        {
          "name": "Zapamiętywanie obrazków",
          "id": 9,
          "rows": {
            "1": {
              "min": 0,
              "max": 5
            },
            "2": {
              "min": 6,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 13
            },
            "5": {
              "min": 14,
              "max": 16
            },
            "6": {
              "min": 17,
              "max": 19
            },
            "7": {
              "min": 20,
              "max": 21
            },
            "8": {
              "min": 22,
              "max": 24
            },
            "9": {
              "min": 25,
              "max": 26
            },
            "10": {
              "min": 27,
              "max": 29
            },
            "11": {
              "min": 30,
              "max": 31
            },
            "12": {
              "min": 32,
              "max": 33
            },
            "13": {
              "min": 34,
              "max": 36
            },
            "14": {
              "min": 37,
              "max": 38
            },
            "15": {
              "min": 39,
              "max": 40
            },
            "16": {
              "min": 41,
              "max": 43
            },
            "17": {
              "min": 44,
              "max": 45
            },
            "18": {
              "min": 46,
              "max": 46
            },
            "19": {
              "min": 47,
              "max": 49
            }
          }
        },
        {
          "name": "Szukanie symboli",
          "id": 10,
          "rows": {
            "1": {
              "min": 0,
              "max": 6
            },
            "2": {
              "min": 7,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 14
            },
            "5": {
              "min": 15,
              "max": 16
            },
            "6": {
              "min": 17,
              "max": 19
            },
            "7": {
              "min": 20,
              "max": 21
            },
            "8": {
              "min": 22,
              "max": 23
            },
            "9": {
              "min": 24,
              "max": 26
            },
            "10": {
              "min": 27,
              "max": 28
            },
            "11": {
              "min": 29,
              "max": 30
            },
            "12": {
              "min": 31,
              "max": 32
            },
            "13": {
              "min": 33,
              "max": 34
            },
            "14": {
              "min": 35,
              "max": 37
            },
            "15": {
              "min": 38,
              "max": 39
            },
            "16": {
              "min": 40,
              "max": 41
            },
            "17": {
              "min": 42,
              "max": 43
            },
            "18": {
              "min": 44,
              "max": 45
            },
            "19": {
              "min": 46,
              "max": 60
            }
          }
        },
        {
          "name": "Wiadomości",
          "id": 11,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 9
            },
            "4": {
              "min": 10,
              "max": 10
            },
            "5": {
              "min": 11,
              "max": 11
            },
            "6": {
              "min": 12,
              "max": 12
            },
            "7": {
              "min": 13,
              "max": 13
            },
            "8": {
              "min": 14,
              "max": 14
            },
            "9": {
              "min": 15,
              "max": 15
            },
            "10": {
              "min": 16,
              "max": 16
            },
            "11": {
              "min": 17,
              "max": 17
            },
            "12": {
              "min": 18,
              "max": 19
            },
            "13": {
              "min": 20,
              "max": 20
            },
            "14": {
              "min": 21,
              "max": 21
            },
            "15": {
              "min": 22,
              "max": 22
            },
            "16": {
              "min": 23,
              "max": 24
            },
            "17": {
              "min": 25,
              "max": 25
            },
            "18": {
              "min": 26,
              "max": 26
            },
            "19": {
              "min": 27,
              "max": 31
            }
          }
        },
        {
          "name": "Tworzenie pojęć",
          "id": 12,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 5
            },
            "3": {
              "min": 6,
              "max": 6
            },
            "4": {
              "min": 7,
              "max": 7
            },
            "5": {
              "min": 8,
              "max": 9
            },
            "6": {
              "min": 10,
              "max": 10
            },
            "7": {
              "min": 11,
              "max": 11
            },
            "8": {
              "min": 12,
              "max": 12
            },
            "9": {
              "min": 13,
              "max": 13
            },
            "10": {
              "min": 14,
              "max": 14
            },
            "11": {
              "min": 15,
              "max": 15
            },
            "12": {
              "min": 16,
              "max": 16
            },
            "13": {
              "min": 17,
              "max": 17
            },
            "14": {
              "min": 18,
              "max": 18
            },
            "15": {
              "min": 19,
              "max": 19
            },
            "16": {
              "min": 20,
              "max": 20
            },
            "17": {
              "min": 21,
              "max": 21
            },
            "18": {
              "min": 22,
              "max": 22
            },
            "19": {
              "min": 23,
              "max": 27
            }
          }
        },
        {
          "name": "Porządkowanie liter i liczb",
          "id": 13,
          "rows": {
            "1": {
              "min": 0,
              "max": 4
            },
            "2": {
              "min": 5,
              "max": 6
            },
            "3": {
              "min": 7,
              "max": 8
            },
            "4": {
              "min": 9,
              "max": 10
            },
            "5": {
              "min": 11,
              "max": 11
            },
            "6": {
              "min": 12,
              "max": 13
            },
            "7": {
              "min": 14,
              "max": 14
            },
            "8": {
              "min": 15,
              "max": 15
            },
            "9": {
              "min": 16,
              "max": 16
            },
            "10": {
              "min": 17,
              "max": 17
            },
            "11": {
              "min": null,
              "max": null
            },
            "12": {
              "min": 18,
              "max": 18
            },
            "13": {
              "min": 19,
              "max": 19
            },
            "14": {
              "min": 20,
              "max": 20
            },
            "15": {
              "min": null,
              "max": null
            },
            "16": {
              "min": 21,
              "max": 21
            },
            "17": {
              "min": 22,
              "max": 22
            },
            "18": {
              "min": null,
              "max": null
            },
            "19": {
              "min": 23,
              "max": 30
            }
          }
        },
        {
          "name": "Wykreślanie",
          "id": 14,
          "rows": {
            "1": {
              "min": 0,
              "max": 25
            },
            "2": {
              "min": 26,
              "max": 29
            },
            "3": {
              "min": 30,
              "max": 34
            },
            "4": {
              "min": 35,
              "max": 38
            },
            "5": {
              "min": 39,
              "max": 43
            },
            "6": {
              "min": 44,
              "max": 48
            },
            "7": {
              "min": 49,
              "max": 53
            },
            "8": {
              "min": 54,
              "max": 59
            },
            "9": {
              "min": 60,
              "max": 64
            },
            "10": {
              "min": 65,
              "max": 70
            },
            "11": {
              "min": 71,
              "max": 76
            },
            "12": {
              "min": 77,
              "max": 82
            },
            "13": {
              "min": 83,
              "max": 88
            },
            "14": {
              "min": 89,
              "max": 95
            },
            "15": {
              "min": 96,
              "max": 101
            },
            "16": {
              "min": 102,
              "max": 108
            },
            "17": {
              "min": 109,
              "max": 114
            },
            "18": {
              "min": 115,
              "max": 120
            },
            "19": {
              "min": 121,
              "max": 128
            }
          }
        },
        {
          "name": "Rozumienie",
          "id": 15,
          "rows": {
            "1": {
              "min": 0,
              "max": 5
            },
            "2": {
              "min": 6,
              "max": 6
            },
            "3": {
              "min": 7,
              "max": 8
            },
            "4": {
              "min": 9,
              "max": 9
            },
            "5": {
              "min": 10,
              "max": 11
            },
            "6": {
              "min": 12,
              "max": 13
            },
            "7": {
              "min": 14,
              "max": 14
            },
            "8": {
              "min": 15,
              "max": 16
            },
            "9": {
              "min": 17,
              "max": 17
            },
            "10": {
              "min": 18,
              "max": 19
            },
            "11": {
              "min": 20,
              "max": 21
            },
            "12": {
              "min": 22,
              "max": 23
            },
            "13": {
              "min": 24,
              "max": 24
            },
            "14": {
              "min": 25,
              "max": 26
            },
            "15": {
              "min": 27,
              "max": 28
            },
            "16": {
              "min": 29,
              "max": 30
            },
            "17": {
              "min": 31,
              "max": 32
            },
            "18": {
              "min": 33,
              "max": 33
            },
            "19": {
              "min": 34,
              "max": 38
            }
          }
        },
        {
          "name": "Arytmetyka",
          "id": 16,
          "rows": {
            "1": {
              "min": 0,
              "max": 4
            },
            "2": {
              "min": 5,
              "max": 7
            },
            "3": {
              "min": 8,
              "max": 9
            },
            "4": {
              "min": 10,
              "max": 11
            },
            "5": {
              "min": 12,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 15
            },
            "7": {
              "min": 16,
              "max": 17
            },
            "8": {
              "min": 18,
              "max": 18
            },
            "9": {
              "min": 19,
              "max": 20
            },
            "10": {
              "min": 21,
              "max": 21
            },
            "11": {
              "min": 22,
              "max": 23
            },
            "12": {
              "min": 24,
              "max": 24
            },
            "13": {
              "min": 25,
              "max": 25
            },
            "14": {
              "min": 26,
              "max": 26
            },
            "15": {
              "min": 27,
              "max": 28
            },
            "16": {
              "min": 29,
              "max": 29
            },
            "17": {
              "min": 30,
              "max": 30
            },
            "18": {
              "min": 31,
              "max": 31
            },
            "19": {
              "min": 32,
              "max": 34
            }
          }
        }
      ],
    
    1181111: [
        {
          "name": "Wzory z klocków",
          "id": 1,
          "rows": {
            "1": {
              "min": 0,
              "max": 6
            },
            "2": {
              "min": 7,
              "max": 10
            },
            "3": {
              "min": 11,
              "max": 12
            },
            "4": {
              "min": 13,
              "max": 15
            },
            "5": {
              "min": 16,
              "max": 18
            },
            "6": {
              "min": 19,
              "max": 21
            },
            "7": {
              "min": 22,
              "max": 24
            },
            "8": {
              "min": 25,
              "max": 27
            },
            "9": {
              "min": 28,
              "max": 30
            },
            "10": {
              "min": 31,
              "max": 33
            },
            "11": {
              "min": 34,
              "max": 36
            },
            "12": {
              "min": 37,
              "max": 39
            },
            "13": {
              "min": 40,
              "max": 42
            },
            "14": {
              "min": 43,
              "max": 45
            },
            "15": {
              "min": 46,
              "max": 48
            },
            "16": {
              "min": 49,
              "max": 51
            },
            "17": {
              "min": 52,
              "max": 53
            },
            "18": {
              "min": 54,
              "max": 55
            },
            "19": {
              "min": 56,
              "max": 58
            }
          }
        },
        {
          "name": "Podobieństwa",
          "id": 2,
          "rows": {
            "1": {
              "min": 0,
              "max": 9
            },
            "2": {
              "min": 10,
              "max": 12
            },
            "3": {
              "min": 13,
              "max": 15
            },
            "4": {
              "min": 16,
              "max": 17
            },
            "5": {
              "min": 18,
              "max": 19
            },
            "6": {
              "min": 20,
              "max": 21
            },
            "7": {
              "min": 22,
              "max": 23
            },
            "8": {
              "min": 24,
              "max": 25
            },
            "9": {
              "min": 26,
              "max": 27
            },
            "10": {
              "min": 28,
              "max": 28
            },
            "11": {
              "min": 29,
              "max": 30
            },
            "12": {
              "min": 31,
              "max": 31
            },
            "13": {
              "min": 32,
              "max": 33
            },
            "14": {
              "min": 34,
              "max": 34
            },
            "15": {
              "min": 35,
              "max": 36
            },
            "16": {
              "min": 37,
              "max": 37
            },
            "17": {
              "min": 38,
              "max": 39
            },
            "18": {
              "min": 40,
              "max": 40
            },
            "19": {
              "min": 41,
              "max": 46
            }
          }
        },
        {
          "name": "Matryce",
          "id": 3,
          "rows": {
            "1": {
              "min": 0,
              "max": 6
            },
            "2": {
              "min": 7,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 9
            },
            "4": {
              "min": 10,
              "max": 11
            },
            "5": {
              "min": 12,
              "max": 12
            },
            "6": {
              "min": 13,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 15
            },
            "8": {
              "min": 16,
              "max": 17
            },
            "9": {
              "min": 18,
              "max": 18
            },
            "10": {
              "min": 19,
              "max": 19
            },
            "11": {
              "min": 20,
              "max": 20
            },
            "12": {
              "min": 21,
              "max": 22
            },
            "13": {
              "min": 23,
              "max": 23
            },
            "14": {
              "min": 24,
              "max": 24
            },
            "15": {
              "min": 25,
              "max": 25
            },
            "16": {
              "min": 26,
              "max": 27
            },
            "17": {
              "min": 28,
              "max": 28
            },
            "18": {
              "min": 29,
              "max": 29
            },
            "19": {
              "min": 30,
              "max": 32
            }
          }
        },
        {
          "name": "Powtarzanie liczb",
          "id": 4,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 10
            },
            "3": {
              "min": 11,
              "max": 12
            },
            "4": {
              "min": 13,
              "max": 14
            },
            "5": {
              "min": 15,
              "max": 16
            },
            "6": {
              "min": 17,
              "max": 18
            },
            "7": {
              "min": 19,
              "max": 19
            },
            "8": {
              "min": 20,
              "max": 21
            },
            "9": {
              "min": 22,
              "max": 23
            },
            "10": {
              "min": 24,
              "max": 24
            },
            "11": {
              "min": 25,
              "max": 26
            },
            "12": {
              "min": 27,
              "max": 28
            },
            "13": {
              "min": 29,
              "max": 29
            },
            "14": {
              "min": 30,
              "max": 31
            },
            "15": {
              "min": 32,
              "max": 32
            },
            "16": {
              "min": 33,
              "max": 34
            },
            "17": {
              "min": 35,
              "max": 35
            },
            "18": {
              "min": 36,
              "max": 37
            },
            "19": {
              "min": 38,
              "max": 54
            }
          }
        },
        {
          "name": "Kodowanie",
          "id": 5,
          "rows": {
            "1": {
              "min": 0,
              "max": 14
            },
            "2": {
              "min": 15,
              "max": 18
            },
            "3": {
              "min": 19,
              "max": 22
            },
            "4": {
              "min": 23,
              "max": 27
            },
            "5": {
              "min": 28,
              "max": 31
            },
            "6": {
              "min": 32,
              "max": 35
            },
            "7": {
              "min": 36,
              "max": 39
            },
            "8": {
              "min": 40,
              "max": 43
            },
            "9": {
              "min": 44,
              "max": 48
            },
            "10": {
              "min": 49,
              "max": 52
            },
            "11": {
              "min": 53,
              "max": 56
            },
            "12": {
              "min": 57,
              "max": 60
            },
            "13": {
              "min": 61,
              "max": 64
            },
            "14": {
              "min": 65,
              "max": 68
            },
            "15": {
              "min": 69,
              "max": 72
            },
            "16": {
              "min": 73,
              "max": 77
            },
            "17": {
              "min": 78,
              "max": 81
            },
            "18": {
              "min": 82,
              "max": 85
            },
            "19": {
              "min": 86,
              "max": 117
            }
          }
        },
        {
          "name": "Słownik",
          "id": 6,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 14
            },
            "5": {
              "min": 15,
              "max": 16
            },
            "6": {
              "min": 17,
              "max": 18
            },
            "7": {
              "min": 19,
              "max": 21
            },
            "8": {
              "min": 22,
              "max": 23
            },
            "9": {
              "min": 24,
              "max": 26
            },
            "10": {
              "min": 27,
              "max": 28
            },
            "11": {
              "min": 29,
              "max": 31
            },
            "12": {
              "min": 32,
              "max": 34
            },
            "13": {
              "min": 35,
              "max": 37
            },
            "14": {
              "min": 38,
              "max": 39
            },
            "15": {
              "min": 40,
              "max": 42
            },
            "16": {
              "min": 43,
              "max": 45
            },
            "17": {
              "min": 46,
              "max": 48
            },
            "18": {
              "min": 49,
              "max": 50
            },
            "19": {
              "min": 51,
              "max": 54
            }
          }
        },
        {
          "name": "Ważenie",
          "id": 7,
          "rows": {
            "1": {
              "min": 0,
              "max": 5
            },
            "2": {
              "min": 6,
              "max": 7
            },
            "3": {
              "min": 8,
              "max": 9
            },
            "4": {
              "min": 10,
              "max": 11
            },
            "5": {
              "min": 12,
              "max": 14
            },
            "6": {
              "min": 15,
              "max": 16
            },
            "7": {
              "min": 17,
              "max": 18
            },
            "8": {
              "min": 19,
              "max": 20
            },
            "9": {
              "min": 21,
              "max": 22
            },
            "10": {
              "min": 23,
              "max": 23
            },
            "11": {
              "min": 24,
              "max": 25
            },
            "12": {
              "min": 26,
              "max": 26
            },
            "13": {
              "min": 27,
              "max": 27
            },
            "14": {
              "min": 28,
              "max": 28
            },
            "15": {
              "min": 29,
              "max": 29
            },
            "16": {
              "min": 30,
              "max": 30
            },
            "17": {
              "min": 31,
              "max": 31
            },
            "18": {
              "min": null,
              "max": null
            },
            "19": {
              "min": 32,
              "max": 34
            }
          }
        },
        {
          "name": "Układnie wzoru",
          "id": 8,
          "rows": {
            "1": {
              "min": 0,
              "max": 2
            },
            "2": {
              "min": 3,
              "max": 4
            },
            "3": {
              "min": 5,
              "max": 5
            },
            "4": {
              "min": 6,
              "max": 7
            },
            "5": {
              "min": 8,
              "max": 10
            },
            "6": {
              "min": 11,
              "max": 12
            },
            "7": {
              "min": 13,
              "max": 13
            },
            "8": {
              "min": 14,
              "max": 15
            },
            "9": {
              "min": 16,
              "max": 17
            },
            "10": {
              "min": 18,
              "max": 18
            },
            "11": {
              "min": 19,
              "max": 19
            },
            "12": {
              "min": 20,
              "max": 20
            },
            "13": {
              "min": 21,
              "max": 22
            },
            "14": {
              "min": 23,
              "max": 23
            },
            "15": {
              "min": null,
              "max": null
            },
            "16": {
              "min": 24,
              "max": 24
            },
            "17": {
              "min": 25,
              "max": 25
            },
            "18": {
              "min": 26,
              "max": 26
            },
            "19": {
              "min": 27,
              "max": 29
            }
          }
        },
        {
          "name": "Zapamiętywanie obrazków",
          "id": 9,
          "rows": {
            "1": {
              "min": 0,
              "max": 6
            },
            "2": {
              "min": 7,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 14
            },
            "5": {
              "min": 15,
              "max": 16
            },
            "6": {
              "min": 17,
              "max": 19
            },
            "7": {
              "min": 20,
              "max": 22
            },
            "8": {
              "min": 23,
              "max": 24
            },
            "9": {
              "min": 25,
              "max": 27
            },
            "10": {
              "min": 28,
              "max": 29
            },
            "11": {
              "min": 30,
              "max": 31
            },
            "12": {
              "min": 32,
              "max": 34
            },
            "13": {
              "min": 35,
              "max": 36
            },
            "14": {
              "min": 37,
              "max": 39
            },
            "15": {
              "min": 40,
              "max": 41
            },
            "16": {
              "min": 42,
              "max": 43
            },
            "17": {
              "min": 44,
              "max": 45
            },
            "18": {
              "min": 46,
              "max": 47
            },
            "19": {
              "min": 48,
              "max": 49
            }
          }
        },
        {
          "name": "Szukanie symboli",
          "id": 10,
          "rows": {
            "1": {
              "min": 0,
              "max": 6
            },
            "2": {
              "min": 7,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 12
            },
            "4": {
              "min": 13,
              "max": 14
            },
            "5": {
              "min": 15,
              "max": 17
            },
            "6": {
              "min": 18,
              "max": 19
            },
            "7": {
              "min": 20,
              "max": 22
            },
            "8": {
              "min": 23,
              "max": 24
            },
            "9": {
              "min": 25,
              "max": 26
            },
            "10": {
              "min": 27,
              "max": 29
            },
            "11": {
              "min": 30,
              "max": 31
            },
            "12": {
              "min": 32,
              "max": 33
            },
            "13": {
              "min": 34,
              "max": 35
            },
            "14": {
              "min": 36,
              "max": 37
            },
            "15": {
              "min": 38,
              "max": 40
            },
            "16": {
              "min": 41,
              "max": 42
            },
            "17": {
              "min": 43,
              "max": 44
            },
            "18": {
              "min": 45,
              "max": 46
            },
            "19": {
              "min": 47,
              "max": 60
            }
          }
        },
        {
          "name": "Wiadomości",
          "id": 11,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 9
            },
            "4": {
              "min": 10,
              "max": 10
            },
            "5": {
              "min": 11,
              "max": 11
            },
            "6": {
              "min": 12,
              "max": 12
            },
            "7": {
              "min": 13,
              "max": 13
            },
            "8": {
              "min": 14,
              "max": 14
            },
            "9": {
              "min": 15,
              "max": 16
            },
            "10": {
              "min": 17,
              "max": 17
            },
            "11": {
              "min": 18,
              "max": 18
            },
            "12": {
              "min": 19,
              "max": 19
            },
            "13": {
              "min": 20,
              "max": 20
            },
            "14": {
              "min": 21,
              "max": 22
            },
            "15": {
              "min": 23,
              "max": 23
            },
            "16": {
              "min": 24,
              "max": 24
            },
            "17": {
              "min": 25,
              "max": 26
            },
            "18": {
              "min": 27,
              "max": 27
            },
            "19": {
              "min": 28,
              "max": 31
            }
          }
        },
        {
          "name": "Tworzenie pojęć",
          "id": 12,
          "rows": {
            "1": {
              "min": 0,
              "max": 4
            },
            "2": {
              "min": 5,
              "max": 5
            },
            "3": {
              "min": 6,
              "max": 6
            },
            "4": {
              "min": 7,
              "max": 8
            },
            "5": {
              "min": 9,
              "max": 9
            },
            "6": {
              "min": 10,
              "max": 10
            },
            "7": {
              "min": 11,
              "max": 11
            },
            "8": {
              "min": 12,
              "max": 12
            },
            "9": {
              "min": 13,
              "max": 13
            },
            "10": {
              "min": 14,
              "max": 15
            },
            "11": {
              "min": 16,
              "max": 16
            },
            "12": {
              "min": 17,
              "max": 17
            },
            "13": {
              "min": 18,
              "max": 18
            },
            "14": {
              "min": 19,
              "max": 19
            },
            "15": {
              "min": 20,
              "max": 20
            },
            "16": {
              "min": 21,
              "max": 21
            },
            "17": {
              "min": 22,
              "max": 22
            },
            "18": {
              "min": null,
              "max": null
            },
            "19": {
              "min": 23,
              "max": 27
            }
          }
        },
        {
          "name": "Porządkowanie liter i liczb",
          "id": 13,
          "rows": {
            "1": {
              "min": 0,
              "max": 5
            },
            "2": {
              "min": 6,
              "max": 7
            },
            "3": {
              "min": 8,
              "max": 8
            },
            "4": {
              "min": 9,
              "max": 10
            },
            "5": {
              "min": 11,
              "max": 12
            },
            "6": {
              "min": 13,
              "max": 13
            },
            "7": {
              "min": 14,
              "max": 14
            },
            "8": {
              "min": 15,
              "max": 15
            },
            "9": {
              "min": 16,
              "max": 16
            },
            "10": {
              "min": 17,
              "max": 17
            },
            "11": {
              "min": 18,
              "max": 18
            },
            "12": {
              "min": null,
              "max": null
            },
            "13": {
              "min": 19,
              "max": 19
            },
            "14": {
              "min": 20,
              "max": 20
            },
            "15": {
              "min": 21,
              "max": 21
            },
            "16": {
              "min": null,
              "max": null
            },
            "17": {
              "min": 22,
              "max": 22
            },
            "18": {
              "min": null,
              "max": null
            },
            "19": {
              "min": 23,
              "max": 30
            }
          }
        },
        {
          "name": "Wykreślanie",
          "id": 14,
          "rows": {
            "1": {
              "min": 0,
              "max": 25
            },
            "2": {
              "min": 26,
              "max": 30
            },
            "3": {
              "min": 31,
              "max": 34
            },
            "4": {
              "min": 35,
              "max": 39
            },
            "5": {
              "min": 40,
              "max": 44
            },
            "6": {
              "min": 45,
              "max": 49
            },
            "7": {
              "min": 50,
              "max": 55
            },
            "8": {
              "min": 56,
              "max": 60
            },
            "9": {
              "min": 61,
              "max": 66
            },
            "10": {
              "min": 67,
              "max": 72
            },
            "11": {
              "min": 73,
              "max": 78
            },
            "12": {
              "min": 79,
              "max": 84
            },
            "13": {
              "min": 85,
              "max": 90
            },
            "14": {
              "min": 91,
              "max": 96
            },
            "15": {
              "min": 97,
              "max": 103
            },
            "16": {
              "min": 104,
              "max": 109
            },
            "17": {
              "min": 110,
              "max": 115
            },
            "18": {
              "min": 116,
              "max": 121
            },
            "19": {
              "min": 122,
              "max": 128
            }
          }
        },
        {
          "name": "Rozumienie",
          "id": 15,
          "rows": {
            "1": {
              "min": 0,
              "max": 6
            },
            "2": {
              "min": 7,
              "max": 7
            },
            "3": {
              "min": 8,
              "max": 8
            },
            "4": {
              "min": 9,
              "max": 10
            },
            "5": {
              "min": 11,
              "max": 11
            },
            "6": {
              "min": 12,
              "max": 13
            },
            "7": {
              "min": 14,
              "max": 15
            },
            "8": {
              "min": 16,
              "max": 16
            },
            "9": {
              "min": 17,
              "max": 18
            },
            "10": {
              "min": 19,
              "max": 20
            },
            "11": {
              "min": 21,
              "max": 21
            },
            "12": {
              "min": 22,
              "max": 23
            },
            "13": {
              "min": 24,
              "max": 25
            },
            "14": {
              "min": 26,
              "max": 27
            },
            "15": {
              "min": 28,
              "max": 29
            },
            "16": {
              "min": 30,
              "max": 30
            },
            "17": {
              "min": 31,
              "max": 32
            },
            "18": {
              "min": 33,
              "max": 34
            },
            "19": {
              "min": 35,
              "max": 38
            }
          }
        },
        {
          "name": "Arytmetyka",
          "id": 16,
          "rows": {
            "1": {
              "min": 0,
              "max": 5
            },
            "2": {
              "min": 6,
              "max": 7
            },
            "3": {
              "min": 8,
              "max": 9
            },
            "4": {
              "min": 10,
              "max": 12
            },
            "5": {
              "min": 13,
              "max": 14
            },
            "6": {
              "min": 15,
              "max": 15
            },
            "7": {
              "min": 16,
              "max": 17
            },
            "8": {
              "min": 18,
              "max": 19
            },
            "9": {
              "min": 20,
              "max": 20
            },
            "10": {
              "min": 21,
              "max": 22
            },
            "11": {
              "min": 23,
              "max": 23
            },
            "12": {
              "min": 24,
              "max": 24
            },
            "13": {
              "min": 25,
              "max": 26
            },
            "14": {
              "min": 27,
              "max": 27
            },
            "15": {
              "min": 28,
              "max": 28
            },
            "16": {
              "min": 29,
              "max": 29
            },
            "17": {
              "min": 30,
              "max": 30
            },
            "18": {
              "min": 31,
              "max": 32
            },
            "19": {
              "min": 33,
              "max": 34
            }
          }
        }
      ],  
     
    120123: [
        {
          "name": "Wzory z klocków",
          "id": 1,
          "rows": {
            "1": {
              "min": 0,
              "max": 6
            },
            "2": {
              "min": 7,
              "max": 10
            },
            "3": {
              "min": 11,
              "max": 13
            },
            "4": {
              "min": 14,
              "max": 16
            },
            "5": {
              "min": 17,
              "max": 19
            },
            "6": {
              "min": 20,
              "max": 21
            },
            "7": {
              "min": 22,
              "max": 24
            },
            "8": {
              "min": 25,
              "max": 27
            },
            "9": {
              "min": 28,
              "max": 30
            },
            "10": {
              "min": 31,
              "max": 33
            },
            "11": {
              "min": 34,
              "max": 36
            },
            "12": {
              "min": 37,
              "max": 39
            },
            "13": {
              "min": 40,
              "max": 43
            },
            "14": {
              "min": 44,
              "max": 45
            },
            "15": {
              "min": 46,
              "max": 48
            },
            "16": {
              "min": 49,
              "max": 51
            },
            "17": {
              "min": 52,
              "max": 54
            },
            "18": {
              "min": 55,
              "max": 56
            },
            "19": {
              "min": 57,
              "max": 58
            }
          }
        },
        {
          "name": "Podobieństwa",
          "id": 2,
          "rows": {
            "1": {
              "min": 0,
              "max": 10
            },
            "2": {
              "min": 11,
              "max": 13
            },
            "3": {
              "min": 14,
              "max": 15
            },
            "4": {
              "min": 16,
              "max": 18
            },
            "5": {
              "min": 19,
              "max": 20
            },
            "6": {
              "min": 21,
              "max": 22
            },
            "7": {
              "min": 23,
              "max": 23
            },
            "8": {
              "min": 24,
              "max": 25
            },
            "9": {
              "min": 26,
              "max": 27
            },
            "10": {
              "min": 28,
              "max": 29
            },
            "11": {
              "min": 30,
              "max": 30
            },
            "12": {
              "min": 31,
              "max": 32
            },
            "13": {
              "min": 33,
              "max": 33
            },
            "14": {
              "min": 34,
              "max": 35
            },
            "15": {
              "min": 36,
              "max": 36
            },
            "16": {
              "min": 37,
              "max": 38
            },
            "17": {
              "min": 39,
              "max": 39
            },
            "18": {
              "min": 40,
              "max": 40
            },
            "19": {
              "min": 41,
              "max": 46
            }
          }
        },
        {
          "name": "Matryce",
          "id": 3,
          "rows": {
            "1": {
              "min": 0,
              "max": 6
            },
            "2": {
              "min": 7,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 11
            },
            "5": {
              "min": 12,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 15
            },
            "8": {
              "min": 16,
              "max": 17
            },
            "9": {
              "min": 18,
              "max": 18
            },
            "10": {
              "min": 19,
              "max": 19
            },
            "11": {
              "min": 20,
              "max": 21
            },
            "12": {
              "min": 22,
              "max": 22
            },
            "13": {
              "min": 23,
              "max": 23
            },
            "14": {
              "min": 24,
              "max": 25
            },
            "15": {
              "min": 26,
              "max": 26
            },
            "16": {
              "min": 27,
              "max": 27
            },
            "17": {
              "min": 28,
              "max": 28
            },
            "18": {
              "min": 29,
              "max": 29
            },
            "19": {
              "min": 30,
              "max": 32
            }
          }
        },
        {
          "name": "Powtarzanie liczb",
          "id": 4,
          "rows": {
            "1": {
              "min": 0,
              "max": 9
            },
            "2": {
              "min": 10,
              "max": 11
            },
            "3": {
              "min": 12,
              "max": 12
            },
            "4": {
              "min": 13,
              "max": 14
            },
            "5": {
              "min": 15,
              "max": 16
            },
            "6": {
              "min": 17,
              "max": 18
            },
            "7": {
              "min": 19,
              "max": 20
            },
            "8": {
              "min": 21,
              "max": 21
            },
            "9": {
              "min": 22,
              "max": 23
            },
            "10": {
              "min": 24,
              "max": 25
            },
            "11": {
              "min": 26,
              "max": 26
            },
            "12": {
              "min": 27,
              "max": 28
            },
            "13": {
              "min": 29,
              "max": 30
            },
            "14": {
              "min": 31,
              "max": 31
            },
            "15": {
              "min": 32,
              "max": 33
            },
            "16": {
              "min": 34,
              "max": 34
            },
            "17": {
              "min": 35,
              "max": 36
            },
            "18": {
              "min": 37,
              "max": 38
            },
            "19": {
              "min": 39,
              "max": 54
            }
          }
        },
        {
          "name": "Kodowanie",
          "id": 5,
          "rows": {
            "1": {
              "min": 0,
              "max": 14
            },
            "2": {
              "min": 15,
              "max": 19
            },
            "3": {
              "min": 20,
              "max": 23
            },
            "4": {
              "min": 24,
              "max": 28
            },
            "5": {
              "min": 29,
              "max": 32
            },
            "6": {
              "min": 33,
              "max": 36
            },
            "7": {
              "min": 37,
              "max": 41
            },
            "8": {
              "min": 42,
              "max": 45
            },
            "9": {
              "min": 46,
              "max": 49
            },
            "10": {
              "min": 50,
              "max": 53
            },
            "11": {
              "min": 54,
              "max": 58
            },
            "12": {
              "min": 59,
              "max": 62
            },
            "13": {
              "min": 63,
              "max": 66
            },
            "14": {
              "min": 67,
              "max": 70
            },
            "15": {
              "min": 71,
              "max": 74
            },
            "16": {
              "min": 75,
              "max": 79
            },
            "17": {
              "min": 80,
              "max": 83
            },
            "18": {
              "min": 84,
              "max": 87
            },
            "19": {
              "min": 88,
              "max": 117
            }
          }
        },
        {
          "name": "Słownik",
          "id": 6,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 14
            },
            "5": {
              "min": 15,
              "max": 16
            },
            "6": {
              "min": 17,
              "max": 19
            },
            "7": {
              "min": 20,
              "max": 21
            },
            "8": {
              "min": 22,
              "max": 24
            },
            "9": {
              "min": 25,
              "max": 27
            },
            "10": {
              "min": 28,
              "max": 29
            },
            "11": {
              "min": 30,
              "max": 32
            },
            "12": {
              "min": 33,
              "max": 35
            },
            "13": {
              "min": 36,
              "max": 38
            },
            "14": {
              "min": 39,
              "max": 41
            },
            "15": {
              "min": 42,
              "max": 43
            },
            "16": {
              "min": 44,
              "max": 46
            },
            "17": {
              "min": 47,
              "max": 49
            },
            "18": {
              "min": 50,
              "max": 51
            },
            "19": {
              "min": 52,
              "max": 54
            }
          }
        },
        {
          "name": "Ważenie",
          "id": 7,
          "rows": {
            "1": {
              "min": 0,
              "max": 5
            },
            "2": {
              "min": 6,
              "max": 7
            },
            "3": {
              "min": 8,
              "max": 9
            },
            "4": {
              "min": 10,
              "max": 12
            },
            "5": {
              "min": 13,
              "max": 14
            },
            "6": {
              "min": 15,
              "max": 16
            },
            "7": {
              "min": 17,
              "max": 18
            },
            "8": {
              "min": 19,
              "max": 20
            },
            "9": {
              "min": 21,
              "max": 22
            },
            "10": {
              "min": 23,
              "max": 24
            },
            "11": {
              "min": 25,
              "max": 25
            },
            "12": {
              "min": 26,
              "max": 26
            },
            "13": {
              "min": 27,
              "max": 27
            },
            "14": {
              "min": 28,
              "max": 28
            },
            "15": {
              "min": 29,
              "max": 29
            },
            "16": {
              "min": 30,
              "max": 30
            },
            "17": {
              "min": 31,
              "max": 31
            },
            "18": {
              "min": null,
              "max": null
            },
            "19": {
              "min": 32,
              "max": 34
            }
          }
        },
        {
          "name": "Układnie wzoru",
          "id": 8,
          "rows": {
            "1": {
              "min": 0,
              "max": 2
            },
            "2": {
              "min": 3,
              "max": 4
            },
            "3": {
              "min": 5,
              "max": 6
            },
            "4": {
              "min": 7,
              "max": 8
            },
            "5": {
              "min": 9,
              "max": 10
            },
            "6": {
              "min": 11,
              "max": 12
            },
            "7": {
              "min": 13,
              "max": 14
            },
            "8": {
              "min": 15,
              "max": 15
            },
            "9": {
              "min": 16,
              "max": 17
            },
            "10": {
              "min": 18,
              "max": 18
            },
            "11": {
              "min": 19,
              "max": 20
            },
            "12": {
              "min": 21,
              "max": 21
            },
            "13": {
              "min": 22,
              "max": 22
            },
            "14": {
              "min": 23,
              "max": 23
            },
            "15": {
              "min": 24,
              "max": 24
            },
            "16": {
              "min": 25,
              "max": 25
            },
            "17": {
              "min": null,
              "max": null
            },
            "18": {
              "min": 26,
              "max": 26
            },
            "19": {
              "min": 27,
              "max": 29
            }
          }
        },
        {
          "name": "Zapamiętywanie obrazków",
          "id": 9,
          "rows": {
            "1": {
              "min": 0,
              "max": 6
            },
            "2": {
              "min": 7,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 1314
            },
            "5": {
              "min": 1315,
              "max": 17
            },
            "6": {
              "min": 18,
              "max": 20
            },
            "7": {
              "min": 21,
              "max": 22
            },
            "8": {
              "min": 23,
              "max": 25
            },
            "9": {
              "min": 26,
              "max": 27
            },
            "10": {
              "min": 28,
              "max": 30
            },
            "11": {
              "min": 31,
              "max": 32
            },
            "12": {
              "min": 33,
              "max": 34
            },
            "13": {
              "min": 35,
              "max": 37
            },
            "14": {
              "min": 38,
              "max": 39
            },
            "15": {
              "min": 40,
              "max": 41
            },
            "16": {
              "min": 42,
              "max": 44
            },
            "17": {
              "min": 45,
              "max": 45
            },
            "18": {
              "min": 46,
              "max": 47
            },
            "19": {
              "min": 48,
              "max": 49
            }
          }
        },
        {
          "name": "Szukanie symboli",
          "id": 10,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 12
            },
            "4": {
              "min": 13,
              "max": 15
            },
            "5": {
              "min": 16,
              "max": 17
            },
            "6": {
              "min": 18,
              "max": 20
            },
            "7": {
              "min": 21,
              "max": 22
            },
            "8": {
              "min": 23,
              "max": 25
            },
            "9": {
              "min": 26,
              "max": 27
            },
            "10": {
              "min": 28,
              "max": 29
            },
            "11": {
              "min": 30,
              "max": 32
            },
            "12": {
              "min": 33,
              "max": 34
            },
            "13": {
              "min": 35,
              "max": 36
            },
            "14": {
              "min": 37,
              "max": 38
            },
            "15": {
              "min": 39,
              "max": 41
            },
            "16": {
              "min": 42,
              "max": 43
            },
            "17": {
              "min": 44,
              "max": 45
            },
            "18": {
              "min": 46,
              "max": 47
            },
            "19": {
              "min": 48,
              "max": 60
            }
          }
        },
        {
          "name": "Wiadomości",
          "id": 11,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 9
            },
            "4": {
              "min": 10,
              "max": 10
            },
            "5": {
              "min": 11,
              "max": 11
            },
            "6": {
              "min": 12,
              "max": 12
            },
            "7": {
              "min": 13,
              "max": 14
            },
            "8": {
              "min": 15,
              "max": 15
            },
            "9": {
              "min": 16,
              "max": 16
            },
            "10": {
              "min": 17,
              "max": 17
            },
            "11": {
              "min": 18,
              "max": 18
            },
            "12": {
              "min": 19,
              "max": 20
            },
            "13": {
              "min": 21,
              "max": 21
            },
            "14": {
              "min": 22,
              "max": 22
            },
            "15": {
              "min": 23,
              "max": 24
            },
            "16": {
              "min": 25,
              "max": 25
            },
            "17": {
              "min": 26,
              "max": 26
            },
            "18": {
              "min": 27,
              "max": 28
            },
            "19": {
              "min": 29,
              "max": 31
            }
          }
        },
        {
          "name": "Tworzenie pojęć",
          "id": 12,
          "rows": {
            "1": {
              "min": 0,
              "max": 4
            },
            "2": {
              "min": 5,
              "max": 5
            },
            "3": {
              "min": 6,
              "max": 7
            },
            "4": {
              "min": 8,
              "max": 8
            },
            "5": {
              "min": 9,
              "max": 9
            },
            "6": {
              "min": 10,
              "max": 10
            },
            "7": {
              "min": 11,
              "max": 12
            },
            "8": {
              "min": 13,
              "max": 13
            },
            "9": {
              "min": 14,
              "max": 14
            },
            "10": {
              "min": 15,
              "max": 15
            },
            "11": {
              "min": 16,
              "max": 16
            },
            "12": {
              "min": 17,
              "max": 17
            },
            "13": {
              "min": 18,
              "max": 18
            },
            "14": {
              "min": 19,
              "max": 19
            },
            "15": {
              "min": 20,
              "max": 20
            },
            "16": {
              "min": 21,
              "max": 21
            },
            "17": {
              "min": 22,
              "max": 22
            },
            "18": {
              "min": 23,
              "max": 23
            },
            "19": {
              "min": 24,
              "max": 27
            }
          }
        },
        {
          "name": "Porządkowanie liter i liczb",
          "id": 13,
          "rows": {
            "1": {
              "min": 0,
              "max": 5
            },
            "2": {
              "min": 6,
              "max": 7
            },
            "3": {
              "min": 8,
              "max": 9
            },
            "4": {
              "min": 10,
              "max": 10
            },
            "5": {
              "min": 11,
              "max": 12
            },
            "6": {
              "min": 13,
              "max": 13
            },
            "7": {
              "min": 14,
              "max": 14
            },
            "8": {
              "min": 15,
              "max": 15
            },
            "9": {
              "min": 16,
              "max": 16
            },
            "10": {
              "min": 17,
              "max": 17
            },
            "11": {
              "min": 18,
              "max": 18
            },
            "12": {
              "min": 19,
              "max": 19
            },
            "13": {
              "min": null,
              "max": null
            },
            "14": {
              "min": 20,
              "max": 20
            },
            "15": {
              "min": 21,
              "max": 21
            },
            "16": {
              "min": null,
              "max": null
            },
            "17": {
              "min": 22,
              "max": 22
            },
            "18": {
              "min": null,
              "max": null
            },
            "19": {
              "min": 23,
              "max": 30
            }
          }
        },
        {
          "name": "Wykreślanie",
          "id": 14,
          "rows": {
            "1": {
              "min": 0,
              "max": 25
            },
            "2": {
              "min": 26,
              "max": 30
            },
            "3": {
              "min": 31,
              "max": 35
            },
            "4": {
              "min": 36,
              "max": 40
            },
            "5": {
              "min": 41,
              "max": 45
            },
            "6": {
              "min": 46,
              "max": 51
            },
            "7": {
              "min": 52,
              "max": 56
            },
            "8": {
              "min": 57,
              "max": 62
            },
            "9": {
              "min": 63,
              "max": 58
            },
            "10": {
              "min": 59,
              "max": 73
            },
            "11": {
              "min": 74,
              "max": 79
            },
            "12": {
              "min": 80,
              "max": 85
            },
            "13": {
              "min": 86,
              "max": 92
            },
            "14": {
              "min": 93,
              "max": 98
            },
            "15": {
              "min": 99,
              "max": 104
            },
            "16": {
              "min": 105,
              "max": 110
            },
            "17": {
              "min": 111,
              "max": 116
            },
            "18": {
              "min": 117,
              "max": 121
            },
            "19": {
              "min": 122,
              "max": 128
            }
          }
        },
        {
          "name": "Rozumienie",
          "id": 15,
          "rows": {
            "1": {
              "min": 0,
              "max": 6
            },
            "2": {
              "min": 7,
              "max": 7
            },
            "3": {
              "min": 8,
              "max": 9
            },
            "4": {
              "min": 10,
              "max": 10
            },
            "5": {
              "min": 11,
              "max": 12
            },
            "6": {
              "min": 13,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 15
            },
            "8": {
              "min": 16,
              "max": 17
            },
            "9": {
              "min": 18,
              "max": 18
            },
            "10": {
              "min": 19,
              "max": 20
            },
            "11": {
              "min": 21,
              "max": 22
            },
            "12": {
              "min": 23,
              "max": 24
            },
            "13": {
              "min": 25,
              "max": 25
            },
            "14": {
              "min": 26,
              "max": 27
            },
            "15": {
              "min": 28,
              "max": 29
            },
            "16": {
              "min": 30,
              "max": 31
            },
            "17": {
              "min": 32,
              "max": 33
            },
            "18": {
              "min": 34,
              "max": 34
            },
            "19": {
              "min": 35,
              "max": 38
            }
          }
        },
        {
          "name": "Arytmetyka",
          "id": 16,
          "rows": {
            "1": {
              "min": 0,
              "max": 5
            },
            "2": {
              "min": 6,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 12
            },
            "5": {
              "min": 13,
              "max": 14
            },
            "6": {
              "min": 15,
              "max": 16
            },
            "7": {
              "min": 17,
              "max": 18
            },
            "8": {
              "min": 19,
              "max": 19
            },
            "9": {
              "min": 20,
              "max": 21
            },
            "10": {
              "min": 22,
              "max": 22
            },
            "11": {
              "min": 23,
              "max": 24
            },
            "12": {
              "min": 25,
              "max": 25
            },
            "13": {
              "min": 26,
              "max": 26
            },
            "14": {
              "min": 27,
              "max": 27
            },
            "15": {
              "min": 28,
              "max": 29
            },
            "16": {
              "min": 30,
              "max": 30
            },
            "17": {
              "min": 31,
              "max": 31
            },
            "18": {
              "min": 32,
              "max": 32
            },
            "19": {
              "min": 33,
              "max": 34
            }
          }
        }
      ],
      
    124127: [
        {
          "name": "Wzory z klocków",
          "id": 1,
          "rows": {
            "1": {
              "min": 0,
              "max": 6
            },
            "2": {
              "min": 7,
              "max": 10
            },
            "3": {
              "min": 11,
              "max": 13
            },
            "4": {
              "min": 14,
              "max": 16
            },
            "5": {
              "min": 17,
              "max": 19
            },
            "6": {
              "min": 20,
              "max": 22
            },
            "7": {
              "min": 23,
              "max": 25
            },
            "8": {
              "min": 26,
              "max": 28
            },
            "9": {
              "min": 29,
              "max": 31
            },
            "10": {
              "min": 32,
              "max": 34
            },
            "11": {
              "min": 35,
              "max": 37
            },
            "12": {
              "min": 38,
              "max": 40
            },
            "13": {
              "min": 41,
              "max": 43
            },
            "14": {
              "min": 44,
              "max": 46
            },
            "15": {
              "min": 47,
              "max": 49
            },
            "16": {
              "min": 50,
              "max": 52
            },
            "17": {
              "min": 53,
              "max": 54
            },
            "18": {
              "min": 55,
              "max": 56
            },
            "19": {
              "min": 57,
              "max": 58
            }
          }
        },
        {
          "name": "Podobieństwa",
          "id": 2,
          "rows": {
            "1": {
              "min": 0,
              "max": 11
            },
            "2": {
              "min": 12,
              "max": 13
            },
            "3": {
              "min": 14,
              "max": 16
            },
            "4": {
              "min": 17,
              "max": 18
            },
            "5": {
              "min": 19,
              "max": 20
            },
            "6": {
              "min": 21,
              "max": 22
            },
            "7": {
              "min": 23,
              "max": 24
            },
            "8": {
              "min": 25,
              "max": 26
            },
            "9": {
              "min": 27,
              "max": 27
            },
            "10": {
              "min": 28,
              "max": 29
            },
            "11": {
              "min": 30,
              "max": 31
            },
            "12": {
              "min": 32,
              "max": 32
            },
            "13": {
              "min": 33,
              "max": 34
            },
            "14": {
              "min": 35,
              "max": 35
            },
            "15": {
              "min": 36,
              "max": 37
            },
            "16": {
              "min": 38,
              "max": 38
            },
            "17": {
              "min": 39,
              "max": 40
            },
            "18": {
              "min": 41,
              "max": 41
            },
            "19": {
              "min": 42,
              "max": 46
            }
          }
        },
        {
          "name": "Matryce",
          "id": 3,
          "rows": {
            "1": {
              "min": 0,
              "max": 6
            },
            "2": {
              "min": 7,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 11
            },
            "5": {
              "min": 12,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 16
            },
            "8": {
              "min": 17,
              "max": 17
            },
            "9": {
              "min": 18,
              "max": 18
            },
            "10": {
              "min": 19,
              "max": 20
            },
            "11": {
              "min": 21,
              "max": 21
            },
            "12": {
              "min": 22,
              "max": 22
            },
            "13": {
              "min": 23,
              "max": 24
            },
            "14": {
              "min": 25,
              "max": 25
            },
            "15": {
              "min": 26,
              "max": 26
            },
            "16": {
              "min": 27,
              "max": 27
            },
            "17": {
              "min": 28,
              "max": 28
            },
            "18": {
              "min": 29,
              "max": 29
            },
            "19": {
              "min": 30,
              "max": 32
            }
          }
        },
        {
          "name": "Powtarzanie liczb",
          "id": 4,
          "rows": {
            "1": {
              "min": 0,
              "max": 9
            },
            "2": {
              "min": 10,
              "max": 11
            },
            "3": {
              "min": 12,
              "max": 13
            },
            "4": {
              "min": 14,
              "max": 15
            },
            "5": {
              "min": 16,
              "max": 16
            },
            "6": {
              "min": 17,
              "max": 18
            },
            "7": {
              "min": 19,
              "max": 20
            },
            "8": {
              "min": 21,
              "max": 22
            },
            "9": {
              "min": 23,
              "max": 23
            },
            "10": {
              "min": 24,
              "max": 25
            },
            "11": {
              "min": 26,
              "max": 27
            },
            "12": {
              "min": 28,
              "max": 28
            },
            "13": {
              "min": 29,
              "max": 30
            },
            "14": {
              "min": 31,
              "max": 32
            },
            "15": {
              "min": 33,
              "max": 33
            },
            "16": {
              "min": 34,
              "max": 35
            },
            "17": {
              "min": 36,
              "max": 36
            },
            "18": {
              "min": 37,
              "max": 38
            },
            "19": {
              "min": 39,
              "max": 54
            }
          }
        },
        {
          "name": "Kodowanie",
          "id": 5,
          "rows": {
            "1": {
              "min": 0,
              "max": 15
            },
            "2": {
              "min": 16,
              "max": 20
            },
            "3": {
              "min": 21,
              "max": 24
            },
            "4": {
              "min": 25,
              "max": 29
            },
            "5": {
              "min": 30,
              "max": 33
            },
            "6": {
              "min": 34,
              "max": 38
            },
            "7": {
              "min": 39,
              "max": 42
            },
            "8": {
              "min": 43,
              "max": 46
            },
            "9": {
              "min": 47,
              "max": 51
            },
            "10": {
              "min": 52,
              "max": 55
            },
            "11": {
              "min": 56,
              "max": 59
            },
            "12": {
              "min": 60,
              "max": 64
            },
            "13": {
              "min": 65,
              "max": 68
            },
            "14": {
              "min": 69,
              "max": 72
            },
            "15": {
              "min": 73,
              "max": 76
            },
            "16": {
              "min": 77,
              "max": 81
            },
            "17": {
              "min": 82,
              "max": 85
            },
            "18": {
              "min": 86,
              "max": 89
            },
            "19": {
              "min": 90,
              "max": 117
            }
          }
        },
        {
          "name": "Słownik",
          "id": 6,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 14
            },
            "5": {
              "min": 15,
              "max": 17
            },
            "6": {
              "min": 18,
              "max": 19
            },
            "7": {
              "min": 20,
              "max": 22
            },
            "8": {
              "min": 23,
              "max": 25
            },
            "9": {
              "min": 26,
              "max": 28
            },
            "10": {
              "min": 29,
              "max": 30
            },
            "11": {
              "min": 31,
              "max": 33
            },
            "12": {
              "min": 34,
              "max": 36
            },
            "13": {
              "min": 37,
              "max": 39
            },
            "14": {
              "min": 40,
              "max": 42
            },
            "15": {
              "min": 43,
              "max": 44
            },
            "16": {
              "min": 45,
              "max": 47
            },
            "17": {
              "min": 48,
              "max": 49
            },
            "18": {
              "min": 50,
              "max": 51
            },
            "19": {
              "min": 52,
              "max": 54
            }
          }
        },
        {
          "name": "Ważenie",
          "id": 7,
          "rows": {
            "1": {
              "min": 0,
              "max": 6
            },
            "2": {
              "min": 7,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 12
            },
            "5": {
              "min": 13,
              "max": 14
            },
            "6": {
              "min": 15,
              "max": 17
            },
            "7": {
              "min": 18,
              "max": 19
            },
            "8": {
              "min": 20,
              "max": 21
            },
            "9": {
              "min": 22,
              "max": 22
            },
            "10": {
              "min": 23,
              "max": 24
            },
            "11": {
              "min": 25,
              "max": 25
            },
            "12": {
              "min": 26,
              "max": 26
            },
            "13": {
              "min": 27,
              "max": 27
            },
            "14": {
              "min": 28,
              "max": 28
            },
            "15": {
              "min": 29,
              "max": 29
            },
            "16": {
              "min": 30,
              "max": 30
            },
            "17": {
              "min": 31,
              "max": 31
            },
            "18": {
              "min": null,
              "max": null
            },
            "19": {
              "min": 32,
              "max": 34
            }
          }
        },
        {
          "name": "Układnie wzoru",
          "id": 8,
          "rows": {
            "1": {
              "min": 0,
              "max": 2
            },
            "2": {
              "min": 3,
              "max": 4
            },
            "3": {
              "min": 5,
              "max": 6
            },
            "4": {
              "min": 7,
              "max": 8
            },
            "5": {
              "min": 9,
              "max": 10
            },
            "6": {
              "min": 11,
              "max": 12
            },
            "7": {
              "min": 13,
              "max": 14
            },
            "8": {
              "min": 15,
              "max": 16
            },
            "9": {
              "min": 17,
              "max": 17
            },
            "10": {
              "min": 18,
              "max": 19
            },
            "11": {
              "min": 20,
              "max": 20
            },
            "12": {
              "min": 21,
              "max": 21
            },
            "13": {
              "min": 22,
              "max": 22
            },
            "14": {
              "min": 23,
              "max": 23
            },
            "15": {
              "min": 24,
              "max": 24
            },
            "16": {
              "min": 25,
              "max": 25
            },
            "17": {
              "min": 26,
              "max": 26
            },
            "18": {
              "min": null,
              "max": null
            },
            "19": {
              "min": 27,
              "max": 29
            }
          }
        },
        {
          "name": "Zapamiętywanie obrazków",
          "id": 9,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 12
            },
            "4": {
              "min": 13,
              "max": 15
            },
            "5": {
              "min": 16,
              "max": 17
            },
            "6": {
              "min": 18,
              "max": 20
            },
            "7": {
              "min": 21,
              "max": 22
            },
            "8": {
              "min": 23,
              "max": 25
            },
            "9": {
              "min": 26,
              "max": 27
            },
            "10": {
              "min": 28,
              "max": 30
            },
            "11": {
              "min": 31,
              "max": 32
            },
            "12": {
              "min": 33,
              "max": 35
            },
            "13": {
              "min": 36,
              "max": 37
            },
            "14": {
              "min": 38,
              "max": 39
            },
            "15": {
              "min": 40,
              "max": 42
            },
            "16": {
              "min": 43,
              "max": 44
            },
            "17": {
              "min": 45,
              "max": 46
            },
            "18": {
              "min": 47,
              "max": 47
            },
            "19": {
              "min": 48,
              "max": 49
            }
          }
        },
        {
          "name": "Szukanie symboli",
          "id": 10,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 10
            },
            "3": {
              "min": 11,
              "max": 13
            },
            "4": {
              "min": 14,
              "max": 15
            },
            "5": {
              "min": 16,
              "max": 18
            },
            "6": {
              "min": 19,
              "max": 20
            },
            "7": {
              "min": 21,
              "max": 23
            },
            "8": {
              "min": 24,
              "max": 25
            },
            "9": {
              "min": 26,
              "max": 28
            },
            "10": {
              "min": 29,
              "max": 30
            },
            "11": {
              "min": 31,
              "max": 32
            },
            "12": {
              "min": 33,
              "max": 35
            },
            "13": {
              "min": 36,
              "max": 37
            },
            "14": {
              "min": 38,
              "max": 39
            },
            "15": {
              "min": 40,
              "max": 41
            },
            "16": {
              "min": 42,
              "max": 44
            },
            "17": {
              "min": 45,
              "max": 46
            },
            "18": {
              "min": 47,
              "max": 48
            },
            "19": {
              "min": 49,
              "max": 60
            }
          }
        },
        {
          "name": "Wiadomości",
          "id": 11,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 9
            },
            "4": {
              "min": 10,
              "max": 11
            },
            "5": {
              "min": 12,
              "max": 12
            },
            "6": {
              "min": 13,
              "max": 13
            },
            "7": {
              "min": 14,
              "max": 14
            },
            "8": {
              "min": 15,
              "max": 15
            },
            "9": {
              "min": 16,
              "max": 16
            },
            "10": {
              "min": 17,
              "max": 18
            },
            "11": {
              "min": 19,
              "max": 19
            },
            "12": {
              "min": 20,
              "max": 20
            },
            "13": {
              "min": 21,
              "max": 21
            },
            "14": {
              "min": 22,
              "max": 23
            },
            "15": {
              "min": 24,
              "max": 24
            },
            "16": {
              "min": 25,
              "max": 26
            },
            "17": {
              "min": 27,
              "max": 27
            },
            "18": {
              "min": 28,
              "max": 28
            },
            "19": {
              "min": 29,
              "max": 31
            }
          }
        },
        {
          "name": "Tworzenie pojęć",
          "id": 12,
          "rows": {
            "1": {
              "min": 0,
              "max": 4
            },
            "2": {
              "min": 5,
              "max": 6
            },
            "3": {
              "min": 7,
              "max": 7
            },
            "4": {
              "min": 8,
              "max": 8
            },
            "5": {
              "min": 9,
              "max": 9
            },
            "6": {
              "min": 10,
              "max": 11
            },
            "7": {
              "min": 12,
              "max": 12
            },
            "8": {
              "min": 13,
              "max": 13
            },
            "9": {
              "min": 14,
              "max": 14
            },
            "10": {
              "min": 15,
              "max": 15
            },
            "11": {
              "min": 16,
              "max": 16
            },
            "12": {
              "min": 17,
              "max": 17
            },
            "13": {
              "min": 18,
              "max": 18
            },
            "14": {
              "min": 19,
              "max": 19
            },
            "15": {
              "min": 20,
              "max": 20
            },
            "16": {
              "min": 21,
              "max": 21
            },
            "17": {
              "min": 22,
              "max": 22
            },
            "18": {
              "min": 23,
              "max": 23
            },
            "19": {
              "min": 24,
              "max": 27
            }
          }
        },
        {
          "name": "Porządkowanie liter i liczb",
          "id": 13,
          "rows": {
            "1": {
              "min": 0,
              "max": 5
            },
            "2": {
              "min": 6,
              "max": 7
            },
            "3": {
              "min": 8,
              "max": 9
            },
            "4": {
              "min": 10,
              "max": 11
            },
            "5": {
              "min": 12,
              "max": 12
            },
            "6": {
              "min": 13,
              "max": 13
            },
            "7": {
              "min": 14,
              "max": 14
            },
            "8": {
              "min": 15,
              "max": 15
            },
            "9": {
              "min": 16,
              "max": 16
            },
            "10": {
              "min": 17,
              "max": 17
            },
            "11": {
              "min": 18,
              "max": 18
            },
            "12": {
              "min": 19,
              "max": 19
            },
            "13": {
              "min": 20,
              "max": 20
            },
            "14": {
              "min": null,
              "max": null
            },
            "15": {
              "min": 21,
              "max": 21
            },
            "16": {
              "min": null,
              "max": null
            },
            "17": {
              "min": 22,
              "max": 22
            },
            "18": {
              "min": 23,
              "max": 23
            },
            "19": {
              "min": 24,
              "max": 30
            }
          }
        },
        {
          "name": "Wykreślanie",
          "id": 14,
          "rows": {
            "1": {
              "min": 0,
              "max": 25
            },
            "2": {
              "min": 26,
              "max": 30
            },
            "3": {
              "min": 31,
              "max": 35
            },
            "4": {
              "min": 36,
              "max": 41
            },
            "5": {
              "min": 42,
              "max": 46
            },
            "6": {
              "min": 47,
              "max": 52
            },
            "7": {
              "min": 53,
              "max": 58
            },
            "8": {
              "min": 59,
              "max": 63
            },
            "9": {
              "min": 64,
              "max": 69
            },
            "10": {
              "min": 70,
              "max": 75
            },
            "11": {
              "min": 76,
              "max": 81
            },
            "12": {
              "min": 82,
              "max": 87
            },
            "13": {
              "min": 88,
              "max": 93
            },
            "14": {
              "min": 94,
              "max": 99
            },
            "15": {
              "min": 100,
              "max": 105
            },
            "16": {
              "min": 106,
              "max": 111
            },
            "17": {
              "min": 112,
              "max": 117
            },
            "18": {
              "min": 118,
              "max": 122
            },
            "19": {
              "min": 123,
              "max": 128
            }
          }
        },
        {
          "name": "Rozumienie",
          "id": 15,
          "rows": {
            "1": {
              "min": 0,
              "max": 6
            },
            "2": {
              "min": 7,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 9
            },
            "4": {
              "min": 10,
              "max": 11
            },
            "5": {
              "min": 12,
              "max": 12
            },
            "6": {
              "min": 13,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 16
            },
            "8": {
              "min": 17,
              "max": 17
            },
            "9": {
              "min": 18,
              "max": 19
            },
            "10": {
              "min": 20,
              "max": 21
            },
            "11": {
              "min": 22,
              "max": 22
            },
            "12": {
              "min": 23,
              "max": 24
            },
            "13": {
              "min": 25,
              "max": 26
            },
            "14": {
              "min": 27,
              "max": 28
            },
            "15": {
              "min": 29,
              "max": 30
            },
            "16": {
              "min": 31,
              "max": 32
            },
            "17": {
              "min": 33,
              "max": 33
            },
            "18": {
              "min": 34,
              "max": 35
            },
            "19": {
              "min": 36,
              "max": 38
            }
          }
        },
        {
          "name": "Arytmetyka",
          "id": 16,
          "rows": {
            "1": {
              "min": 0,
              "max": 5
            },
            "2": {
              "min": 6,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 12
            },
            "5": {
              "min": 13,
              "max": 14
            },
            "6": {
              "min": 15,
              "max": 16
            },
            "7": {
              "min": 17,
              "max": 18
            },
            "8": {
              "min": 19,
              "max": 20
            },
            "9": {
              "min": 21,
              "max": 21
            },
            "10": {
              "min": 22,
              "max": 23
            },
            "11": {
              "min": 24,
              "max": 24
            },
            "12": {
              "min": 25,
              "max": 25
            },
            "13": {
              "min": 26,
              "max": 27
            },
            "14": {
              "min": 28,
              "max": 28
            },
            "15": {
              "min": 29,
              "max": 29
            },
            "16": {
              "min": 30,
              "max": 30
            },
            "17": {
              "min": 31,
              "max": 31
            },
            "18": {
              "min": 32,
              "max": 32
            },
            "19": {
              "min": 33,
              "max": 34
            }
          }
        }
      ], 
    
    1281211: [
        {
          "name": "Wzory z klocków",
          "id": 1,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 10
            },
            "3": {
              "min": 11,
              "max": 13
            },
            "4": {
              "min": 14,
              "max": 16
            },
            "5": {
              "min": 17,
              "max": 19
            },
            "6": {
              "min": 20,
              "max": 23
            },
            "7": {
              "min": 24,
              "max": 26
            },
            "8": {
              "min": 27,
              "max": 29
            },
            "9": {
              "min": 30,
              "max": 32
            },
            "10": {
              "min": 33,
              "max": 35
            },
            "11": {
              "min": 36,
              "max": 38
            },
            "12": {
              "min": 39,
              "max": 41
            },
            "13": {
              "min": 42,
              "max": 44
            },
            "14": {
              "min": 45,
              "max": 47
            },
            "15": {
              "min": 48,
              "max": 49
            },
            "16": {
              "min": 50,
              "max": 52
            },
            "17": {
              "min": 53,
              "max": 54
            },
            "18": {
              "min": 55,
              "max": 56
            },
            "19": {
              "min": 57,
              "max": 58
            }
          }
        },
        {
          "name": "Podobieństwa",
          "id": 2,
          "rows": {
            "1": {
              "min": 0,
              "max": 11
            },
            "2": {
              "min": 12,
              "max": 14
            },
            "3": {
              "min": 15,
              "max": 16
            },
            "4": {
              "min": 17,
              "max": 18
            },
            "5": {
              "min": 19,
              "max": 20
            },
            "6": {
              "min": 21,
              "max": 22
            },
            "7": {
              "min": 23,
              "max": 24
            },
            "8": {
              "min": 25,
              "max": 26
            },
            "9": {
              "min": 27,
              "max": 28
            },
            "10": {
              "min": 29,
              "max": 29
            },
            "11": {
              "min": 30,
              "max": 31
            },
            "12": {
              "min": 32,
              "max": 33
            },
            "13": {
              "min": 34,
              "max": 34
            },
            "14": {
              "min": 35,
              "max": 36
            },
            "15": {
              "min": 37,
              "max": 37
            },
            "16": {
              "min": 38,
              "max": 39
            },
            "17": {
              "min": 40,
              "max": 40
            },
            "18": {
              "min": 41,
              "max": 41
            },
            "19": {
              "min": 42,
              "max": 46
            }
          }
        },
        {
          "name": "Matryce",
          "id": 3,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 12
            },
            "5": {
              "min": 13,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 15
            },
            "7": {
              "min": 16,
              "max": 16
            },
            "8": {
              "min": 17,
              "max": 17
            },
            "9": {
              "min": 18,
              "max": 19
            },
            "10": {
              "min": 20,
              "max": 20
            },
            "11": {
              "min": 21,
              "max": 21
            },
            "12": {
              "min": 22,
              "max": 23
            },
            "13": {
              "min": 24,
              "max": 24
            },
            "14": {
              "min": 25,
              "max": 25
            },
            "15": {
              "min": 26,
              "max": 26
            },
            "16": {
              "min": 27,
              "max": 27
            },
            "17": {
              "min": 28,
              "max": 29
            },
            "18": {
              "min": 30,
              "max": 30
            },
            "19": {
              "min": 31,
              "max": 32
            }
          }
        },
        {
          "name": "Powtarzanie liczb",
          "id": 4,
          "rows": {
            "1": {
              "min": 0,
              "max": 9
            },
            "2": {
              "min": 10,
              "max": 11
            },
            "3": {
              "min": 12,
              "max": 13
            },
            "4": {
              "min": 14,
              "max": 15
            },
            "5": {
              "min": 16,
              "max": 17
            },
            "6": {
              "min": 18,
              "max": 18
            },
            "7": {
              "min": 19,
              "max": 20
            },
            "8": {
              "min": 21,
              "max": 22
            },
            "9": {
              "min": 23,
              "max": 24
            },
            "10": {
              "min": 25,
              "max": 25
            },
            "11": {
              "min": 26,
              "max": 27
            },
            "12": {
              "min": 28,
              "max": 29
            },
            "13": {
              "min": 30,
              "max": 30
            },
            "14": {
              "min": 31,
              "max": 32
            },
            "15": {
              "min": 33,
              "max": 34
            },
            "16": {
              "min": 35,
              "max": 35
            },
            "17": {
              "min": 36,
              "max": 37
            },
            "18": {
              "min": 38,
              "max": 39
            },
            "19": {
              "min": 40,
              "max": 54
            }
          }
        },
        {
          "name": "Kodowanie",
          "id": 5,
          "rows": {
            "1": {
              "min": 0,
              "max": 16
            },
            "2": {
              "min": 17,
              "max": 20
            },
            "3": {
              "min": 21,
              "max": 25
            },
            "4": {
              "min": 26,
              "max": 30
            },
            "5": {
              "min": 31,
              "max": 34
            },
            "6": {
              "min": 35,
              "max": 39
            },
            "7": {
              "min": 40,
              "max": 43
            },
            "8": {
              "min": 44,
              "max": 48
            },
            "9": {
              "min": 49,
              "max": 52
            },
            "10": {
              "min": 53,
              "max": 57
            },
            "11": {
              "min": 58,
              "max": 61
            },
            "12": {
              "min": 62,
              "max": 65
            },
            "13": {
              "min": 66,
              "max": 70
            },
            "14": {
              "min": 71,
              "max": 74
            },
            "15": {
              "min": 75,
              "max": 78
            },
            "16": {
              "min": 79,
              "max": 83
            },
            "17": {
              "min": 84,
              "max": 87
            },
            "18": {
              "min": 88,
              "max": 91
            },
            "19": {
              "min": 92,
              "max": 117
            }
          }
        },
        {
          "name": "Słownik",
          "id": 6,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 14
            },
            "5": {
              "min": 15,
              "max": 17
            },
            "6": {
              "min": 18,
              "max": 20
            },
            "7": {
              "min": 21,
              "max": 23
            },
            "8": {
              "min": 24,
              "max": 26
            },
            "9": {
              "min": 27,
              "max": 28
            },
            "10": {
              "min": 29,
              "max": 31
            },
            "11": {
              "min": 32,
              "max": 34
            },
            "12": {
              "min": 35,
              "max": 37
            },
            "13": {
              "min": 38,
              "max": 40
            },
            "14": {
              "min": 41,
              "max": 42
            },
            "15": {
              "min": 43,
              "max": 45
            },
            "16": {
              "min": 46,
              "max": 48
            },
            "17": {
              "min": 49,
              "max": 50
            },
            "18": {
              "min": 51,
              "max": 52
            },
            "19": {
              "min": 53,
              "max": 54
            }
          }
        },
        {
          "name": "Ważenie",
          "id": 7,
          "rows": {
            "1": {
              "min": 0,
              "max": 6
            },
            "2": {
              "min": 7,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 12
            },
            "5": {
              "min": 13,
              "max": 15
            },
            "6": {
              "min": 16,
              "max": 17
            },
            "7": {
              "min": 18,
              "max": 19
            },
            "8": {
              "min": 20,
              "max": 21
            },
            "9": {
              "min": 22,
              "max": 23
            },
            "10": {
              "min": 24,
              "max": 24
            },
            "11": {
              "min": 25,
              "max": 25
            },
            "12": {
              "min": 26,
              "max": 26
            },
            "13": {
              "min": 27,
              "max": 27
            },
            "14": {
              "min": 28,
              "max": 28
            },
            "15": {
              "min": 29,
              "max": 29
            },
            "16": {
              "min": 30,
              "max": 30
            },
            "17": {
              "min": 31,
              "max": 31
            },
            "18": {
              "min": null,
              "max": null
            },
            "19": {
              "min": 32,
              "max": 34
            }
          }
        },
        {
          "name": "Układnie wzoru",
          "id": 8,
          "rows": {
            "1": {
              "min": 0,
              "max": 2
            },
            "2": {
              "min": 3,
              "max": 4
            },
            "3": {
              "min": 5,
              "max": 6
            },
            "4": {
              "min": 7,
              "max": 8
            },
            "5": {
              "min": 9,
              "max": 10
            },
            "6": {
              "min": 11,
              "max": 12
            },
            "7": {
              "min": 13,
              "max": 14
            },
            "8": {
              "min": 15,
              "max": 16
            },
            "9": {
              "min": 17,
              "max": 17
            },
            "10": {
              "min": 18,
              "max": 19
            },
            "11": {
              "min": 20,
              "max": 20
            },
            "12": {
              "min": 21,
              "max": 21
            },
            "13": {
              "min": 22,
              "max": 22
            },
            "14": {
              "min": 23,
              "max": 23
            },
            "15": {
              "min": 24,
              "max": 24
            },
            "16": {
              "min": 25,
              "max": 25
            },
            "17": {
              "min": 26,
              "max": 26
            },
            "18": {
              "min": 27,
              "max": 27
            },
            "19": {
              "min": 28,
              "max": 29
            }
          }
        },
        {
          "name": "Zapamiętywanie obrazków",
          "id": 9,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 10
            },
            "3": {
              "min": 11,
              "max": 13
            },
            "4": {
              "min": 14,
              "max": 15
            },
            "5": {
              "min": 16,
              "max": 18
            },
            "6": {
              "min": 19,
              "max": 20
            },
            "7": {
              "min": 21,
              "max": 23
            },
            "8": {
              "min": 24,
              "max": 25
            },
            "9": {
              "min": 26,
              "max": 28
            },
            "10": {
              "min": 29,
              "max": 30
            },
            "11": {
              "min": 31,
              "max": 33
            },
            "12": {
              "min": 34,
              "max": 35
            },
            "13": {
              "min": 36,
              "max": 37
            },
            "14": {
              "min": 38,
              "max": 40
            },
            "15": {
              "min": 41,
              "max": 42
            },
            "16": {
              "min": 43,
              "max": 44
            },
            "17": {
              "min": 45,
              "max": 46
            },
            "18": {
              "min": 47,
              "max": 47
            },
            "19": {
              "min": 48,
              "max": 49
            }
          }
        },
        {
          "name": "Szukanie symboli",
          "id": 10,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 10
            },
            "3": {
              "min": 11,
              "max": 13
            },
            "4": {
              "min": 14,
              "max": 16
            },
            "5": {
              "min": 17,
              "max": 18
            },
            "6": {
              "min": 19,
              "max": 21
            },
            "7": {
              "min": 22,
              "max": 24
            },
            "8": {
              "min": 25,
              "max": 26
            },
            "9": {
              "min": 27,
              "max": 28
            },
            "10": {
              "min": 29,
              "max": 31
            },
            "11": {
              "min": 32,
              "max": 33
            },
            "12": {
              "min": 34,
              "max": 35
            },
            "13": {
              "min": 36,
              "max": 38
            },
            "14": {
              "min": 39,
              "max": 40
            },
            "15": {
              "min": 41,
              "max": 42
            },
            "16": {
              "min": 43,
              "max": 44
            },
            "17": {
              "min": 45,
              "max": 47
            },
            "18": {
              "min": 48,
              "max": 49
            },
            "19": {
              "min": 50,
              "max": 60
            }
          }
        },
        {
          "name": "Wiadomości",
          "id": 11,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 11
            },
            "5": {
              "min": 12,
              "max": 12
            },
            "6": {
              "min": 13,
              "max": 13
            },
            "7": {
              "min": 14,
              "max": 14
            },
            "8": {
              "min": 15,
              "max": 15
            },
            "9": {
              "min": 16,
              "max": 17
            },
            "10": {
              "min": 18,
              "max": 18
            },
            "11": {
              "min": 19,
              "max": 19
            },
            "12": {
              "min": 20,
              "max": 21
            },
            "13": {
              "min": 22,
              "max": 22
            },
            "14": {
              "min": 23,
              "max": 23
            },
            "15": {
              "min": 24,
              "max": 25
            },
            "16": {
              "min": 26,
              "max": 26
            },
            "17": {
              "min": 27,
              "max": 28
            },
            "18": {
              "min": 29,
              "max": 29
            },
            "19": {
              "min": 30,
              "max": 31
            }
          }
        },
        {
          "name": "Tworzenie pojęć",
          "id": 12,
          "rows": {
            "1": {
              "min": 0,
              "max": 5
            },
            "2": {
              "min": 6,
              "max": 6
            },
            "3": {
              "min": 7,
              "max": 7
            },
            "4": {
              "min": 8,
              "max": 9
            },
            "5": {
              "min": 10,
              "max": 10
            },
            "6": {
              "min": 11,
              "max": 11
            },
            "7": {
              "min": 12,
              "max": 12
            },
            "8": {
              "min": 13,
              "max": 13
            },
            "9": {
              "min": 14,
              "max": 14
            },
            "10": {
              "min": 15,
              "max": 15
            },
            "11": {
              "min": 16,
              "max": 16
            },
            "12": {
              "min": 17,
              "max": 17
            },
            "13": {
              "min": 18,
              "max": 18
            },
            "14": {
              "min": 19,
              "max": 19
            },
            "15": {
              "min": 20,
              "max": 20
            },
            "16": {
              "min": 21,
              "max": 21
            },
            "17": {
              "min": 22,
              "max": 22
            },
            "18": {
              "min": 23,
              "max": 23
            },
            "19": {
              "min": 24,
              "max": 27
            }
          }
        },
        {
          "name": "Porządkowanie liter i liczb",
          "id": 13,
          "rows": {
            "1": {
              "min": 0,
              "max": 6
            },
            "2": {
              "min": 7,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 9
            },
            "4": {
              "min": 10,
              "max": 11
            },
            "5": {
              "min": 12,
              "max": 12
            },
            "6": {
              "min": 13,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 5
            },
            "8": {
              "min": 6,
              "max": 16
            },
            "9": {
              "min": 17,
              "max": 17
            },
            "10": {
              "min": null,
              "max": null
            },
            "11": {
              "min": 18,
              "max": 18
            },
            "12": {
              "min": 19,
              "max": 19
            },
            "13": {
              "min": 20,
              "max": 20
            },
            "14": {
              "min": null,
              "max": null
            },
            "15": {
              "min": 21,
              "max": 21
            },
            "16": {
              "min": 22,
              "max": 22
            },
            "17": {
              "min": null,
              "max": null
            },
            "18": {
              "min": 23,
              "max": 23
            },
            "19": {
              "min": 24,
              "max": 30
            }
          }
        },
        {
          "name": "Wykreślanie",
          "id": 14,
          "rows": {
            "1": {
              "min": 0,
              "max": 25
            },
            "2": {
              "min": 26,
              "max": 30
            },
            "3": {
              "min": 31,
              "max": 36
            },
            "4": {
              "min": 37,
              "max": 42
            },
            "5": {
              "min": 43,
              "max": 47
            },
            "6": {
              "min": 48,
              "max": 53
            },
            "7": {
              "min": 54,
              "max": 59
            },
            "8": {
              "min": 60,
              "max": 65
            },
            "9": {
              "min": 66,
              "max": 71
            },
            "10": {
              "min": 72,
              "max": 77
            },
            "11": {
              "min": 78,
              "max": 83
            },
            "12": {
              "min": 84,
              "max": 89
            },
            "13": {
              "min": 90,
              "max": 95
            },
            "14": {
              "min": 96,
              "max": 101
            },
            "15": {
              "min": 102,
              "max": 107
            },
            "16": {
              "min": 108,
              "max": 112
            },
            "17": {
              "min": 113,
              "max": 118
            },
            "18": {
              "min": 119,
              "max": 122
            },
            "19": {
              "min": 123,
              "max": 128
            }
          }
        },
        {
          "name": "Rozumienie",
          "id": 15,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 11
            },
            "5": {
              "min": 12,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 16
            },
            "8": {
              "min": 17,
              "max": 18
            },
            "9": {
              "min": 19,
              "max": 19
            },
            "10": {
              "min": 20,
              "max": 21
            },
            "11": {
              "min": 22,
              "max": 23
            },
            "12": {
              "min": 24,
              "max": 25
            },
            "13": {
              "min": 26,
              "max": 26
            },
            "14": {
              "min": 27,
              "max": 28
            },
            "15": {
              "min": 29,
              "max": 30
            },
            "16": {
              "min": 31,
              "max": 32
            },
            "17": {
              "min": 33,
              "max": 34
            },
            "18": {
              "min": 35,
              "max": 35
            },
            "19": {
              "min": 36,
              "max": 38
            }
          }
        },
        {
          "name": "Arytmetyka",
          "id": 16,
          "rows": {
            "1": {
              "min": 0,
              "max": 6
            },
            "2": {
              "min": 7,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 13
            },
            "5": {
              "min": 14,
              "max": 15
            },
            "6": {
              "min": 16,
              "max": 17
            },
            "7": {
              "min": 18,
              "max": 18
            },
            "8": {
              "min": 19,
              "max": 20
            },
            "9": {
              "min": 21,
              "max": 22
            },
            "10": {
              "min": 23,
              "max": 23
            },
            "11": {
              "min": 24,
              "max": 24
            },
            "12": {
              "min": 25,
              "max": 26
            },
            "13": {
              "min": 27,
              "max": 27
            },
            "14": {
              "min": 28,
              "max": 28
            },
            "15": {
              "min": 29,
              "max": 29
            },
            "16": {
              "min": 30,
              "max": 30
            },
            "17": {
              "min": 31,
              "max": 32
            },
            "18": {
              "min": null,
              "max": null
            },
            "19": {
              "min": 33,
              "max": 34
            }
          }
        }
      ],
    
    130133: [
        {
          "name": "Wzory z klocków",
          "id": 1,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 10
            },
            "3": {
              "min": 11,
              "max": 14
            },
            "4": {
              "min": 15,
              "max": 17
            },
            "5": {
              "min": 18,
              "max": 20
            },
            "6": {
              "min": 21,
              "max": 23
            },
            "7": {
              "min": 24,
              "max": 26
            },
            "8": {
              "min": 27,
              "max": 29
            },
            "9": {
              "min": 30,
              "max": 32
            },
            "10": {
              "min": 33,
              "max": 35
            },
            "11": {
              "min": 36,
              "max": 38
            },
            "12": {
              "min": 39,
              "max": 41
            },
            "13": {
              "min": 42,
              "max": 44
            },
            "14": {
              "min": 45,
              "max": 47
            },
            "15": {
              "min": 48,
              "max": 50
            },
            "16": {
              "min": 51,
              "max": 52
            },
            "17": {
              "min": 53,
              "max": 55
            },
            "18": {
              "min": 56,
              "max": 56
            },
            "19": {
              "min": 57,
              "max": 58
            }
          }
        },
        {
          "name": "Podobieństwa",
          "id": 2,
          "rows": {
            "1": {
              "min": 0,
              "max": 12
            },
            "2": {
              "min": 13,
              "max": 14
            },
            "3": {
              "min": 15,
              "max": 17
            },
            "4": {
              "min": 18,
              "max": 19
            },
            "5": {
              "min": 20,
              "max": 21
            },
            "6": {
              "min": 22,
              "max": 23
            },
            "7": {
              "min": 24,
              "max": 25
            },
            "8": {
              "min": 26,
              "max": 26
            },
            "9": {
              "min": 27,
              "max": 28
            },
            "10": {
              "min": 29,
              "max": 30
            },
            "11": {
              "min": 31,
              "max": 31
            },
            "12": {
              "min": 32,
              "max": 33
            },
            "13": {
              "min": 34,
              "max": 35
            },
            "14": {
              "min": 36,
              "max": 36
            },
            "15": {
              "min": 37,
              "max": 38
            },
            "16": {
              "min": 39,
              "max": 39
            },
            "17": {
              "min": 40,
              "max": 40
            },
            "18": {
              "min": 41,
              "max": 42
            },
            "19": {
              "min": 43,
              "max": 46
            }
          }
        },
        {
          "name": "Matryce",
          "id": 3,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 12
            },
            "5": {
              "min": 13,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 15
            },
            "7": {
              "min": 16,
              "max": 16
            },
            "8": {
              "min": 17,
              "max": 18
            },
            "9": {
              "min": 19,
              "max": 19
            },
            "10": {
              "min": 20,
              "max": 20
            },
            "11": {
              "min": 21,
              "max": 22
            },
            "12": {
              "min": 23,
              "max": 23
            },
            "13": {
              "min": 24,
              "max": 24
            },
            "14": {
              "min": 25,
              "max": 25
            },
            "15": {
              "min": 26,
              "max": 27
            },
            "16": {
              "min": 28,
              "max": 28
            },
            "17": {
              "min": 29,
              "max": 29
            },
            "18": {
              "min": 30,
              "max": 30
            },
            "19": {
              "min": 31,
              "max": 32
            }
          }
        },
        {
          "name": "Powtarzanie liczb",
          "id": 4,
          "rows": {
            "1": {
              "min": 0,
              "max": 9
            },
            "2": {
              "min": 10,
              "max": 11
            },
            "3": {
              "min": 12,
              "max": 13
            },
            "4": {
              "min": 14,
              "max": 15
            },
            "5": {
              "min": 16,
              "max": 17
            },
            "6": {
              "min": 18,
              "max": 19
            },
            "7": {
              "min": 20,
              "max": 20
            },
            "8": {
              "min": 21,
              "max": 22
            },
            "9": {
              "min": 23,
              "max": 24
            },
            "10": {
              "min": 25,
              "max": 26
            },
            "11": {
              "min": 27,
              "max": 27
            },
            "12": {
              "min": 28,
              "max": 29
            },
            "13": {
              "min": 30,
              "max": 31
            },
            "14": {
              "min": 32,
              "max": 32
            },
            "15": {
              "min": 33,
              "max": 34
            },
            "16": {
              "min": 35,
              "max": 36
            },
            "17": {
              "min": 37,
              "max": 37
            },
            "18": {
              "min": 38,
              "max": 39
            },
            "19": {
              "min": 40,
              "max": 54
            }
          }
        },
        {
          "name": "Kodowanie",
          "id": 5,
          "rows": {
            "1": {
              "min": 0,
              "max": 16
            },
            "2": {
              "min": 17,
              "max": 21
            },
            "3": {
              "min": 22,
              "max": 26
            },
            "4": {
              "min": 27,
              "max": 31
            },
            "5": {
              "min": 32,
              "max": 35
            },
            "6": {
              "min": 36,
              "max": 40
            },
            "7": {
              "min": 41,
              "max": 44
            },
            "8": {
              "min": 45,
              "max": 49
            },
            "9": {
              "min": 50,
              "max": 54
            },
            "10": {
              "min": 55,
              "max": 58
            },
            "11": {
              "min": 59,
              "max": 63
            },
            "12": {
              "min": 64,
              "max": 67
            },
            "13": {
              "min": 68,
              "max": 71
            },
            "14": {
              "min": 72,
              "max": 76
            },
            "15": {
              "min": 77,
              "max": 80
            },
            "16": {
              "min": 81,
              "max": 85
            },
            "17": {
              "min": 86,
              "max": 89
            },
            "18": {
              "min": 90,
              "max": 93
            },
            "19": {
              "min": 94,
              "max": 117
            }
          }
        },
        {
          "name": "Słownik",
          "id": 6,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 14
            },
            "5": {
              "min": 15,
              "max": 17
            },
            "6": {
              "min": 18,
              "max": 20
            },
            "7": {
              "min": 21,
              "max": 23
            },
            "8": {
              "min": 24,
              "max": 26
            },
            "9": {
              "min": 27,
              "max": 29
            },
            "10": {
              "min": 30,
              "max": 32
            },
            "11": {
              "min": 33,
              "max": 35
            },
            "12": {
              "min": 36,
              "max": 38
            },
            "13": {
              "min": 39,
              "max": 41
            },
            "14": {
              "min": 42,
              "max": 43
            },
            "15": {
              "min": 44,
              "max": 46
            },
            "16": {
              "min": 47,
              "max": 48
            },
            "17": {
              "min": 49,
              "max": 50
            },
            "18": {
              "min": 51,
              "max": 52
            },
            "19": {
              "min": 53,
              "max": 54
            }
          }
        },
        {
          "name": "Ważenie",
          "id": 7,
          "rows": {
            "1": {
              "min": 0,
              "max": 6
            },
            "2": {
              "min": 7,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 13
            },
            "5": {
              "min": 14,
              "max": 15
            },
            "6": {
              "min": 16,
              "max": 17
            },
            "7": {
              "min": 18,
              "max": 19
            },
            "8": {
              "min": 20,
              "max": 21
            },
            "9": {
              "min": 22,
              "max": 23
            },
            "10": {
              "min": 24,
              "max": 24
            },
            "11": {
              "min": 25,
              "max": 25
            },
            "12": {
              "min": 26,
              "max": 27
            },
            "13": {
              "min": 28,
              "max": 28
            },
            "14": {
              "min": 29,
              "max": 29
            },
            "15": {
              "min": null,
              "max": null
            },
            "16": {
              "min": 30,
              "max": 30
            },
            "17": {
              "min": 31,
              "max": 31
            },
            "18": {
              "min": 32,
              "max": 32
            },
            "19": {
              "min": 33,
              "max": 34
            }
          }
        },
        {
          "name": "Układnie wzoru",
          "id": 8,
          "rows": {
            "1": {
              "min": 0,
              "max": 2
            },
            "2": {
              "min": 3,
              "max": 4
            },
            "3": {
              "min": 5,
              "max": 6
            },
            "4": {
              "min": 7,
              "max": 8
            },
            "5": {
              "min": 9,
              "max": 10
            },
            "6": {
              "min": 11,
              "max": 13
            },
            "7": {
              "min": 14,
              "max": 14
            },
            "8": {
              "min": 15,
              "max": 16
            },
            "9": {
              "min": 17,
              "max": 18
            },
            "10": {
              "min": 19,
              "max": 19
            },
            "11": {
              "min": 20,
              "max": 20
            },
            "12": {
              "min": 21,
              "max": 21
            },
            "13": {
              "min": 22,
              "max": 22
            },
            "14": {
              "min": 23,
              "max": 23
            },
            "15": {
              "min": 24,
              "max": 24
            },
            "16": {
              "min": 25,
              "max": 25
            },
            "17": {
              "min": 26,
              "max": 26
            },
            "18": {
              "min": 27,
              "max": 27
            },
            "19": {
              "min": 28,
              "max": 29
            }
          }
        },
        {
          "name": "Zapamiętywanie obrazków",
          "id": 9,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 10
            },
            "3": {
              "min": 11,
              "max": 13
            },
            "4": {
              "min": 14,
              "max": 16
            },
            "5": {
              "min": 17,
              "max": 18
            },
            "6": {
              "min": 19,
              "max": 21
            },
            "7": {
              "min": 22,
              "max": 23
            },
            "8": {
              "min": 24,
              "max": 26
            },
            "9": {
              "min": 27,
              "max": 28
            },
            "10": {
              "min": 29,
              "max": 31
            },
            "11": {
              "min": 32,
              "max": 33
            },
            "12": {
              "min": 34,
              "max": 35
            },
            "13": {
              "min": 36,
              "max": 38
            },
            "14": {
              "min": 39,
              "max": 40
            },
            "15": {
              "min": 41,
              "max": 42
            },
            "16": {
              "min": 43,
              "max": 44
            },
            "17": {
              "min": 45,
              "max": 46
            },
            "18": {
              "min": 47,
              "max": 47
            },
            "19": {
              "min": 48,
              "max": 49
            }
          }
        },
        {
          "name": "Szukanie symboli",
          "id": 10,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 11
            },
            "3": {
              "min": 12,
              "max": 13
            },
            "4": {
              "min": 14,
              "max": 16
            },
            "5": {
              "min": 17,
              "max": 19
            },
            "6": {
              "min": 20,
              "max": 22
            },
            "7": {
              "min": 23,
              "max": 24
            },
            "8": {
              "min": 25,
              "max": 27
            },
            "9": {
              "min": 28,
              "max": 29
            },
            "10": {
              "min": 30,
              "max": 31
            },
            "11": {
              "min": 32,
              "max": 34
            },
            "12": {
              "min": 35,
              "max": 36
            },
            "13": {
              "min": 37,
              "max": 39
            },
            "14": {
              "min": 40,
              "max": 41
            },
            "15": {
              "min": 42,
              "max": 43
            },
            "16": {
              "min": 44,
              "max": 45
            },
            "17": {
              "min": 46,
              "max": 47
            },
            "18": {
              "min": 48,
              "max": 50
            },
            "19": {
              "min": 51,
              "max": 60
            }
          }
        },
        {
          "name": "Wiadomości",
          "id": 11,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 11
            },
            "5": {
              "min": 12,
              "max": 12
            },
            "6": {
              "min": 13,
              "max": 13
            },
            "7": {
              "min": 14,
              "max": 14
            },
            "8": {
              "min": 15,
              "max": 16
            },
            "9": {
              "min": 17,
              "max": 17
            },
            "10": {
              "min": 18,
              "max": 18
            },
            "11": {
              "min": 19,
              "max": 20
            },
            "12": {
              "min": 21,
              "max": 21
            },
            "13": {
              "min": 22,
              "max": 23
            },
            "14": {
              "min": 24,
              "max": 24
            },
            "15": {
              "min": 25,
              "max": 25
            },
            "16": {
              "min": 26,
              "max": 27
            },
            "17": {
              "min": 28,
              "max": 28
            },
            "18": {
              "min": 29,
              "max": 29
            },
            "19": {
              "min": 30,
              "max": 31
            }
          }
        },
        {
          "name": "Tworzenie pojęć",
          "id": 12,
          "rows": {
            "1": {
              "min": 0,
              "max": 5
            },
            "2": {
              "min": 6,
              "max": 6
            },
            "3": {
              "min": 7,
              "max": 8
            },
            "4": {
              "min": 9,
              "max": 9
            },
            "5": {
              "min": 10,
              "max": 10
            },
            "6": {
              "min": 11,
              "max": 11
            },
            "7": {
              "min": 12,
              "max": 12
            },
            "8": {
              "min": 13,
              "max": 13
            },
            "9": {
              "min": 14,
              "max": 14
            },
            "10": {
              "min": 15,
              "max": 15
            },
            "11": {
              "min": 16,
              "max": 16
            },
            "12": {
              "min": 17,
              "max": 17
            },
            "13": {
              "min": 18,
              "max": 18
            },
            "14": {
              "min": 19,
              "max": 19
            },
            "15": {
              "min": 20,
              "max": 20
            },
            "16": {
              "min": 21,
              "max": 21
            },
            "17": {
              "min": 22,
              "max": 22
            },
            "18": {
              "min": 23,
              "max": 23
            },
            "19": {
              "min": 24,
              "max": 27
            }
          }
        },
        {
          "name": "Porządkowanie liter i liczb",
          "id": 13,
          "rows": {
            "1": {
              "min": 0,
              "max": 6
            },
            "2": {
              "min": 7,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 11
            },
            "5": {
              "min": 12,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 15
            },
            "8": {
              "min": 16,
              "max": 16
            },
            "9": {
              "min": 17,
              "max": 17
            },
            "10": {
              "min": 18,
              "max": 18
            },
            "11": {
              "min": null,
              "max": null
            },
            "12": {
              "min": 19,
              "max": 19
            },
            "13": {
              "min": 20,
              "max": 20
            },
            "14": {
              "min": null,
              "max": null
            },
            "15": {
              "min": 21,
              "max": 21
            },
            "16": {
              "min": 22,
              "max": 22
            },
            "17": {
              "min": null,
              "max": null
            },
            "18": {
              "min": 23,
              "max": 23
            },
            "19": {
              "min": 24,
              "max": 30
            }
          }
        },
        {
          "name": "Wykreślanie",
          "id": 14,
          "rows": {
            "1": {
              "min": 0,
              "max": 25
            },
            "2": {
              "min": 26,
              "max": 30
            },
            "3": {
              "min": 31,
              "max": 36
            },
            "4": {
              "min": 37,
              "max": 42
            },
            "5": {
              "min": 43,
              "max": 48
            },
            "6": {
              "min": 49,
              "max": 54
            },
            "7": {
              "min": 55,
              "max": 60
            },
            "8": {
              "min": 61,
              "max": 66
            },
            "9": {
              "min": 67,
              "max": 72
            },
            "10": {
              "min": 73,
              "max": 78
            },
            "11": {
              "min": 79,
              "max": 84
            },
            "12": {
              "min": 85,
              "max": 90
            },
            "13": {
              "min": 91,
              "max": 96
            },
            "14": {
              "min": 97,
              "max": 102
            },
            "15": {
              "min": 103,
              "max": 108
            },
            "16": {
              "min": 109,
              "max": 113
            },
            "17": {
              "min": 114,
              "max": 119
            },
            "18": {
              "min": 120,
              "max": 123
            },
            "19": {
              "min": 124,
              "max": 128
            }
          }
        },
        {
          "name": "Rozumienie",
          "id": 15,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 12
            },
            "5": {
              "min": 13,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 15
            },
            "7": {
              "min": 16,
              "max": 16
            },
            "8": {
              "min": 17,
              "max": 18
            },
            "9": {
              "min": 19,
              "max": 20
            },
            "10": {
              "min": 21,
              "max": 22
            },
            "11": {
              "min": 23,
              "max": 23
            },
            "12": {
              "min": 24,
              "max": 25
            },
            "13": {
              "min": 26,
              "max": 27
            },
            "14": {
              "min": 28,
              "max": 29
            },
            "15": {
              "min": 30,
              "max": 31
            },
            "16": {
              "min": 32,
              "max": 32
            },
            "17": {
              "min": 33,
              "max": 34
            },
            "18": {
              "min": 35,
              "max": 36
            },
            "19": {
              "min": 37,
              "max": 38
            }
          }
        },
        {
          "name": "Arytmetyka",
          "id": 16,
          "rows": {
            "1": {
              "min": 0,
              "max": 6
            },
            "2": {
              "min": 7,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 13
            },
            "5": {
              "min": 14,
              "max": 15
            },
            "6": {
              "min": 16,
              "max": 17
            },
            "7": {
              "min": 18,
              "max": 19
            },
            "8": {
              "min": 20,
              "max": 20
            },
            "9": {
              "min": 21,
              "max": 22
            },
            "10": {
              "min": 23,
              "max": 23
            },
            "11": {
              "min": 24,
              "max": 25
            },
            "12": {
              "min": 26,
              "max": 26
            },
            "13": {
              "min": 27,
              "max": 27
            },
            "14": {
              "min": 28,
              "max": 29
            },
            "15": {
              "min": 30,
              "max": 30
            },
            "16": {
              "min": 31,
              "max": 31
            },
            "17": {
              "min": 32,
              "max": 32
            },
            "18": {
              "min": 33,
              "max": 33
            },
            "19": {
              "min": 34,
              "max": 34
            }
          }
        }
      ],   
    
    134137: [
        {
          "name": "Wzory z klocków",
          "id": 1,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 10
            },
            "3": {
              "min": 11,
              "max": 14
            },
            "4": {
              "min": 15,
              "max": 17
            },
            "5": {
              "min": 18,
              "max": 20
            },
            "6": {
              "min": 21,
              "max": 24
            },
            "7": {
              "min": 25,
              "max": 27
            },
            "8": {
              "min": 28,
              "max": 30
            },
            "9": {
              "min": 31,
              "max": 33
            },
            "10": {
              "min": 34,
              "max": 36
            },
            "11": {
              "min": 37,
              "max": 39
            },
            "12": {
              "min": 40,
              "max": 42
            },
            "13": {
              "min": 43,
              "max": 45
            },
            "14": {
              "min": 46,
              "max": 48
            },
            "15": {
              "min": 49,
              "max": 50
            },
            "16": {
              "min": 51,
              "max": 53
            },
            "17": {
              "min": 54,
              "max": 55
            },
            "18": {
              "min": 56,
              "max": 56
            },
            "19": {
              "min": 57,
              "max": 58
            }
          }
        },
        {
          "name": "Podobieństwa",
          "id": 2,
          "rows": {
            "1": {
              "min": 0,
              "max": 12
            },
            "2": {
              "min": 13,
              "max": 14
            },
            "3": {
              "min": 15,
              "max": 17
            },
            "4": {
              "min": 18,
              "max": 19
            },
            "5": {
              "min": 20,
              "max": 21
            },
            "6": {
              "min": 22,
              "max": 23
            },
            "7": {
              "min": 24,
              "max": 25
            },
            "8": {
              "min": 26,
              "max": 27
            },
            "9": {
              "min": 28,
              "max": 28
            },
            "10": {
              "min": 29,
              "max": 30
            },
            "11": {
              "min": 31,
              "max": 32
            },
            "12": {
              "min": 33,
              "max": 33
            },
            "13": {
              "min": 34,
              "max": 35
            },
            "14": {
              "min": 36,
              "max": 36
            },
            "15": {
              "min": 37,
              "max": 38
            },
            "16": {
              "min": 39,
              "max": 39
            },
            "17": {
              "min": 40,
              "max": 41
            },
            "18": {
              "min": 42,
              "max": 42
            },
            "19": {
              "min": 43,
              "max": 46
            }
          }
        },
        {
          "name": "Matryce",
          "id": 3,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 12
            },
            "5": {
              "min": 13,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 15
            },
            "7": {
              "min": 16,
              "max": 16
            },
            "8": {
              "min": 17,
              "max": 18
            },
            "9": {
              "min": 19,
              "max": 19
            },
            "10": {
              "min": 20,
              "max": 20
            },
            "11": {
              "min": 21,
              "max": 22
            },
            "12": {
              "min": 23,
              "max": 23
            },
            "13": {
              "min": 24,
              "max": 24
            },
            "14": {
              "min": 25,
              "max": 26
            },
            "15": {
              "min": 27,
              "max": 27
            },
            "16": {
              "min": 28,
              "max": 28
            },
            "17": {
              "min": 29,
              "max": 29
            },
            "18": {
              "min": 30,
              "max": 30
            },
            "19": {
              "min": 31,
              "max": 32
            }
          }
        },
        {
          "name": "Powtarzanie liczb",
          "id": 4,
          "rows": {
            "1": {
              "min": 0,
              "max": 9
            },
            "2": {
              "min": 10,
              "max": 11
            },
            "3": {
              "min": 12,
              "max": 13
            },
            "4": {
              "min": 14,
              "max": 15
            },
            "5": {
              "min": 16,
              "max": 17
            },
            "6": {
              "min": 18,
              "max": 19
            },
            "7": {
              "min": 20,
              "max": 21
            },
            "8": {
              "min": 22,
              "max": 22
            },
            "9": {
              "min": 23,
              "max": 24
            },
            "10": {
              "min": 25,
              "max": 26
            },
            "11": {
              "min": 27,
              "max": 28
            },
            "12": {
              "min": 29,
              "max": 29
            },
            "13": {
              "min": 30,
              "max": 31
            },
            "14": {
              "min": 32,
              "max": 33
            },
            "15": {
              "min": 34,
              "max": 35
            },
            "16": {
              "min": 36,
              "max": 36
            },
            "17": {
              "min": 37,
              "max": 38
            },
            "18": {
              "min": 39,
              "max": 39
            },
            "19": {
              "min": 40,
              "max": 54
            }
          }
        },
        {
          "name": "Kodowanie",
          "id": 5,
          "rows": {
            "1": {
              "min": 0,
              "max": 17
            },
            "2": {
              "min": 18,
              "max": 22
            },
            "3": {
              "min": 23,
              "max": 27
            },
            "4": {
              "min": 28,
              "max": 32
            },
            "5": {
              "min": 33,
              "max": 36
            },
            "6": {
              "min": 37,
              "max": 41
            },
            "7": {
              "min": 42,
              "max": 46
            },
            "8": {
              "min": 47,
              "max": 50
            },
            "9": {
              "min": 51,
              "max": 55
            },
            "10": {
              "min": 56,
              "max": 60
            },
            "11": {
              "min": 61,
              "max": 64
            },
            "12": {
              "min": 65,
              "max": 69
            },
            "13": {
              "min": 70,
              "max": 73
            },
            "14": {
              "min": 74,
              "max": 78
            },
            "15": {
              "min": 79,
              "max": 82
            },
            "16": {
              "min": 83,
              "max": 87
            },
            "17": {
              "min": 88,
              "max": 91
            },
            "18": {
              "min": 92,
              "max": 96
            },
            "19": {
              "min": 97,
              "max": 117
            }
          }
        },
        {
          "name": "Słownik",
          "id": 6,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 14
            },
            "5": {
              "min": 15,
              "max": 18
            },
            "6": {
              "min": 19,
              "max": 21
            },
            "7": {
              "min": 22,
              "max": 24
            },
            "8": {
              "min": 25,
              "max": 27
            },
            "9": {
              "min": 28,
              "max": 30
            },
            "10": {
              "min": 31,
              "max": 33
            },
            "11": {
              "min": 34,
              "max": 36
            },
            "12": {
              "min": 37,
              "max": 39
            },
            "13": {
              "min": 40,
              "max": 41
            },
            "14": {
              "min": 42,
              "max": 44
            },
            "15": {
              "min": 45,
              "max": 47
            },
            "16": {
              "min": 48,
              "max": 49
            },
            "17": {
              "min": 50,
              "max": 51
            },
            "18": {
              "min": 52,
              "max": 52
            },
            "19": {
              "min": 53,
              "max": 54
            }
          }
        },
        {
          "name": "Ważenie",
          "id": 7,
          "rows": {
            "1": {
              "min": 0,
              "max": 6
            },
            "2": {
              "min": 7,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 13
            },
            "5": {
              "min": 14,
              "max": 15
            },
            "6": {
              "min": 16,
              "max": 18
            },
            "7": {
              "min": 19,
              "max": 20
            },
            "8": {
              "min": 21,
              "max": 21
            },
            "9": {
              "min": 22,
              "max": 23
            },
            "10": {
              "min": 24,
              "max": 24
            },
            "11": {
              "min": 25,
              "max": 26
            },
            "12": {
              "min": 27,
              "max": 27
            },
            "13": {
              "min": 28,
              "max": 28
            },
            "14": {
              "min": 29,
              "max": 29
            },
            "15": {
              "min": null,
              "max": null
            },
            "16": {
              "min": 30,
              "max": 30
            },
            "17": {
              "min": 31,
              "max": 31
            },
            "18": {
              "min": 32,
              "max": 32
            },
            "19": {
              "min": 33,
              "max": 34
            }
          }
        },
        {
          "name": "Układnie wzoru",
          "id": 8,
          "rows": {
            "1": {
              "min": 0,
              "max": 2
            },
            "2": {
              "min": 3,
              "max": 4
            },
            "3": {
              "min": 5,
              "max": 6
            },
            "4": {
              "min": 7,
              "max": 8
            },
            "5": {
              "min": 9,
              "max": 11
            },
            "6": {
              "min": 12,
              "max": 13
            },
            "7": {
              "min": 14,
              "max": 15
            },
            "8": {
              "min": 16,
              "max": 16
            },
            "9": {
              "min": 17,
              "max": 18
            },
            "10": {
              "min": 19,
              "max": 19
            },
            "11": {
              "min": 20,
              "max": 20
            },
            "12": {
              "min": 21,
              "max": 22
            },
            "13": {
              "min": 23,
              "max": 23
            },
            "14": {
              "min": 24,
              "max": 24
            },
            "15": {
              "min": 25,
              "max": 25
            },
            "16": {
              "min": null,
              "max": null
            },
            "17": {
              "min": 26,
              "max": 26
            },
            "18": {
              "min": 27,
              "max": 27
            },
            "19": {
              "min": 28,
              "max": 29
            }
          }
        },
        {
          "name": "Zapamiętywanie obrazków",
          "id": 9,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 11
            },
            "3": {
              "min": 12,
              "max": 13
            },
            "4": {
              "min": 14,
              "max": 16
            },
            "5": {
              "min": 17,
              "max": 19
            },
            "6": {
              "min": 20,
              "max": 21
            },
            "7": {
              "min": 22,
              "max": 24
            },
            "8": {
              "min": 25,
              "max": 26
            },
            "9": {
              "min": 27,
              "max": 29
            },
            "10": {
              "min": 30,
              "max": 31
            },
            "11": {
              "min": 32,
              "max": 33
            },
            "12": {
              "min": 34,
              "max": 36
            },
            "13": {
              "min": 37,
              "max": 38
            },
            "14": {
              "min": 39,
              "max": 40
            },
            "15": {
              "min": 41,
              "max": 42
            },
            "16": {
              "min": 43,
              "max": 44
            },
            "17": {
              "min": 45,
              "max": 46
            },
            "18": {
              "min": 47,
              "max": 47
            },
            "19": {
              "min": 48,
              "max": 49
            }
          }
        },
        {
          "name": "Szukanie symboli",
          "id": 10,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 11
            },
            "3": {
              "min": 12,
              "max": 14
            },
            "4": {
              "min": 15,
              "max": 17
            },
            "5": {
              "min": 18,
              "max": 19
            },
            "6": {
              "min": 20,
              "max": 22
            },
            "7": {
              "min": 23,
              "max": 25
            },
            "8": {
              "min": 26,
              "max": 27
            },
            "9": {
              "min": 28,
              "max": 30
            },
            "10": {
              "min": 31,
              "max": 32
            },
            "11": {
              "min": 33,
              "max": 35
            },
            "12": {
              "min": 36,
              "max": 37
            },
            "13": {
              "min": 38,
              "max": 39
            },
            "14": {
              "min": 40,
              "max": 42
            },
            "15": {
              "min": 43,
              "max": 44
            },
            "16": {
              "min": 45,
              "max": 46
            },
            "17": {
              "min": 47,
              "max": 48
            },
            "18": {
              "min": 49,
              "max": 50
            },
            "19": {
              "min": 51,
              "max": 60
            }
          }
        },
        {
          "name": "Wiadomości",
          "id": 11,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 11
            },
            "5": {
              "min": 12,
              "max": 12
            },
            "6": {
              "min": 13,
              "max": 13
            },
            "7": {
              "min": 14,
              "max": 15
            },
            "8": {
              "min": 16,
              "max": 16
            },
            "9": {
              "min": 17,
              "max": 17
            },
            "10": {
              "min": 18,
              "max": 19
            },
            "11": {
              "min": 20,
              "max": 20
            },
            "12": {
              "min": 21,
              "max": 22
            },
            "13": {
              "min": 23,
              "max": 23
            },
            "14": {
              "min": 24,
              "max": 25
            },
            "15": {
              "min": 26,
              "max": 26
            },
            "16": {
              "min": 27,
              "max": 27
            },
            "17": {
              "min": 28,
              "max": 29
            },
            "18": {
              "min": 30,
              "max": 30
            },
            "19": {
              "min": 31,
              "max": 31
            }
          }
        },
        {
          "name": "Tworzenie pojęć",
          "id": 12,
          "rows": {
            "1": {
              "min": 0,
              "max": 5
            },
            "2": {
              "min": 6,
              "max": 6
            },
            "3": {
              "min": 7,
              "max": 8
            },
            "4": {
              "min": 9,
              "max": 9
            },
            "5": {
              "min": 10,
              "max": 10
            },
            "6": {
              "min": 11,
              "max": 11
            },
            "7": {
              "min": 12,
              "max": 12
            },
            "8": {
              "min": 13,
              "max": 13
            },
            "9": {
              "min": 14,
              "max": 15
            },
            "10": {
              "min": 16,
              "max": 16
            },
            "11": {
              "min": 17,
              "max": 17
            },
            "12": {
              "min": 18,
              "max": 18
            },
            "13": {
              "min": 19,
              "max": 19
            },
            "14": {
              "min": 20,
              "max": 20
            },
            "15": {
              "min": 21,
              "max": 21
            },
            "16": {
              "min": null,
              "max": null
            },
            "17": {
              "min": 22,
              "max": 22
            },
            "18": {
              "min": 23,
              "max": 23
            },
            "19": {
              "min": 24,
              "max": 27
            }
          }
        },
        {
          "name": "Porządkowanie liter i liczb",
          "id": 13,
          "rows": {
            "1": {
              "min": 0,
              "max": 6
            },
            "2": {
              "min": 7,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 11
            },
            "5": {
              "min": 12,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 15
            },
            "8": {
              "min": 16,
              "max": 16
            },
            "9": {
              "min": 17,
              "max": 17
            },
            "10": {
              "min": 18,
              "max": 18
            },
            "11": {
              "min": null,
              "max": null
            },
            "12": {
              "min": 19,
              "max": 19
            },
            "13": {
              "min": 20,
              "max": 20
            },
            "14": {
              "min": 21,
              "max": 21
            },
            "15": {
              "min": null,
              "max": null
            },
            "16": {
              "min": 22,
              "max": 22
            },
            "17": {
              "min": null,
              "max": null
            },
            "18": {
              "min": 23,
              "max": 23
            },
            "19": {
              "min": 24,
              "max": 30
            }
          }
        },
        {
          "name": "Wykreślanie",
          "id": 14,
          "rows": {
            "1": {
              "min": 0,
              "max": 25
            },
            "2": {
              "min": 26,
              "max": 30
            },
            "3": {
              "min": 31,
              "max": 37
            },
            "4": {
              "min": 38,
              "max": 43
            },
            "5": {
              "min": 44,
              "max": 49
            },
            "6": {
              "min": 50,
              "max": 56
            },
            "7": {
              "min": 57,
              "max": 62
            },
            "8": {
              "min": 63,
              "max": 68
            },
            "9": {
              "min": 69,
              "max": 74
            },
            "10": {
              "min": 75,
              "max": 80
            },
            "11": {
              "min": 81,
              "max": 86
            },
            "12": {
              "min": 87,
              "max": 92
            },
            "13": {
              "min": 93,
              "max": 98
            },
            "14": {
              "min": 99,
              "max": 103
            },
            "15": {
              "min": 104,
              "max": 109
            },
            "16": {
              "min": 110,
              "max": 114
            },
            "17": {
              "min": 115,
              "max": 119
            },
            "18": {
              "min": 120,
              "max": 123
            },
            "19": {
              "min": 124,
              "max": 128
            }
          }
        },
        {
          "name": "Rozumienie",
          "id": 15,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 12
            },
            "5": {
              "min": 13,
              "max": 14
            },
            "6": {
              "min": 15,
              "max": 15
            },
            "7": {
              "min": 16,
              "max": 17
            },
            "8": {
              "min": 18,
              "max": 19
            },
            "9": {
              "min": 20,
              "max": 20
            },
            "10": {
              "min": 21,
              "max": 22
            },
            "11": {
              "min": 23,
              "max": 24
            },
            "12": {
              "min": 25,
              "max": 26
            },
            "13": {
              "min": 27,
              "max": 27
            },
            "14": {
              "min": 28,
              "max": 29
            },
            "15": {
              "min": 30,
              "max": 31
            },
            "16": {
              "min": 32,
              "max": 33
            },
            "17": {
              "min": 34,
              "max": 34
            },
            "18": {
              "min": 35,
              "max": 36
            },
            "19": {
              "min": 37,
              "max": 38
            }
          }
        },
        {
          "name": "Arytmetyka",
          "id": 16,
          "rows": {
            "1": {
              "min": 0,
              "max": 6
            },
            "2": {
              "min": 7,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 14
            },
            "5": {
              "min": 15,
              "max": 16
            },
            "6": {
              "min": 17,
              "max": 17
            },
            "7": {
              "min": 18,
              "max": 19
            },
            "8": {
              "min": 20,
              "max": 21
            },
            "9": {
              "min": 22,
              "max": 22
            },
            "10": {
              "min": 23,
              "max": 24
            },
            "11": {
              "min": 25,
              "max": 25
            },
            "12": {
              "min": 26,
              "max": 26
            },
            "13": {
              "min": 27,
              "max": 28
            },
            "14": {
              "min": 29,
              "max": 29
            },
            "15": {
              "min": 30,
              "max": 30
            },
            "16": {
              "min": 31,
              "max": 31
            },
            "17": {
              "min": 32,
              "max": 32
            },
            "18": {
              "min": 33,
              "max": 33
            },
            "19": {
              "min": 34,
              "max": 34
            }
          }
        }
      ],  
      
    1381311: [
        {
          "name": "Wzory z klocków",
          "id": 1,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 10
            },
            "3": {
              "min": 11,
              "max": 14
            },
            "4": {
              "min": 15,
              "max": 17
            },
            "5": {
              "min": 18,
              "max": 21
            },
            "6": {
              "min": 22,
              "max": 24
            },
            "7": {
              "min": 25,
              "max": 27
            },
            "8": {
              "min": 28,
              "max": 30
            },
            "9": {
              "min": 31,
              "max": 33
            },
            "10": {
              "min": 34,
              "max": 37
            },
            "11": {
              "min": 38,
              "max": 40
            },
            "12": {
              "min": 41,
              "max": 42
            },
            "13": {
              "min": 43,
              "max": 45
            },
            "14": {
              "min": 46,
              "max": 48
            },
            "15": {
              "min": 49,
              "max": 51
            },
            "16": {
              "min": 52,
              "max": 53
            },
            "17": {
              "min": 54,
              "max": 55
            },
            "18": {
              "min": 56,
              "max": 56
            },
            "19": {
              "min": 57,
              "max": 58
            }
          }
        },
        {
          "name": "Podobieństwa",
          "id": 2,
          "rows": {
            "1": {
              "min": 0,
              "max": 12
            },
            "2": {
              "min": 13,
              "max": 15
            },
            "3": {
              "min": 16,
              "max": 17
            },
            "4": {
              "min": 18,
              "max": 19
            },
            "5": {
              "min": 20,
              "max": 21
            },
            "6": {
              "min": 22,
              "max": 23
            },
            "7": {
              "min": 24,
              "max": 25
            },
            "8": {
              "min": 26,
              "max": 27
            },
            "9": {
              "min": 28,
              "max": 29
            },
            "10": {
              "min": 30,
              "max": 30
            },
            "11": {
              "min": 31,
              "max": 32
            },
            "12": {
              "min": 33,
              "max": 34
            },
            "13": {
              "min": 35,
              "max": 35
            },
            "14": {
              "min": 36,
              "max": 37
            },
            "15": {
              "min": 38,
              "max": 38
            },
            "16": {
              "min": 39,
              "max": 40
            },
            "17": {
              "min": 41,
              "max": 41
            },
            "18": {
              "min": 42,
              "max": 43
            },
            "19": {
              "min": 44,
              "max": 46
            }
          }
        },
        {
          "name": "Matryce",
          "id": 3,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 12
            },
            "5": {
              "min": 13,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 15
            },
            "7": {
              "min": 16,
              "max": 16
            },
            "8": {
              "min": 17,
              "max": 18
            },
            "9": {
              "min": 19,
              "max": 19
            },
            "10": {
              "min": 20,
              "max": 21
            },
            "11": {
              "min": 22,
              "max": 22
            },
            "12": {
              "min": 23,
              "max": 23
            },
            "13": {
              "min": 24,
              "max": 25
            },
            "14": {
              "min": 26,
              "max": 26
            },
            "15": {
              "min": 27,
              "max": 27
            },
            "16": {
              "min": 28,
              "max": 28
            },
            "17": {
              "min": 29,
              "max": 29
            },
            "18": {
              "min": 30,
              "max": 30
            },
            "19": {
              "min": 31,
              "max": 32
            }
          }
        },
        {
          "name": "Powtarzanie liczb",
          "id": 4,
          "rows": {
            "1": {
              "min": 0,
              "max": 9
            },
            "2": {
              "min": 10,
              "max": 11
            },
            "3": {
              "min": 12,
              "max": 13
            },
            "4": {
              "min": 14,
              "max": 15
            },
            "5": {
              "min": 16,
              "max": 17
            },
            "6": {
              "min": 18,
              "max": 19
            },
            "7": {
              "min": 20,
              "max": 21
            },
            "8": {
              "min": 22,
              "max": 23
            },
            "9": {
              "min": 24,
              "max": 24
            },
            "10": {
              "min": 25,
              "max": 26
            },
            "11": {
              "min": 27,
              "max": 28
            },
            "12": {
              "min": 29,
              "max": 30
            },
            "13": {
              "min": 31,
              "max": 31
            },
            "14": {
              "min": 32,
              "max": 33
            },
            "15": {
              "min": 34,
              "max": 35
            },
            "16": {
              "min": 36,
              "max": 37
            },
            "17": {
              "min": 38,
              "max": 38
            },
            "18": {
              "min": 39,
              "max": 40
            },
            "19": {
              "min": 41,
              "max": 54
            }
          }
        },
        {
          "name": "Kodowanie",
          "id": 5,
          "rows": {
            "1": {
              "min": 0,
              "max": 18
            },
            "2": {
              "min": 19,
              "max": 23
            },
            "3": {
              "min": 24,
              "max": 28
            },
            "4": {
              "min": 29,
              "max": 33
            },
            "5": {
              "min": 34,
              "max": 37
            },
            "6": {
              "min": 38,
              "max": 42
            },
            "7": {
              "min": 43,
              "max": 47
            },
            "8": {
              "min": 48,
              "max": 52
            },
            "9": {
              "min": 53,
              "max": 56
            },
            "10": {
              "min": 57,
              "max": 61
            },
            "11": {
              "min": 62,
              "max": 66
            },
            "12": {
              "min": 67,
              "max": 70
            },
            "13": {
              "min": 71,
              "max": 75
            },
            "14": {
              "min": 76,
              "max": 79
            },
            "15": {
              "min": 80,
              "max": 84
            },
            "16": {
              "min": 85,
              "max": 89
            },
            "17": {
              "min": 90,
              "max": 93
            },
            "18": {
              "min": 94,
              "max": 98
            },
            "19": {
              "min": 99,
              "max": 117
            }
          }
        },
        {
          "name": "Słownik",
          "id": 6,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 14
            },
            "5": {
              "min": 15,
              "max": 18
            },
            "6": {
              "min": 19,
              "max": 21
            },
            "7": {
              "min": 22,
              "max": 25
            },
            "8": {
              "min": 26,
              "max": 28
            },
            "9": {
              "min": 29,
              "max": 31
            },
            "10": {
              "min": 32,
              "max": 34
            },
            "11": {
              "min": 35,
              "max": 37
            },
            "12": {
              "min": 38,
              "max": 40
            },
            "13": {
              "min": 41,
              "max": 42
            },
            "14": {
              "min": 43,
              "max": 45
            },
            "15": {
              "min": 46,
              "max": 47
            },
            "16": {
              "min": 48,
              "max": 49
            },
            "17": {
              "min": 50,
              "max": 51
            },
            "18": {
              "min": 52,
              "max": 52
            },
            "19": {
              "min": 53,
              "max": 54
            }
          }
        },
        {
          "name": "Ważenie",
          "id": 7,
          "rows": {
            "1": {
              "min": 0,
              "max": 6
            },
            "2": {
              "min": 7,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 14
            },
            "5": {
              "min": 15,
              "max": 18
            },
            "6": {
              "min": 19,
              "max": 21
            },
            "7": {
              "min": 22,
              "max": 25
            },
            "8": {
              "min": 26,
              "max": 28
            },
            "9": {
              "min": 29,
              "max": 31
            },
            "10": {
              "min": 32,
              "max": 34
            },
            "11": {
              "min": 35,
              "max": 37
            },
            "12": {
              "min": 38,
              "max": 40
            },
            "13": {
              "min": 41,
              "max": 42
            },
            "14": {
              "min": 43,
              "max": 45
            },
            "15": {
              "min": 46,
              "max": 47
            },
            "16": {
              "min": 48,
              "max": 49
            },
            "17": {
              "min": 50,
              "max": 51
            },
            "18": {
              "min": 52,
              "max": 52
            },
            "19": {
              "min": 53,
              "max": 54
            }
          }
        },
        {
          "name": "Układnie wzoru",
          "id": 8,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 4
            },
            "3": {
              "min": 5,
              "max": 6
            },
            "4": {
              "min": 7,
              "max": 9
            },
            "5": {
              "min": 10,
              "max": 11
            },
            "6": {
              "min": 12,
              "max": 13
            },
            "7": {
              "min": 14,
              "max": 15
            },
            "8": {
              "min": 16,
              "max": 17
            },
            "9": {
              "min": 18,
              "max": 18
            },
            "10": {
              "min": 19,
              "max": 19
            },
            "11": {
              "min": 20,
              "max": 21
            },
            "12": {
              "min": 22,
              "max": 22
            },
            "13": {
              "min": 23,
              "max": 23
            },
            "14": {
              "min": 24,
              "max": 24
            },
            "15": {
              "min": 25,
              "max": 25
            },
            "16": {
              "min": 26,
              "max": 26
            },
            "17": {
              "min": null,
              "max": null
            },
            "18": {
              "min": 27,
              "max": 27
            },
            "19": {
              "min": 28,
              "max": 29
            }
          }
        },
        {
          "name": "Zapamiętywanie obrazków",
          "id": 9,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 11
            },
            "3": {
              "min": 12,
              "max": 14
            },
            "4": {
              "min": 15,
              "max": 16
            },
            "5": {
              "min": 17,
              "max": 19
            },
            "6": {
              "min": 20,
              "max": 21
            },
            "7": {
              "min": 22,
              "max": 24
            },
            "8": {
              "min": 25,
              "max": 26
            },
            "9": {
              "min": 27,
              "max": 29
            },
            "10": {
              "min": 30,
              "max": 31
            },
            "11": {
              "min": 32,
              "max": 34
            },
            "12": {
              "min": 35,
              "max": 36
            },
            "13": {
              "min": 37,
              "max": 38
            },
            "14": {
              "min": 39,
              "max": 40
            },
            "15": {
              "min": 41,
              "max": 43
            },
            "16": {
              "min": 44,
              "max": 45
            },
            "17": {
              "min": 46,
              "max": 46
            },
            "18": {
              "min": 47,
              "max": 47
            },
            "19": {
              "min": 48,
              "max": 49
            }
          }
        },
        {
          "name": "Szukanie symboli",
          "id": 10,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 11
            },
            "3": {
              "min": 12,
              "max": 14
            },
            "4": {
              "min": 15,
              "max": 17
            },
            "5": {
              "min": 18,
              "max": 20
            },
            "6": {
              "min": 21,
              "max": 23
            },
            "7": {
              "min": 24,
              "max": 25
            },
            "8": {
              "min": 26,
              "max": 28
            },
            "9": {
              "min": 29,
              "max": 30
            },
            "10": {
              "min": 31,
              "max": 33
            },
            "11": {
              "min": 34,
              "max": 35
            },
            "12": {
              "min": 36,
              "max": 38
            },
            "13": {
              "min": 39,
              "max": 40
            },
            "14": {
              "min": 41,
              "max": 42
            },
            "15": {
              "min": 43,
              "max": 45
            },
            "16": {
              "min": 46,
              "max": 47
            },
            "17": {
              "min": 48,
              "max": 49
            },
            "18": {
              "min": 50,
              "max": 51
            },
            "19": {
              "min": 52,
              "max": 60
            }
          }
        },
        {
          "name": "Wiadomości",
          "id": 11,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 11
            },
            "5": {
              "min": 12,
              "max": 12
            },
            "6": {
              "min": 13,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 15
            },
            "8": {
              "min": 16,
              "max": 16
            },
            "9": {
              "min": 17,
              "max": 18
            },
            "10": {
              "min": 19,
              "max": 19
            },
            "11": {
              "min": 20,
              "max": 21
            },
            "12": {
              "min": 22,
              "max": 22
            },
            "13": {
              "min": 23,
              "max": 24
            },
            "14": {
              "min": 25,
              "max": 25
            },
            "15": {
              "min": 26,
              "max": 27
            },
            "16": {
              "min": 28,
              "max": 28
            },
            "17": {
              "min": 29,
              "max": 29
            },
            "18": {
              "min": 30,
              "max": 30
            },
            "19": {
              "min": 31,
              "max": 31
            }
          }
        },
        {
          "name": "Tworzenie pojęć",
          "id": 12,
          "rows": {
            "1": {
              "min": 0,
              "max": 5
            },
            "2": {
              "min": 6,
              "max": 6
            },
            "3": {
              "min": 7,
              "max": 8
            },
            "4": {
              "min": 9,
              "max": 9
            },
            "5": {
              "min": 10,
              "max": 10
            },
            "6": {
              "min": 11,
              "max": 11
            },
            "7": {
              "min": 12,
              "max": 12
            },
            "8": {
              "min": 13,
              "max": 14
            },
            "9": {
              "min": 15,
              "max": 15
            },
            "10": {
              "min": 16,
              "max": 16
            },
            "11": {
              "min": 17,
              "max": 17
            },
            "12": {
              "min": 18,
              "max": 18
            },
            "13": {
              "min": 19,
              "max": 19
            },
            "14": {
              "min": 20,
              "max": 20
            },
            "15": {
              "min": 21,
              "max": 21
            },
            "16": {
              "min": 22,
              "max": 22
            },
            "17": {
              "min": 23,
              "max": 23
            },
            "18": {
              "min": 24,
              "max": 24
            },
            "19": {
              "min": 25,
              "max": 27
            }
          }
        },
        {
          "name": "Porządkowanie liter i liczb",
          "id": 13,
          "rows": {
            "1": {
              "min": 0,
              "max": 6
            },
            "2": {
              "min": 7,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 11
            },
            "5": {
              "min": 12,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 15
            },
            "8": {
              "min": 16,
              "max": 16
            },
            "9": {
              "min": 17,
              "max": 17
            },
            "10": {
              "min": 18,
              "max": 18
            },
            "11": {
              "min": 19,
              "max": 19
            },
            "12": {
              "min": null,
              "max": null
            },
            "13": {
              "min": 20,
              "max": 20
            },
            "14": {
              "min": 21,
              "max": 21
            },
            "15": {
              "min": null,
              "max": null
            },
            "16": {
              "min": 22,
              "max": 22
            },
            "17": {
              "min": 23,
              "max": 23
            },
            "18": {
              "min": null,
              "max": null
            },
            "19": {
              "min": 24,
              "max": 30
            }
          }
        },
        {
          "name": "Wykreślanie",
          "id": 14,
          "rows": {
            "1": {
              "min": 0,
              "max": 25
            },
            "2": {
              "min": 26,
              "max": 30
            },
            "3": {
              "min": 31,
              "max": 37
            },
            "4": {
              "min": 38,
              "max": 44
            },
            "5": {
              "min": 45,
              "max": 50
            },
            "6": {
              "min": 51,
              "max": 57
            },
            "7": {
              "min": 58,
              "max": 63
            },
            "8": {
              "min": 64,
              "max": 69
            },
            "9": {
              "min": 70,
              "max": 76
            },
            "10": {
              "min": 77,
              "max": 82
            },
            "11": {
              "min": 83,
              "max": 88
            },
            "12": {
              "min": 89,
              "max": 93
            },
            "13": {
              "min": 94,
              "max": 99
            },
            "14": {
              "min": 100,
              "max": 105
            },
            "15": {
              "min": 106,
              "max": 110
            },
            "16": {
              "min": 111,
              "max": 116
            },
            "17": {
              "min": 117,
              "max": 120
            },
            "18": {
              "min": 121,
              "max": 124
            },
            "19": {
              "min": 125,
              "max": 128
            }
          }
        },
        {
          "name": "Rozumienie",
          "id": 15,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 12
            },
            "5": {
              "min": 13,
              "max": 14
            },
            "6": {
              "min": 15,
              "max": 16
            },
            "7": {
              "min": 17,
              "max": 17
            },
            "8": {
              "min": 18,
              "max": 19
            },
            "9": {
              "min": 20,
              "max": 21
            },
            "10": {
              "min": 22,
              "max": 23
            },
            "11": {
              "min": 24,
              "max": 24
            },
            "12": {
              "min": 25,
              "max": 26
            },
            "13": {
              "min": 27,
              "max": 28
            },
            "14": {
              "min": 29,
              "max": 30
            },
            "15": {
              "min": 31,
              "max": 31
            },
            "16": {
              "min": 32,
              "max": 33
            },
            "17": {
              "min": 34,
              "max": 35
            },
            "18": {
              "min": 36,
              "max": 36
            },
            "19": {
              "min": 37,
              "max": 38
            }
          }
        },
        {
          "name": "Arytmetyka",
          "id": 16,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 12
            },
            "4": {
              "min": 13,
              "max": 14
            },
            "5": {
              "min": 15,
              "max": 16
            },
            "6": {
              "min": 17,
              "max": 18
            },
            "7": {
              "min": 19,
              "max": 19
            },
            "8": {
              "min": 20,
              "max": 21
            },
            "9": {
              "min": 22,
              "max": 23
            },
            "10": {
              "min": 24,
              "max": 24
            },
            "11": {
              "min": 25,
              "max": 25
            },
            "12": {
              "min": 26,
              "max": 27
            },
            "13": {
              "min": 28,
              "max": 28
            },
            "14": {
              "min": 29,
              "max": 29
            },
            "15": {
              "min": 30,
              "max": 30
            },
            "16": {
              "min": 31,
              "max": 31
            },
            "17": {
              "min": 32,
              "max": 32
            },
            "18": {
              "min": 33,
              "max": 33
            },
            "19": {
              "min": 34,
              "max": 34
            }
          }
        }
      ],  

    140143: [
        {
          "name": "Wzory z klocków",
          "id": 1,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 10
            },
            "3": {
              "min": 11,
              "max": 14
            },
            "4": {
              "min": 15,
              "max": 18
            },
            "5": {
              "min": 19,
              "max": 21
            },
            "6": {
              "min": 22,
              "max": 25
            },
            "7": {
              "min": 26,
              "max": 28
            },
            "8": {
              "min": 29,
              "max": 31
            },
            "9": {
              "min": 32,
              "max": 34
            },
            "10": {
              "min": 35,
              "max": 37
            },
            "11": {
              "min": 38,
              "max": 40
            },
            "12": {
              "min": 41,
              "max": 43
            },
            "13": {
              "min": 44,
              "max": 46
            },
            "14": {
              "min": 47,
              "max": 48
            },
            "15": {
              "min": 49,
              "max": 51
            },
            "16": {
              "min": 52,
              "max": 53
            },
            "17": {
              "min": 54,
              "max": 55
            },
            "18": {
              "min": 56,
              "max": 56
            },
            "19": {
              "min": 57,
              "max": 58
            }
          }
        },
        {
          "name": "Podobieństwa",
          "id": 2,
          "rows": {
            "1": {
              "min": 0,
              "max": 12
            },
            "2": {
              "min": 13,
              "max": 15
            },
            "3": {
              "min": 16,
              "max": 17
            },
            "4": {
              "min": 18,
              "max": 19
            },
            "5": {
              "min": 20,
              "max": 21
            },
            "6": {
              "min": 22,
              "max": 23
            },
            "7": {
              "min": 24,
              "max": 25
            },
            "8": {
              "min": 26,
              "max": 27
            },
            "9": {
              "min": 28,
              "max": 29
            },
            "10": {
              "min": 30,
              "max": 31
            },
            "11": {
              "min": 32,
              "max": 32
            },
            "12": {
              "min": 33,
              "max": 34
            },
            "13": {
              "min": 35,
              "max": 36
            },
            "14": {
              "min": 37,
              "max": 37
            },
            "15": {
              "min": 38,
              "max": 39
            },
            "16": {
              "min": 40,
              "max": 40
            },
            "17": {
              "min": 41,
              "max": 42
            },
            "18": {
              "min": 43,
              "max": 43
            },
            "19": {
              "min": 44,
              "max": 46
            }
          }
        },
        {
          "name": "Matryce",
          "id": 3,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 12
            },
            "5": {
              "min": 13,
              "max": 14
            },
            "6": {
              "min": 15,
              "max": 15
            },
            "7": {
              "min": 16,
              "max": 17
            },
            "8": {
              "min": 18,
              "max": 18
            },
            "9": {
              "min": 19,
              "max": 19
            },
            "10": {
              "min": 20,
              "max": 21
            },
            "11": {
              "min": 22,
              "max": 22
            },
            "12": {
              "min": 23,
              "max": 23
            },
            "13": {
              "min": 24,
              "max": 25
            },
            "14": {
              "min": 26,
              "max": 26
            },
            "15": {
              "min": 27,
              "max": 27
            },
            "16": {
              "min": 28,
              "max": 28
            },
            "17": {
              "min": 29,
              "max": 29
            },
            "18": {
              "min": 30,
              "max": 30
            },
            "19": {
              "min": 31,
              "max": 32
            }
          }
        },
        {
          "name": "Powtarzanie liczb",
          "id": 4,
          "rows": {
            "1": {
              "min": 0,
              "max": 9
            },
            "2": {
              "min": 10,
              "max": 11
            },
            "3": {
              "min": 12,
              "max": 13
            },
            "4": {
              "min": 14,
              "max": 15
            },
            "5": {
              "min": 16,
              "max": 17
            },
            "6": {
              "min": 18,
              "max": 19
            },
            "7": {
              "min": 20,
              "max": 21
            },
            "8": {
              "min": 22,
              "max": 23
            },
            "9": {
              "min": 24,
              "max": 25
            },
            "10": {
              "min": 26,
              "max": 26
            },
            "11": {
              "min": 27,
              "max": 28
            },
            "12": {
              "min": 29,
              "max": 30
            },
            "13": {
              "min": 31,
              "max": 32
            },
            "14": {
              "min": 33,
              "max": 34
            },
            "15": {
              "min": 35,
              "max": 35
            },
            "16": {
              "min": 36,
              "max": 37
            },
            "17": {
              "min": 38,
              "max": 39
            },
            "18": {
              "min": 40,
              "max": 40
            },
            "19": {
              "min": 41,
              "max": 54
            }
          }
        },
        {
          "name": "Kodowanie",
          "id": 5,
          "rows": {
            "1": {
              "min": 0,
              "max": 19
            },
            "2": {
              "min": 20,
              "max": 24
            },
            "3": {
              "min": 25,
              "max": 29
            },
            "4": {
              "min": 30,
              "max": 34
            },
            "5": {
              "min": 35,
              "max": 38
            },
            "6": {
              "min": 39,
              "max": 43
            },
            "7": {
              "min": 44,
              "max": 48
            },
            "8": {
              "min": 49,
              "max": 53
            },
            "9": {
              "min": 54,
              "max": 58
            },
            "10": {
              "min": 59,
              "max": 62
            },
            "11": {
              "min": 63,
              "max": 67
            },
            "12": {
              "min": 68,
              "max": 72
            },
            "13": {
              "min": 73,
              "max": 77
            },
            "14": {
              "min": 78,
              "max": 81
            },
            "15": {
              "min": 82,
              "max": 86
            },
            "16": {
              "min": 87,
              "max": 90
            },
            "17": {
              "min": 91,
              "max": 95
            },
            "18": {
              "min": 96,
              "max": 99
            },
            "19": {
              "min": 100,
              "max": 117
            }
          }
        },
        {
          "name": "Słownik",
          "id": 6,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 14
            },
            "5": {
              "min": 15,
              "max": 18
            },
            "6": {
              "min": 19,
              "max": 22
            },
            "7": {
              "min": 23,
              "max": 25
            },
            "8": {
              "min": 26,
              "max": 29
            },
            "9": {
              "min": 30,
              "max": 32
            },
            "10": {
              "min": 33,
              "max": 35
            },
            "11": {
              "min": 36,
              "max": 38
            },
            "12": {
              "min": 39,
              "max": 40
            },
            "13": {
              "min": 41,
              "max": 43
            },
            "14": {
              "min": 44,
              "max": 45
            },
            "15": {
              "min": 46,
              "max": 48
            },
            "16": {
              "min": 49,
              "max": 50
            },
            "17": {
              "min": 51,
              "max": 51
            },
            "18": {
              "min": 52,
              "max": 53
            },
            "19": {
              "min": 54,
              "max": 54
            }
          }
        },
        {
          "name": "Ważenie",
          "id": 7,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 13
            },
            "5": {
              "min": 14,
              "max": 16
            },
            "6": {
              "min": 17,
              "max": 18
            },
            "7": {
              "min": 19,
              "max": 20
            },
            "8": {
              "min": 21,
              "max": 22
            },
            "9": {
              "min": 23,
              "max": 23
            },
            "10": {
              "min": 24,
              "max": 25
            },
            "11": {
              "min": 26,
              "max": 26
            },
            "12": {
              "min": 27,
              "max": 27
            },
            "13": {
              "min": 28,
              "max": 28
            },
            "14": {
              "min": 29,
              "max": 29
            },
            "15": {
              "min": 30,
              "max": 30
            },
            "16": {
              "min": null,
              "max": null
            },
            "17": {
              "min": 31,
              "max": 31
            },
            "18": {
              "min": 32,
              "max": 32
            },
            "19": {
              "min": 33,
              "max": 34
            }
          }
        },
        {
          "name": "Układnie wzoru",
          "id": 8,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 4
            },
            "3": {
              "min": 5,
              "max": 6
            },
            "4": {
              "min": 7,
              "max": 9
            },
            "5": {
              "min": 10,
              "max": 11
            },
            "6": {
              "min": 12,
              "max": 13
            },
            "7": {
              "min": 14,
              "max": 15
            },
            "8": {
              "min": 16,
              "max": 17
            },
            "9": {
              "min": 18,
              "max": 18
            },
            "10": {
              "min": 19,
              "max": 20
            },
            "11": {
              "min": 21,
              "max": 21
            },
            "12": {
              "min": 22,
              "max": 22
            },
            "13": {
              "min": 23,
              "max": 23
            },
            "14": {
              "min": 24,
              "max": 24
            },
            "15": {
              "min": 25,
              "max": 25
            },
            "16": {
              "min": 26,
              "max": 26
            },
            "17": {
              "min": 27,
              "max": 27
            },
            "18": {
              "min": null,
              "max": null
            },
            "19": {
              "min": 28,
              "max": 29
            }
          }
        },
        {
          "name": "Zapamiętywanie obrazków",
          "id": 9,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 11
            },
            "3": {
              "min": 12,
              "max": 14
            },
            "4": {
              "min": 15,
              "max": 16
            },
            "5": {
              "min": 17,
              "max": 19
            },
            "6": {
              "min": 20,
              "max": 22
            },
            "7": {
              "min": 23,
              "max": 24
            },
            "8": {
              "min": 25,
              "max": 27
            },
            "9": {
              "min": 28,
              "max": 29
            },
            "10": {
              "min": 30,
              "max": 31
            },
            "11": {
              "min": 32,
              "max": 34
            },
            "12": {
              "min": 35,
              "max": 36
            },
            "13": {
              "min": 37,
              "max": 38
            },
            "14": {
              "min": 39,
              "max": 41
            },
            "15": {
              "min": 42,
              "max": 43
            },
            "16": {
              "min": 44,
              "max": 45
            },
            "17": {
              "min": 46,
              "max": 46
            },
            "18": {
              "min": 47,
              "max": 47
            },
            "19": {
              "min": 48,
              "max": 49
            }
          }
        },
        {
          "name": "Szukanie symboli",
          "id": 10,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 12
            },
            "3": {
              "min": 13,
              "max": 15
            },
            "4": {
              "min": 16,
              "max": 18
            },
            "5": {
              "min": 19,
              "max": 20
            },
            "6": {
              "min": 21,
              "max": 23
            },
            "7": {
              "min": 24,
              "max": 26
            },
            "8": {
              "min": 27,
              "max": 28
            },
            "9": {
              "min": 29,
              "max": 31
            },
            "10": {
              "min": 32,
              "max": 33
            },
            "11": {
              "min": 34,
              "max": 36
            },
            "12": {
              "min": 37,
              "max": 38
            },
            "13": {
              "min": 39,
              "max": 41
            },
            "14": {
              "min": 42,
              "max": 43
            },
            "15": {
              "min": 44,
              "max": 45
            },
            "16": {
              "min": 46,
              "max": 47
            },
            "17": {
              "min": 48,
              "max": 50
            },
            "18": {
              "min": 51,
              "max": 52
            },
            "19": {
              "min": 53,
              "max": 60
            }
          }
        },
        {
          "name": "Wiadomości",
          "id": 11,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 11
            },
            "5": {
              "min": 12,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 15
            },
            "8": {
              "min": 16,
              "max": 17
            },
            "9": {
              "min": 18,
              "max": 18
            },
            "10": {
              "min": 19,
              "max": 20
            },
            "11": {
              "min": 21,
              "max": 21
            },
            "12": {
              "min": 22,
              "max": 23
            },
            "13": {
              "min": 24,
              "max": 24
            },
            "14": {
              "min": 25,
              "max": 26
            },
            "15": {
              "min": 27,
              "max": 27
            },
            "16": {
              "min": 28,
              "max": 28
            },
            "17": {
              "min": 29,
              "max": 29
            },
            "18": {
              "min": 30,
              "max": 30
            },
            "19": {
              "min": 31,
              "max": 31
            }
          }
        },
        {
          "name": "Tworzenie pojęć",
          "id": 12,
          "rows": {
            "1": {
              "min": 0,
              "max": 5
            },
            "2": {
              "min": 6,
              "max": 6
            },
            "3": {
              "min": 7,
              "max": 8
            },
            "4": {
              "min": 9,
              "max": 9
            },
            "5": {
              "min": 10,
              "max": 10
            },
            "6": {
              "min": 11,
              "max": 11
            },
            "7": {
              "min": 12,
              "max": 13
            },
            "8": {
              "min": 14,
              "max": 14
            },
            "9": {
              "min": 15,
              "max": 15
            },
            "10": {
              "min": 16,
              "max": 16
            },
            "11": {
              "min": 17,
              "max": 17
            },
            "12": {
              "min": 18,
              "max": 18
            },
            "13": {
              "min": 19,
              "max": 19
            },
            "14": {
              "min": 20,
              "max": 20
            },
            "15": {
              "min": 21,
              "max": 21
            },
            "16": {
              "min": 22,
              "max": 22
            },
            "17": {
              "min": 23,
              "max": 23
            },
            "18": {
              "min": 24,
              "max": 24
            },
            "19": {
              "min": 25,
              "max": 27
            }
          }
        },
        {
          "name": "Porządkowanie liter i liczb",
          "id": 13,
          "rows": {
            "1": {
              "min": 0,
              "max": 6
            },
            "2": {
              "min": 7,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 12
            },
            "5": {
              "min": 13,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 15
            },
            "8": {
              "min": 16,
              "max": 16
            },
            "9": {
              "min": 17,
              "max": 17
            },
            "10": {
              "min": 18,
              "max": 18
            },
            "11": {
              "min": 19,
              "max": 19
            },
            "12": {
              "min": null,
              "max": null
            },
            "13": {
              "min": 20,
              "max": 20
            },
            "14": {
              "min": 21,
              "max": 21
            },
            "15": {
              "min": 22,
              "max": 22
            },
            "16": {
              "min": null,
              "max": null
            },
            "17": {
              "min": 23,
              "max": 23
            },
            "18": {
              "min": null,
              "max": null
            },
            "19": {
              "min": 24,
              "max": 30
            }
          }
        },
        {
          "name": "Wykreślanie",
          "id": 14,
          "rows": {
            "1": {
              "min": 0,
              "max": 25
            },
            "2": {
              "min": 26,
              "max": 30
            },
            "3": {
              "min": 31,
              "max": 37
            },
            "4": {
              "min": 38,
              "max": 45
            },
            "5": {
              "min": 46,
              "max": 51
            },
            "6": {
              "min": 52,
              "max": 58
            },
            "7": {
              "min": 59,
              "max": 65
            },
            "8": {
              "min": 66,
              "max": 71
            },
            "9": {
              "min": 72,
              "max": 77
            },
            "10": {
              "min": 78,
              "max": 83
            },
            "11": {
              "min": 84,
              "max": 89
            },
            "12": {
              "min": 90,
              "max": 95
            },
            "13": {
              "min": 96,
              "max": 101
            },
            "14": {
              "min": 102,
              "max": 106
            },
            "15": {
              "min": 107,
              "max": 112
            },
            "16": {
              "min": 113,
              "max": 117
            },
            "17": {
              "min": 118,
              "max": 121
            },
            "18": {
              "min": 122,
              "max": 124
            },
            "19": {
              "min": 125,
              "max": 128
            }
          }
        },
        {
          "name": "Rozumienie",
          "id": 15,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 12
            },
            "5": {
              "min": 13,
              "max": 14
            },
            "6": {
              "min": 15,
              "max": 16
            },
            "7": {
              "min": 17,
              "max": 18
            },
            "8": {
              "min": 19,
              "max": 19
            },
            "9": {
              "min": 20,
              "max": 21
            },
            "10": {
              "min": 22,
              "max": 23
            },
            "11": {
              "min": 24,
              "max": 25
            },
            "12": {
              "min": 26,
              "max": 26
            },
            "13": {
              "min": 27,
              "max": 28
            },
            "14": {
              "min": 29,
              "max": 30
            },
            "15": {
              "min": 31,
              "max": 32
            },
            "16": {
              "min": 33,
              "max": 33
            },
            "17": {
              "min": 34,
              "max": 35
            },
            "18": {
              "min": 36,
              "max": 36
            },
            "19": {
              "min": 37,
              "max": 38
            }
          }
        },
        {
          "name": "Arytmetyka",
          "id": 16,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 10
            },
            "3": {
              "min": 11,
              "max": 12
            },
            "4": {
              "min": 13,
              "max": 14
            },
            "5": {
              "min": 15,
              "max": 16
            },
            "6": {
              "min": 17,
              "max": 18
            },
            "7": {
              "min": 19,
              "max": 20
            },
            "8": {
              "min": 21,
              "max": 21
            },
            "9": {
              "min": 22,
              "max": 23
            },
            "10": {
              "min": 24,
              "max": 24
            },
            "11": {
              "min": 25,
              "max": 26
            },
            "12": {
              "min": 27,
              "max": 27
            },
            "13": {
              "min": 28,
              "max": 28
            },
            "14": {
              "min": 29,
              "max": 29
            },
            "15": {
              "min": 30,
              "max": 30
            },
            "16": {
              "min": 31,
              "max": 31
            },
            "17": {
              "min": 32,
              "max": 32
            },
            "18": {
              "min": 33,
              "max": 33
            },
            "19": {
              "min": 34,
              "max": 34
            }
          }
        }
      ],  

    144147: [
        {
          "name": "Wzory z klocków",
          "id": 1,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 10
            },
            "3": {
              "min": 11,
              "max": 14
            },
            "4": {
              "min": 15,
              "max": 18
            },
            "5": {
              "min": 19,
              "max": 22
            },
            "6": {
              "min": 23,
              "max": 25
            },
            "7": {
              "min": 26,
              "max": 28
            },
            "8": {
              "min": 29,
              "max": 31
            },
            "9": {
              "min": 32,
              "max": 35
            },
            "10": {
              "min": 36,
              "max": 38
            },
            "11": {
              "min": 39,
              "max": 41
            },
            "12": {
              "min": 42,
              "max": 43
            },
            "13": {
              "min": 44,
              "max": 46
            },
            "14": {
              "min": 47,
              "max": 49
            },
            "15": {
              "min": 50,
              "max": 51
            },
            "16": {
              "min": 52,
              "max": 54
            },
            "17": {
              "min": 55,
              "max": 55
            },
            "18": {
              "min": 56,
              "max": 57
            },
            "19": {
              "min": 58,
              "max": 58
            }
          }
        },
        {
          "name": "Podobieństwa",
          "id": 2,
          "rows": {
            "1": {
              "min": 0,
              "max": 12
            },
            "2": {
              "min": 13,
              "max": 15
            },
            "3": {
              "min": 16,
              "max": 17
            },
            "4": {
              "min": 18,
              "max": 19
            },
            "5": {
              "min": 20,
              "max": 21
            },
            "6": {
              "min": 22,
              "max": 23
            },
            "7": {
              "min": 24,
              "max": 25
            },
            "8": {
              "min": 26,
              "max": 27
            },
            "9": {
              "min": 28,
              "max": 29
            },
            "10": {
              "min": 30,
              "max": 31
            },
            "11": {
              "min": 32,
              "max": 33
            },
            "12": {
              "min": 34,
              "max": 34
            },
            "13": {
              "min": 35,
              "max": 36
            },
            "14": {
              "min": 37,
              "max": 38
            },
            "15": {
              "min": 39,
              "max": 39
            },
            "16": {
              "min": 40,
              "max": 41
            },
            "17": {
              "min": 42,
              "max": 42
            },
            "18": {
              "min": 43,
              "max": 43
            },
            "19": {
              "min": 44,
              "max": 46
            }
          }
        },
        {
          "name": "Matryce",
          "id": 3,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 12
            },
            "5": {
              "min": 13,
              "max": 14
            },
            "6": {
              "min": 15,
              "max": 15
            },
            "7": {
              "min": 16,
              "max": 17
            },
            "8": {
              "min": 18,
              "max": 18
            },
            "9": {
              "min": 19,
              "max": 20
            },
            "10": {
              "min": 21,
              "max": 21
            },
            "11": {
              "min": 22,
              "max": 22
            },
            "12": {
              "min": 23,
              "max": 24
            },
            "13": {
              "min": 25,
              "max": 25
            },
            "14": {
              "min": 26,
              "max": 26
            },
            "15": {
              "min": 27,
              "max": 27
            },
            "16": {
              "min": 28,
              "max": 29
            },
            "17": {
              "min": 30,
              "max": 30
            },
            "18": {
              "min": null,
              "max": null
            },
            "19": {
              "min": 31,
              "max": 31
            }
          }
        },
        {
          "name": "Powtarzanie liczb",
          "id": 4,
          "rows": {
            "1": {
              "min": 0,
              "max": 9
            },
            "2": {
              "min": 10,
              "max": 11
            },
            "3": {
              "min": 12,
              "max": 13
            },
            "4": {
              "min": 14,
              "max": 15
            },
            "5": {
              "min": 16,
              "max": 17
            },
            "6": {
              "min": 18,
              "max": 19
            },
            "7": {
              "min": 20,
              "max": 21
            },
            "8": {
              "min": 22,
              "max": 23
            },
            "9": {
              "min": 24,
              "max": 25
            },
            "10": {
              "min": 26,
              "max": 27
            },
            "11": {
              "min": 28,
              "max": 28
            },
            "12": {
              "min": 29,
              "max": 30
            },
            "13": {
              "min": 31,
              "max": 32
            },
            "14": {
              "min": 33,
              "max": 34
            },
            "15": {
              "min": 35,
              "max": 36
            },
            "16": {
              "min": 37,
              "max": 37
            },
            "17": {
              "min": 38,
              "max": 39
            },
            "18": {
              "min": 40,
              "max": 41
            },
            "19": {
              "min": 42,
              "max": 54
            }
          }
        },
        {
          "name": "Kodowanie",
          "id": 5,
          "rows": {
            "1": {
              "min": 0,
              "max": 19
            },
            "2": {
              "min": 20,
              "max": 24
            },
            "3": {
              "min": 25,
              "max": 29
            },
            "4": {
              "min": 30,
              "max": 35
            },
            "5": {
              "min": 36,
              "max": 40
            },
            "6": {
              "min": 41,
              "max": 44
            },
            "7": {
              "min": 45,
              "max": 49
            },
            "8": {
              "min": 50,
              "max": 54
            },
            "9": {
              "min": 55,
              "max": 59
            },
            "10": {
              "min": 60,
              "max": 64
            },
            "11": {
              "min": 65,
              "max": 69
            },
            "12": {
              "min": 70,
              "max": 73
            },
            "13": {
              "min": 74,
              "max": 78
            },
            "14": {
              "min": 79,
              "max": 83
            },
            "15": {
              "min": 84,
              "max": 88
            },
            "16": {
              "min": 89,
              "max": 92
            },
            "17": {
              "min": 93,
              "max": 97
            },
            "18": {
              "min": 98,
              "max": 101
            },
            "19": {
              "min": 102,
              "max": 117
            }
          }
        },
        {
          "name": "Słownik",
          "id": 6,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 15
            },
            "5": {
              "min": 16,
              "max": 19
            },
            "6": {
              "min": 20,
              "max": 23
            },
            "7": {
              "min": 24,
              "max": 26
            },
            "8": {
              "min": 27,
              "max": 30
            },
            "9": {
              "min": 31,
              "max": 33
            },
            "10": {
              "min": 34,
              "max": 36
            },
            "11": {
              "min": 37,
              "max": 38
            },
            "12": {
              "min": 39,
              "max": 41
            },
            "13": {
              "min": 42,
              "max": 44
            },
            "14": {
              "min": 45,
              "max": 46
            },
            "15": {
              "min": 47,
              "max": 48
            },
            "16": {
              "min": 49,
              "max": 50
            },
            "17": {
              "min": 51,
              "max": 52
            },
            "18": {
              "min": 53,
              "max": 53
            },
            "19": {
              "min": 54,
              "max": 54
            }
          }
        },
        {
          "name": "Ważenie",
          "id": 7,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 13
            },
            "5": {
              "min": 14,
              "max": 16
            },
            "6": {
              "min": 17,
              "max": 18
            },
            "7": {
              "min": 19,
              "max": 20
            },
            "8": {
              "min": 21,
              "max": 22
            },
            "9": {
              "min": 23,
              "max": 23
            },
            "10": {
              "min": 24,
              "max": 25
            },
            "11": {
              "min": 26,
              "max": 26
            },
            "12": {
              "min": 27,
              "max": 27
            },
            "13": {
              "min": 28,
              "max": 28
            },
            "14": {
              "min": 29,
              "max": 29
            },
            "15": {
              "min": 30,
              "max": 30
            },
            "16": {
              "min": 31,
              "max": 31
            },
            "17": {
              "min": null,
              "max": null
            },
            "18": {
              "min": 32,
              "max": 32
            },
            "19": {
              "min": 33,
              "max": 34
            }
          }
        },
        {
          "name": "Układnie wzoru",
          "id": 8,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 4
            },
            "3": {
              "min": 5,
              "max": 7
            },
            "4": {
              "min": 8,
              "max": 9
            },
            "5": {
              "min": 10,
              "max": 11
            },
            "6": {
              "min": 12,
              "max": 13
            },
            "7": {
              "min": 14,
              "max": 15
            },
            "8": {
              "min": 16,
              "max": 17
            },
            "9": {
              "min": 18,
              "max": 18
            },
            "10": {
              "min": 19,
              "max": 20
            },
            "11": {
              "min": 21,
              "max": 21
            },
            "12": {
              "min": 22,
              "max": 22
            },
            "13": {
              "min": 23,
              "max": 23
            },
            "14": {
              "min": 24,
              "max": 24
            },
            "15": {
              "min": 25,
              "max": 25
            },
            "16": {
              "min": 26,
              "max": 26
            },
            "17": {
              "min": 27,
              "max": 27
            },
            "18": {
              "min": null,
              "max": null
            },
            "19": {
              "min": 28,
              "max": 29
            }
          }
        },
        {
          "name": "Zapamiętywanie obrazków",
          "id": 9,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 11
            },
            "3": {
              "min": 12,
              "max": 14
            },
            "4": {
              "min": 15,
              "max": 17
            },
            "5": {
              "min": 18,
              "max": 19
            },
            "6": {
              "min": 20,
              "max": 22
            },
            "7": {
              "min": 23,
              "max": 24
            },
            "8": {
              "min": 25,
              "max": 27
            },
            "9": {
              "min": 28,
              "max": 29
            },
            "10": {
              "min": 30,
              "max": 32
            },
            "11": {
              "min": 33,
              "max": 34
            },
            "12": {
              "min": 35,
              "max": 36
            },
            "13": {
              "min": 37,
              "max": 38
            },
            "14": {
              "min": 39,
              "max": 41
            },
            "15": {
              "min": 42,
              "max": 43
            },
            "16": {
              "min": 44,
              "max": 45
            },
            "17": {
              "min": 46,
              "max": 46
            },
            "18": {
              "min": 47,
              "max": 47
            },
            "19": {
              "min": 48,
              "max": 49
            }
          }
        },
        {
          "name": "Szukanie symboli",
          "id": 10,
          "rows": {
            "1": {
              "min": 0,
              "max": 9
            },
            "2": {
              "min": 10,
              "max": 12
            },
            "3": {
              "min": 13,
              "max": 15
            },
            "4": {
              "min": 16,
              "max": 18
            },
            "5": {
              "min": 19,
              "max": 21
            },
            "6": {
              "min": 22,
              "max": 24
            },
            "7": {
              "min": 25,
              "max": 26
            },
            "8": {
              "min": 27,
              "max": 29
            },
            "9": {
              "min": 30,
              "max": 31
            },
            "10": {
              "min": 32,
              "max": 34
            },
            "11": {
              "min": 35,
              "max": 36
            },
            "12": {
              "min": 37,
              "max": 39
            },
            "13": {
              "min": 40,
              "max": 41
            },
            "14": {
              "min": 42,
              "max": 43
            },
            "15": {
              "min": 44,
              "max": 46
            },
            "16": {
              "min": 47,
              "max": 48
            },
            "17": {
              "min": 49,
              "max": 50
            },
            "18": {
              "min": 51,
              "max": 52
            },
            "19": {
              "min": 53,
              "max": 60
            }
          }
        },
        {
          "name": "Wiadomości",
          "id": 11,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 11
            },
            "5": {
              "min": 12,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 15
            },
            "8": {
              "min": 16,
              "max": 17
            },
            "9": {
              "min": 18,
              "max": 18
            },
            "10": {
              "min": 19,
              "max": 20
            },
            "11": {
              "min": 21,
              "max": 21
            },
            "12": {
              "min": 22,
              "max": 23
            },
            "13": {
              "min": 24,
              "max": 25
            },
            "14": {
              "min": 26,
              "max": 26
            },
            "15": {
              "min": 27,
              "max": 28
            },
            "16": {
              "min": 29,
              "max": 29
            },
            "17": {
              "min": 30,
              "max": 30
            },
            "18": {
              "min": null,
              "max": null
            },
            "19": {
              "min": 31,
              "max": 31
            }
          }
        },
        {
          "name": "Tworzenie pojęć",
          "id": 12,
          "rows": {
            "1": {
              "min": 0,
              "max": 5
            },
            "2": {
              "min": 6,
              "max": 6
            },
            "3": {
              "min": 7,
              "max": 8
            },
            "4": {
              "min": 9,
              "max": 9
            },
            "5": {
              "min": 10,
              "max": 10
            },
            "6": {
              "min": 11,
              "max": 11
            },
            "7": {
              "min": 12,
              "max": 13
            },
            "8": {
              "min": 14,
              "max": 14
            },
            "9": {
              "min": 15,
              "max": 15
            },
            "10": {
              "min": 16,
              "max": 16
            },
            "11": {
              "min": 17,
              "max": 17
            },
            "12": {
              "min": 18,
              "max": 18
            },
            "13": {
              "min": 19,
              "max": 19
            },
            "14": {
              "min": 20,
              "max": 20
            },
            "15": {
              "min": 21,
              "max": 21
            },
            "16": {
              "min": 22,
              "max": 22
            },
            "17": {
              "min": 23,
              "max": 23
            },
            "18": {
              "min": 24,
              "max": 24
            },
            "19": {
              "min": 25,
              "max": 27
            }
          }
        },
        {
          "name": "Porządkowanie liter i liczb",
          "id": 13,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 12
            },
            "5": {
              "min": 13,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 15
            },
            "8": {
              "min": 16,
              "max": 16
            },
            "9": {
              "min": 17,
              "max": 17
            },
            "10": {
              "min": 18,
              "max": 18
            },
            "11": {
              "min": 19,
              "max": 19
            },
            "12": {
              "min": 20,
              "max": 20
            },
            "13": {
              "min": null,
              "max": null
            },
            "14": {
              "min": 21,
              "max": 21
            },
            "15": {
              "min": 22,
              "max": 22
            },
            "16": {
              "min": null,
              "max": null
            },
            "17": {
              "min": 23,
              "max": 23
            },
            "18": {
              "min": 24,
              "max": 24
            },
            "19": {
              "min": 25,
              "max": 30
            }
          }
        },
        {
          "name": "Wykreślanie",
          "id": 14,
          "rows": {
            "1": {
              "min": 0,
              "max": 25
            },
            "2": {
              "min": 26,
              "max": 30
            },
            "3": {
              "min": 31,
              "max": 38
            },
            "4": {
              "min": 39,
              "max": 45
            },
            "5": {
              "min": 46,
              "max": 53
            },
            "6": {
              "min": 54,
              "max": 59
            },
            "7": {
              "min": 60,
              "max": 66
            },
            "8": {
              "min": 67,
              "max": 72
            },
            "9": {
              "min": 73,
              "max": 79
            },
            "10": {
              "min": 80,
              "max": 85
            },
            "11": {
              "min": 86,
              "max": 91
            },
            "12": {
              "min": 92,
              "max": 97
            },
            "13": {
              "min": 98,
              "max": 102
            },
            "14": {
              "min": 103,
              "max": 108
            },
            "15": {
              "min": 109,
              "max": 113
            },
            "16": {
              "min": 114,
              "max": 118
            },
            "17": {
              "min": 119,
              "max": 122
            },
            "18": {
              "min": 123,
              "max": 125
            },
            "19": {
              "min": 126,
              "max": 128
            }
          }
        },
        {
          "name": "Rozumienie",
          "id": 15,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 13
            },
            "5": {
              "min": 14,
              "max": 15
            },
            "6": {
              "min": 16,
              "max": 16
            },
            "7": {
              "min": 17,
              "max": 18
            },
            "8": {
              "min": 19,
              "max": 20
            },
            "9": {
              "min": 21,
              "max": 22
            },
            "10": {
              "min": 23,
              "max": 23
            },
            "11": {
              "min": 24,
              "max": 25
            },
            "12": {
              "min": 26,
              "max": 27
            },
            "13": {
              "min": 28,
              "max": 29
            },
            "14": {
              "min": 30,
              "max": 30
            },
            "15": {
              "min": 31,
              "max": 32
            },
            "16": {
              "min": 33,
              "max": 33
            },
            "17": {
              "min": 34,
              "max": 35
            },
            "18": {
              "min": 36,
              "max": 36
            },
            "19": {
              "min": 37,
              "max": 38
            }
          }
        },
        {
          "name": "Arytmetyka",
          "id": 16,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 10
            },
            "3": {
              "min": 11,
              "max": 12
            },
            "4": {
              "min": 13,
              "max": 15
            },
            "5": {
              "min": 16,
              "max": 17
            },
            "6": {
              "min": 18,
              "max": 18
            },
            "7": {
              "min": 19,
              "max": 20
            },
            "8": {
              "min": 21,
              "max": 22
            },
            "9": {
              "min": 23,
              "max": 23
            },
            "10": {
              "min": 24,
              "max": 24
            },
            "11": {
              "min": 25,
              "max": 26
            },
            "12": {
              "min": 27,
              "max": 27
            },
            "13": {
              "min": 28,
              "max": 28
            },
            "14": {
              "min": 29,
              "max": 29
            },
            "15": {
              "min": 30,
              "max": 30
            },
            "16": {
              "min": 31,
              "max": 31
            },
            "17": {
              "min": 32,
              "max": 32
            },
            "18": {
              "min": 33,
              "max": 33
            },
            "19": {
              "min": 34,
              "max": 34
            }
          }
        }
      ],  
    
    1481411: [
        {
          "name": "Wzory z klocków",
          "id": 1,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 10
            },
            "3": {
              "min": 11,
              "max": 14
            },
            "4": {
              "min": 15,
              "max": 18
            },
            "5": {
              "min": 19,
              "max": 22
            },
            "6": {
              "min": 23,
              "max": 25
            },
            "7": {
              "min": 26,
              "max": 29
            },
            "8": {
              "min": 30,
              "max": 32
            },
            "9": {
              "min": 33,
              "max": 35
            },
            "10": {
              "min": 36,
              "max": 38
            },
            "11": {
              "min": 39,
              "max": 41
            },
            "12": {
              "min": 42,
              "max": 44
            },
            "13": {
              "min": 45,
              "max": 47
            },
            "14": {
              "min": 48,
              "max": 49
            },
            "15": {
              "min": 50,
              "max": 52
            },
            "16": {
              "min": 53,
              "max": 54
            },
            "17": {
              "min": 55,
              "max": 56
            },
            "18": {
              "min": 57,
              "max": 57
            },
            "19": {
              "min": 58,
              "max": 58
            }
          }
        },
        {
          "name": "Podobieństwa",
          "id": 2,
          "rows": {
            "1": {
              "min": 0,
              "max": 12
            },
            "2": {
              "min": 13,
              "max": 15
            },
            "3": {
              "min": 16,
              "max": 17
            },
            "4": {
              "min": 18,
              "max": 19
            },
            "5": {
              "min": 20,
              "max": 21
            },
            "6": {
              "min": 22,
              "max": 24
            },
            "7": {
              "min": 25,
              "max": 26
            },
            "8": {
              "min": 27,
              "max": 28
            },
            "9": {
              "min": 29,
              "max": 29
            },
            "10": {
              "min": 30,
              "max": 31
            },
            "11": {
              "min": 32,
              "max": 33
            },
            "12": {
              "min": 34,
              "max": 35
            },
            "13": {
              "min": 36,
              "max": 36
            },
            "14": {
              "min": 37,
              "max": 38
            },
            "15": {
              "min": 39,
              "max": 40
            },
            "16": {
              "min": 41,
              "max": 41
            },
            "17": {
              "min": 42,
              "max": 42
            },
            "18": {
              "min": 43,
              "max": 44
            },
            "19": {
              "min": 45,
              "max": 46
            }
          }
        },
        {
          "name": "Matryce",
          "id": 3,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 12
            },
            "5": {
              "min": 13,
              "max": 14
            },
            "6": {
              "min": 15,
              "max": 15
            },
            "7": {
              "min": 16,
              "max": 17
            },
            "8": {
              "min": 18,
              "max": 18
            },
            "9": {
              "min": 19,
              "max": 20
            },
            "10": {
              "min": 21,
              "max": 21
            },
            "11": {
              "min": 22,
              "max": 22
            },
            "12": {
              "min": 23,
              "max": 24
            },
            "13": {
              "min": 25,
              "max": 25
            },
            "14": {
              "min": 26,
              "max": 26
            },
            "15": {
              "min": 27,
              "max": 28
            },
            "16": {
              "min": 29,
              "max": 29
            },
            "17": {
              "min": 30,
              "max": 30
            },
            "18": {
              "min": 31,
              "max": 31
            },
            "19": {
              "min": 32,
              "max": 32
            }
          }
        },
        {
          "name": "Powtarzanie liczb",
          "id": 4,
          "rows": {
            "1": {
              "min": 0,
              "max": 9
            },
            "2": {
              "min": 10,
              "max": 11
            },
            "3": {
              "min": 12,
              "max": 13
            },
            "4": {
              "min": 14,
              "max": 15
            },
            "5": {
              "min": 16,
              "max": 17
            },
            "6": {
              "min": 18,
              "max": 19
            },
            "7": {
              "min": 20,
              "max": 21
            },
            "8": {
              "min": 22,
              "max": 23
            },
            "9": {
              "min": 24,
              "max": 25
            },
            "10": {
              "min": 26,
              "max": 27
            },
            "11": {
              "min": 28,
              "max": 29
            },
            "12": {
              "min": 30,
              "max": 30
            },
            "13": {
              "min": 31,
              "max": 32
            },
            "14": {
              "min": 33,
              "max": 34
            },
            "15": {
              "min": 35,
              "max": 36
            },
            "16": {
              "min": 37,
              "max": 38
            },
            "17": {
              "min": 39,
              "max": 39
            },
            "18": {
              "min": 40,
              "max": 41
            },
            "19": {
              "min": 42,
              "max": 54
            }
          }
        },
        {
          "name": "Kodowanie",
          "id": 5,
          "rows": {
            "1": {
              "min": 0,
              "max": 20
            },
            "2": {
              "min": 21,
              "max": 25
            },
            "3": {
              "min": 26,
              "max": 30
            },
            "4": {
              "min": 31,
              "max": 35
            },
            "5": {
              "min": 36,
              "max": 41
            },
            "6": {
              "min": 42,
              "max": 46
            },
            "7": {
              "min": 47,
              "max": 51
            },
            "8": {
              "min": 52,
              "max": 56
            },
            "9": {
              "min": 57,
              "max": 60
            },
            "10": {
              "min": 61,
              "max": 65
            },
            "11": {
              "min": 66,
              "max": 70
            },
            "12": {
              "min": 71,
              "max": 75
            },
            "13": {
              "min": 76,
              "max": 80
            },
            "14": {
              "min": 81,
              "max": 84
            },
            "15": {
              "min": 85,
              "max": 89
            },
            "16": {
              "min": 90,
              "max": 94
            },
            "17": {
              "min": 95,
              "max": 99
            },
            "18": {
              "min": 100,
              "max": 103
            },
            "19": {
              "min": 104,
              "max": 117
            }
          }
        },
        {
          "name": "Słownik",
          "id": 6,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 15
            },
            "5": {
              "min": 16,
              "max": 19
            },
            "6": {
              "min": 20,
              "max": 23
            },
            "7": {
              "min": 24,
              "max": 27
            },
            "8": {
              "min": 28,
              "max": 30
            },
            "9": {
              "min": 31,
              "max": 33
            },
            "10": {
              "min": 34,
              "max": 36
            },
            "11": {
              "min": 37,
              "max": 39
            },
            "12": {
              "min": 40,
              "max": 42
            },
            "13": {
              "min": 43,
              "max": 44
            },
            "14": {
              "min": 45,
              "max": 47
            },
            "15": {
              "min": 48,
              "max": 49
            },
            "16": {
              "min": 50,
              "max": 50
            },
            "17": {
              "min": 51,
              "max": 52
            },
            "18": {
              "min": 53,
              "max": 53
            },
            "19": {
              "min": 54,
              "max": 54
            }
          }
        },
        {
          "name": "Ważenie",
          "id": 7,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 13
            },
            "5": {
              "min": 14,
              "max": 16
            },
            "6": {
              "min": 17,
              "max": 18
            },
            "7": {
              "min": 19,
              "max": 20
            },
            "8": {
              "min": 21,
              "max": 22
            },
            "9": {
              "min": 23,
              "max": 23
            },
            "10": {
              "min": 24,
              "max": 25
            },
            "11": {
              "min": 26,
              "max": 26
            },
            "12": {
              "min": 27,
              "max": 27
            },
            "13": {
              "min": 28,
              "max": 28
            },
            "14": {
              "min": 29,
              "max": 29
            },
            "15": {
              "min": 30,
              "max": 30
            },
            "16": {
              "min": 31,
              "max": 31
            },
            "17": {
              "min": null,
              "max": null
            },
            "18": {
              "min": 32,
              "max": 32
            },
            "19": {
              "min": 33,
              "max": 34
            }
          }
        },
        {
          "name": "Układnie wzoru",
          "id": 8,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 5
            },
            "3": {
              "min": 6,
              "max": 7
            },
            "4": {
              "min": 8,
              "max": 9
            },
            "5": {
              "min": 10,
              "max": 11
            },
            "6": {
              "min": 12,
              "max": 13
            },
            "7": {
              "min": 14,
              "max": 15
            },
            "8": {
              "min": 16,
              "max": 17
            },
            "9": {
              "min": 18,
              "max": 18
            },
            "10": {
              "min": 19,
              "max": 20
            },
            "11": {
              "min": 21,
              "max": 21
            },
            "12": {
              "min": 22,
              "max": 22
            },
            "13": {
              "min": 23,
              "max": 23
            },
            "14": {
              "min": 24,
              "max": 24
            },
            "15": {
              "min": 25,
              "max": 25
            },
            "16": {
              "min": 26,
              "max": 26
            },
            "17": {
              "min": 27,
              "max": 27
            },
            "18": {
              "min": 28,
              "max": 28
            },
            "19": {
              "min": 29,
              "max": 29
            }
          }
        },
        {
          "name": "Zapamiętywanie obrazków",
          "id": 9,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 11
            },
            "3": {
              "min": 12,
              "max": 14
            },
            "4": {
              "min": 15,
              "max": 17
            },
            "5": {
              "min": 18,
              "max": 20
            },
            "6": {
              "min": 21,
              "max": 22
            },
            "7": {
              "min": 23,
              "max": 25
            },
            "8": {
              "min": 26,
              "max": 27
            },
            "9": {
              "min": 28,
              "max": 29
            },
            "10": {
              "min": 30,
              "max": 32
            },
            "11": {
              "min": 33,
              "max": 34
            },
            "12": {
              "min": 35,
              "max": 36
            },
            "13": {
              "min": 37,
              "max": 39
            },
            "14": {
              "min": 40,
              "max": 41
            },
            "15": {
              "min": 42,
              "max": 43
            },
            "16": {
              "min": 44,
              "max": 45
            },
            "17": {
              "min": 46,
              "max": 46
            },
            "18": {
              "min": 47,
              "max": 47
            },
            "19": {
              "min": 48,
              "max": 49
            }
          }
        },
        {
          "name": "Szukanie symboli",
          "id": 10,
          "rows": {
            "1": {
              "min": 0,
              "max": 9
            },
            "2": {
              "min": 10,
              "max": 12
            },
            "3": {
              "min": 13,
              "max": 15
            },
            "4": {
              "min": 16,
              "max": 18
            },
            "5": {
              "min": 19,
              "max": 21
            },
            "6": {
              "min": 22,
              "max": 24
            },
            "7": {
              "min": 25,
              "max": 27
            },
            "8": {
              "min": 28,
              "max": 29
            },
            "9": {
              "min": 30,
              "max": 32
            },
            "10": {
              "min": 33,
              "max": 34
            },
            "11": {
              "min": 35,
              "max": 37
            },
            "12": {
              "min": 38,
              "max": 39
            },
            "13": {
              "min": 40,
              "max": 42
            },
            "14": {
              "min": 43,
              "max": 44
            },
            "15": {
              "min": 45,
              "max": 46
            },
            "16": {
              "min": 47,
              "max": 49
            },
            "17": {
              "min": 50,
              "max": 51
            },
            "18": {
              "min": 52,
              "max": 53
            },
            "19": {
              "min": 54,
              "max": 60
            }
          }
        },
        {
          "name": "Wiadomości",
          "id": 11,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 11
            },
            "5": {
              "min": 12,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 16
            },
            "8": {
              "min": 17,
              "max": 17
            },
            "9": {
              "min": 18,
              "max": 19
            },
            "10": {
              "min": 20,
              "max": 20
            },
            "11": {
              "min": 21,
              "max": 22
            },
            "12": {
              "min": 23,
              "max": 23
            },
            "13": {
              "min": 24,
              "max": 25
            },
            "14": {
              "min": 26,
              "max": 27
            },
            "15": {
              "min": 28,
              "max": 28
            },
            "16": {
              "min": 29,
              "max": 29
            },
            "17": {
              "min": 30,
              "max": 30
            },
            "18": {
              "min": null,
              "max": null
            },
            "19": {
              "min": 31,
              "max": 31
            }
          }
        },
        {
          "name": "Tworzenie pojęć",
          "id": 12,
          "rows": {
            "1": {
              "min": 0,
              "max": 5
            },
            "2": {
              "min": 6,
              "max": 6
            },
            "3": {
              "min": 7,
              "max": 8
            },
            "4": {
              "min": 9,
              "max": 9
            },
            "5": {
              "min": 10,
              "max": 10
            },
            "6": {
              "min": 11,
              "max": 11
            },
            "7": {
              "min": 12,
              "max": 13
            },
            "8": {
              "min": 14,
              "max": 14
            },
            "9": {
              "min": 15,
              "max": 15
            },
            "10": {
              "min": 16,
              "max": 16
            },
            "11": {
              "min": 17,
              "max": 17
            },
            "12": {
              "min": 18,
              "max": 18
            },
            "13": {
              "min": 19,
              "max": 19
            },
            "14": {
              "min": 20,
              "max": 20
            },
            "15": {
              "min": 21,
              "max": 21
            },
            "16": {
              "min": 22,
              "max": 22
            },
            "17": {
              "min": 23,
              "max": 23
            },
            "18": {
              "min": 24,
              "max": 24
            },
            "19": {
              "min": 25,
              "max": 27
            }
          }
        },
        {
          "name": "Porządkowanie liter i liczb",
          "id": 13,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 12
            },
            "5": {
              "min": 13,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 15
            },
            "8": {
              "min": 16,
              "max": 16
            },
            "9": {
              "min": 17,
              "max": 17
            },
            "10": {
              "min": 18,
              "max": 18
            },
            "11": {
              "min": 19,
              "max": 19
            },
            "12": {
              "min": 20,
              "max": 20
            },
            "13": {
              "min": null,
              "max": null
            },
            "14": {
              "min": 21,
              "max": 21
            },
            "15": {
              "min": 22,
              "max": 22
            },
            "16": {
              "min": 23,
              "max": 23
            },
            "17": {
              "min": null,
              "max": null
            },
            "18": {
              "min": 24,
              "max": 24
            },
            "19": {
              "min": 25,
              "max": 30
            }
          }
        },
        {
          "name": "Wykreślanie",
          "id": 14,
          "rows": {
            "1": {
              "min": 0,
              "max": 25
            },
            "2": {
              "min": 26,
              "max": 31
            },
            "3": {
              "min": 32,
              "max": 39
            },
            "4": {
              "min": 40,
              "max": 46
            },
            "5": {
              "min": 47,
              "max": 54
            },
            "6": {
              "min": 55,
              "max": 61
            },
            "7": {
              "min": 62,
              "max": 67
            },
            "8": {
              "min": 68,
              "max": 74
            },
            "9": {
              "min": 75,
              "max": 80
            },
            "10": {
              "min": 81,
              "max": 86
            },
            "11": {
              "min": 87,
              "max": 92
            },
            "12": {
              "min": 93,
              "max": 98
            },
            "13": {
              "min": 99,
              "max": 104
            },
            "14": {
              "min": 105,
              "max": 109
            },
            "15": {
              "min": 110,
              "max": 114
            },
            "16": {
              "min": 115,
              "max": 119
            },
            "17": {
              "min": 120,
              "max": 123
            },
            "18": {
              "min": 124,
              "max": 125
            },
            "19": {
              "min": 126,
              "max": 128
            }
          }
        },
        {
          "name": "Rozumienie",
          "id": 15,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 13
            },
            "5": {
              "min": 14,
              "max": 15
            },
            "6": {
              "min": 16,
              "max": 17
            },
            "7": {
              "min": 18,
              "max": 18
            },
            "8": {
              "min": 19,
              "max": 20
            },
            "9": {
              "min": 21,
              "max": 22
            },
            "10": {
              "min": 23,
              "max": 24
            },
            "11": {
              "min": 25,
              "max": 25
            },
            "12": {
              "min": 26,
              "max": 27
            },
            "13": {
              "min": 28,
              "max": 29
            },
            "14": {
              "min": 30,
              "max": 30
            },
            "15": {
              "min": 31,
              "max": 32
            },
            "16": {
              "min": 33,
              "max": 34
            },
            "17": {
              "min": 35,
              "max": 35
            },
            "18": {
              "min": 36,
              "max": 36
            },
            "19": {
              "min": 37,
              "max": 38
            }
          }
        },
        {
          "name": "Arytmetyka",
          "id": 16,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 10
            },
            "3": {
              "min": 11,
              "max": 13
            },
            "4": {
              "min": 14,
              "max": 15
            },
            "5": {
              "min": 16,
              "max": 17
            },
            "6": {
              "min": 18,
              "max": 19
            },
            "7": {
              "min": 20,
              "max": 20
            },
            "8": {
              "min": 21,
              "max": 22
            },
            "9": {
              "min": 23,
              "max": 23
            },
            "10": {
              "min": 24,
              "max": 25
            },
            "11": {
              "min": 26,
              "max": 26
            },
            "12": {
              "min": 27,
              "max": 27
            },
            "13": {
              "min": 28,
              "max": 28
            },
            "14": {
              "min": 29,
              "max": 30
            },
            "15": {
              "min": 31,
              "max": 31
            },
            "16": {
              "min": 32,
              "max": 32
            },
            "17": {
              "min": null,
              "max": null
            },
            "18": {
              "min": 33,
              "max": 33
            },
            "19": {
              "min": 34,
              "max": 34
            }
          }
        }
      ],

    150153: [
        {
          "name": "Wzory z klocków",
          "id": 1,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 10
            },
            "3": {
              "min": 11,
              "max": 14
            },
            "4": {
              "min": 15,
              "max": 18
            },
            "5": {
              "min": 19,
              "max": 22
            },
            "6": {
              "min": 23,
              "max": 26
            },
            "7": {
              "min": 27,
              "max": 29
            },
            "8": {
              "min": 30,
              "max": 33
            },
            "9": {
              "min": 34,
              "max": 36
            },
            "10": {
              "min": 37,
              "max": 39
            },
            "11": {
              "min": 40,
              "max": 42
            },
            "12": {
              "min": 43,
              "max": 44
            },
            "13": {
              "min": 45,
              "max": 47
            },
            "14": {
              "min": 48,
              "max": 50
            },
            "15": {
              "min": 51,
              "max": 52
            },
            "16": {
              "min": 53,
              "max": 54
            },
            "17": {
              "min": 55,
              "max": 56
            },
            "18": {
              "min": 57,
              "max": 57
            },
            "19": {
              "min": 58,
              "max": 58
            }
          }
        },
        {
          "name": "Podobieństwa",
          "id": 2,
          "rows": {
            "1": {
              "min": 0,
              "max": 12
            },
            "2": {
              "min": 13,
              "max": 15
            },
            "3": {
              "min": 16,
              "max": 17
            },
            "4": {
              "min": 18,
              "max": 19
            },
            "5": {
              "min": 20,
              "max": 22
            },
            "6": {
              "min": 23,
              "max": 24
            },
            "7": {
              "min": 25,
              "max": 26
            },
            "8": {
              "min": 27,
              "max": 28
            },
            "9": {
              "min": 29,
              "max": 30
            },
            "10": {
              "min": 31,
              "max": 32
            },
            "11": {
              "min": 33,
              "max": 33
            },
            "12": {
              "min": 34,
              "max": 35
            },
            "13": {
              "min": 36,
              "max": 37
            },
            "14": {
              "min": 38,
              "max": 38
            },
            "15": {
              "min": 39,
              "max": 40
            },
            "16": {
              "min": 41,
              "max": 41
            },
            "17": {
              "min": 42,
              "max": 43
            },
            "18": {
              "min": 44,
              "max": 44
            },
            "19": {
              "min": 45,
              "max": 46
            }
          }
        },
        {
          "name": "Matryce",
          "id": 3,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 12
            },
            "5": {
              "min": 13,
              "max": 14
            },
            "6": {
              "min": 15,
              "max": 15
            },
            "7": {
              "min": 16,
              "max": 17
            },
            "8": {
              "min": 18,
              "max": 18
            },
            "9": {
              "min": 19,
              "max": 20
            },
            "10": {
              "min": 21,
              "max": 21
            },
            "11": {
              "min": 22,
              "max": 22
            },
            "12": {
              "min": 23,
              "max": 24
            },
            "13": {
              "min": 25,
              "max": 25
            },
            "14": {
              "min": 26,
              "max": 26
            },
            "15": {
              "min": 27,
              "max": 28
            },
            "16": {
              "min": 29,
              "max": 29
            },
            "17": {
              "min": 30,
              "max": 30
            },
            "18": {
              "min": 31,
              "max": 31
            },
            "19": {
              "min": 32,
              "max": 32
            }
          }
        },
        {
          "name": "Powtarzanie liczb",
          "id": 4,
          "rows": {
            "1": {
              "min": 0,
              "max": 9
            },
            "2": {
              "min": 10,
              "max": 11
            },
            "3": {
              "min": 12,
              "max": 13
            },
            "4": {
              "min": 14,
              "max": 15
            },
            "5": {
              "min": 16,
              "max": 17
            },
            "6": {
              "min": 18,
              "max": 19
            },
            "7": {
              "min": 20,
              "max": 21
            },
            "8": {
              "min": 22,
              "max": 23
            },
            "9": {
              "min": 24,
              "max": 25
            },
            "10": {
              "min": 26,
              "max": 27
            },
            "11": {
              "min": 28,
              "max": 29
            },
            "12": {
              "min": 30,
              "max": 31
            },
            "13": {
              "min": 32,
              "max": 33
            },
            "14": {
              "min": 34,
              "max": 34
            },
            "15": {
              "min": 35,
              "max": 36
            },
            "16": {
              "min": 37,
              "max": 38
            },
            "17": {
              "min": 39,
              "max": 40
            },
            "18": {
              "min": 41,
              "max": 42
            },
            "19": {
              "min": 43,
              "max": 54
            }
          }
        },
        {
          "name": "Kodowanie",
          "id": 5,
          "rows": {
            "1": {
              "min": 0,
              "max": 21
            },
            "2": {
              "min": 22,
              "max": 26
            },
            "3": {
              "min": 27,
              "max": 31
            },
            "4": {
              "min": 32,
              "max": 36
            },
            "5": {
              "min": 37,
              "max": 42
            },
            "6": {
              "min": 43,
              "max": 47
            },
            "7": {
              "min": 48,
              "max": 52
            },
            "8": {
              "min": 53,
              "max": 57
            },
            "9": {
              "min": 58,
              "max": 62
            },
            "10": {
              "min": 63,
              "max": 67
            },
            "11": {
              "min": 68,
              "max": 72
            },
            "12": {
              "min": 73,
              "max": 76
            },
            "13": {
              "min": 77,
              "max": 81
            },
            "14": {
              "min": 82,
              "max": 86
            },
            "15": {
              "min": 87,
              "max": 91
            },
            "16": {
              "min": 92,
              "max": 96
            },
            "17": {
              "min": 97,
              "max": 100
            },
            "18": {
              "min": 101,
              "max": 105
            },
            "19": {
              "min": 106,
              "max": 117
            }
          }
        },
        {
          "name": "Słownik",
          "id": 6,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 15
            },
            "5": {
              "min": 16,
              "max": 20
            },
            "6": {
              "min": 21,
              "max": 24
            },
            "7": {
              "min": 25,
              "max": 28
            },
            "8": {
              "min": 29,
              "max": 31
            },
            "9": {
              "min": 32,
              "max": 34
            },
            "10": {
              "min": 35,
              "max": 37
            },
            "11": {
              "min": 38,
              "max": 40
            },
            "12": {
              "min": 41,
              "max": 42
            },
            "13": {
              "min": 43,
              "max": 45
            },
            "14": {
              "min": 46,
              "max": 47
            },
            "15": {
              "min": 48,
              "max": 49
            },
            "16": {
              "min": 50,
              "max": 51
            },
            "17": {
              "min": 52,
              "max": 52
            },
            "18": {
              "min": 53,
              "max": 53
            },
            "19": {
              "min": 54,
              "max": 54
            }
          }
        },
        {
          "name": "Ważenie",
          "id": 7,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 13
            },
            "5": {
              "min": 14,
              "max": 16
            },
            "6": {
              "min": 17,
              "max": 18
            },
            "7": {
              "min": 19,
              "max": 20
            },
            "8": {
              "min": 21,
              "max": 22
            },
            "9": {
              "min": 23,
              "max": 23
            },
            "10": {
              "min": 24,
              "max": 25
            },
            "11": {
              "min": 26,
              "max": 26
            },
            "12": {
              "min": 27,
              "max": 27
            },
            "13": {
              "min": 28,
              "max": 28
            },
            "14": {
              "min": 29,
              "max": 29
            },
            "15": {
              "min": 30,
              "max": 30
            },
            "16": {
              "min": 31,
              "max": 31
            },
            "17": {
              "min": 32,
              "max": 32
            },
            "18": {
              "min": null,
              "max": null
            },
            "19": {
              "min": 33,
              "max": 34
            }
          }
        },
        {
          "name": "Układnie wzoru",
          "id": 8,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 5
            },
            "3": {
              "min": 6,
              "max": 7
            },
            "4": {
              "min": 8,
              "max": 9
            },
            "5": {
              "min": 10,
              "max": 12
            },
            "6": {
              "min": 13,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 15
            },
            "8": {
              "min": 16,
              "max": 17
            },
            "9": {
              "min": 18,
              "max": 18
            },
            "10": {
              "min": 19,
              "max": 20
            },
            "11": {
              "min": 21,
              "max": 21
            },
            "12": {
              "min": 22,
              "max": 22
            },
            "13": {
              "min": 23,
              "max": 23
            },
            "14": {
              "min": 24,
              "max": 24
            },
            "15": {
              "min": 25,
              "max": 25
            },
            "16": {
              "min": 26,
              "max": 26
            },
            "17": {
              "min": 27,
              "max": 27
            },
            "18": {
              "min": 28,
              "max": 28
            },
            "19": {
              "min": 29,
              "max": 29
            }
          }
        },
        {
          "name": "Zapamiętywanie obrazków",
          "id": 9,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 11
            },
            "3": {
              "min": 12,
              "max": 14
            },
            "4": {
              "min": 15,
              "max": 17
            },
            "5": {
              "min": 18,
              "max": 20
            },
            "6": {
              "min": 21,
              "max": 22
            },
            "7": {
              "min": 23,
              "max": 25
            },
            "8": {
              "min": 26,
              "max": 27
            },
            "9": {
              "min": 28,
              "max": 30
            },
            "10": {
              "min": 31,
              "max": 32
            },
            "11": {
              "min": 33,
              "max": 34
            },
            "12": {
              "min": 35,
              "max": 36
            },
            "13": {
              "min": 37,
              "max": 39
            },
            "14": {
              "min": 40,
              "max": 41
            },
            "15": {
              "min": 42,
              "max": 43
            },
            "16": {
              "min": 44,
              "max": 45
            },
            "17": {
              "min": 46,
              "max": 46
            },
            "18": {
              "min": 47,
              "max": 47
            },
            "19": {
              "min": 48,
              "max": 49
            }
          }
        },
        {
          "name": "Szukanie symboli",
          "id": 10,
          "rows": {
            "1": {
              "min": 0,
              "max": 9
            },
            "2": {
              "min": 10,
              "max": 13
            },
            "3": {
              "min": 14,
              "max": 16
            },
            "4": {
              "min": 17,
              "max": 19
            },
            "5": {
              "min": 20,
              "max": 22
            },
            "6": {
              "min": 23,
              "max": 24
            },
            "7": {
              "min": 25,
              "max": 27
            },
            "8": {
              "min": 28,
              "max": 30
            },
            "9": {
              "min": 31,
              "max": 32
            },
            "10": {
              "min": 33,
              "max": 35
            },
            "11": {
              "min": 36,
              "max": 37
            },
            "12": {
              "min": 38,
              "max": 40
            },
            "13": {
              "min": 41,
              "max": 42
            },
            "14": {
              "min": 43,
              "max": 44
            },
            "15": {
              "min": 45,
              "max": 47
            },
            "16": {
              "min": 48,
              "max": 49
            },
            "17": {
              "min": 50,
              "max": 51
            },
            "18": {
              "min": 52,
              "max": 54
            },
            "19": {
              "min": 55,
              "max": 60
            }
          }
        },
        {
          "name": "Wiadomości",
          "id": 11,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 11
            },
            "5": {
              "min": 12,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 16
            },
            "8": {
              "min": 17,
              "max": 17
            },
            "9": {
              "min": 18,
              "max": 19
            },
            "10": {
              "min": 20,
              "max": 21
            },
            "11": {
              "min": 22,
              "max": 22
            },
            "12": {
              "min": 23,
              "max": 24
            },
            "13": {
              "min": 25,
              "max": 26
            },
            "14": {
              "min": 27,
              "max": 27
            },
            "15": {
              "min": 28,
              "max": 28
            },
            "16": {
              "min": 29,
              "max": 29
            },
            "17": {
              "min": 30,
              "max": 30
            },
            "18": {
              "min": null,
              "max": null
            },
            "19": {
              "min": 31,
              "max": 31
            }
          }
        },
        {
          "name": "Tworzenie pojęć",
          "id": 12,
          "rows": {
            "1": {
              "min": 0,
              "max": 5
            },
            "2": {
              "min": 6,
              "max": 6
            },
            "3": {
              "min": 7,
              "max": 8
            },
            "4": {
              "min": 9,
              "max": 9
            },
            "5": {
              "min": 10,
              "max": 10
            },
            "6": {
              "min": 11,
              "max": 11
            },
            "7": {
              "min": 12,
              "max": 13
            },
            "8": {
              "min": 14,
              "max": 14
            },
            "9": {
              "min": 15,
              "max": 15
            },
            "10": {
              "min": 16,
              "max": 16
            },
            "11": {
              "min": 17,
              "max": 17
            },
            "12": {
              "min": 18,
              "max": 18
            },
            "13": {
              "min": 19,
              "max": 19
            },
            "14": {
              "min": 20,
              "max": 20
            },
            "15": {
              "min": 21,
              "max": 21
            },
            "16": {
              "min": 22,
              "max": 22
            },
            "17": {
              "min": 23,
              "max": 23
            },
            "18": {
              "min": 24,
              "max": 24
            },
            "19": {
              "min": 25,
              "max": 27
            }
          }
        },
        {
          "name": "Porządkowanie liter i liczb",
          "id": 13,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 12
            },
            "5": {
              "min": 13,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 15
            },
            "8": {
              "min": 16,
              "max": 16
            },
            "9": {
              "min": 17,
              "max": 17
            },
            "10": {
              "min": 18,
              "max": 18
            },
            "11": {
              "min": 19,
              "max": 19
            },
            "12": {
              "min": 20,
              "max": 20
            },
            "13": {
              "min": 21,
              "max": 21
            },
            "14": {
              "min": null,
              "max": null
            },
            "15": {
              "min": 22,
              "max": 22
            },
            "16": {
              "min": 23,
              "max": 23
            },
            "17": {
              "min": 24,
              "max": 24
            },
            "18": {
              "min": null,
              "max": null
            },
            "19": {
              "min": 25,
              "max": 30
            }
          }
        },
        {
          "name": "Wykreślanie",
          "id": 14,
          "rows": {
            "1": {
              "min": 0,
              "max": 25
            },
            "2": {
              "min": 26,
              "max": 31
            },
            "3": {
              "min": 32,
              "max": 40
            },
            "4": {
              "min": 41,
              "max": 48
            },
            "5": {
              "min": 49,
              "max": 55
            },
            "6": {
              "min": 56,
              "max": 62
            },
            "7": {
              "min": 63,
              "max": 69
            },
            "8": {
              "min": 70,
              "max": 76
            },
            "9": {
              "min": 77,
              "max": 82
            },
            "10": {
              "min": 83,
              "max": 88
            },
            "11": {
              "min": 89,
              "max": 94
            },
            "12": {
              "min": 95,
              "max": 100
            },
            "13": {
              "min": 101,
              "max": 105
            },
            "14": {
              "min": 106,
              "max": 111
            },
            "15": {
              "min": 112,
              "max": 116
            },
            "16": {
              "min": 117,
              "max": 120
            },
            "17": {
              "min": 121,
              "max": 123
            },
            "18": {
              "min": 124,
              "max": 126
            },
            "19": {
              "min": 127,
              "max": 128
            }
          }
        },
        {
          "name": "Rozumienie",
          "id": 15,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 13
            },
            "5": {
              "min": 14,
              "max": 15
            },
            "6": {
              "min": 16,
              "max": 17
            },
            "7": {
              "min": 18,
              "max": 19
            },
            "8": {
              "min": 20,
              "max": 21
            },
            "9": {
              "min": 22,
              "max": 22
            },
            "10": {
              "min": 23,
              "max": 24
            },
            "11": {
              "min": 25,
              "max": 26
            },
            "12": {
              "min": 27,
              "max": 28
            },
            "13": {
              "min": 29,
              "max": 29
            },
            "14": {
              "min": 30,
              "max": 31
            },
            "15": {
              "min": 32,
              "max": 32
            },
            "16": {
              "min": 33,
              "max": 34
            },
            "17": {
              "min": 35,
              "max": 35
            },
            "18": {
              "min": 36,
              "max": 36
            },
            "19": {
              "min": 37,
              "max": 38
            }
          }
        },
        {
          "name": "Arytmetyka",
          "id": 16,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 11
            },
            "3": {
              "min": 12,
              "max": 13
            },
            "4": {
              "min": 14,
              "max": 15
            },
            "5": {
              "min": 16,
              "max": 17
            },
            "6": {
              "min": 18,
              "max": 19
            },
            "7": {
              "min": 20,
              "max": 21
            },
            "8": {
              "min": 22,
              "max": 22
            },
            "9": {
              "min": 23,
              "max": 23
            },
            "10": {
              "min": 24,
              "max": 25
            },
            "11": {
              "min": 26,
              "max": 26
            },
            "12": {
              "min": 27,
              "max": 27
            },
            "13": {
              "min": 28,
              "max": 29
            },
            "14": {
              "min": 30,
              "max": 30
            },
            "15": {
              "min": 31,
              "max": 31
            },
            "16": {
              "min": 32,
              "max": 32
            },
            "17": {
              "min": null,
              "max": null
            },
            "18": {
              "min": 33,
              "max": 33
            },
            "19": {
              "min": 34,
              "max": 34
            }
          }
        }
      ], 

    154157: [
        {
          "name": "Wzory z klocków",
          "id": 1,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 10
            },
            "3": {
              "min": 11,
              "max": 14
            },
            "4": {
              "min": 15,
              "max": 19
            },
            "5": {
              "min": 20,
              "max": 23
            },
            "6": {
              "min": 24,
              "max": 26
            },
            "7": {
              "min": 27,
              "max": 30
            },
            "8": {
              "min": 31,
              "max": 33
            },
            "9": {
              "min": 34,
              "max": 36
            },
            "10": {
              "min": 37,
              "max": 39
            },
            "11": {
              "min": 40,
              "max": 42
            },
            "12": {
              "min": 43,
              "max": 45
            },
            "13": {
              "min": 46,
              "max": 47
            },
            "14": {
              "min": 48,
              "max": 50
            },
            "15": {
              "min": 51,
              "max": 52
            },
            "16": {
              "min": 53,
              "max": 54
            },
            "17": {
              "min": 55,
              "max": 56
            },
            "18": {
              "min": 57,
              "max": 57
            },
            "19": {
              "min": 58,
              "max": 58
            }
          }
        },
        {
          "name": "Podobieństwa",
          "id": 2,
          "rows": {
            "1": {
              "min": 0,
              "max": 12
            },
            "2": {
              "min": 13,
              "max": 15
            },
            "3": {
              "min": 16,
              "max": 17
            },
            "4": {
              "min": 18,
              "max": 19
            },
            "5": {
              "min": 20,
              "max": 22
            },
            "6": {
              "min": 23,
              "max": 24
            },
            "7": {
              "min": 25,
              "max": 26
            },
            "8": {
              "min": 27,
              "max": 28
            },
            "9": {
              "min": 29,
              "max": 30
            },
            "10": {
              "min": 31,
              "max": 32
            },
            "11": {
              "min": 33,
              "max": 34
            },
            "12": {
              "min": 35,
              "max": 35
            },
            "13": {
              "min": 36,
              "max": 37
            },
            "14": {
              "min": 38,
              "max": 39
            },
            "15": {
              "min": 40,
              "max": 40
            },
            "16": {
              "min": 41,
              "max": 42
            },
            "17": {
              "min": 43,
              "max": 43
            },
            "18": {
              "min": 44,
              "max": 44
            },
            "19": {
              "min": 45,
              "max": 46
            }
          }
        },
        {
          "name": "Matryce",
          "id": 3,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 12
            },
            "5": {
              "min": 13,
              "max": 14
            },
            "6": {
              "min": 15,
              "max": 15
            },
            "7": {
              "min": 16,
              "max": 17
            },
            "8": {
              "min": 18,
              "max": 18
            },
            "9": {
              "min": 19,
              "max": 20
            },
            "10": {
              "min": 21,
              "max": 21
            },
            "11": {
              "min": 22,
              "max": 23
            },
            "12": {
              "min": 24,
              "max": 24
            },
            "13": {
              "min": 25,
              "max": 25
            },
            "14": {
              "min": 26,
              "max": 27
            },
            "15": {
              "min": 28,
              "max": 28
            },
            "16": {
              "min": 29,
              "max": 29
            },
            "17": {
              "min": 30,
              "max": 30
            },
            "18": {
              "min": 31,
              "max": 31
            },
            "19": {
              "min": 32,
              "max": null
            }
          }
        },
        {
          "name": "Powtarzanie liczb",
          "id": 4,
          "rows": {
            "1": {
              "min": 0,
              "max": 9
            },
            "2": {
              "min": 10,
              "max": 11
            },
            "3": {
              "min": 12,
              "max": 13
            },
            "4": {
              "min": 14,
              "max": 15
            },
            "5": {
              "min": 16,
              "max": 17
            },
            "6": {
              "min": 18,
              "max": 19
            },
            "7": {
              "min": 20,
              "max": 21
            },
            "8": {
              "min": 22,
              "max": 23
            },
            "9": {
              "min": 24,
              "max": 25
            },
            "10": {
              "min": 26,
              "max": 27
            },
            "11": {
              "min": 28,
              "max": 29
            },
            "12": {
              "min": 30,
              "max": 31
            },
            "13": {
              "min": 32,
              "max": 33
            },
            "14": {
              "min": 34,
              "max": 35
            },
            "15": {
              "min": 36,
              "max": 37
            },
            "16": {
              "min": 38,
              "max": 38
            },
            "17": {
              "min": 39,
              "max": 40
            },
            "18": {
              "min": 41,
              "max": 42
            },
            "19": {
              "min": 43,
              "max": 54
            }
          }
        },
        {
          "name": "Kodowanie",
          "id": 5,
          "rows": {
            "1": {
              "min": 0,
              "max": 21
            },
            "2": {
              "min": 22,
              "max": 27
            },
            "3": {
              "min": 28,
              "max": 32
            },
            "4": {
              "min": 33,
              "max": 37
            },
            "5": {
              "min": 38,
              "max": 43
            },
            "6": {
              "min": 44,
              "max": 48
            },
            "7": {
              "min": 49,
              "max": 53
            },
            "8": {
              "min": 54,
              "max": 58
            },
            "9": {
              "min": 59,
              "max": 63
            },
            "10": {
              "min": 64,
              "max": 68
            },
            "11": {
              "min": 69,
              "max": 73
            },
            "12": {
              "min": 74,
              "max": 78
            },
            "13": {
              "min": 79,
              "max": 83
            },
            "14": {
              "min": 84,
              "max": 88
            },
            "15": {
              "min": 89,
              "max": 93
            },
            "16": {
              "min": 94,
              "max": 97
            },
            "17": {
              "min": 98,
              "max": 102
            },
            "18": {
              "min": 103,
              "max": 107
            },
            "19": {
              "min": 108,
              "max": 117
            }
          }
        },
        {
          "name": "Słownik",
          "id": 6,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 12
            },
            "4": {
              "min": 13,
              "max": 16
            },
            "5": {
              "min": 17,
              "max": 20
            },
            "6": {
              "min": 21,
              "max": 25
            },
            "7": {
              "min": 26,
              "max": 28
            },
            "8": {
              "min": 29,
              "max": 32
            },
            "9": {
              "min": 33,
              "max": 35
            },
            "10": {
              "min": 36,
              "max": 38
            },
            "11": {
              "min": 39,
              "max": 40
            },
            "12": {
              "min": 41,
              "max": 43
            },
            "13": {
              "min": 44,
              "max": 45
            },
            "14": {
              "min": 46,
              "max": 47
            },
            "15": {
              "min": 48,
              "max": 49
            },
            "16": {
              "min": 50,
              "max": 51
            },
            "17": {
              "min": 52,
              "max": 52
            },
            "18": {
              "min": 53,
              "max": 53
            },
            "19": {
              "min": 54,
              "max": 54
            }
          }
        },
        {
          "name": "Ważenie",
          "id": 7,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 13
            },
            "5": {
              "min": 14,
              "max": 16
            },
            "6": {
              "min": 17,
              "max": 18
            },
            "7": {
              "min": 19,
              "max": 20
            },
            "8": {
              "min": 21,
              "max": 22
            },
            "9": {
              "min": 23,
              "max": 23
            },
            "10": {
              "min": 24,
              "max": 25
            },
            "11": {
              "min": 26,
              "max": 26
            },
            "12": {
              "min": 27,
              "max": 27
            },
            "13": {
              "min": 28,
              "max": 28
            },
            "14": {
              "min": 29,
              "max": 29
            },
            "15": {
              "min": 30,
              "max": 30
            },
            "16": {
              "min": 31,
              "max": 31
            },
            "17": {
              "min": 32,
              "max": 32
            },
            "18": {
              "min": null,
              "max": null
            },
            "19": {
              "min": 33,
              "max": 34
            }
          }
        },
        {
          "name": "Układnie wzoru",
          "id": 8,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 5
            },
            "3": {
              "min": 6,
              "max": 7
            },
            "4": {
              "min": 8,
              "max": 10
            },
            "5": {
              "min": 11,
              "max": 12
            },
            "6": {
              "min": 13,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 16
            },
            "8": {
              "min": 17,
              "max": 17
            },
            "9": {
              "min": 18,
              "max": 19
            },
            "10": {
              "min": 20,
              "max": 20
            },
            "11": {
              "min": 21,
              "max": 21
            },
            "12": {
              "min": 22,
              "max": 22
            },
            "13": {
              "min": 23,
              "max": 23
            },
            "14": {
              "min": 24,
              "max": 24
            },
            "15": {
              "min": 25,
              "max": 25
            },
            "16": {
              "min": 26,
              "max": 26
            },
            "17": {
              "min": 27,
              "max": 27
            },
            "18": {
              "min": 28,
              "max": 28
            },
            "19": {
              "min": 29,
              "max": null
            }
          }
        },
        {
          "name": "Zapamiętywanie obrazków",
          "id": 9,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 11
            },
            "3": {
              "min": 12,
              "max": 14
            },
            "4": {
              "min": 15,
              "max": 17
            },
            "5": {
              "min": 18,
              "max": 20
            },
            "6": {
              "min": 21,
              "max": 22
            },
            "7": {
              "min": 23,
              "max": 25
            },
            "8": {
              "min": 26,
              "max": 27
            },
            "9": {
              "min": 28,
              "max": 30
            },
            "10": {
              "min": 31,
              "max": 32
            },
            "11": {
              "min": 33,
              "max": 34
            },
            "12": {
              "min": 35,
              "max": 36
            },
            "13": {
              "min": 37,
              "max": 39
            },
            "14": {
              "min": 40,
              "max": 41
            },
            "15": {
              "min": 42,
              "max": 43
            },
            "16": {
              "min": 44,
              "max": 45
            },
            "17": {
              "min": 46,
              "max": 46
            },
            "18": {
              "min": 47,
              "max": 47
            },
            "19": {
              "min": 48,
              "max": 49
            }
          }
        },
        {
          "name": "Szukanie symboli",
          "id": 10,
          "rows": {
            "1": {
              "min": 0,
              "max": 10
            },
            "2": {
              "min": 11,
              "max": 13
            },
            "3": {
              "min": 14,
              "max": 16
            },
            "4": {
              "min": 17,
              "max": 19
            },
            "5": {
              "min": 20,
              "max": 22
            },
            "6": {
              "min": 23,
              "max": 25
            },
            "7": {
              "min": 26,
              "max": 28
            },
            "8": {
              "min": 29,
              "max": 30
            },
            "9": {
              "min": 31,
              "max": 33
            },
            "10": {
              "min": 34,
              "max": 35
            },
            "11": {
              "min": 36,
              "max": 36
            },
            "12": {
              "min": 37,
              "max": 40
            },
            "13": {
              "min": 41,
              "max": 43
            },
            "14": {
              "min": 44,
              "max": 45
            },
            "15": {
              "min": 46,
              "max": 47
            },
            "16": {
              "min": 48,
              "max": 49
            },
            "17": {
              "min": 50,
              "max": 52
            },
            "18": {
              "min": 53,
              "max": 54
            },
            "19": {
              "min": 55,
              "max": 60
            }
          }
        },
        {
          "name": "Wiadomości",
          "id": 11,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 11
            },
            "5": {
              "min": 12,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 16
            },
            "8": {
              "min": 17,
              "max": 18
            },
            "9": {
              "min": 19,
              "max": 19
            },
            "10": {
              "min": 20,
              "max": 21
            },
            "11": {
              "min": 22,
              "max": 23
            },
            "12": {
              "min": 24,
              "max": 24
            },
            "13": {
              "min": 25,
              "max": 26
            },
            "14": {
              "min": 27,
              "max": 27
            },
            "15": {
              "min": 28,
              "max": 29
            },
            "16": {
              "min": 30,
              "max": 30
            },
            "17": {
              "min": null,
              "max": null
            },
            "18": {
              "min": null,
              "max": null
            },
            "19": {
              "min": 31,
              "max": 31
            }
          }
        },
        {
          "name": "Tworzenie pojęć",
          "id": 12,
          "rows": {
            "1": {
              "min": 0,
              "max": 5
            },
            "2": {
              "min": 6,
              "max": 6
            },
            "3": {
              "min": 7,
              "max": 8
            },
            "4": {
              "min": 9,
              "max": 9
            },
            "5": {
              "min": 10,
              "max": 10
            },
            "6": {
              "min": 11,
              "max": 11
            },
            "7": {
              "min": 12,
              "max": 13
            },
            "8": {
              "min": 14,
              "max": 14
            },
            "9": {
              "min": 15,
              "max": 15
            },
            "10": {
              "min": 16,
              "max": 16
            },
            "11": {
              "min": 17,
              "max": 17
            },
            "12": {
              "min": 18,
              "max": 18
            },
            "13": {
              "min": 19,
              "max": 19
            },
            "14": {
              "min": 20,
              "max": 20
            },
            "15": {
              "min": 21,
              "max": 21
            },
            "16": {
              "min": 22,
              "max": 22
            },
            "17": {
              "min": 23,
              "max": 23
            },
            "18": {
              "min": 24,
              "max": 24
            },
            "19": {
              "min": 25,
              "max": 27
            }
          }
        },
        {
          "name": "Porządkowanie liter i liczb",
          "id": 13,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 12
            },
            "5": {
              "min": 13,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 15
            },
            "8": {
              "min": 16,
              "max": 16
            },
            "9": {
              "min": 17,
              "max": 17
            },
            "10": {
              "min": 18,
              "max": 18
            },
            "11": {
              "min": 19,
              "max": 19
            },
            "12": {
              "min": 20,
              "max": 20
            },
            "13": {
              "min": 21,
              "max": 21
            },
            "14": {
              "min": 22,
              "max": 22
            },
            "15": {
              "min": null,
              "max": null
            },
            "16": {
              "min": 23,
              "max": 23
            },
            "17": {
              "min": 24,
              "max": 24
            },
            "18": {
              "min": 25,
              "max": 25
            },
            "19": {
              "min": 26,
              "max": 30
            }
          }
        },
        {
          "name": "Wykreślanie",
          "id": 14,
          "rows": {
            "1": {
              "min": 0,
              "max": 25
            },
            "2": {
              "min": 26,
              "max": 32
            },
            "3": {
              "min": 33,
              "max": 41
            },
            "4": {
              "min": 42,
              "max": 49
            },
            "5": {
              "min": 50,
              "max": 56
            },
            "6": {
              "min": 57,
              "max": 64
            },
            "7": {
              "min": 65,
              "max": 70
            },
            "8": {
              "min": 71,
              "max": 77
            },
            "9": {
              "min": 78,
              "max": 83
            },
            "10": {
              "min": 84,
              "max": 90
            },
            "11": {
              "min": 91,
              "max": 96
            },
            "12": {
              "min": 97,
              "max": 101
            },
            "13": {
              "min": 102,
              "max": 107
            },
            "14": {
              "min": 108,
              "max": 112
            },
            "15": {
              "min": 113,
              "max": 117
            },
            "16": {
              "min": 118,
              "max": 121
            },
            "17": {
              "min": 122,
              "max": 124
            },
            "18": {
              "min": 125,
              "max": 126
            },
            "19": {
              "min": 127,
              "max": 128
            }
          }
        },
        {
          "name": "Rozumienie",
          "id": 15,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 13
            },
            "5": {
              "min": 14,
              "max": 15
            },
            "6": {
              "min": 16,
              "max": 17
            },
            "7": {
              "min": 18,
              "max": 19
            },
            "8": {
              "min": 20,
              "max": 21
            },
            "9": {
              "min": 22,
              "max": 23
            },
            "10": {
              "min": 24,
              "max": 25
            },
            "11": {
              "min": 26,
              "max": 26
            },
            "12": {
              "min": 27,
              "max": 28
            },
            "13": {
              "min": 29,
              "max": 29
            },
            "14": {
              "min": 30,
              "max": 31
            },
            "15": {
              "min": 32,
              "max": 32
            },
            "16": {
              "min": 33,
              "max": 34
            },
            "17": {
              "min": 35,
              "max": 35
            },
            "18": {
              "min": 36,
              "max": 36
            },
            "19": {
              "min": 37,
              "max": 38
            }
          }
        },
        {
          "name": "Arytmetyka",
          "id": 16,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 11
            },
            "3": {
              "min": 12,
              "max": 13
            },
            "4": {
              "min": 14,
              "max": 15
            },
            "5": {
              "min": 16,
              "max": 17
            },
            "6": {
              "min": 18,
              "max": 19
            },
            "7": {
              "min": 20,
              "max": 21
            },
            "8": {
              "min": 22,
              "max": 22
            },
            "9": {
              "min": 23,
              "max": 24
            },
            "10": {
              "min": 25,
              "max": 25
            },
            "11": {
              "min": 26,
              "max": 26
            },
            "12": {
              "min": 27,
              "max": 27
            },
            "13": {
              "min": 28,
              "max": 29
            },
            "14": {
              "min": 30,
              "max": 30
            },
            "15": {
              "min": 31,
              "max": 31
            },
            "16": {
              "min": 32,
              "max": 32
            },
            "17": {
              "min": null,
              "max": null
            },
            "18": {
              "min": 33,
              "max": 33
            },
            "19": {
              "min": 34,
              "max": 34
            }
          }
        }
      ],

    1581511: [
        {
          "name": "Wzory z klocków",
          "id": 1,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 10
            },
            "3": {
              "min": 11,
              "max": 14
            },
            "4": {
              "min": 15,
              "max": 19
            },
            "5": {
              "min": 20,
              "max": 23
            },
            "6": {
              "min": 24,
              "max": 27
            },
            "7": {
              "min": 28,
              "max": 30
            },
            "8": {
              "min": 31,
              "max": 34
            },
            "9": {
              "min": 35,
              "max": 37
            },
            "10": {
              "min": 38,
              "max": 40
            },
            "11": {
              "min": 41,
              "max": 42
            },
            "12": {
              "min": 43,
              "max": 45
            },
            "13": {
              "min": 46,
              "max": 48
            },
            "14": {
              "min": 49,
              "max": 50
            },
            "15": {
              "min": 51,
              "max": 52
            },
            "16": {
              "min": 53,
              "max": 54
            },
            "17": {
              "min": 55,
              "max": 56
            },
            "18": {
              "min": 57,
              "max": 57
            },
            "19": {
              "min": 58,
              "max": 58
            }
          }
        },
        {
          "name": "Podobieństwa",
          "id": 2,
          "rows": {
            "1": {
              "min": 0,
              "max": 12
            },
            "2": {
              "min": 13,
              "max": 15
            },
            "3": {
              "min": 16,
              "max": 17
            },
            "4": {
              "min": 18,
              "max": 19
            },
            "5": {
              "min": 20,
              "max": 22
            },
            "6": {
              "min": 23,
              "max": 24
            },
            "7": {
              "min": 25,
              "max": 26
            },
            "8": {
              "min": 27,
              "max": 28
            },
            "9": {
              "min": 29,
              "max": 30
            },
            "10": {
              "min": 31,
              "max": 32
            },
            "11": {
              "min": 33,
              "max": 34
            },
            "12": {
              "min": 35,
              "max": 36
            },
            "13": {
              "min": 37,
              "max": 38
            },
            "14": {
              "min": 39,
              "max": 39
            },
            "15": {
              "min": 40,
              "max": 41
            },
            "16": {
              "min": 42,
              "max": 42
            },
            "17": {
              "min": 43,
              "max": 43
            },
            "18": {
              "min": 44,
              "max": 44
            },
            "19": {
              "min": 45,
              "max": 46
            }
          }
        },
        {
          "name": "Matryce",
          "id": 3,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 12
            },
            "5": {
              "min": 13,
              "max": 14
            },
            "6": {
              "min": 15,
              "max": 15
            },
            "7": {
              "min": 16,
              "max": 17
            },
            "8": {
              "min": 18,
              "max": 18
            },
            "9": {
              "min": 19,
              "max": 20
            },
            "10": {
              "min": 21,
              "max": 21
            },
            "11": {
              "min": 22,
              "max": 23
            },
            "12": {
              "min": 24,
              "max": 24
            },
            "13": {
              "min": 25,
              "max": 25
            },
            "14": {
              "min": 26,
              "max": 27
            },
            "15": {
              "min": 28,
              "max": 28
            },
            "16": {
              "min": 29,
              "max": 29
            },
            "17": {
              "min": 30,
              "max": 30
            },
            "18": {
              "min": 31,
              "max": 31
            },
            "19": {
              "min": 32,
              "max": 32
            }
          }
        },
        {
          "name": "Powtarzanie liczb",
          "id": 4,
          "rows": {
            "1": {
              "min": 0,
              "max": 9
            },
            "2": {
              "min": 10,
              "max": 11
            },
            "3": {
              "min": 12,
              "max": 13
            },
            "4": {
              "min": 14,
              "max": 15
            },
            "5": {
              "min": 16,
              "max": 17
            },
            "6": {
              "min": 18,
              "max": 19
            },
            "7": {
              "min": 20,
              "max": 21
            },
            "8": {
              "min": 22,
              "max": 23
            },
            "9": {
              "min": 24,
              "max": 25
            },
            "10": {
              "min": 26,
              "max": 27
            },
            "11": {
              "min": 28,
              "max": 29
            },
            "12": {
              "min": 30,
              "max": 31
            },
            "13": {
              "min": 32,
              "max": 33
            },
            "14": {
              "min": 34,
              "max": 35
            },
            "15": {
              "min": 36,
              "max": 37
            },
            "16": {
              "min": 38,
              "max": 39
            },
            "17": {
              "min": 40,
              "max": 41
            },
            "18": {
              "min": 42,
              "max": 42
            },
            "19": {
              "min": 43,
              "max": 54
            }
          }
        },
        {
          "name": "Kodowanie",
          "id": 5,
          "rows": {
            "1": {
              "min": 0,
              "max": 22
            },
            "2": {
              "min": 23,
              "max": 28
            },
            "3": {
              "min": 29,
              "max": 33
            },
            "4": {
              "min": 34,
              "max": 38
            },
            "5": {
              "min": 39,
              "max": 44
            },
            "6": {
              "min": 45,
              "max": 49
            },
            "7": {
              "min": 50,
              "max": 54
            },
            "8": {
              "min": 55,
              "max": 59
            },
            "9": {
              "min": 60,
              "max": 64
            },
            "10": {
              "min": 65,
              "max": 69
            },
            "11": {
              "min": 70,
              "max": 74
            },
            "12": {
              "min": 75,
              "max": 79
            },
            "13": {
              "min": 80,
              "max": 84
            },
            "14": {
              "min": 85,
              "max": 89
            },
            "15": {
              "min": 90,
              "max": 94
            },
            "16": {
              "min": 95,
              "max": 99
            },
            "17": {
              "min": 100,
              "max": 104
            },
            "18": {
              "min": 105,
              "max": 108
            },
            "19": {
              "min": 109,
              "max": 117
            }
          }
        },
        {
          "name": "Słownik",
          "id": 6,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 12
            },
            "4": {
              "min": 13,
              "max": 17
            },
            "5": {
              "min": 18,
              "max": 21
            },
            "6": {
              "min": 22,
              "max": 25
            },
            "7": {
              "min": 26,
              "max": 29
            },
            "8": {
              "min": 30,
              "max": 32
            },
            "9": {
              "min": 33,
              "max": 36
            },
            "10": {
              "min": 37,
              "max": 38
            },
            "11": {
              "min": 39,
              "max": 41
            },
            "12": {
              "min": 42,
              "max": 43
            },
            "13": {
              "min": 44,
              "max": 46
            },
            "14": {
              "min": 47,
              "max": 48
            },
            "15": {
              "min": 49,
              "max": 49
            },
            "16": {
              "min": 50,
              "max": 51
            },
            "17": {
              "min": 52,
              "max": 52
            },
            "18": {
              "min": 53,
              "max": 53
            },
            "19": {
              "min": 54,
              "max": 54
            }
          }
        },
        {
          "name": "Ważenie",
          "id": 7,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 13
            },
            "5": {
              "min": 14,
              "max": 16
            },
            "6": {
              "min": 17,
              "max": 18
            },
            "7": {
              "min": 19,
              "max": 20
            },
            "8": {
              "min": 21,
              "max": 22
            },
            "9": {
              "min": 23,
              "max": 23
            },
            "10": {
              "min": 24,
              "max": 25
            },
            "11": {
              "min": 26,
              "max": 26
            },
            "12": {
              "min": 27,
              "max": 27
            },
            "13": {
              "min": 28,
              "max": 28
            },
            "14": {
              "min": 29,
              "max": 29
            },
            "15": {
              "min": 30,
              "max": 30
            },
            "16": {
              "min": 31,
              "max": 31
            },
            "17": {
              "min": 32,
              "max": 32
            },
            "18": {
              "min": 33,
              "max": 33
            },
            "19": {
              "min": 34,
              "max": 34
            }
          }
        },
        {
          "name": "Układnie wzoru",
          "id": 8,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 5
            },
            "3": {
              "min": 6,
              "max": 8
            },
            "4": {
              "min": 9,
              "max": 10
            },
            "5": {
              "min": 11,
              "max": 12
            },
            "6": {
              "min": 13,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 16
            },
            "8": {
              "min": 17,
              "max": 17
            },
            "9": {
              "min": 18,
              "max": 19
            },
            "10": {
              "min": 20,
              "max": 20
            },
            "11": {
              "min": 21,
              "max": 21
            },
            "12": {
              "min": 22,
              "max": 22
            },
            "13": {
              "min": 23,
              "max": 23
            },
            "14": {
              "min": 24,
              "max": 24
            },
            "15": {
              "min": 25,
              "max": 25
            },
            "16": {
              "min": 26,
              "max": 26
            },
            "17": {
              "min": 27,
              "max": 27
            },
            "18": {
              "min": 28,
              "max": 28
            },
            "19": {
              "min": 29,
              "max": 29
            }
          }
        },
        {
          "name": "Zapamiętywanie obrazków",
          "id": 9,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 11
            },
            "3": {
              "min": 12,
              "max": 14
            },
            "4": {
              "min": 15,
              "max": 17
            },
            "5": {
              "min": 18,
              "max": 20
            },
            "6": {
              "min": 21,
              "max": 22
            },
            "7": {
              "min": 23,
              "max": 25
            },
            "8": {
              "min": 26,
              "max": 27
            },
            "9": {
              "min": 28,
              "max": 30
            },
            "10": {
              "min": 31,
              "max": 32
            },
            "11": {
              "min": 33,
              "max": 34
            },
            "12": {
              "min": 35,
              "max": 36
            },
            "13": {
              "min": 37,
              "max": 39
            },
            "14": {
              "min": 40,
              "max": 41
            },
            "15": {
              "min": 42,
              "max": 43
            },
            "16": {
              "min": 44,
              "max": 45
            },
            "17": {
              "min": 46,
              "max": 46
            },
            "18": {
              "min": 47,
              "max": 47
            },
            "19": {
              "min": 48,
              "max": 49
            }
          }
        },
        {
          "name": "Szukanie symboli",
          "id": 10,
          "rows": {
            "1": {
              "min": 0,
              "max": 10
            },
            "2": {
              "min": 11,
              "max": 13
            },
            "3": {
              "min": 14,
              "max": 16
            },
            "4": {
              "min": 17,
              "max": 19
            },
            "5": {
              "min": 20,
              "max": 22
            },
            "6": {
              "min": 23,
              "max": 25
            },
            "7": {
              "min": 26,
              "max": 28
            },
            "8": {
              "min": 29,
              "max": 31
            },
            "9": {
              "min": 32,
              "max": 33
            },
            "10": {
              "min": 34,
              "max": 36
            },
            "11": {
              "min": 37,
              "max": 38
            },
            "12": {
              "min": 39,
              "max": 41
            },
            "13": {
              "min": 42,
              "max": 43
            },
            "14": {
              "min": 44,
              "max": 45
            },
            "15": {
              "min": 46,
              "max": 48
            },
            "16": {
              "min": 49,
              "max": 50
            },
            "17": {
              "min": 51,
              "max": 52
            },
            "18": {
              "min": 53,
              "max": 54
            },
            "19": {
              "min": 55,
              "max": 60
            }
          }
        },
        {
          "name": "Wiadomości",
          "id": 11,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 11
            },
            "5": {
              "min": 12,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 16
            },
            "8": {
              "min": 17,
              "max": 18
            },
            "9": {
              "min": 19,
              "max": 19
            },
            "10": {
              "min": 20,
              "max": 21
            },
            "11": {
              "min": 22,
              "max": 23
            },
            "12": {
              "min": 24,
              "max": 25
            },
            "13": {
              "min": 26,
              "max": 26
            },
            "14": {
              "min": 27,
              "max": 28
            },
            "15": {
              "min": 29,
              "max": 29
            },
            "16": {
              "min": 30,
              "max": 30
            },
            "17": {
              "min": null,
              "max": null
            },
            "18": {
              "min": null,
              "max": null
            },
            "19": {
              "min": 31,
              "max": 31
            }
          }
        },
        {
          "name": "Tworzenie pojęć",
          "id": 12,
          "rows": {
            "1": {
              "min": 0,
              "max": 5
            },
            "2": {
              "min": 6,
              "max": 6
            },
            "3": {
              "min": 7,
              "max": 8
            },
            "4": {
              "min": 9,
              "max": 9
            },
            "5": {
              "min": 10,
              "max": 10
            },
            "6": {
              "min": 11,
              "max": 11
            },
            "7": {
              "min": 12,
              "max": 13
            },
            "8": {
              "min": 14,
              "max": 14
            },
            "9": {
              "min": 15,
              "max": 15
            },
            "10": {
              "min": 16,
              "max": 16
            },
            "11": {
              "min": 17,
              "max": 17
            },
            "12": {
              "min": 18,
              "max": 19
            },
            "13": {
              "min": 20,
              "max": 20
            },
            "14": {
              "min": 21,
              "max": 21
            },
            "15": {
              "min": null,
              "max": null
            },
            "16": {
              "min": 22,
              "max": 22
            },
            "17": {
              "min": 23,
              "max": 23
            },
            "18": {
              "min": 24,
              "max": 24
            },
            "19": {
              "min": 25,
              "max": 27
            }
          }
        },
        {
          "name": "Porządkowanie liter i liczb",
          "id": 13,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 12
            },
            "5": {
              "min": 13,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 15
            },
            "8": {
              "min": 16,
              "max": 16
            },
            "9": {
              "min": 17,
              "max": 17
            },
            "10": {
              "min": 18,
              "max": 18
            },
            "11": {
              "min": 19,
              "max": 19
            },
            "12": {
              "min": 20,
              "max": 20
            },
            "13": {
              "min": 21,
              "max": 21
            },
            "14": {
              "min": 22,
              "max": 22
            },
            "15": {
              "min": 23,
              "max": 23
            },
            "16": {
              "min": 24,
              "max": 24
            },
            "17": {
              "min": null,
              "max": null
            },
            "18": {
              "min": 25,
              "max": 25
            },
            "19": {
              "min": 26,
              "max": 30
            }
          }
        },
        {
          "name": "Wykreślanie",
          "id": 14,
          "rows": {
            "1": {
              "min": 0,
              "max": 25
            },
            "2": {
              "min": 26,
              "max": 33
            },
            "3": {
              "min": 34,
              "max": 42
            },
            "4": {
              "min": 43,
              "max": 50
            },
            "5": {
              "min": 51,
              "max": 58
            },
            "6": {
              "min": 59,
              "max": 65
            },
            "7": {
              "min": 66,
              "max": 72
            },
            "8": {
              "min": 73,
              "max": 79
            },
            "9": {
              "min": 80,
              "max": 85
            },
            "10": {
              "min": 86,
              "max": 91
            },
            "11": {
              "min": 92,
              "max": 97
            },
            "12": {
              "min": 98,
              "max": 103
            },
            "13": {
              "min": 104,
              "max": 108
            },
            "14": {
              "min": 109,
              "max": 113
            },
            "15": {
              "min": 114,
              "max": 118
            },
            "16": {
              "min": 119,
              "max": 122
            },
            "17": {
              "min": 123,
              "max": 125
            },
            "18": {
              "min": 126,
              "max": 126
            },
            "19": {
              "min": 127,
              "max": 128
            }
          }
        },
        {
          "name": "Rozumienie",
          "id": 15,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 13
            },
            "5": {
              "min": 14,
              "max": 15
            },
            "6": {
              "min": 16,
              "max": 17
            },
            "7": {
              "min": 18,
              "max": 19
            },
            "8": {
              "min": 20,
              "max": 21
            },
            "9": {
              "min": 22,
              "max": 23
            },
            "10": {
              "min": 24,
              "max": 25
            },
            "11": {
              "min": 26,
              "max": 27
            },
            "12": {
              "min": 28,
              "max": 28
            },
            "13": {
              "min": 29,
              "max": 30
            },
            "14": {
              "min": 31,
              "max": 31
            },
            "15": {
              "min": 32,
              "max": 33
            },
            "16": {
              "min": 34,
              "max": 34
            },
            "17": {
              "min": 35,
              "max": 35
            },
            "18": {
              "min": 36,
              "max": 36
            },
            "19": {
              "min": 37,
              "max": 38
            }
          }
        },
        {
          "name": "Arytmetyka",
          "id": 16,
          "rows": {
            "1": {
              "min": 0,
              "max": 9
            },
            "2": {
              "min": 10,
              "max": 11
            },
            "3": {
              "min": 12,
              "max": 14
            },
            "4": {
              "min": 15,
              "max": 16
            },
            "5": {
              "min": 17,
              "max": 18
            },
            "6": {
              "min": 19,
              "max": 19
            },
            "7": {
              "min": 20,
              "max": 21
            },
            "8": {
              "min": 22,
              "max": 22
            },
            "9": {
              "min": 23,
              "max": 24
            },
            "10": {
              "min": 25,
              "max": 25
            },
            "11": {
              "min": 26,
              "max": 26
            },
            "12": {
              "min": 27,
              "max": 28
            },
            "13": {
              "min": 29,
              "max": 29
            },
            "14": {
              "min": 30,
              "max": 30
            },
            "15": {
              "min": 31,
              "max": 31
            },
            "16": {
              "min": 32,
              "max": 32
            },
            "17": {
              "min": null,
              "max": null
            },
            "18": {
              "min": 33,
              "max": 33
            },
            "19": {
              "min": 34,
              "max": 34
            }
          }
        }
      ],   
    160163: [
        {
          "name": "Wzory z klocków",
          "id": 1,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 10
            },
            "3": {
              "min": 11,
              "max": 14
            },
            "4": {
              "min": 15,
              "max": 19
            },
            "5": {
              "min": 20,
              "max": 23
            },
            "6": {
              "min": 24,
              "max": 27
            },
            "7": {
              "min": 28,
              "max": 31
            },
            "8": {
              "min": 32,
              "max": 34
            },
            "9": {
              "min": 35,
              "max": 37
            },
            "10": {
              "min": 38,
              "max": 40
            },
            "11": {
              "min": 41,
              "max": 43
            },
            "12": {
              "min": 44,
              "max": 46
            },
            "13": {
              "min": 47,
              "max": 48
            },
            "14": {
              "min": 49,
              "max": 50
            },
            "15": {
              "min": 51,
              "max": 53
            },
            "16": {
              "min": 54,
              "max": 54
            },
            "17": {
              "min": 55,
              "max": 56
            },
            "18": {
              "min": 57,
              "max": 57
            },
            "19": {
              "min": 58,
              "max": 58
            }
          }
        },
        {
          "name": "Podobieństwa",
          "id": 2,
          "rows": {
            "1": {
              "min": 0,
              "max": 12
            },
            "2": {
              "min": 13,
              "max": 15
            },
            "3": {
              "min": 16,
              "max": 17
            },
            "4": {
              "min": 18,
              "max": 19
            },
            "5": {
              "min": 20,
              "max": 22
            },
            "6": {
              "min": 23,
              "max": 24
            },
            "7": {
              "min": 25,
              "max": 26
            },
            "8": {
              "min": 27,
              "max": 29
            },
            "9": {
              "min": 30,
              "max": 31
            },
            "10": {
              "min": 32,
              "max": 33
            },
            "11": {
              "min": 34,
              "max": 35
            },
            "12": {
              "min": 36,
              "max": 36
            },
            "13": {
              "min": 37,
              "max": 38
            },
            "14": {
              "min": 39,
              "max": 40
            },
            "15": {
              "min": 41,
              "max": 41
            },
            "16": {
              "min": 42,
              "max": 43
            },
            "17": {
              "min": 44,
              "max": 44
            },
            "18": {
              "min": 45,
              "max": 45
            },
            "19": {
              "min": 46,
              "max": 46
            }
          }
        },
        {
          "name": "Matryce",
          "id": 3,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 12
            },
            "5": {
              "min": 13,
              "max": 14
            },
            "6": {
              "min": 15,
              "max": 15
            },
            "7": {
              "min": 16,
              "max": 17
            },
            "8": {
              "min": 18,
              "max": 18
            },
            "9": {
              "min": 19,
              "max": 20
            },
            "10": {
              "min": 21,
              "max": 21
            },
            "11": {
              "min": 22,
              "max": 23
            },
            "12": {
              "min": 24,
              "max": 24
            },
            "13": {
              "min": 25,
              "max": 25
            },
            "14": {
              "min": 26,
              "max": 27
            },
            "15": {
              "min": 28,
              "max": 28
            },
            "16": {
              "min": 29,
              "max": 29
            },
            "17": {
              "min": 30,
              "max": 30
            },
            "18": {
              "min": 31,
              "max": 31
            },
            "19": {
              "min": 32,
              "max": 32
            }
          }
        },
        {
          "name": "Powtarzanie liczb",
          "id": 4,
          "rows": {
            "1": {
              "min": 0,
              "max": 9
            },
            "2": {
              "min": 10,
              "max": 11
            },
            "3": {
              "min": 12,
              "max": 13
            },
            "4": {
              "min": 14,
              "max": 15
            },
            "5": {
              "min": 16,
              "max": 17
            },
            "6": {
              "min": 18,
              "max": 19
            },
            "7": {
              "min": 20,
              "max": 21
            },
            "8": {
              "min": 22,
              "max": 23
            },
            "9": {
              "min": 24,
              "max": 25
            },
            "10": {
              "min": 26,
              "max": 27
            },
            "11": {
              "min": 28,
              "max": 29
            },
            "12": {
              "min": 30,
              "max": 31
            },
            "13": {
              "min": 32,
              "max": 33
            },
            "14": {
              "min": 34,
              "max": 35
            },
            "15": {
              "min": 36,
              "max": 37
            },
            "16": {
              "min": 38,
              "max": 39
            },
            "17": {
              "min": 40,
              "max": 41
            },
            "18": {
              "min": 42,
              "max": 43
            },
            "19": {
              "min": 44,
              "max": 54
            }
          }
        },
        {
          "name": "Kodowanie",
          "id": 5,
          "rows": {
            "1": {
              "min": 0,
              "max": 23
            },
            "2": {
              "min": 24,
              "max": 28
            },
            "3": {
              "min": 29,
              "max": 34
            },
            "4": {
              "min": 35,
              "max": 39
            },
            "5": {
              "min": 40,
              "max": 45
            },
            "6": {
              "min": 46,
              "max": 50
            },
            "7": {
              "min": 51,
              "max": 55
            },
            "8": {
              "min": 56,
              "max": 60
            },
            "9": {
              "min": 61,
              "max": 66
            },
            "10": {
              "min": 67,
              "max": 71
            },
            "11": {
              "min": 72,
              "max": 76
            },
            "12": {
              "min": 77,
              "max": 81
            },
            "13": {
              "min": 82,
              "max": 86
            },
            "14": {
              "min": 87,
              "max": 91
            },
            "15": {
              "min": 92,
              "max": 96
            },
            "16": {
              "min": 97,
              "max": 100
            },
            "17": {
              "min": 101,
              "max": 105
            },
            "18": {
              "min": 106,
              "max": 109
            },
            "19": {
              "min": 110,
              "max": 117
            }
          }
        },
        {
          "name": "Słownik",
          "id": 6,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 13
            },
            "4": {
              "min": 14,
              "max": 17
            },
            "5": {
              "min": 18,
              "max": 22
            },
            "6": {
              "min": 23,
              "max": 23
            },
            "7": {
              "min": 24,
              "max": 30
            },
            "8": {
              "min": 31,
              "max": 33
            },
            "9": {
              "min": 34,
              "max": 36
            },
            "10": {
              "min": 37,
              "max": 39
            },
            "11": {
              "min": 40,
              "max": 41
            },
            "12": {
              "min": 42,
              "max": 44
            },
            "13": {
              "min": 45,
              "max": 46
            },
            "14": {
              "min": 47,
              "max": 48
            },
            "15": {
              "min": 49,
              "max": 50
            },
            "16": {
              "min": 51,
              "max": 51
            },
            "17": {
              "min": 52,
              "max": 52
            },
            "18": {
              "min": 53,
              "max": 53
            },
            "19": {
              "min": 54,
              "max": 54
            }
          }
        },
        {
          "name": "Ważenie",
          "id": 7,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 13
            },
            "5": {
              "min": 14,
              "max": 16
            },
            "6": {
              "min": 17,
              "max": 18
            },
            "7": {
              "min": 19,
              "max": 20
            },
            "8": {
              "min": 21,
              "max": 22
            },
            "9": {
              "min": 23,
              "max": 23
            },
            "10": {
              "min": 24,
              "max": 25
            },
            "11": {
              "min": 26,
              "max": 26
            },
            "12": {
              "min": 27,
              "max": 27
            },
            "13": {
              "min": 28,
              "max": 29
            },
            "14": {
              "min": 30,
              "max": 30
            },
            "15": {
              "min": 31,
              "max": 31
            },
            "16": {
              "min": null,
              "max": null
            },
            "17": {
              "min": 32,
              "max": 32
            },
            "18": {
              "min": 33,
              "max": 33
            },
            "19": {
              "min": 34,
              "max": 34
            }
          }
        },
        {
          "name": "Układnie wzoru",
          "id": 8,
          "rows": {
            "1": {
              "min": 0,
              "max": 3
            },
            "2": {
              "min": 4,
              "max": 6
            },
            "3": {
              "min": 7,
              "max": 8
            },
            "4": {
              "min": 9,
              "max": 10
            },
            "5": {
              "min": 11,
              "max": 12
            },
            "6": {
              "min": 13,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 16
            },
            "8": {
              "min": 17,
              "max": 17
            },
            "9": {
              "min": 18,
              "max": 19
            },
            "10": {
              "min": 20,
              "max": 20
            },
            "11": {
              "min": 21,
              "max": 21
            },
            "12": {
              "min": 22,
              "max": 22
            },
            "13": {
              "min": 23,
              "max": 23
            },
            "14": {
              "min": 24,
              "max": 24
            },
            "15": {
              "min": 25,
              "max": 25
            },
            "16": {
              "min": 26,
              "max": 26
            },
            "17": {
              "min": 27,
              "max": 27
            },
            "18": {
              "min": 28,
              "max": 28
            },
            "19": {
              "min": 29,
              "max": 29
            }
          }
        },
        {
          "name": "Zapamiętywanie obrazków",
          "id": 9,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 11
            },
            "3": {
              "min": 12,
              "max": 14
            },
            "4": {
              "min": 15,
              "max": 17
            },
            "5": {
              "min": 18,
              "max": 20
            },
            "6": {
              "min": 21,
              "max": 23
            },
            "7": {
              "min": 24,
              "max": 25
            },
            "8": {
              "min": 26,
              "max": 28
            },
            "9": {
              "min": 29,
              "max": 30
            },
            "10": {
              "min": 31,
              "max": 32
            },
            "11": {
              "min": 33,
              "max": 34
            },
            "12": {
              "min": 35,
              "max": 36
            },
            "13": {
              "min": 37,
              "max": 39
            },
            "14": {
              "min": 40,
              "max": 41
            },
            "15": {
              "min": 42,
              "max": 43
            },
            "16": {
              "min": 44,
              "max": 45
            },
            "17": {
              "min": 46,
              "max": 46
            },
            "18": {
              "min": 47,
              "max": 47
            },
            "19": {
              "min": 48,
              "max": 49
            }
          }
        },
        {
          "name": "Szukanie symboli",
          "id": 10,
          "rows": {
            "1": {
              "min": 0,
              "max": 10
            },
            "2": {
              "min": 11,
              "max": 13
            },
            "3": {
              "min": 14,
              "max": 17
            },
            "4": {
              "min": 18,
              "max": 20
            },
            "5": {
              "min": 21,
              "max": 23
            },
            "6": {
              "min": 24,
              "max": 25
            },
            "7": {
              "min": 26,
              "max": 28
            },
            "8": {
              "min": 29,
              "max": 31
            },
            "9": {
              "min": 32,
              "max": 33
            },
            "10": {
              "min": 34,
              "max": 36
            },
            "11": {
              "min": 37,
              "max": 38
            },
            "12": {
              "min": 39,
              "max": 41
            },
            "13": {
              "min": 42,
              "max": 43
            },
            "14": {
              "min": 44,
              "max": 46
            },
            "15": {
              "min": 47,
              "max": 48
            },
            "16": {
              "min": 49,
              "max": 50
            },
            "17": {
              "min": 51,
              "max": 52
            },
            "18": {
              "min": 53,
              "max": 54
            },
            "19": {
              "min": 55,
              "max": 60
            }
          }
        },
        {
          "name": "Wiadomości",
          "id": 11,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 11
            },
            "5": {
              "min": 12,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 16
            },
            "8": {
              "min": 17,
              "max": 18
            },
            "9": {
              "min": 19,
              "max": 20
            },
            "10": {
              "min": 21,
              "max": 21
            },
            "11": {
              "min": 22,
              "max": 23
            },
            "12": {
              "min": 24,
              "max": 25
            },
            "13": {
              "min": 26,
              "max": 27
            },
            "14": {
              "min": 28,
              "max": 28
            },
            "15": {
              "min": 29,
              "max": 29
            },
            "16": {
              "min": 30,
              "max": 30
            },
            "17": {
              "min": null,
              "max": null
            },
            "18": {
              "min": null,
              "max": null
            },
            "19": {
              "min": 31,
              "max": 31
            }
          }
        },
        {
          "name": "Tworzenie pojęć",
          "id": 12,
          "rows": {
            "1": {
              "min": 0,
              "max": 5
            },
            "2": {
              "min": 6,
              "max": 6
            },
            "3": {
              "min": 7,
              "max": 8
            },
            "4": {
              "min": 9,
              "max": 9
            },
            "5": {
              "min": 10,
              "max": 10
            },
            "6": {
              "min": 11,
              "max": 11
            },
            "7": {
              "min": 12,
              "max": 13
            },
            "8": {
              "min": 14,
              "max": 14
            },
            "9": {
              "min": 15,
              "max": 15
            },
            "10": {
              "min": 16,
              "max": 16
            },
            "11": {
              "min": 17,
              "max": 18
            },
            "12": {
              "min": 19,
              "max": 19
            },
            "13": {
              "min": 20,
              "max": 20
            },
            "14": {
              "min": 21,
              "max": 21
            },
            "15": {
              "min": 22,
              "max": 22
            },
            "16": {
              "min": 23,
              "max": 23
            },
            "17": {
              "min": null,
              "max": null
            },
            "18": {
              "min": 24,
              "max": 24
            },
            "19": {
              "min": 25,
              "max": 27
            }
          }
        },
        {
          "name": "Porządkowanie liter i liczb",
          "id": 13,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 12
            },
            "5": {
              "min": 13,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 15
            },
            "8": {
              "min": 16,
              "max": 16
            },
            "9": {
              "min": 17,
              "max": 17
            },
            "10": {
              "min": 18,
              "max": 18
            },
            "11": {
              "min": 19,
              "max": 1
            },
            "12": {
              "min": 2,
              "max": 20
            },
            "13": {
              "min": 21,
              "max": 21
            },
            "14": {
              "min": 22,
              "max": 22
            },
            "15": {
              "min": 23,
              "max": 23
            },
            "16": {
              "min": 24,
              "max": 24
            },
            "17": {
              "min": 25,
              "max": 25
            },
            "18": {
              "min": 26,
              "max": 26
            },
            "19": {
              "min": 27,
              "max": 30
            }
          }
        },
        {
          "name": "Wykreślanie",
          "id": 14,
          "rows": {
            "1": {
              "min": 0,
              "max": 26
            },
            "2": {
              "min": 27,
              "max": 35
            },
            "3": {
              "min": 36,
              "max": 44
            },
            "4": {
              "min": 45,
              "max": 52
            },
            "5": {
              "min": 53,
              "max": 59
            },
            "6": {
              "min": 60,
              "max": 67
            },
            "7": {
              "min": 68,
              "max": 73
            },
            "8": {
              "min": 74,
              "max": 80
            },
            "9": {
              "min": 81,
              "max": 86
            },
            "10": {
              "min": 87,
              "max": 93
            },
            "11": {
              "min": 94,
              "max": 99
            },
            "12": {
              "min": 100,
              "max": 104
            },
            "13": {
              "min": 105,
              "max": 110
            },
            "14": {
              "min": 111,
              "max": 115
            },
            "15": {
              "min": 116,
              "max": 119
            },
            "16": {
              "min": 120,
              "max": 123
            },
            "17": {
              "min": 124,
              "max": 125
            },
            "18": {
              "min": 126,
              "max": 127
            },
            "19": {
              "min": 128,
              "max": 128
            }
          }
        },
        {
          "name": "Rozumienie",
          "id": 15,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 13
            },
            "5": {
              "min": 14,
              "max": 15
            },
            "6": {
              "min": 16,
              "max": 18
            },
            "7": {
              "min": 19,
              "max": 20
            },
            "8": {
              "min": 21,
              "max": 22
            },
            "9": {
              "min": 23,
              "max": 24
            },
            "10": {
              "min": 25,
              "max": 25
            },
            "11": {
              "min": 26,
              "max": 27
            },
            "12": {
              "min": 28,
              "max": 28
            },
            "13": {
              "min": 29,
              "max": 30
            },
            "14": {
              "min": 31,
              "max": 31
            },
            "15": {
              "min": 32,
              "max": 33
            },
            "16": {
              "min": 34,
              "max": 34
            },
            "17": {
              "min": 35,
              "max": 35
            },
            "18": {
              "min": 36,
              "max": 36
            },
            "19": {
              "min": 37,
              "max": 38
            }
          }
        },
        {
          "name": "Arytmetyka",
          "id": 16,
          "rows": {
            "1": {
              "min": 0,
              "max": 9
            },
            "2": {
              "min": 10,
              "max": 11
            },
            "3": {
              "min": 12,
              "max": 14
            },
            "4": {
              "min": 15,
              "max": 16
            },
            "5": {
              "min": 17,
              "max": 18
            },
            "6": {
              "min": 19,
              "max": 19
            },
            "7": {
              "min": 20,
              "max": 21
            },
            "8": {
              "min": 22,
              "max": 22
            },
            "9": {
              "min": 23,
              "max": 24
            },
            "10": {
              "min": 25,
              "max": 25
            },
            "11": {
              "min": 26,
              "max": 26
            },
            "12": {
              "min": 27,
              "max": 28
            },
            "13": {
              "min": 29,
              "max": 29
            },
            "14": {
              "min": 30,
              "max": 30
            },
            "15": {
              "min": 31,
              "max": 31
            },
            "16": {
              "min": 32,
              "max": 32
            },
            "17": {
              "min": null,
              "max": null
            },
            "18": {
              "min": 33,
              "max": 33
            },
            "19": {
              "min": 34,
              "max": 34
            }
          }
        }
      ],

    164167: [
        {
          "name": "Wzory z klocków",
          "id": 1,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 10
            },
            "3": {
              "min": 11,
              "max": 15
            },
            "4": {
              "min": 16,
              "max": 19
            },
            "5": {
              "min": 20,
              "max": 23
            },
            "6": {
              "min": 24,
              "max": 27
            },
            "7": {
              "min": 28,
              "max": 31
            },
            "8": {
              "min": 32,
              "max": 34
            },
            "9": {
              "min": 35,
              "max": 38
            },
            "10": {
              "min": 39,
              "max": 41
            },
            "11": {
              "min": 42,
              "max": 43
            },
            "12": {
              "min": 44,
              "max": 46
            },
            "13": {
              "min": 47,
              "max": 48
            },
            "14": {
              "min": 49,
              "max": 51
            },
            "15": {
              "min": 52,
              "max": 53
            },
            "16": {
              "min": 54,
              "max": 55
            },
            "17": {
              "min": 56,
              "max": 56
            },
            "18": {
              "min": 57,
              "max": 57
            },
            "19": {
              "min": 58,
              "max": 58
            }
          }
        },
        {
          "name": "Podobieństwa",
          "id": 2,
          "rows": {
            "1": {
              "min": 0,
              "max": 12
            },
            "2": {
              "min": 13,
              "max": 15
            },
            "3": {
              "min": 16,
              "max": 17
            },
            "4": {
              "min": 18,
              "max": 19
            },
            "5": {
              "min": 20,
              "max": 22
            },
            "6": {
              "min": 23,
              "max": 24
            },
            "7": {
              "min": 25,
              "max": 27
            },
            "8": {
              "min": 28,
              "max": 29
            },
            "9": {
              "min": 30,
              "max": 31
            },
            "10": {
              "min": 32,
              "max": 33
            },
            "11": {
              "min": 34,
              "max": 35
            },
            "12": {
              "min": 36,
              "max": 37
            },
            "13": {
              "min": 38,
              "max": 38
            },
            "14": {
              "min": 39,
              "max": 40
            },
            "15": {
              "min": 41,
              "max": 42
            },
            "16": {
              "min": 43,
              "max": 43
            },
            "17": {
              "min": 44,
              "max": 44
            },
            "18": {
              "min": 45,
              "max": 45
            },
            "19": {
              "min": 46,
              "max": 46
            }
          }
        },
        {
          "name": "Matryce",
          "id": 3,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 12
            },
            "5": {
              "min": 13,
              "max": 14
            },
            "6": {
              "min": 15,
              "max": 15
            },
            "7": {
              "min": 16,
              "max": 17
            },
            "8": {
              "min": 18,
              "max": 18
            },
            "9": {
              "min": 19,
              "max": 20
            },
            "10": {
              "min": 21,
              "max": 21
            },
            "11": {
              "min": 22,
              "max": 23
            },
            "12": {
              "min": 24,
              "max": 24
            },
            "13": {
              "min": 25,
              "max": 26
            },
            "14": {
              "min": 27,
              "max": 27
            },
            "15": {
              "min": 28,
              "max": 28
            },
            "16": {
              "min": 29,
              "max": 29
            },
            "17": {
              "min": 30,
              "max": 30
            },
            "18": {
              "min": 31,
              "max": 31
            },
            "19": {
              "min": 32,
              "max": 32
            }
          }
        },
        {
          "name": "Powtarzanie liczb",
          "id": 4,
          "rows": {
            "1": {
              "min": 0,
              "max": 9
            },
            "2": {
              "min": 10,
              "max": 11
            },
            "3": {
              "min": 12,
              "max": 13
            },
            "4": {
              "min": 14,
              "max": 15
            },
            "5": {
              "min": 16,
              "max": 17
            },
            "6": {
              "min": 18,
              "max": 19
            },
            "7": {
              "min": 20,
              "max": 21
            },
            "8": {
              "min": 22,
              "max": 23
            },
            "9": {
              "min": 24,
              "max": 25
            },
            "10": {
              "min": 26,
              "max": 27
            },
            "11": {
              "min": 28,
              "max": 29
            },
            "12": {
              "min": 30,
              "max": 31
            },
            "13": {
              "min": 32,
              "max": 33
            },
            "14": {
              "min": 34,
              "max": 35
            },
            "15": {
              "min": 36,
              "max": 37
            },
            "16": {
              "min": 38,
              "max": 39
            },
            "17": {
              "min": 40,
              "max": 41
            },
            "18": {
              "min": 42,
              "max": 43
            },
            "19": {
              "min": 44,
              "max": 54
            }
          }
        },
        {
          "name": "Kodowanie",
          "id": 5,
          "rows": {
            "1": {
              "min": 0,
              "max": 23
            },
            "2": {
              "min": 24,
              "max": 29
            },
            "3": {
              "min": 30,
              "max": 35
            },
            "4": {
              "min": 36,
              "max": 40
            },
            "5": {
              "min": 41,
              "max": 46
            },
            "6": {
              "min": 47,
              "max": 51
            },
            "7": {
              "min": 52,
              "max": 56
            },
            "8": {
              "min": 57,
              "max": 62
            },
            "9": {
              "min": 63,
              "max": 67
            },
            "10": {
              "min": 68,
              "max": 72
            },
            "11": {
              "min": 73,
              "max": 77
            },
            "12": {
              "min": 78,
              "max": 82
            },
            "13": {
              "min": 83,
              "max": 87
            },
            "14": {
              "min": 88,
              "max": 92
            },
            "15": {
              "min": 93,
              "max": 97
            },
            "16": {
              "min": 98,
              "max": 102
            },
            "17": {
              "min": 103,
              "max": 106
            },
            "18": {
              "min": 107,
              "max": 111
            },
            "19": {
              "min": 112,
              "max": 117
            }
          }
        },
        {
          "name": "Słownik",
          "id": 6,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 10
            },
            "3": {
              "min": 11,
              "max": 14
            },
            "4": {
              "min": 15,
              "max": 18
            },
            "5": {
              "min": 19,
              "max": 22
            },
            "6": {
              "min": 23,
              "max": 27
            },
            "7": {
              "min": 28,
              "max": 30
            },
            "8": {
              "min": 31,
              "max": 34
            },
            "9": {
              "min": 35,
              "max": 37
            },
            "10": {
              "min": 38,
              "max": 39
            },
            "11": {
              "min": 40,
              "max": 42
            },
            "12": {
              "min": 43,
              "max": 44
            },
            "13": {
              "min": 45,
              "max": 46
            },
            "14": {
              "min": 47,
              "max": 48
            },
            "15": {
              "min": 49,
              "max": 50
            },
            "16": {
              "min": 51,
              "max": 51
            },
            "17": {
              "min": 52,
              "max": 52
            },
            "18": {
              "min": 53,
              "max": 53
            },
            "19": {
              "min": 54,
              "max": 54
            }
          }
        },
        {
          "name": "Ważenie",
          "id": 7,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 13
            },
            "5": {
              "min": 14,
              "max": 16
            },
            "6": {
              "min": 17,
              "max": 18
            },
            "7": {
              "min": 19,
              "max": 20
            },
            "8": {
              "min": 21,
              "max": 22
            },
            "9": {
              "min": 23,
              "max": 23
            },
            "10": {
              "min": 24,
              "max": 25
            },
            "11": {
              "min": 26,
              "max": 26
            },
            "12": {
              "min": 27,
              "max": 28
            },
            "13": {
              "min": 29,
              "max": 29
            },
            "14": {
              "min": 30,
              "max": 30
            },
            "15": {
              "min": 31,
              "max": 31
            },
            "16": {
              "min": 32,
              "max": 32
            },
            "17": {
              "min": 33,
              "max": 33
            },
            "18": {
              "min": null,
              "max": null
            },
            "19": {
              "min": 34,
              "max": 34
            }
          }
        },
        {
          "name": "Układnie wzoru",
          "id": 8,
          "rows": {
            "1": {
              "min": 0,
              "max": 4
            },
            "2": {
              "min": 5,
              "max": 6
            },
            "3": {
              "min": 7,
              "max": 9
            },
            "4": {
              "min": 10,
              "max": 11
            },
            "5": {
              "min": 12,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 15
            },
            "7": {
              "min": 16,
              "max": 16
            },
            "8": {
              "min": 17,
              "max": 17
            },
            "9": {
              "min": 18,
              "max": 19
            },
            "10": {
              "min": 20,
              "max": 20
            },
            "11": {
              "min": 21,
              "max": 21
            },
            "12": {
              "min": 22,
              "max": 22
            },
            "13": {
              "min": 23,
              "max": 23
            },
            "14": {
              "min": 24,
              "max": 25
            },
            "15": {
              "min": 26,
              "max": 26
            },
            "16": {
              "min": null,
              "max": null
            },
            "17": {
              "min": 27,
              "max": 27
            },
            "18": {
              "min": 28,
              "max": 28
            },
            "19": {
              "min": 29,
              "max": 29
            }
          }
        },
        {
          "name": "Zapamiętywanie obrazków",
          "id": 9,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 11
            },
            "3": {
              "min": 12,
              "max": 14
            },
            "4": {
              "min": 15,
              "max": 17
            },
            "5": {
              "min": 18,
              "max": 20
            },
            "6": {
              "min": 21,
              "max": 23
            },
            "7": {
              "min": 24,
              "max": 25
            },
            "8": {
              "min": 26,
              "max": 28
            },
            "9": {
              "min": 29,
              "max": 30
            },
            "10": {
              "min": 31,
              "max": 32
            },
            "11": {
              "min": 33,
              "max": 34
            },
            "12": {
              "min": 35,
              "max": 36
            },
            "13": {
              "min": 37,
              "max": 39
            },
            "14": {
              "min": 40,
              "max": 41
            },
            "15": {
              "min": 42,
              "max": 43
            },
            "16": {
              "min": 44,
              "max": 45
            },
            "17": {
              "min": 46,
              "max": 46
            },
            "18": {
              "min": 47,
              "max": 47
            },
            "19": {
              "min": 48,
              "max": 49
            }
          }
        },
        {
          "name": "Szukanie symboli",
          "id": 10,
          "rows": {
            "1": {
              "min": 0,
              "max": 10
            },
            "2": {
              "min": 11,
              "max": 14
            },
            "3": {
              "min": 15,
              "max": 17
            },
            "4": {
              "min": 18,
              "max": 20
            },
            "5": {
              "min": 21,
              "max": 23
            },
            "6": {
              "min": 24,
              "max": 26
            },
            "7": {
              "min": 27,
              "max": 28
            },
            "8": {
              "min": 29,
              "max": 31
            },
            "9": {
              "min": 32,
              "max": 34
            },
            "10": {
              "min": 35,
              "max": 36
            },
            "11": {
              "min": 37,
              "max": 39
            },
            "12": {
              "min": 40,
              "max": 41
            },
            "13": {
              "min": 42,
              "max": 43
            },
            "14": {
              "min": 44,
              "max": 46
            },
            "15": {
              "min": 47,
              "max": 48
            },
            "16": {
              "min": 49,
              "max": 50
            },
            "17": {
              "min": 51,
              "max": 52
            },
            "18": {
              "min": 53,
              "max": 55
            },
            "19": {
              "min": 56,
              "max": 60
            }
          }
        },
        {
          "name": "Wiadomości",
          "id": 11,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 11
            },
            "5": {
              "min": 12,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 16
            },
            "8": {
              "min": 17,
              "max": 18
            },
            "9": {
              "min": 19,
              "max": 20
            },
            "10": {
              "min": 21,
              "max": 22
            },
            "11": {
              "min": 23,
              "max": 23
            },
            "12": {
              "min": 24,
              "max": 25
            },
            "13": {
              "min": 26,
              "max": 27
            },
            "14": {
              "min": 28,
              "max": 28
            },
            "15": {
              "min": 29,
              "max": 29
            },
            "16": {
              "min": 30,
              "max": 30
            },
            "17": {
              "min": null,
              "max": null
            },
            "18": {
              "min": null,
              "max": null
            },
            "19": {
              "min": 31,
              "max": 31
            }
          }
        },
        {
          "name": "Tworzenie pojęć",
          "id": 12,
          "rows": {
            "1": {
              "min": 0,
              "max": 5
            },
            "2": {
              "min": 6,
              "max": 6
            },
            "3": {
              "min": 7,
              "max": 8
            },
            "4": {
              "min": 9,
              "max": 9
            },
            "5": {
              "min": 10,
              "max": 10
            },
            "6": {
              "min": 11,
              "max": 11
            },
            "7": {
              "min": 12,
              "max": 13
            },
            "8": {
              "min": 14,
              "max": 14
            },
            "9": {
              "min": 15,
              "max": 15
            },
            "10": {
              "min": 16,
              "max": 16
            },
            "11": {
              "min": 17,
              "max": 18
            },
            "12": {
              "min": 19,
              "max": 19
            },
            "13": {
              "min": 20,
              "max": 20
            },
            "14": {
              "min": 21,
              "max": 21
            },
            "15": {
              "min": 22,
              "max": 22
            },
            "16": {
              "min": 23,
              "max": 23
            },
            "17": {
              "min": null,
              "max": null
            },
            "18": {
              "min": 24,
              "max": 24
            },
            "19": {
              "min": 25,
              "max": 27
            }
          }
        },
        {
          "name": "Porządkowanie liter i liczb",
          "id": 13,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 12
            },
            "5": {
              "min": 13,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 15
            },
            "8": {
              "min": 16,
              "max": 16
            },
            "9": {
              "min": 17,
              "max": 17
            },
            "10": {
              "min": 18,
              "max": 19
            },
            "11": {
              "min": 20,
              "max": 20
            },
            "12": {
              "min": 21,
              "max": 21
            },
            "13": {
              "min": 22,
              "max": 22
            },
            "14": {
              "min": 23,
              "max": 23
            },
            "15": {
              "min": 24,
              "max": 24
            },
            "16": {
              "min": 25,
              "max": 25
            },
            "17": {
              "min": 26,
              "max": 26
            },
            "18": {
              "min": 27,
              "max": 27
            },
            "19": {
              "min": 28,
              "max": 30
            }
          }
        },
        {
          "name": "Wykreślanie",
          "id": 14,
          "rows": {
            "1": {
              "min": 0,
              "max": 28
            },
            "2": {
              "min": 29,
              "max": 37
            },
            "3": {
              "min": 38,
              "max": 45
            },
            "4": {
              "min": 46,
              "max": 53
            },
            "5": {
              "min": 54,
              "max": 61
            },
            "6": {
              "min": 62,
              "max": 68
            },
            "7": {
              "min": 69,
              "max": 75
            },
            "8": {
              "min": 76,
              "max": 82
            },
            "9": {
              "min": 83,
              "max": 88
            },
            "10": {
              "min": 89,
              "max": 94
            },
            "11": {
              "min": 95,
              "max": 100
            },
            "12": {
              "min": 101,
              "max": 106
            },
            "13": {
              "min": 107,
              "max": 111
            },
            "14": {
              "min": 112,
              "max": 116
            },
            "15": {
              "min": 117,
              "max": 120
            },
            "16": {
              "min": 121,
              "max": 123
            },
            "17": {
              "min": 124,
              "max": 126
            },
            "18": {
              "min": 127,
              "max": 127
            },
            "19": {
              "min": 128,
              "max": 128
            }
          }
        },
        {
          "name": "Rozumienie",
          "id": 15,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 13
            },
            "5": {
              "min": 14,
              "max": 15
            },
            "6": {
              "min": 16,
              "max": 18
            },
            "7": {
              "min": 19,
              "max": 20
            },
            "8": {
              "min": 21,
              "max": 22
            },
            "9": {
              "min": 23,
              "max": 24
            },
            "10": {
              "min": 25,
              "max": 26
            },
            "11": {
              "min": 27,
              "max": 27
            },
            "12": {
              "min": 28,
              "max": 29
            },
            "13": {
              "min": 30,
              "max": 30
            },
            "14": {
              "min": 31,
              "max": 31
            },
            "15": {
              "min": 32,
              "max": 33
            },
            "16": {
              "min": 34,
              "max": 34
            },
            "17": {
              "min": 35,
              "max": 35
            },
            "18": {
              "min": 36,
              "max": 36
            },
            "19": {
              "min": 37,
              "max": 38
            }
          }
        },
        {
          "name": "Arytmetyka",
          "id": 16,
          "rows": {
            "1": {
              "min": 0,
              "max": 9
            },
            "2": {
              "min": 10,
              "max": 12
            },
            "3": {
              "min": 13,
              "max": 14
            },
            "4": {
              "min": 15,
              "max": 16
            },
            "5": {
              "min": 17,
              "max": 18
            },
            "6": {
              "min": 19,
              "max": 20
            },
            "7": {
              "min": 21,
              "max": 21
            },
            "8": {
              "min": 22,
              "max": 23
            },
            "9": {
              "min": 24,
              "max": 24
            },
            "10": {
              "min": 25,
              "max": 25
            },
            "11": {
              "min": 26,
              "max": 26
            },
            "12": {
              "min": 27,
              "max": 28
            },
            "13": {
              "min": 29,
              "max": 29
            },
            "14": {
              "min": 30,
              "max": 30
            },
            "15": {
              "min": 31,
              "max": 31
            },
            "16": {
              "min": 32,
              "max": 32
            },
            "17": {
              "min": null,
              "max": null
            },
            "18": {
              "min": 33,
              "max": 33
            },
            "19": {
              "min": 34,
              "max": 34
            }
          }
        }
      ],  
    
    1681611: [
        {
          "name": "Wzory z klocków",
          "id": 1,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 10
            },
            "3": {
              "min": 11,
              "max": 15
            },
            "4": {
              "min": 16,
              "max": 19
            },
            "5": {
              "min": 20,
              "max": 24
            },
            "6": {
              "min": 25,
              "max": 28
            },
            "7": {
              "min": 29,
              "max": 32
            },
            "8": {
              "min": 33,
              "max": 35
            },
            "9": {
              "min": 36,
              "max": 38
            },
            "10": {
              "min": 39,
              "max": 41
            },
            "11": {
              "min": 42,
              "max": 44
            },
            "12": {
              "min": 45,
              "max": 46
            },
            "13": {
              "min": 47,
              "max": 49
            },
            "14": {
              "min": 50,
              "max": 51
            },
            "15": {
              "min": 52,
              "max": 53
            },
            "16": {
              "min": 54,
              "max": 55
            },
            "17": {
              "min": 56,
              "max": 56
            },
            "18": {
              "min": 57,
              "max": 57
            },
            "19": {
              "min": 58,
              "max": 58
            }
          }
        },
        {
          "name": "Podobieństwa",
          "id": 2,
          "rows": {
            "1": {
              "min": 0,
              "max": 12
            },
            "2": {
              "min": 13,
              "max": 15
            },
            "3": {
              "min": 16,
              "max": 17
            },
            "4": {
              "min": 18,
              "max": 19
            },
            "5": {
              "min": 20,
              "max": 22
            },
            "6": {
              "min": 23,
              "max": 25
            },
            "7": {
              "min": 26,
              "max": 27
            },
            "8": {
              "min": 28,
              "max": 29
            },
            "9": {
              "min": 30,
              "max": 32
            },
            "10": {
              "min": 33,
              "max": 34
            },
            "11": {
              "min": 35,
              "max": 36
            },
            "12": {
              "min": 37,
              "max": 37
            },
            "13": {
              "min": 38,
              "max": 39
            },
            "14": {
              "min": 40,
              "max": 41
            },
            "15": {
              "min": 42,
              "max": 42
            },
            "16": {
              "min": 43,
              "max": 43
            },
            "17": {
              "min": 44,
              "max": 44
            },
            "18": {
              "min": 45,
              "max": 45
            },
            "19": {
              "min": 46,
              "max": 46
            }
          }
        },
        {
          "name": "Matryce",
          "id": 3,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 8
            },
            "3": {
              "min": 9,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 12
            },
            "5": {
              "min": 13,
              "max": 14
            },
            "6": {
              "min": 15,
              "max": 15
            },
            "7": {
              "min": 16,
              "max": 17
            },
            "8": {
              "min": 18,
              "max": 18
            },
            "9": {
              "min": 19,
              "max": 20
            },
            "10": {
              "min": 21,
              "max": 21
            },
            "11": {
              "min": 22,
              "max": 23
            },
            "12": {
              "min": 24,
              "max": 24
            },
            "13": {
              "min": 25,
              "max": 26
            },
            "14": {
              "min": 27,
              "max": 27
            },
            "15": {
              "min": 28,
              "max": 28
            },
            "16": {
              "min": 29,
              "max": 29
            },
            "17": {
              "min": 30,
              "max": 30
            },
            "18": {
              "min": 31,
              "max": 31
            },
            "19": {
              "min": 32,
              "max": 32
            }
          }
        },
        {
          "name": "Powtarzanie liczb",
          "id": 4,
          "rows": {
            "1": {
              "min": 0,
              "max": 9
            },
            "2": {
              "min": 10,
              "max": 11
            },
            "3": {
              "min": 12,
              "max": 13
            },
            "4": {
              "min": 14,
              "max": 15
            },
            "5": {
              "min": 16,
              "max": 17
            },
            "6": {
              "min": 18,
              "max": 19
            },
            "7": {
              "min": 20,
              "max": 21
            },
            "8": {
              "min": 22,
              "max": 23
            },
            "9": {
              "min": 24,
              "max": 25
            },
            "10": {
              "min": 26,
              "max": 27
            },
            "11": {
              "min": 28,
              "max": 29
            },
            "12": {
              "min": 30,
              "max": 31
            },
            "13": {
              "min": 32,
              "max": 33
            },
            "14": {
              "min": 34,
              "max": 36
            },
            "15": {
              "min": 37,
              "max": 38
            },
            "16": {
              "min": 39,
              "max": 40
            },
            "17": {
              "min": 41,
              "max": 42
            },
            "18": {
              "min": 43,
              "max": 44
            },
            "19": {
              "min": 45,
              "max": 54
            }
          }
        },
        {
          "name": "Kodowanie",
          "id": 5,
          "rows": {
            "1": {
              "min": 0,
              "max": 24
            },
            "2": {
              "min": 25,
              "max": 30
            },
            "3": {
              "min": 31,
              "max": 36
            },
            "4": {
              "min": 37,
              "max": 41
            },
            "5": {
              "min": 42,
              "max": 47
            },
            "6": {
              "min": 48,
              "max": 52
            },
            "7": {
              "min": 53,
              "max": 57
            },
            "8": {
              "min": 58,
              "max": 63
            },
            "9": {
              "min": 64,
              "max": 68
            },
            "10": {
              "min": 69,
              "max": 73
            },
            "11": {
              "min": 74,
              "max": 78
            },
            "12": {
              "min": 79,
              "max": 83
            },
            "13": {
              "min": 84,
              "max": 89
            },
            "14": {
              "min": 90,
              "max": 94
            },
            "15": {
              "min": 95,
              "max": 98
            },
            "16": {
              "min": 99,
              "max": 103
            },
            "17": {
              "min": 104,
              "max": 108
            },
            "18": {
              "min": 109,
              "max": 112
            },
            "19": {
              "min": 113,
              "max": 117
            }
          }
        },
        {
          "name": "Słownik",
          "id": 6,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 10
            },
            "3": {
              "min": 11,
              "max": 14
            },
            "4": {
              "min": 15,
              "max": 19
            },
            "5": {
              "min": 20,
              "max": 23
            },
            "6": {
              "min": 24,
              "max": 27
            },
            "7": {
              "min": 28,
              "max": 31
            },
            "8": {
              "min": 32,
              "max": 34
            },
            "9": {
              "min": 35,
              "max": 37
            },
            "10": {
              "min": 38,
              "max": 40
            },
            "11": {
              "min": 41,
              "max": 42
            },
            "12": {
              "min": 43,
              "max": 44
            },
            "13": {
              "min": 45,
              "max": 46
            },
            "14": {
              "min": 47,
              "max": 48
            },
            "15": {
              "min": 49,
              "max": 50
            },
            "16": {
              "min": 51,
              "max": 51
            },
            "17": {
              "min": 52,
              "max": 52
            },
            "18": {
              "min": 53,
              "max": 53
            },
            "19": {
              "min": 54,
              "max": 54
            }
          }
        },
        {
          "name": "Ważenie",
          "id": 7,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 13
            },
            "5": {
              "min": 14,
              "max": 16
            },
            "6": {
              "min": 17,
              "max": 18
            },
            "7": {
              "min": 19,
              "max": 20
            },
            "8": {
              "min": 21,
              "max": 22
            },
            "9": {
              "min": 23,
              "max": 23
            },
            "10": {
              "min": 24,
              "max": 25
            },
            "11": {
              "min": 26,
              "max": 26
            },
            "12": {
              "min": 27,
              "max": 28
            },
            "13": {
              "min": 29,
              "max": 29
            },
            "14": {
              "min": 30,
              "max": 30
            },
            "15": {
              "min": 31,
              "max": 31
            },
            "16": {
              "min": 32,
              "max": 32
            },
            "17": {
              "min": 33,
              "max": 33
            },
            "18": {
              "min": null,
              "max": null
            },
            "19": {
              "min": 34,
              "max": 34
            }
          }
        },
        {
          "name": "Układnie wzoru",
          "id": 8,
          "rows": {
            "1": {
              "min": 0,
              "max": 5
            },
            "2": {
              "min": 6,
              "max": 7
            },
            "3": {
              "min": 8,
              "max": 9
            },
            "4": {
              "min": 10,
              "max": 11
            },
            "5": {
              "min": 12,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 16
            },
            "8": {
              "min": 17,
              "max": 17
            },
            "9": {
              "min": 18,
              "max": 19
            },
            "10": {
              "min": 20,
              "max": 20
            },
            "11": {
              "min": 21,
              "max": 21
            },
            "12": {
              "min": 22,
              "max": 22
            },
            "13": {
              "min": 23,
              "max": 23
            },
            "14": {
              "min": 24,
              "max": 25
            },
            "15": {
              "min": 26,
              "max": 26
            },
            "16": {
              "min": null,
              "max": null
            },
            "17": {
              "min": 27,
              "max": 27
            },
            "18": {
              "min": 28,
              "max": 28
            },
            "19": {
              "min": 29,
              "max": 29
            }
          }
        },
        {
          "name": "Zapamiętywanie obrazków",
          "id": 9,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 11
            },
            "3": {
              "min": 12,
              "max": 14
            },
            "4": {
              "min": 15,
              "max": 17
            },
            "5": {
              "min": 18,
              "max": 20
            },
            "6": {
              "min": 21,
              "max": 23
            },
            "7": {
              "min": 24,
              "max": 25
            },
            "8": {
              "min": 26,
              "max": 28
            },
            "9": {
              "min": 29,
              "max": 30
            },
            "10": {
              "min": 31,
              "max": 32
            },
            "11": {
              "min": 33,
              "max": 34
            },
            "12": {
              "min": 35,
              "max": 36
            },
            "13": {
              "min": 37,
              "max": 39
            },
            "14": {
              "min": 40,
              "max": 41
            },
            "15": {
              "min": 42,
              "max": 43
            },
            "16": {
              "min": 44,
              "max": 45
            },
            "17": {
              "min": 46,
              "max": 46
            },
            "18": {
              "min": 47,
              "max": 47
            },
            "19": {
              "min": 48,
              "max": 49
            }
          }
        },
        {
          "name": "Szukanie symboli",
          "id": 10,
          "rows": {
            "1": {
              "min": 0,
              "max": 11
            },
            "2": {
              "min": 12,
              "max": 14
            },
            "3": {
              "min": 15,
              "max": 17
            },
            "4": {
              "min": 18,
              "max": 20
            },
            "5": {
              "min": 21,
              "max": 23
            },
            "6": {
              "min": 24,
              "max": 26
            },
            "7": {
              "min": 27,
              "max": 29
            },
            "8": {
              "min": 30,
              "max": 31
            },
            "9": {
              "min": 32,
              "max": 34
            },
            "10": {
              "min": 35,
              "max": 36
            },
            "11": {
              "min": 37,
              "max": 39
            },
            "12": {
              "min": 40,
              "max": 41
            },
            "13": {
              "min": 42,
              "max": 44
            },
            "14": {
              "min": 45,
              "max": 46
            },
            "15": {
              "min": 47,
              "max": 48
            },
            "16": {
              "min": 49,
              "max": 50
            },
            "17": {
              "min": 51,
              "max": 53
            },
            "18": {
              "min": 54,
              "max": 55
            },
            "19": {
              "min": 56,
              "max": 60
            }
          }
        },
        {
          "name": "Wiadomości",
          "id": 11,
          "rows": {
            "1": {
              "min": 0,
              "max": 8
            },
            "2": {
              "min": 9,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 11
            },
            "5": {
              "min": 12,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 16
            },
            "8": {
              "min": 17,
              "max": 18
            },
            "9": {
              "min": 19,
              "max": 20
            },
            "10": {
              "min": 21,
              "max": 22
            },
            "11": {
              "min": 23,
              "max": 24
            },
            "12": {
              "min": 25,
              "max": 25
            },
            "13": {
              "min": 26,
              "max": 27
            },
            "14": {
              "min": 28,
              "max": 28
            },
            "15": {
              "min": 29,
              "max": 29
            },
            "16": {
              "min": 30,
              "max": 30
            },
            "17": {
              "min": null,
              "max": null
            },
            "18": {
              "min": null,
              "max": null
            },
            "19": {
              "min": 31,
              "max": 31
            }
          }
        },
        {
          "name": "Tworzenie pojęć",
          "id": 12,
          "rows": {
            "1": {
              "min": 0,
              "max": 5
            },
            "2": {
              "min": 6,
              "max": 6
            },
            "3": {
              "min": 7,
              "max": 8
            },
            "4": {
              "min": 9,
              "max": 9
            },
            "5": {
              "min": 10,
              "max": 10
            },
            "6": {
              "min": 11,
              "max": 11
            },
            "7": {
              "min": 12,
              "max": 13
            },
            "8": {
              "min": 14,
              "max": 14
            },
            "9": {
              "min": 15,
              "max": 15
            },
            "10": {
              "min": 16,
              "max": 17
            },
            "11": {
              "min": 18,
              "max": 18
            },
            "12": {
              "min": 19,
              "max": 19
            },
            "13": {
              "min": 20,
              "max": 20
            },
            "14": {
              "min": 21,
              "max": 21
            },
            "15": {
              "min": 22,
              "max": 22
            },
            "16": {
              "min": 23,
              "max": 23
            },
            "17": {
              "min": 24,
              "max": 24
            },
            "18": {
              "min": null,
              "max": null
            },
            "19": {
              "min": 25,
              "max": 27
            }
          }
        },
        {
          "name": "Porządkowanie liter i liczb",
          "id": 13,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 10
            },
            "4": {
              "min": 11,
              "max": 12
            },
            "5": {
              "min": 13,
              "max": 13
            },
            "6": {
              "min": 14,
              "max": 14
            },
            "7": {
              "min": 15,
              "max": 15
            },
            "8": {
              "min": 16,
              "max": 16
            },
            "9": {
              "min": 17,
              "max": 18
            },
            "10": {
              "min": 19,
              "max": 19
            },
            "11": {
              "min": 20,
              "max": 20
            },
            "12": {
              "min": 21,
              "max": 21
            },
            "13": {
              "min": 22,
              "max": 22
            },
            "14": {
              "min": 23,
              "max": 23
            },
            "15": {
              "min": 24,
              "max": 24
            },
            "16": {
              "min": 25,
              "max": 25
            },
            "17": {
              "min": 26,
              "max": 26
            },
            "18": {
              "min": 27,
              "max": 27
            },
            "19": {
              "min": 28,
              "max": 30
            }
          }
        },
        {
          "name": "Wykreślanie",
          "id": 14,
          "rows": {
            "1": {
              "min": 0,
              "max": 30
            },
            "2": {
              "min": 31,
              "max": 39
            },
            "3": {
              "min": 40,
              "max": 47
            },
            "4": {
              "min": 48,
              "max": 55
            },
            "5": {
              "min": 56,
              "max": 63
            },
            "6": {
              "min": 64,
              "max": 70
            },
            "7": {
              "min": 71,
              "max": 77
            },
            "8": {
              "min": 78,
              "max": 83
            },
            "9": {
              "min": 84,
              "max": 89
            },
            "10": {
              "min": 90,
              "max": 96
            },
            "11": {
              "min": 97,
              "max": 101
            },
            "12": {
              "min": 102,
              "max": 107
            },
            "13": {
              "min": 108,
              "max": 112
            },
            "14": {
              "min": 113,
              "max": 117
            },
            "15": {
              "min": 118,
              "max": 121
            },
            "16": {
              "min": 122,
              "max": 124
            },
            "17": {
              "min": 125,
              "max": 126
            },
            "18": {
              "min": 127,
              "max": 127
            },
            "19": {
              "min": 128,
              "max": 128
            }
          }
        },
        {
          "name": "Rozumienie",
          "id": 15,
          "rows": {
            "1": {
              "min": 0,
              "max": 7
            },
            "2": {
              "min": 8,
              "max": 9
            },
            "3": {
              "min": 10,
              "max": 11
            },
            "4": {
              "min": 12,
              "max": 13
            },
            "5": {
              "min": 14,
              "max": 16
            },
            "6": {
              "min": 17,
              "max": 18
            },
            "7": {
              "min": 19,
              "max": 21
            },
            "8": {
              "min": 22,
              "max": 23
            },
            "9": {
              "min": 24,
              "max": 24
            },
            "10": {
              "min": 25,
              "max": 26
            },
            "11": {
              "min": 27,
              "max": 28
            },
            "12": {
              "min": 29,
              "max": 29
            },
            "13": {
              "min": 30,
              "max": 30
            },
            "14": {
              "min": 31,
              "max": 32
            },
            "15": {
              "min": 33,
              "max": 33
            },
            "16": {
              "min": 34,
              "max": 34
            },
            "17": {
              "min": 35,
              "max": 35
            },
            "18": {
              "min": 36,
              "max": 36
            },
            "19": {
              "min": 37,
              "max": 38
            }
          }
        },
        {
          "name": "Arytmetyka",
          "id": 16,
          "rows": {
            "1": {
              "min": 0,
              "max": 9
            },
            "2": {
              "min": 10,
              "max": 12
            },
            "3": {
              "min": 13,
              "max": 14
            },
            "4": {
              "min": 15,
              "max": 16
            },
            "5": {
              "min": 17,
              "max": 18
            },
            "6": {
              "min": 19,
              "max": 20
            },
            "7": {
              "min": 21,
              "max": 21
            },
            "8": {
              "min": 22,
              "max": 23
            },
            "9": {
              "min": 24,
              "max": 24
            },
            "10": {
              "min": 25,
              "max": 25
            },
            "11": {
              "min": 26,
              "max": 26
            },
            "12": {
              "min": 27,
              "max": 28
            },
            "13": {
              "min": 29,
              "max": 29
            },
            "14": {
              "min": 30,
              "max": 30
            },
            "15": {
              "min": 31,
              "max": 31
            },
            "16": {
              "min": 32,
              "max": 32
            },
            "17": {
              "min": null,
              "max": null
            },
            "18": {
              "min": 33,
              "max": 33
            },
            "19": {
              "min": 34,
              "max": 34
            }
          }
        }
      ],  

    }



export default wiscvRaw;
