<template>
 
      <div class="min-h-full flex justify-center mt-9">
      
      <main>
         <h1 class="text-3xl font-bold leading-tight tracking-tight text-gray-900">Export data</h1>
         <button @click="exportData(dataForExport)" class="bg-green-dla-400 hover:bg-green-dla-500 text-white font-bold py-2 px-4 rounded">
            Podgląd
            </button>
      </main>
   </div>


</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import Navbar from '@/components/Navbar.vue';
import RatioSmallCards from '@/components/RatioSmallCards.vue';

// import ids2data from '@/data/ids-2-pelny-data.js';
import dataForExport from '@/data/wisc-v-data-raw.js';



import addDocumentWithIdSet from '../composables/addDocumentWithIdSet.js';


export default {
   name: 'Export',
   components: {
      VueDatePicker, Navbar, RatioSmallCards
   },

   
   props: ["id"],
   setup(props) {

   
   

      // const exportData = async (data) => {
      //    console.log("exportData")
      //    // const chosenData = data[505230][1]
      //    // console.log("chosenData: ", chosenData)
      //    for (const [key, items] of Object.entries(data)) {
      //       for (const item of items) {
      //          const documentId = key.toString(); // Konwersja klucza na string, jeśli jest to konieczne
      //          console.log(documentId, item)
      //          // await addDocumentWithId('data-Ids2-RAW', documentId, item);
      //       }
      //    }
      // }


      const exportData = async (data) => {
         console.log("exportData");
         console.log("data: ", data);
         for (const [key, items] of Object.entries(data)) {
            const documentId = key.toString(); 
            console.log("Document ID:", documentId, "Items:", items);
            await addDocumentWithIdSet('data-WiscV-RAW', documentId, { items });
         }
         };


      return {
         dataForExport,
         exportData,
         
      };
   },
};
</script>


<style scope>
.section {
   /* border: 1px solid grey; */
   padding: 20px;
   border-radius: 7px;
   margin-top: 0px;
   width: 650px;

   display: flex;
   flex-direction: column;
}
.section h2 {
   text-align: left;
   color: black;
   font-weight: 400;
   font-size: 1.8rem;
   padding-bottom: 00px;
   margin-bottom: 15px;
}

.number-container {
   /* overflow: auto; */
   display: flex;
   flex-direction: row;
   /* justify-content: center; */
}
/* .number-item {
    width: 30px; 
} */
.number-item span {
   display: flex;
   flex-direction: column;
}
.number-item label {
   font-size: 0.9rem;
}

/* .number-container input[type="checkbox"] {
  transform: scale(2.5);
} */
.number-container input/*checkbox class name*/ {
   cursor: pointer;
   width: 24px /*preferred width*/;
   height: 24px /*preferred height*/;
}
/* .number-container input:hover {
} */

a {
   margin-right: 0px;
   padding: 2px 5px;
   border-radius: 7px;
}
a:hover {
   background-color: rgb(223, 223, 223);
   cursor: pointer;
}

.testheader {
   /* width: 100%; */
   height: 50px;
   background-color: #7d367d;
   color: black;
   padding: 25px;
   text-align: left;
   font-size: 3rem;
   font-weight: bold;
}
.testheader span {
   color: white;
}

.home {
   display: flex;
   flex-flow: column wrap;
   justify-content: center;
   align-items: center;
}

.details {
   padding-top: 35px;
   display: flex;
   flex-flow: column wrap;
   justify-content: center;
   align-items: center;
}

.normal {
   display: block;
   padding: 0px;
   width: 22px;
   height: 22px;
   margin: 3px;
   border: 2px solid rgb(196, 196, 196);
   background-color: rgb(196, 196, 196);
   color: rgb(196, 196, 196);
}

.normal span {
   font-size: 1.4rem;
}

.normal:hover {
   border: 2px solid #886f88;
   background-color: #886f88;
   transition-duration: 0.5s;
   color: #886f88;
}

.choosen {
   display: block;
   padding: 0px;
   width: 22px;
   height: 22px;
   margin: 3px;
   border: 2px solid #7d367d;
   background-color: #7d367d;
   color: white;
}
.choosen span {
   font-size: 1.4rem;
}

.choosen:hover {
   background-color: #7d367d;
}

.copy {
   padding: 0 35px;
   display: flex;
   /* justify-content: center; */
   justify-content: space-between;

   flex-flow: row-reverse;
}

button {
   padding: 15px;
   border: 0px;
   border-radius: 7px;
   color: white;
   margin: 5px;
   width: fit-content;
   font-size: 1.2rem;
   display: flex;
}

button:hover {
   /* background-color: rgb(0, 145, 77); */
   background-color: #1664c9;
   color: white;

   transition-duration: 0.3s;
   cursor: pointer;
}

.preview {
   padding: 25px;
   display: flex;
   justify-content: center;
   flex-flow: column wrap;
}

.preview textarea {
   border-radius: 7px;
   padding: 15px;
   font-size: 1.2rem;
   color: #4b4b4b;
   border: 1px solid #dadce0;
   font-family: Roboto, Helvetica, Arial, sans-serif;
   /* width: 90vh; */
   min-height: 300px;
}

.preview textarea:focus {
   outline: none !important;
   border: 1px solid #dadce0;
   /* box-shadow: 0 0 10px #e4e4e4; */
   -webkit-box-shadow: 10px 11px 30px -12px rgba(218, 218, 218, 1);
   -moz-box-shadow: 10px 11px 30px -12px rgba(218, 218, 218, 1);
   box-shadow: 10px 11px 30px -12px rgba(218, 218, 218, 1);
}

.backBtn {
   background-color: rgb(207, 207, 207);
}
.mainBtn {
   /* background-color: rgb(0, 196, 104); */
   background-color: #1a73e8;
   padding: 15px 35px;
}

.dp-custom-menu .dp__now_button {
   padding: 10px 35px;
   margin-bottom: 15px;
   margin-left: auto;
   margin-right: auto;
}

/* .dp-custom-menu {
  box-shadow: 0 0 6px #1976d2;
} */

.plecItem {
   border: 1px solid #ddd;
   border-radius: 4px;
   padding: 3px 12px;
   float: left;

   margin-right: 8px;
   margin-left: 8px;
   width: 81px;
}

.control-group {
   margin-top: 20px;
}

.control {
   font-family: arial;
   display: block;
   position: relative;
   padding-left: 30px;
   margin-bottom: 5px;
   padding-top: 3px;
   cursor: pointer;
   font-size: 16px;
}
.control input {
   position: absolute;
   z-index: -1;
   opacity: 0;
}
.control_indicator {
   position: absolute;
   top: 4px;
   left: 0;
   height: 20px;
   width: 20px;
   background: #e6e6e6;
   border: 0px solid #000000;
   border-radius: undefinedpx;
}
.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
   background: #cccccc;
}

.control input:checked ~ .control_indicator {
   background: #7d367d;
}
.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
   background: #7d367d;
}
.control input:disabled ~ .control_indicator {
   background: #e6e6e6;
   opacity: 0.6;
   pointer-events: none;
}
.control_indicator:after {
   box-sizing: unset;
   content: '';
   position: absolute;
   display: none;
}
.control input:checked ~ .control_indicator:after {
   display: block;
}
.control-radio .control_indicator {
   border-radius: 50%;
}

.control-radio .control_indicator:after {
   left: 7px;
   top: 7px;
   height: 6px;
   width: 6px;
   border-radius: 50%;
   background: #ffffff;
   transition: background 250ms;
}
.control-radio input:disabled ~ .control_indicator:after {
   background: #7b7b7b;
}
.control-radio .control_indicator::before {
   content: '';
   display: block;
   position: absolute;
   left: -5px;
   top: -5px;
   width: 4.5rem;
   height: 4.5rem;
   margin-left: -1.3rem;
   margin-top: -1.3rem;
   background: #7d367d;
   border-radius: 3rem;
   opacity: 0.6;
   z-index: 99999;
   transform: scale(0);
}
@keyframes s-ripple {
   0% {
      opacity: 0;
      transform: scale(0);
   }
   20% {
      transform: scale(1);
   }
   100% {
      opacity: 0.01;
      transform: scale(1);
   }
}
@keyframes s-ripple-dup {
   0% {
      transform: scale(0);
   }
   30% {
      transform: scale(1);
   }
   60% {
      transform: scale(1);
   }
   100% {
      opacity: 0;
      transform: scale(1);
   }
}
.control-radio input + .control_indicator::before {
   animation: s-ripple 250ms ease-out;
}
.control-radio input:checked + .control_indicator::before {
   animation-name: s-ripple-dup;
}


.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  /* width: 140px; */
  /* background-color: #555; */
  /* color: #fff; */
  /* text-align: center;
  border-radius: 6px;
  padding: 5px; */
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}




/* kalendarz */
  /* .dp__overlay_cell_active {
    @apply bg-green-dla-400;

   }

   .dp__action_buttons {
      margin:auto;
   }

   .dp__action_buttons button {
      padding: 5px 15px 25px 15px;
   }

   .dp__action_buttons button:hover {
      @apply bg-green-dla-400;

      @apply border-green-dla-400;
   } */


/* .dp__action_buttons{
   @apply bg-green-dla-400;
   text-align: center;
} */


.showSmooth{
  opacity: 0; /* Początkowa wartość */
  animation: showFull 1s ease-in forwards; /* Animacja trwająca 2 sekundy i zmieniająca właściwość do końcowej wartości */
}

@keyframes showFull {
  to {
    opacity: 1;
  }
}

</style>
