<template>
 <div class="min-h-full">

<TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="open = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
              <div>
                <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                  <ArrowPathIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">Aktualizacja Dostępna!</DialogTitle>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">Wprowadziliśmy ważne zmiany, które mogą poprawić Twoje doświadczenie. Proszę&nbsp;odśwież stronę, żeby korzystać z najnowszych funkcji.</p>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6">
                <button type="button" class="inline-flex w-full justify-center rounded-md border border-transparent bg-green-dla-400 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-dla-500 focus:outline-none focus:ring-2 focus:ring-green-dla-500 focus:ring-offset-2 sm:text-sm" @click="open = false, refreshPage()">Odświeżam stronę</button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>





 
    <!-- <Navbar /> -->
   <header class="bg-white shadow">

      <div class="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
        <h1 class="text-3xl font-bold leading-tight tracking-tight text-gray-900">Moje opisy</h1>
      </div>
    </header>
    <main>
      <div class="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
        <!-- Replace with your content -->
        <!-- <div class="px-4 py-4 sm:px-0">
          <DescriptionComponentList :doneDescriptions="doneDescriptions" />
        </div> -->
        <div class="px-4 py-4 sm:px-0">
          <!-- <Wsparcie v-if="doneDescriptions"/>  -->
          
          <!-- <div class="h-96 rounded-lg border-4 border-dashed border-gray-200" /> -->
          <TableComponent :doneDescriptions="doneDescriptions" />
        </div>
        <!-- /End replace -->
      </div>
      <div><p class="mt-10 text-center text-xs text-gray-300">dlaPsychologa {{version}} wersja BETA</p></div>
    </main>
   
  </div>
</template>


<script setup>
import { onMounted, ref } from "vue";
import getCollection from '@/composables/getCollection';
import getDescriptionsById from '@/composables/getDescriptionsById';
import getUser from '@/composables/getUser';


import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ArrowPathIcon } from '@heroicons/vue/24/outline'
// ArrowPath

// import Navbar from '@/components/Navbar.vue';
import Navbar from '@/components/Navbar.vue';
import DescriptionComponentList  from '@/components/descriptionComponent.vue';
import TableComponent  from '@/components/TableComponent.vue';
import Wsparcie  from '@/components/Wsparcie.vue';



const version = '20.01.2025'
const { user } = getUser();
const myUid = user.value.uid;
const { documents: doneDescriptions } = getCollection('descriptions', ['userUID', '==', myUid], 5, true); //
const refreshMonit = ref(false);


const open = ref(false)



      onMounted(async () => {
      try {
            const config = await getDescriptionsById("config", "config"); 

            if (config) {
              // console.log("config.version: ", config.version);
              // console.log("web version: ", version);
              if (version !== config.version) {
                // console.log("Version mismatch");
                open.value = true;
              }
            } 
      } catch (err) {
        console.error("Error in onMounted: ", err);
      }
      })


      const refreshPage = () => {
        window.location.reload();
      }      



</script>

<script>
export default {
   name: 'Home',
   components: { Navbar, DescriptionComponentList, TableComponent  },
};
</script>


